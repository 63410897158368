import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { AxiosProgressEvent } from 'axios';

export type UploadResultVideo = {
  urlVideo: string;
  urlImage: string;
};

export type UploadResultImage = {
  url: string;
};

interface UploadConfig {
  formData: FormData;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  key?: string;
}

export const uploadFile = createAsyncThunk(
  'file/uploadVideo',
  async (config: UploadConfig) => {
    const { formData, onUploadProgress } = config;
    try {
      const response = await apiRequest<UploadResultVideo>(
        'POST',
        '/file/uploadVideo',
        undefined,
        formData,
        onUploadProgress,
      );

      return response;
    } catch (error) {
      throw error;
    }
  },
);

export const uploadFileImage = createAsyncThunk(
  'file/upload',
  async (config: UploadConfig) => {
    const { formData, onUploadProgress } = config;
    try {
      const response = await apiRequest<UploadResultImage>(
        'POST',
        '/file/upload',
        undefined,
        formData,
        onUploadProgress,
      );

      return response;
    } catch (error) {
      throw error;
    }
  },
);

export const removeFile = createAsyncThunk(
  'file/remove',
  async (key: string) => {
    try {
      const response = await apiRequest<void>(
        'POST',
        '/file/remove',
        undefined,
        { key },
      );

      return response;
    } catch (error) {
      throw error;
    }
  },
);
