import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Dialog, TextField, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { deleteTestimonial, Testimonial } from 'src/slices/testimonial';
import {
  SideBarHandleContext,
  TestimonialEditionHandleContext,
} from 'src/hooks/context';
import { theme } from '../../constants/theme';
import { User } from 'src/slices/auth';
import { useAppSelector } from 'src/hooks/store';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { handleNotification } from '../../helpers/notifications';
import { useAppDispatch } from 'src/hooks/store';
import { useSnackbar } from 'notistack';
import MouseIcon from '@mui/icons-material/Mouse';
import CompanyIcon from '../../assets/company.svg';
import ReactPlayer from 'react-player';
import VideoIcon from '../../assets/video-icon.png';
import CircleIcon from '@mui/icons-material/Circle';
import ButtonBase from '@mui/material/ButtonBase';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import TestimonialCard from './TestimonialCard/TestimonialCard';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '../../assets/delete-icon.png';

const useStyles = createUseStyles({
  testimonialCardMoreAction: {},
  testimonialCardMoreActionMenu: {},
  testimonialCardActions: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    fontSize: '.8em',
    paddingTop: '0px',
  },
  testimonialCardActionsEdit: { fontSize: 'inherit' },
  testimonialCardActionsPublish: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardActionsUnpublish: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardActionsArchive: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardActionsUnarchive: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardActionsDelete: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialComponent: {
    width: '1200px',
    height: '80px',
    display: 'flex',
    background: '#FFFFFF',
    borderRadius: '20px',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '22px',
    gap: '20px',
    marginRight: '50px',
  },
  idleButtonImage: {
    width: '45px',
    height: '45px',
  },
  testimonialsContent: {
    width: '272px',
    height: '50px',
    background: '#F7F8FD',
    borderRadius: '10px',
    padding: '10px 15px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#000000',
  },
  testimonialsContentVideo: {
    top: '15px',
    opacity: '0.6',
    background: '#F7F8FD',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  videoIconContainer: {
    width: '27px',
    height: '27px',
    borderRadius: '50%',
  },
  stateContainer: {
    width: '176px',
    height: '66px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px',
  },
  state: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '10px',
    width: '126px',
    height: '23px',
  },
  publicationPageContainer: {
    top: '17px',
    width: '213px',
    padding: '15px',
  },
  typographyState: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '23px',
    color: '#273EAC',
  },
  publicationPageButton: {
    display: 'flex',
    alignItem: 'flex-start',
    flexDirection: 'row',
    padding: '0px',
    gap: '1px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '23px',
    color: '#273EAC',
  },
  statisticsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '25px',
    gap: '25px',
    width: '153px',
    height: '24px',
  },
  statisticsItem: {
    gap: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreOptionContainer: {
    gap: '15px',
    width: '134px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '25px',
  },
  moreOptionButton: {
    border: '1px solid #273EAC',
    borderRadius: '50%',
    width: '34px',
    height: '34px',
  },
  deleteDialog: {
    zIndex: 3000,
    '& .MuiDialog-paper': {
      textAlign: 'center',
      overflow: 'hidden',
      zIndex: '2000',
      padding: '50px',
    },
  },
  dialogContainer: {
    position: 'relative',
    display: 'flex',
    width: '360px',
    alignSelf: 'center',
    flexDirection: 'column',
    paddingTop: '24px',
    gap: '25px',
    alignItems: 'center',
  },
  dialogDeleteTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#060640',
    flex: 1,
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '140%',
    color: '#060640',
    flex: 1,
  },
  dialogContent: {
    position: 'relative',
    top: '85px',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldDeletePage: {
    alignItems: 'center',
    flex: 1,
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },
    '& .MuiOutlinedInput-root': {
      width: '350px',
      borderRadius: '8px',
      border: '1px solid #8F93B8',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #8F93B8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #8F93B8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  dialogActionDeleteButton: {
    borderRadius: '8px',
    width: '123px',
    padding: '10px',
    flex: 1,
  },
  videoPlayerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '276px',
  },
});

interface Props {
  testimonial: Testimonial;
  disableAll?: boolean;
}

const TestimonialComponent: React.FC<Props> = ({ testimonial, disableAll }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const sidebarContext = useContext(SideBarHandleContext);
  const testimonialEditionContext = useContext(TestimonialEditionHandleContext);
  const dispatch = useAppDispatch();
  const [deleteTemp, setDeleteTemp] = React.useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const editTestimonial = () => {
    if (testimonialEditionContext) {
      testimonialEditionContext.setNewTestimonial(false);
      sidebarContext?.setOpenTestimonialEdition(true);
      testimonialEditionContext.setTestimonial(testimonial);
    }
  };

  const [totalHoverNumber, setTotalHoverNumber] = useState<number>(0);
  const [totalClickNumber, setTotalClickNumber] = useState<number>(0);

  useEffect(() => {
    let totalHoverNumberTemp = 0;
    let totalClickNumberTemp = 0;
    testimonial?.displayProperties?.forEach((display) => {
      totalHoverNumberTemp += display.hoverNumber;
      totalClickNumberTemp += display.clickedNumber;
    });
    totalHoverNumberTemp += testimonial?.statistics.hoverNumber;
    totalClickNumberTemp += testimonial?.statistics.clickedNumber;

    setTotalHoverNumber(totalHoverNumberTemp);
    setTotalClickNumber(totalClickNumberTemp);
  }, [testimonial]);

  const confirmDeleteTestimonial = async () => {
    setOpenDeleteDialog(false);
    await handleNotification(
      dispatch(deleteTestimonial(deleteTestimonialDialog)),
      t('pages.Testimonials.successDeleteTestimonial'),
      t('pages.Testimonials.errorDeleteTestimonial'),
      enqueueSnackbar,
      closeSnackbar,
    );

    setDeleteTestimonialDialog('');
  };

  const deleteTestimonialById = async (testimonialId: string) => {
    setOpenDeleteDialog(true);
    setDeleteTestimonialDialog(testimonialId);
  };

  const [deleteTestimonialDialog, setDeleteTestimonialDialog] =
    useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [content, setContent] = useState<string>(
    testimonial.content ? testimonial.content : '',
  );

  useEffect(() => {
    if (testimonial.content) {
      if (testimonial.content.length > 30) {
        setContent(testimonial.content.slice(0, 50) + '...');
      }
    }
  }, [testimonial.content]);

  const { user }: { user: User | null } = useAppSelector(
    ({ auth: { user } }) => ({
      user,
    }),
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      minWidth: 'fit-content',
      minHeight: 'fit-content',
      background: '#F7F8FD',
      boxShadow: '2px 2px 4px rgba(7, 7, 33, 0.14)',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      color: 'black',
      padding: '50px',
    },
  }));

  const HtmlTooltipStatistics = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ),
  )(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      minWidth: 'fit-content',
      minHeight: 'fit-content',
      background: '#F7F8FD',
      boxShadow: '2px 2px 4px rgba(7, 7, 33, 0.14)',
      borderRadius: '20px',
      color: 'black',
    },
  }));

  const [timeDuration, setTimeDuration] = useState<string>('00:00');

  const [testimonialContent, setTestimonialContent] = useState(() => {
    if (
      testimonial.content.includes('kudeo-documents.s3.eu-west-3.amazonaws.com')
    ) {
      return testimonial.content.replace(
        'kudeo-documents.s3.eu-west-3.amazonaws.com',
        'd2t50mqmhcn624.cloudfront.net',
      );
    } else {
      return testimonial.content;
    }
  });

  useEffect(() => {
    if (
      testimonial.content.includes('kudeo-documents.s3.eu-west-3.amazonaws.com')
    ) {
      setTestimonialContent(
        testimonial.content.replace(
          'kudeo-documents.s3.eu-west-3.amazonaws.com',
          'd2t50mqmhcn624.cloudfront.net',
        ),
      );
    } else {
      setTestimonialContent(testimonial.content);
    }
  }, [testimonial.content]);

  const ColoredLine = (color: string) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: '63px',
        width: '1px',
        border: 'none',
      }}
    />
  );

  return (
    <div>
      <div
        className={classes.testimonialComponent}
        style={disableAll ? { pointerEvents: 'none' } : {}}
      >
        <HtmlTooltip
          title={
            <React.Fragment>
              <TestimonialCard
                key={testimonial._id}
                displayPreferences={testimonial.displayPreferences}
                createdAt={testimonial.createdAt?.split('T')[0]}
                content={testimonialContent}
                details={testimonial.details ?? ''}
                callToActionText={testimonial.callToActionText}
                mainColor={user?.settings.pluginSettings.mainColor}
                secondaryColor={user?.settings.pluginSettings.secondaryColor}
                testimonialType={testimonial.testimonialType}
                information={testimonial.information}
                logo={testimonial.logo}
                picture={testimonial.picture}
              />
            </React.Fragment>
          }
        >
          <ButtonBase>
            <VisibilityIcon
              style={{
                color: '#8F93B8',
              }}
            />
          </ButtonBase>
        </HtmlTooltip>

        <Avatar
          alt="Company logo"
          src={
            testimonial?.logo && testimonial.displayPreferences.showCompanyLogo
              ? testimonial.logo
              : CompanyIcon
          }
          aria-label="company logo"
          className={classes.idleButtonImage}
          sx={
            !testimonial.logo || !testimonial.displayPreferences.showCompanyLogo
              ? {
                  backgroundColor: '#FFD600',
                  '& img': {
                    width: '18.75px',
                    height: '18.75px',
                  },
                }
              : {}
          }
        />
        {testimonial.testimonialType === 'video' ? (
          <div className={classes.videoPlayerContainer}>
            <ReactPlayer
              url={testimonialContent}
              playing={false}
              loop={false}
              volume={0}
              width={'80px'}
              height={'50px'}
              className={classes.testimonialsContentVideo}
              onDuration={(time) => {
                const minutes = Math.floor(time / 60);
                const seconds = Math.trunc(time % 60);
                if (minutes < 9) {
                  if (seconds < 9) {
                    setTimeDuration(
                      '0'
                        .concat(minutes.toString())
                        .concat(':0')
                        .concat(seconds.toString()),
                    );
                  } else {
                    setTimeDuration(
                      '0'
                        .concat(minutes.toString())
                        .concat(':')
                        .concat(seconds.toString()),
                    );
                  }
                } else {
                  if (seconds < 9) {
                    setTimeDuration(
                      minutes
                        .toString()
                        .concat(':0')
                        .concat(seconds.toString()),
                    );
                  } else {
                    setTimeDuration(
                      minutes.toString().concat(':').concat(seconds.toString()),
                    );
                  }
                }
              }}
            />
            <img src={VideoIcon} className={classes.videoIconContainer} />
            <Typography>{timeDuration}</Typography>
          </div>
        ) : (
          <div className={classes.testimonialsContent}>« {content} »</div>
        )}

        {ColoredLine('#E9EAF4')}

        <div className={classes.stateContainer}>
          {t('pages.Testimonials.state')}

          {testimonial.tags.length ? (
            <div className={classes.state}>
              <CircleIcon
                style={{ width: '13px', height: '13px', color: '#23D796' }}
              />
              <Typography className={classes.typographyState}>
                {t('pages.Testimonials.published')}
              </Typography>
            </div>
          ) : (
            <div className={classes.state}>
              <CircleIcon
                style={{ width: '13px', height: '13px', color: '#FFD600' }}
              />
              <Typography className={classes.typographyState}>
                {t('pages.Testimonials.unpublished')}
              </Typography>
            </div>
          )}
        </div>
        {ColoredLine('#E9EAF4')}
        <div className={classes.publicationPageContainer}>
          {t('pages.Testimonials.pagePublication')}

          {testimonial.tags.length ? (
            <>
              <ButtonBase
                className={classes.publicationPageButton}
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                {testimonial.tags.length}{' '}
                {testimonial.tags.length > 1
                  ? t('pages.Testimonials.pages')
                  : t('pages.Testimonials.page')}
                <ArrowDropDownIcon />
              </ButtonBase>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                style={{ zIndex: '2000' }}
              >
                {testimonial.tags.map((tag, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={handleClose}
                      style={{ color: '#273EAC' }}
                    >
                      {tag}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          ) : (
            <div className={classes.publicationPageButton}>
              {t('pages.Testimonials.noPage')}
            </div>
          )}
        </div>
        {ColoredLine('#E9EAF4')}
        <div className={classes.statisticsContainer}>
          <HtmlTooltipStatistics
            title={t('pages.Testimonials.hoverNumber') as string}
          >
            <div className={classes.statisticsItem}>
              <VisibilityIcon
                style={{ color: '#273EAC', width: '20px', height: '20px' }}
              />
              {totalHoverNumber}
            </div>
          </HtmlTooltipStatistics>
          <HtmlTooltipStatistics
            title={t('pages.Testimonials.clickedNumber') as string}
          >
            <div className={classes.statisticsItem}>
              <MouseIcon
                style={{ color: '#273EAC', width: '20px', height: '20px' }}
              />
              {totalClickNumber}
            </div>
          </HtmlTooltipStatistics>
        </div>

        {ColoredLine('#E9EAF4')}

        <div className={classes.moreOptionContainer}>
          <ButtonBase
            className={classes.moreOptionButton}
            onClick={() => {
              editTestimonial();
            }}
          >
            <BorderColorIcon
              style={{ width: '15px', height: '15px', color: '#273EAC' }}
            />
          </ButtonBase>
          <ButtonBase
            className={classes.moreOptionButton}
            onClick={() => {
              deleteTestimonialById(testimonial._id);
            }}
          >
            <img
              src={DeleteIcon}
              style={{ width: '14px', height: '14px', color: '#273EAC' }}
            />
          </ButtonBase>
        </div>
      </div>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDeleteTestimonialDialog('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.deleteDialog}
      >
        <ButtonBase
          style={{ position: 'absolute', right: '10px', top: '10px' }}
          onClick={() => {
            setOpenDeleteDialog(false);
            setDeleteTestimonialDialog('');
          }}
        >
          <CloseIcon style={{ color: '#414354', borderRadius: '50%' }} />
        </ButtonBase>

        <div id="alert-dialog-title" className={classes.dialogContainer}>
          <Typography className={classes.dialogTitle}>
            {t('pages.Testimonials.confirmDeleteTestimonialTitle')}
          </Typography>

          <Typography className={classes.dialogDeleteTypography}>
            {t('pages.Testimonials.confirmDeleteTestimonial')}
          </Typography>
          <TextField
            onChange={(e) => {
              if (e.target.value == 'DELETE') {
                setDeleteTemp(true);
              } else {
                setDeleteTemp(false);
              }
            }}
            className={classes.textFieldDeletePage}
            placeholder={t('pages.Testimonials.titleOfPage')}
          ></TextField>
          <ButtonBase
            onClick={() => {
              confirmDeleteTestimonial();
            }}
            className={classes.dialogActionDeleteButton}
            disabled={deleteTemp ? false : true}
            style={
              deleteTemp
                ? {
                    background: '#273EAC',
                    color: 'white',
                    border: '1px solid #273EAC',
                  }
                : {
                    background: 'none',
                    color: 'grey',
                    border: '1px solid grey',
                  }
            }
          >
            <DoneIcon />
            {t('common.validate')}
          </ButtonBase>
        </div>
      </Dialog>
    </div>
  );
};

export default TestimonialComponent;
