import { ButtonBase, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { TestimonialActionsHandleContext } from 'src/hooks/context';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  archiveTestimonial,
  fetchAllTestimonialsByUser,
  publishTestimonial,
  Testimonial,
  unarchiveTestimonial,
  unpublishTestimonial,
} from 'src/slices/testimonial';
import { testimonialsSelector } from 'src/store';
import { theme } from 'src/constants/theme';
import PageList from '../components/testimonials/PageList';
import AddIcon from '@mui/icons-material/Add';

const useStyles = createUseStyles({
  testimonialsPage: {
    paddingTop: '16px',
    paddingLeft: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
  },
  testimonialsPageHeaderActions: {
    marginBottom: '15px',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  testimonialsPageHeaderActionsSearch: {},
  testimonialsPageHeaderActionsNew: {
    marginLeft: '30px',
  },
  testimonialsPageLoading: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  typographyTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '14px',
    color: '#060640',
  },
  buttonAddPage: {
    background: '#273EAC',
    borderRadius: '80px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    gap: '10px',
    color: 'white',
    position: 'absolute',
    right: '80px',
    top: '20px',
  },
  navigatorContainer: {
    display: 'flex',
    gap: '2px',
    paddingLeft: '30px',
  },
});

const Website = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listContainer = document.getElementById('listContainer');
  const { user, firstUserId } = useAppSelector(
    ({ auth: { user, firstUserId } }) => ({
      user,
      firstUserId,
    }),
  );

  const [addPageButtonClicked, setAddPageButtonClicked] = useState(false);

  const testimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );

  useEffect(() => {
    if (user) {
      dispatch(fetchAllTestimonialsByUser(user?._id));
    }
  }, [user]);

  const publishTestimonialById = async (testimonialId: string) => {
    dispatch(publishTestimonial(testimonialId));
  };

  const unpublishTestimonialById = async (testimonialId: string) => {
    dispatch(unpublishTestimonial(testimonialId));
  };

  const archiveTestimonialByID = async (testimonialId: string) => {
    dispatch(archiveTestimonial(testimonialId));
  };

  const unarchiveTestimonialByID = async (testimonialId: string) => {
    dispatch(unarchiveTestimonial(testimonialId));
  };
  const [isDisabled, setIsDisabled] = useState(false);

  const [disableAll, setDisableAll] = useState(false);

  useEffect(() => {
    if (user?._id != firstUserId) {
      setDisableAll(true);
    }
  });

  useEffect(() => {
    if (user?.limitation?.maxNumberOfPages) {
      setIsDisabled(
        user?.limitation?.maxNumberOfPages <
          user?.settings.pluginSettings.tagsList.length + 1,
      );
    }
  }, [user]);

  return (
    <>
      <div className={classes.testimonialsPage}>
        <div
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div className={classes.navigatorContainer}>
            <Typography className={classes.typographyTitle}>
              {t('common.website')}
            </Typography>
            <Typography className={classes.typographyTitle}>{'>'}</Typography>
            <Typography className={classes.typographyTitle}>
              {t('pages.Website.pages')}
            </Typography>
            <Typography className={classes.typographyTitle}>
              {'(' + user?.settings.pluginSettings.tagsList.length + ')'}
            </Typography>
          </div>

          {user && user.settings.pluginSettings.tagsList.length > 2 && (
            <ButtonBase
              className={classes.buttonAddPage}
              onClick={() => {
                if (listContainer) {
                  listContainer.scrollBy({
                    top: 0,
                    left: listContainer.scrollWidth,
                    behavior: 'smooth',
                  });
                  setAddPageButtonClicked(true);
                }
              }}
              style={isDisabled ? { backgroundColor: 'grey' } : {}}
              disabled={isDisabled}
            >
              <AddIcon />
              <Typography> {t('pages.Testimonials.addPage')}</Typography>
            </ButtonBase>
          )}
        </div>
        <TestimonialActionsHandleContext.Provider
          value={{
            publishTestimonial: publishTestimonialById,
            unpublishTestimonial: unpublishTestimonialById,
            archiveTestimonial: archiveTestimonialByID,
            unarchiveTestimonial: unarchiveTestimonialByID,
          }}
        >
          <PageList
            testimonials={testimonials}
            allTestimonials={testimonials}
            user={user}
            addPageButtonClicked={addPageButtonClicked}
            setAddPageButtonClicked={setAddPageButtonClicked}
            disableAll={disableAll}
          />
        </TestimonialActionsHandleContext.Provider>
      </div>
    </>
  );
};

export default Website;
