import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { fetchUserById, User } from 'src/slices/auth';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LoginIcon from '@mui/icons-material/Login';
import { Flex } from './Flex';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TextFieldWrapper } from './TextFieldWrapper';
import { Tag } from '../Tag';
import { useDispatch } from 'react-redux';
import {
  fetchAllTestimonialsByUser,
  Testimonial,
} from '../../slices/testimonial';
import { useAppSelector } from '../../hooks/store';
import { AppDispatch, testimonialsSelector } from '../../store';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import { retrieveInvoices } from 'src/slices/payment';
const useStyles = createUseStyles({
  profileContainer: {
    color: 'rgb(97, 97, 97)',
    padding: theme.spacing(3),
    backgroundColor: 'rgb(250, 250, 250)',
  },
  profileHeader: {
    '& .MuiCardHeader-action': {
      alignSelf: 'center',
    },
  },
});

interface Props {
  user: User;
  addAdmin: (userId: string) => void;
  undoAdminAccountFunction: (userId: string) => void;
  adminDeleteAccount: (userId: string) => void;
  updateCompanyRole: (
    userId: string,
    values: { company?: string; position?: string },
  ) => void;
  activateAccount: (userId: string) => void;
  updateEarlyAccessFunction: (userId: string, earlyAccess: boolean) => void;
  changeSubscription: (userId: string, subscriptionType: string) => void;
  changePluginPosition: (userId: string, pluginPosition: string) => void;
  changeUserEmailAndPassword: (userId: string) => void;
  changeUserLimit: (userId: string) => void;
  setMaxLimitePages: React.Dispatch<React.SetStateAction<number>>;
  setMaxLimiteVideos: React.Dispatch<React.SetStateAction<number>>;
  setMaxLimiteDomainUrl: React.Dispatch<React.SetStateAction<number>>;
  handleOpenTestimonialsDialog: (testimonials: Testimonial[]) => void;
  handleOpenProfilCardDialog: () => void;
  changeZindex: (userId: string, index: number) => void;
  changeStepTutorial: (userId: string) => void;
  changeAccountType: (userId: string) => void;
  addChildrenToParentProfileCard: (userId: string) => void;
  setMaxVideoHosted: React.Dispatch<React.SetStateAction<number>>;
  setMaxNumberOfDisplayedTestimonials: React.Dispatch<
    React.SetStateAction<number>
  >;
  hideKudeoButtonFunction: (userId: string, value: boolean) => void;
  openEngagementDialogFunction: (
    userId: string,
    paymentInterval?: string,
  ) => void;
}

const ProfileCard: React.FC<Props> = ({
  user,
  addAdmin,
  adminDeleteAccount,
  updateCompanyRole,
  activateAccount,
  changeSubscription,
  changePluginPosition,
  changeUserEmailAndPassword,
  changeUserLimit,
  setMaxLimitePages,
  setMaxLimiteVideos,
  setMaxLimiteDomainUrl,
  handleOpenTestimonialsDialog,
  handleOpenProfilCardDialog,
  changeZindex,
  changeStepTutorial,
  changeAccountType,
  addChildrenToParentProfileCard,
  undoAdminAccountFunction,
  setMaxVideoHosted,
  setMaxNumberOfDisplayedTestimonials,
  hideKudeoButtonFunction,
  updateEarlyAccessFunction,
  openEngagementDialogFunction,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [onHoverTotalNumber, setOnHoverTotalNumber] = useState<number>(0);
  const [onClickTotalNumber, setOnClickTotalNumber] = useState<number>(0);
  const [editCompanyRole, setEditCompanyRole] = useState<boolean>(false);

  const testimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );

  useEffect(() => {
    dispatch(fetchAllTestimonialsByUser(user._id));
  }, [user]);

  useEffect(() => {
    if (user?.limitation) {
      setMaxLimitePages(user.limitation.maxNumberOfPages);
      setMaxLimiteVideos(user.limitation.maxNumberOfVideos);
      setMaxLimiteDomainUrl(user.limitation.maxDomainUrl);
      setMaxVideoHosted(user.limitation.maxNumberOfVideoHosted ?? 0);
      setMaxNumberOfDisplayedTestimonials(
        user.limitation.maxNumberOfDisplayedTestimonials ?? 0,
      );
    }
  }, [user]);

  useEffect(() => {
    let tempHoverTotal = 0;
    let tempClickTotal = 0;
    testimonials.map((testimonial) => {
      tempHoverTotal += testimonial.statistics.hoverNumber;
      tempClickTotal += testimonial.statistics.clickedNumber;

      if (testimonial.displayProperties) {
        testimonial.displayProperties.map((temp) => {
          tempHoverTotal += temp.hoverNumber;
          tempClickTotal += temp.clickedNumber;
        });
      }
    });
    setOnHoverTotalNumber(tempHoverTotal);
    setOnClickTotalNumber(tempClickTotal);
  }, [testimonials]);

  useEffect(() => {
    return () => {
      resetEdit();
    };
  }, []);

  const formikValidationSchema = yup.object({
    company: yup.string(),
    position: yup.string(),
  });

  const retrieveInvoicesFunction = async (customerId?: string) => {
    if (customerId)
      dispatch(retrieveInvoices(customerId)).then((result: any) => {
        window.open(result.payload, '_blank');
      });
  };

  const initialValues = {
    company: '',
    position: '',
  };

  const dispatch = useDispatch<AppDispatch>();

  const formikOnSubmit = async (values: {
    company: string;
    position: string;
  }) => {
    const updatedValues = {
      company:
        values.company === initialValues.company ? undefined : values.company,
      position:
        values.position === initialValues.position
          ? undefined
          : values.position,
    };

    updateCompanyRole(user._id, updatedValues);
    resetEdit();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formikValidationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: formikOnSubmit,
  });

  const userRegisterDate = new Date(user.createdAt ?? Date.now());
  const userLastConnectionDate = user.stats.lastConnection
    ? new Date(user.stats.lastConnection)
    : userRegisterDate;

  const resetEdit = async () => {
    formik.resetForm();
    formik.setValues(initialValues);
    setEditCompanyRole(false);
  };

  const goToAccount = async (id: string) => {
    dispatch(fetchUserById(id));
    handleOpenProfilCardDialog();
  };

  return (
    <>
      <Card className={classes.profileContainer}>
        <CardHeader
          className={classes.profileHeader}
          avatar={
            <Avatar
              src={user.profilePictureUrl}
              style={{ marginBottom: theme.spacing(1), width: 60, height: 60 }}
            />
          }
          title={<div>{user.firstName + ' ' + user.lastName}</div>}
          subheader={<div>{user.email}</div>}
          action={
            user.isAdmin ? (
              <>
                <AdminPanelSettingsIcon color="primary" />
              </>
            ) : (
              <></>
            )
          }
        />
        <CardContent>
          {user.stripeCustomerId != undefined && (
            <Button
              onClick={() => retrieveInvoicesFunction(user.stripeCustomerId)}
            >
              Retrieves Invoices
            </Button>
          )}
          <Grid container spacing={2}>
            {(user.company || user.position) && (
              <Grid item xs={12}>
                <Flex style={{ alignItems: 'center' }}>
                  <div>
                    <Typography component="div" variant="h5">
                      {user.company ?? ''}
                    </Typography>
                    <Typography variant="body2" color="#637381" component="div">
                      {user.position ?? ''}
                    </Typography>
                  </div>
                  <IconButton style={{ marginLeft: theme.spacing(3) }}>
                    <EditIcon
                      onClick={() => setEditCompanyRole(!editCompanyRole)}
                    />
                  </IconButton>
                </Flex>

                {editCompanyRole && (
                  <div style={{ marginTop: theme.spacing(3) }}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      sx={{ margin: theme.spacing(1, 0, 0.5, 0) }}
                      spacing={2}
                    >
                      <TextFieldWrapper
                        id="company"
                        label={`${t('common.company')}`}
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="company"
                        placeholder={user.company}
                        InputLabelProps={{ shrink: true }}
                        value={formik.values.company}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.company &&
                          Boolean(formik.errors.company)
                        }
                        helperText={
                          formik.touched.company && formik.errors.company
                        }
                      />

                      <TextFieldWrapper
                        id="position"
                        label={`${t('common.position')}`}
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="position"
                        placeholder={user.position}
                        InputLabelProps={{ shrink: true }}
                        value={formik.values.position}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.position &&
                          Boolean(formik.errors.position)
                        }
                        helperText={
                          formik.touched.position && formik.errors.position
                        }
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      sx={{ margin: theme.spacing(2, 0, 0.5, 0) }}
                      spacing={1}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => resetEdit()}
                        fullWidth
                      >
                        {t('common.cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => formik.submitForm()}
                        fullWidth
                      >
                        {t('common.save')}
                      </Button>
                    </Stack>
                  </div>
                )}
              </Grid>
            )}

            {(user.settings.pluginSettings.websiteUrl ||
              user.settings.pluginSettings.tagsList) && (
              <Grid item xs={12}>
                <Typography component="div" variant="h5">
                  Plugin
                </Typography>

                {user.settings.pluginSettings.websiteUrl && (
                  <div>
                    {t('pages.Admin.website')}
                    {' : '}
                    <a
                      href={user.settings.pluginSettings.websiteUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {user.settings.pluginSettings.websiteUrl}
                    </a>
                  </div>
                )}
                {user.settings.pluginSettings.tagsList && (
                  <div>
                    {t('common.tags')}
                    {' : '}
                    {user.settings.pluginSettings.tagsList.length &&
                      user.settings.pluginSettings.tagsList.map((tags) => (
                        <Tag key={tags.tag}>{tags.tag}</Tag>
                      ))}
                  </div>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography component="div" variant="h5">
                {t('pages.Admin.stats')}
              </Typography>
              <div>{'Témoignages : ' + user.testimonials.length}</div>
              <div>{'Click : ' + onClickTotalNumber}</div>
              <div>{'Survol : ' + onHoverTotalNumber}</div>
              <div>{'Tutorial Step : ' + user.step}</div>
              <Button
                onClick={() => handleOpenTestimonialsDialog(testimonials)}
              >
                Show all
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" variant="h5">
                {t('pages.Admin.userInfo')}
              </Typography>
              <div>
                {"Date d'inscription : " +
                  userRegisterDate.toLocaleDateString() +
                  '-' +
                  userRegisterDate.toLocaleTimeString()}
              </div>
              <div>
                {'Dernière connexion : ' +
                  userLastConnectionDate.toLocaleDateString() +
                  '-' +
                  userLastConnectionDate.toLocaleTimeString()}
              </div>
              <div>
                {'Nombre de connexion : ' +
                  (user.stats?.numberOfConnections ?? 0)}
              </div>
              <div>{'Type de compte : ' + (user.accountType ?? 'normal')}</div>
              <div>
                {'Z-INDEX : ' + (user.settings.pluginSettings.zIndex ?? '999')}
              </div>
              <div>{'Utilisation de Kudeo : ' + user.whyUseKudeo}</div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: 'right',
            alignItems: 'stretch',
            display: 'block',
          }}
        >
          {!user.isActiveAccount ? (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ManageAccountsIcon />}
              onClick={() => activateAccount(user._id)}
            >
              {t('pages.Admin.activateAccount')}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="error"
              startIcon={<ManageAccountsIcon />}
              onClick={() => activateAccount(user._id)}
            >
              {t('pages.Admin.deactivateAccount')}
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => changeSubscription(user._id, user.subscriptionType)}
          >
            {t('pages.Admin.changeSubscription')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() =>
              changeZindex(user._id, user.settings.pluginSettings.zIndex ?? 999)
            }
          >
            Modifier le Z-INDEX
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<LoginIcon />}
            onClick={() => goToAccount(user._id)}
          >
            {'Entrer dans le compte'}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() =>
              changePluginPosition(
                user._id,
                user.settings.pluginSettings.position ?? 'bottom',
              )
            }
          >
            {'Modifier la position du plugin'}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => changeUserEmailAndPassword(user._id)}
          >
            {'Modifier le user'}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => changeUserLimit(user._id)}
          >
            {'Modifier les limites du User'}
          </Button>
          <Button
            variant="outlined"
            color={user.isAdmin ? 'error' : 'primary'}
            startIcon={<AdminPanelSettingsIcon />}
            onClick={() => {
              if (user.isAdmin) {
                undoAdminAccountFunction(user._id);
                console.log('here');
              } else {
                addAdmin(user._id);
              }
            }}
          >
            {user?.isAdmin
              ? t('pages.Admin.removeAdmin')
              : t('pages.Admin.addAdmin')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => changeStepTutorial(user._id)}
          >
            Modifier l&apos;étape du tutoriel
          </Button>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => changeAccountType(user._id)}
          >
            Ajouter le type du compte en compte parent
          </Button>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => addChildrenToParentProfileCard(user._id)}
          >
            Ajouter a ce compte des comptes enfants
          </Button>

          <Button
            variant="outlined"
            color="primary"
            startIcon={
              user?.settings.pluginSettings.hideKudeoButton ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )
            }
            onClick={() =>
              hideKudeoButtonFunction(
                user._id,
                !user?.settings.pluginSettings.hideKudeoButton,
              )
            }
          >
            {user?.settings.pluginSettings.hideKudeoButton
              ? 'Afficher bouton Kudeo'
              : 'Masquer bouton Kudeo'}
          </Button>

          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteForeverIcon />}
            onClick={() => adminDeleteAccount(user._id)}
          >
            {t('common.delete')}
          </Button>

          {!user.earlyAccess ? (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<KeyIcon />}
              onClick={() => updateEarlyAccessFunction(user._id, true)}
            >
              Activer EarlyAccess
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="error"
              startIcon={<KeyOffIcon />}
              onClick={() => updateEarlyAccessFunction(user._id, false)}
            >
              Désactiver EarlyAccess
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() =>
              openEngagementDialogFunction(user._id, user?.paymentInterval)
            }
          >
            Modifier l&apos;engagement
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default ProfileCard;
