import { styled } from '@mui/system';

export const Tag = styled('div')({
  boxSizing: 'border-box',
  margin: '3px',
  fontVariant: 'tabular-nums',
  lineHeight: '1.5',
  listStyle: 'none',
  fontFeatureSettings: 'tnum',
  display: 'inline-block',
  height: 'auto',
  padding: '0 7px',
  whiteSpace: 'nowrap',
  border: '1px solid',
  borderRadius: '2px',
  opacity: '1',
  transition: 'all .3s',
  color: '#096dd9',
  background: '#e6f7ff',
  borderColor: '#91d5ff',
});
