import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ReactPlayer from 'react-player';
import { ButtonBase } from '@mui/material';

const useStyles = createUseStyles({
  bubbleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '13px',
    flex: '0.3',
    alignItems: 'center',
  },

  video: {
    objectFit: 'cover',
    overflow: 'hidden',
  },
  testimonialScale: {
    transform: 'scale(0.95)',
    transition: 'all .15s',
    '&.mouseEnter': {
      transform: 'scale(1)',
    },
  },
  notOpened: {
    transform: 'scale(0.9)',
    opacity: '0.5',
  },
});

interface Props {
  borderRadiusBubble: number;
  outlineBubble: string;
  bubbleSize: string;
}

const TestimonialsBubblePreview: React.FC<Props> = ({
  borderRadiusBubble,
  outlineBubble,
  bubbleSize,
}) => {
  const classes = useStyles();
  const [size, setSize] = React.useState(56);

  const [mouseEnter, setMouseEnter] = useState<
    { index: number; state: boolean }[]
  >([
    { index: 0, state: false },
    { index: 1, state: false },
    { index: 2, state: false },
  ]);

  useEffect(() => {
    if (bubbleSize === 'small') {
      setSize(56);
    } else if (bubbleSize === 'medium') {
      setSize(63);
    } else if (bubbleSize === 'large') {
      setSize(70);
    }
  });

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    let isHere = false;
    mouseEnter.map((mouseenter) => {
      if (mouseenter.state === true) {
        isHere = true;
      }
    });
    setIsOpened(isHere);
  }, [mouseEnter]);

  return (
    <div className={classes.bubbleContainer}>
      {Array.from({ length: 3 }).map((_, index) => (
        <ButtonBase
          key={index}
          className={`${classes.testimonialScale} ${
            mouseEnter[index].state ? 'mouseEnter' : ''
          } ${
            isOpened && mouseEnter[index].state === false
              ? classes.notOpened
              : ''
          }`}
          style={
            outlineBubble != ''
              ? {
                  width: `${size - 4}px`,
                  height: `${size - 4}px`,
                }
              : { width: `${size}px`, height: `${size}px` }
          }
          onMouseEnter={() => {
            setMouseEnter((prevState) => {
              const newMouseEnter = [...prevState];
              newMouseEnter[index].state = true;
              return newMouseEnter;
            });
          }}
          onMouseLeave={() => {
            setMouseEnter((prevState) => {
              const newMouseEnter = [...prevState];
              newMouseEnter[index].state = false;
              return newMouseEnter;
            });
          }}
        >
          <ReactPlayer
            url={
              'https://d2t50mqmhcn624.cloudfront.net/3b2afa06b16d4876bca5d671c3434920'
            }
            playing={!mouseEnter[index].state}
            loop={true}
            volume={0}
            className={`${classes.video} ${classes.testimonialScale} ${
              mouseEnter[index].state ? 'mouseEnter' : ''
            }`}
            width={'100%'}
            height={'100%'}
            style={
              outlineBubble != ''
                ? {
                    borderRadius: `${borderRadiusBubble}%`,
                    outline: `2px solid ${outlineBubble}`,
                    outlineOffset: '2px',
                  }
                : {
                    borderRadius: `${borderRadiusBubble}%`,
                  }
            }
          />
        </ButtonBase>
      ))}
    </div>
  );
};

export default TestimonialsBubblePreview;
