import React, { useEffect } from 'react';

import { Container, Typography, Box } from '@mui/material';
import { AuthSocial } from '../components/authentication/';
import { useTranslation } from 'react-i18next';
import SignInForm from 'src/containers/authentication/SignInForm';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  signInContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
    borderRadius: '8px',
    paddingTop: '40px',
    width: '428px',
  },
  logo: {
    width: '138px',
    height: '42px',
    marginBottom: '24px',
    alignSelf: 'center',
  },

  signInToText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px',
    marginBottom: '40px',
    textAlign: 'center',
  },
  welcomeBackText: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '25px',
  },
  connectingText: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '25px',
  },
  iframeStyle: {
    display: 'none !important',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  logoCompany: {
    width: '215px',
    height: '88px',
  },
});
export const SignIn = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    // Apply styles to existing iframes on initial mount
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      iframe.classList.add(classes.iframeStyle);
    });

    // Create a MutationObserver to detect dynamically added iframes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeName === 'IFRAME') {
              const iframe = node as HTMLIFrameElement;
              iframe.classList.add(classes.iframeStyle);
              iframe.addEventListener('load', handleIframeLoad);
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Event listener callback to handle iframe load event
    const handleIframeLoad = (event: Event) => {
      const iframe = event.target as HTMLIFrameElement;
      iframe.classList.add(classes.iframeStyle);
    };

    // Cleanup function to remove event listeners
    return () => {
      observer.disconnect();

      iframes.forEach((iframe) => {
        iframe.removeEventListener('load', handleIframeLoad);
      });
    };
  }, [classes.iframeStyle]);

  return (
    <div
      title="SignIn | Kudeo"
      style={{
        height: '100%',
        backgroundImage: `url('/background.svg')`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Container className={classes.signInContainer}>
          <Box component="img" src="/Logo.svg" className={classes.logo} />

          <Typography variant="h4" className={classes.signInToText}>
            {t('pages.SignIn.welcomeBack')}
          </Typography>

          <AuthSocial />

          <SignInForm />
        </Container>
      </div>
    </div>
  );
};
