import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'src/components/Tabs';
import { createUseStyles } from 'react-jss';
import { TestimonialActionsHandleContext } from 'src/hooks/context';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  archiveTestimonial,
  fetchAllTestimonialsByUser,
  publishTestimonial,
  Testimonial,
  unarchiveTestimonial,
  unpublishTestimonial,
} from 'src/slices/testimonial';
import { testimonialsSelector } from 'src/store';
import TestimonialList from '../components/testimonials/TestimonialList';
import { theme } from 'src/constants/theme';

const useStyles = createUseStyles({
  testimonialsPage: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    paddingLeft: '20px',
  },
  testimonialsPageLoading: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  addTestimonialButton: {
    background: '#273EAC',
    borderRadius: '80px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    gap: '10px',
    color: 'white',
  },
  addTestimonialTypography: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#FFFFFF',
  },
});

const Testimonials = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const testimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );

  const { user, firstUserId } = useAppSelector(
    ({ auth: { user, firstUserId } }) => ({
      user,
      firstUserId,
    }),
  );

  const [disableAll, setDisableAll] = useState(false);

  useEffect(() => {
    if (user?._id != firstUserId) {
      setDisableAll(true);
    }
  });

  useEffect(() => {
    if (user) {
      dispatch(fetchAllTestimonialsByUser(user?._id));
    }
  }, [user]);

  const [tabsTestimonials, setTabsTestimonials] = useState<Testimonial[]>([]);
  const [filteredTestimonials, setFilteredTestimonials] = useState<
    Testimonial[]
  >([]);

  const [value, setValue] = useState(0);
  const changeTab = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setFilteredTestimonials(tabsTestimonials);
  }, [tabsTestimonials, testimonials]);

  const tabs = ['all', 'written', 'video'];

  useEffect(() => {
    setTabsTestimonials(
      testimonials.filter((testimonial) => {
        if (tabs[value] === 'written') {
          return testimonial.testimonialType != 'video';
        } else if (tabs[value] === 'video') {
          return testimonial.testimonialType === 'video';
        } else if (tabs[value] === 'all') {
          return true;
        }
      }),
    );
  }, [testimonials, value]);

  const publishTestimonialById = async (testimonialId: string) => {
    dispatch(publishTestimonial(testimonialId));
  };

  const unpublishTestimonialById = async (testimonialId: string) => {
    dispatch(unpublishTestimonial(testimonialId));
  };

  const archiveTestimonialByID = async (testimonialId: string) => {
    dispatch(archiveTestimonial(testimonialId));
  };

  const unarchiveTestimonialByID = async (testimonialId: string) => {
    dispatch(unarchiveTestimonial(testimonialId));
  };

  return (
    <>
      <div className={classes.testimonialsPage}>
        <Tabs
          value={value}
          changeTab={(_, newValue) => changeTab(newValue)}
          tabsValue={tabs.map(
            (tab) =>
              `${t(`pages.Testimonials.types.${tab}`)} (${
                testimonials.filter((testimonial) => {
                  if (tab === 'all') {
                    return true;
                  } else if (tab === 'video') {
                    return testimonial.testimonialType === 'video';
                  } else if (tab === 'written') {
                    return testimonial.testimonialType != 'video';
                  }
                }).length
              })`,
          )}
        />

        <TestimonialActionsHandleContext.Provider
          value={{
            publishTestimonial: publishTestimonialById,
            unpublishTestimonial: unpublishTestimonialById,
            archiveTestimonial: archiveTestimonialByID,
            unarchiveTestimonial: unarchiveTestimonialByID,
          }}
        >
          <TestimonialList
            testimonials={filteredTestimonials}
            disableAll={disableAll}
          />
        </TestimonialActionsHandleContext.Provider>
      </div>
    </>
  );
};

export default Testimonials;
