import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useAppDispatch } from 'src/hooks/store';
import { sendLostPassword, useLostPasswordToken } from 'src/slices/auth';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { TextFieldWrapper } from 'src/components/basic/TextFieldWrapper';

const useStyles = createUseStyles({
  box: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
  },
  boxWide: {
    minWidth: '550px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    '&:first-child': {
      marginTop: '0px',
    },
    '&:last-child': {
      marginBottom: '0px',
    },
    marginTop: '12px',
    marginBottom: '12px',
  },
  error: {
    color: '#ff4d4f',
  },
  lostPasswordFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  registerSubmitButton: {
    background: '#273EAC',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.4px',
    marginBottom: '25px',
  },
  lostPasswordTextField: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },

    '& .MuiOutlinedInput-root': {
      height: '40px',
      borderRadius: '8px',
      border: '1px solid #CED3E8',
      background: 'white',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #CED3E8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #CED3E8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const tailFormItemLayout = {
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 14,
    offset: 10,
  },
};

const LostPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useNavigate();
  const { search } = useLocation();
  const { token }: any = queryString.parse(search);
  const { t } = useTranslation();

  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState(false);

  const validationSchema = yup.object(
    token
      ? {
          password: yup.string().min(10).required(t('forms.rules.required')),
          confirmPassword: yup
            .string()
            .oneOf(
              [yup.ref('password')],
              t('forms.errorConfirmPasswordNotMatch'),
            )
            .required(t('forms.rules.required')),
        }
      : {
          email: yup
            .string()
            .email(t('forms.rules.email'))
            .required(t('forms.rules.required')),
        },
  );

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  const submitForm = async (values: {
    password: string;
    email: string;
    confirmPassword: string;
  }) => {
    if (error) {
      setError(false);
    }

    const resultAction = await (token
      ? dispatch(useLostPasswordToken({ token, password: values.password }))
      : dispatch(sendLostPassword({ email: values.email })));

    if (
      (token ? useLostPasswordToken : sendLostPassword).rejected.match(
        resultAction,
      )
    ) {
      setError(true);
    } else {
      history('/');
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => submitForm(values),
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const renderSendLostPasswordFormItems = () => (
    <TextFieldWrapper
      fullWidth
      type="email"
      className={classes.lostPasswordTextField}
      placeholder={t('common.email')}
      {...getFieldProps('email')}
      error={Boolean(touched.email && errors.email)}
      helperText={touched.email && errors.email}
      style={{ marginBottom: '25px' }}
    />
  );

  const hidePasswordIcon = () => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setHidePassword(!hidePassword)}
        edge="end"
      >
        {hidePassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  const renderUseLostPasswordTokenFormItems = () => (
    <div className={classes.lostPasswordFormContainer}>
      <TextFieldWrapper
        className={classes.lostPasswordTextField}
        fullWidth
        type={hidePassword ? 'text' : 'password'}
        placeholder={t('common.password')}
        {...getFieldProps('password')}
        InputProps={{
          endAdornment: hidePasswordIcon(),
        }}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
      />
      <TextFieldWrapper
        className={classes.lostPasswordTextField}
        fullWidth
        type={hidePassword ? 'text' : 'password'}
        placeholder={t('forms.confirmPassword')}
        {...getFieldProps('confirmPassword')}
        InputProps={{
          endAdornment: hidePasswordIcon(),
        }}
        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
        helperText={touched.confirmPassword && errors.confirmPassword}
      />
    </div>
  );

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          className={classes.form}
          {...(!!token ? formItemLayout : {})}
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className={classes.lostPasswordFormContainer}>
            {!token
              ? renderSendLostPasswordFormItems()
              : renderUseLostPasswordTokenFormItems()}

            <div {...(!!token ? tailFormItemLayout : {})}>
              <LoadingButton
                className={classes.registerSubmitButton}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {t('common.submit')}
              </LoadingButton>
              {error && (
                <div className={classes.error}>
                  {t(token ? 'forms.errorBadToken' : 'common.errorGeneric')}
                  &nbsp;
                </div>
              )}
              <Typography
                variant="body2"
                align="center"
                sx={{ color: 'text.secondary', mt: 3 }}
              >
                <Link to="/" title={t('pages.SignUp.backToSignIn')} />
              </Typography>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

export default LostPassword;
