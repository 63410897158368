import React from 'react';
import { createUseStyles } from 'react-jss';
import { Box, Container } from '@mui/material';
import LostPasswordForm from 'src/containers/authentication/LostPasswordForm';

const useStyles = createUseStyles({
  registerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
    borderRadius: '8px',
    paddingTop: '40px',
    width: '428px',
  },
  logo: {
    width: '138px',
    height: '42px',
    marginBottom: '24px',
    alignSelf: 'center',
  },
  signInToText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px',
    marginBottom: '40px',
    textAlign: 'center',
  },
  iframeStyle: {
    display: 'none !important',
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  logoCompany: {
    width: '215px',
    height: '88px',
  },
});

export const LostPassword = () => {
  const classes = useStyles();

  return (
    <div
      title="Register | Kudeo"
      style={{
        height: '100%',
        backgroundImage: `url('/background.svg')`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Container className={classes.registerContainer}>
          <Box component="img" src="/Logo.svg" className={classes.logo} />
          <LostPasswordForm />
        </Container>
      </div>
    </div>
  );
};
