import React from 'react';
import TestimonialComponent from './TestimonialComponent';
import { createUseStyles } from 'react-jss';
import { Testimonial } from 'src/slices/testimonial';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/hooks/store';
import { User } from '../../slices/auth';
import { theme } from '../../constants/theme';
const MAX_WIDTH = '50rem';

const useStyles = createUseStyles({
  testimonialsNoContent: {
    margin: 'auto',
    color: '#637381',
    textAlign: 'center',
  },
  TestimonialListItem: {
    display: 'inline-block',
  },
  buttonWrapper: {
    background: 'white',
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingLeft: '240px',
    zIndex: 4,
    width: '100%',
    // boxShadow: '0 -1px 30px 0 rgba(50, 50, 93, 0.05)',
    boxShadow:
      'rgba(255, 255, 255, 0.1) 0px -1px 1px 0px inset, rgba(50, 50, 93, 0.1) 0px -50px 100px -20px, rgba(0, 0, 0, 0.2) 0px -30px 60px -30px',
  },
  buttonWrapperInner: {
    padding: theme.spacing(2),
    maxWidth: MAX_WIDTH,
    margin: 'auto',
  },
  buttonCancel: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  testimonialsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
    overflow: 'scroll',
    alignItems: 'center',
    width: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
});

interface Props {
  testimonials: Testimonial[];
  disableAll?: boolean;
}

const TestimonialList: React.FC<Props> = ({ testimonials, disableAll }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user }: { user: User | null } = useAppSelector(
    ({ auth: { user } }) => ({
      user,
    }),
  );

  return user != undefined ? (
    testimonials.length !== 0 ? (
      <>
        <div className={classes.testimonialsListContainer}>
          {testimonials.map((testimonial) => (
            <TestimonialComponent
              key={testimonial._id}
              testimonial={testimonial}
              disableAll={disableAll}
            />
          ))}
        </div>
      </>
    ) : (
      <div className={classes.testimonialsNoContent}>
        {t('pages.Testimonials.noTestimonials')}
      </div>
    )
  ) : (
    <></>
  );
};

export default TestimonialList;
