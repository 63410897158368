import React, { useEffect, useState } from 'react';
import { Dialog, Typography, ButtonBase } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { fetchCurrentUser } from 'src/slices/auth';
import {
  SessionIdResult,
  changeSubscription,
  paymentCheckout,
  paymentShopify,
  retrieveSubscription,
} from 'src/slices/payment';
import { useStripe } from '@stripe/react-stripe-js';
import { message } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
// import backgroundOffer from '../assets/backgroundOffer.avif';
import backgroundOffer3 from '../assets/backgroundOffer3.avif';
import backgroundOffer4 from '../assets/backgroundOffer4.avif';
import { Testimonial } from 'src/slices/testimonial';
import { testimonialsSelector } from 'src/store';
import { useAppBridge } from '@shopify/app-bridge-react';

const useStyles = createUseStyles({
  offerDialog: {
    zIndex: 3000,
    '& .MuiDialog-paper': {
      width: 'auto',
      borderRadius: '8px',
      background: 'white',
      paddingTop: '50px',
      paddingBottom: '30px',
      paddingLeft: '58px',
      paddingRight: '58px',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '90%',
    },
  },
  offerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  offerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#060640',
    marginBottom: '24px',
  },
  tutorialContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#060640',
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginBottom: '22px',
    marginRight: '-37px',
  },
  subTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '140%',
    color: '#060640',
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginBottom: '40px',
  },
  switchButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    borderRadius: '90px',
    background: '#EDF0FF',
    width: '284px',
    height: '56px',
    backdropFilter: 'blur(8px)',
    marginBottom: '48px',
    padding: '8px',
  },
  switchButton: {
    position: 'relative',
    borderRadius: '90px',
    background: 'none',
    height: '100%',
    width: '100%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    color: '#060640',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    '&.active': {
      background: 'white',
    },
  },
  offerCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    alignItems: 'flex-start',
  },
  offerCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '288px',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
    borderRadius: '8px',
    paddingTop: '40px',
    paddingBottom: '15px',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
  },
  offerCardTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '35px',
    color: 'white',
    paddingLeft: '48px',
    marginBottom: '24px',
  },
  priceContainer: {
    width: '180px',
    height: '56px',
    background: '#F9F9F9',
    filter: 'drop-shadow(0px 10px 30px rgba(3, 3, 37, 0.10))',
    borderRadius: '8px',
    position: 'relative',
    right: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    marginBottom: '24px',
  },
  priceTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '26px',
    color: '#273EAC',
    paddingLeft: '49px',
  },
  perMonth: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '10px',
    color: '#273EAC',
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
    paddingLeft: '32px',
    marginBottom: '32px',
  },
  informationDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
  },
  informationDetailsText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '500',
    color: '#060640',
  },
  selectButton: {
    borderRadius: '8px',
    background: '#273EAC',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    width: '233px',
    height: '40px',
    alignSelf: 'center',
  },
  iconContainer: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  overlay: {
    position: 'absolute',
    display: 'flex',
    top: '0px',
    background: '#FFFFFF',
    flexDirection: 'column',
    placeContent: 'center',
    color: '#060640',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    zIndex: 3000,
    padding: '25px',
    width: '100%',
    height: '100%',
    transformOrigin: 'top',
    transform: 'translateY(-100%)',
    transition: 'transform 0.5s ease',
    opacity: 1,
    '&.active': {
      transform: 'translateY(0%)',
    },
  },
  titleTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    color: '#060640',
    marginBottom: '24px',
    alignSelf: 'center',
  },
});

interface Props {
  openOfferDialog: boolean;
  setOpenOfferDialog: (value: boolean) => void;
}

const UpgradeDialog: React.FC<Props> = ({
  openOfferDialog,
  setOpenOfferDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [switchButton, setSwitchButton] = useState<boolean>(false);

  const stripe = useStripe();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  // const [hoveredCardStartUp, setHoveredCardStartUp] = useState<boolean>(false);

  const [hoveredCardRegular, setHoveredCardRegular] = useState<boolean>(false);

  const testimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );

  const [sessionId, setSessionId] = useState<string>();

  const [totalVideosHosted, setTotalVideosHosted] = useState<number>(0);

  const [totalVideo, setTotalVideo] = useState<number>(0);

  const [totalPage, setTotalPage] = useState<number>(0);

  const [publicTotalVideo, setPublicTotalVideo] = useState<number>(0);

  useEffect(() => {
    setPublicTotalVideo(totalVideo - 5);
  }, []);

  useEffect(() => {
    setTotalVideo(
      testimonials.filter(
        (testimonial) => testimonial.testimonialType === 'video',
      ).length,
    );
    setTotalVideosHosted(
      testimonials.filter((testimonial) => {
        if (testimonial.content)
          return testimonial.content.startsWith(
            'https://kudeo-documents.s3.eu-west-3.amazonaws.com/',
          );
      }).length,
    );
    setTotalPage(user?.settings.pluginSettings.tagsList.length || 0);
  }, [testimonials, user]);

  useEffect(() => {
    if (user?.isShopifyAccount) {
      setSubscriptionType(user?.subscriptionType + ' ' + user?.paymentInterval);
    }
  }, [user]);

  const checkoutSession = async (type: number) => {
    const result = await dispatch(
      paymentCheckout({
        originUrl: window.location.origin,
        stripeSubscriptionType: type,
      }),
    );
    let sessionId: SessionIdResult = { sessionId: '' };
    if (result.meta.requestStatus === 'fulfilled') {
      sessionId = result.payload as SessionIdResult;
      setSessionId(sessionId.sessionId);
    }

    if (result.meta.requestStatus === 'fulfilled') {
      await stripe?.redirectToCheckout({
        sessionId: sessionId.sessionId,
      });
    } else {
      message.error('Error');
    }
  };

  const validateSession = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      dispatch(fetchCurrentUser());
    } catch (error) {
      message.error(
        'Une erreur est survenue. Merci de rafraichir la page pour réessayer',
      );
    }
  };

  const pushBuyButton = async (type: number) => {
    if (stripe) {
      if (!sessionId) {
        checkoutSession(type);
      } else {
        validateSession();
      }
    }
  };

  const [subscriptionType, setSubscriptionType] = useState<string>('');

  const changeSubscriptionFunction = async (
    subscription: string,
    type: string,
  ) => {
    const originUrl = window.location.origin;
    const result = await dispatch(
      changeSubscription({ subscription, type, originUrl }),
    );
    if (result.meta.requestStatus === 'fulfilled') {
      window.location.href = result.payload as string;
    }
  };

  useEffect(() => {
    if (!user?.isShopifyAccount) {
      dispatch(retrieveSubscription()).then((result) => {
        const subscriptionPayload: { subscription: string; date: Date } =
          result.payload as { subscription: string; date: Date };
        setSubscriptionType(subscriptionPayload.subscription);
      });
    }
  }, [user]);

  const doneIcon = (
    <div className={classes.iconContainer}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M13.9084 21.0595C13.7715 21.2186 13.5348 21.2307 13.3823 21.0865L7.61884 15.6315C7.46638 15.4873 7.45975 15.2445 7.60395 15.092L9.44022 13.1517C9.5845 12.9992 9.82727 12.9926 9.97974 13.1368L13.1281 16.1164C13.2805 16.2607 13.5173 16.2485 13.6542 16.0894L19.8049 8.94279C19.9418 8.7837 20.1841 8.76554 20.3431 8.9025L22.3677 10.6451C22.5268 10.782 22.545 11.0242 22.408 11.1833L13.9084 21.0595Z"
          fill="#FFC001"
        />
        <path
          d="M13.9084 21.0595C13.7715 21.2186 13.5348 21.2307 13.3823 21.0865L7.61884 15.6315C7.46638 15.4873 7.45975 15.2445 7.60395 15.092L9.44022 13.1517C9.5845 12.9992 9.82727 12.9926 9.97974 13.1368L13.1281 16.1164C13.2805 16.2607 13.5173 16.2485 13.6542 16.0894L19.8049 8.94279C19.9418 8.7837 20.1841 8.76554 20.3431 8.9025L22.3677 10.6451C22.5268 10.782 22.545 11.0242 22.408 11.1833L13.9084 21.0595Z"
          fill="#08E8AF"
        />
      </svg>
    </div>
  );

  const subscribeWithShopify = async (subscription: string) => {
    const shopifyApp = useAppBridge();

    dispatch(
      paymentShopify({
        shop: shopifyApp.config.shop,
        code: await shopifyApp.idToken(),
        planChoice: subscription,
      }),
    ).then((e) => {
      if (e.meta.requestStatus === 'fulfilled') {
        if (e.payload) {
          open(e.payload as string, '_top');
        }
      }
    });
  };

  return (
    <Dialog
      open={openOfferDialog}
      onClose={() => {
        setOpenOfferDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.offerDialog}
    >
      <div className={classes.offerContainer}>
        <Typography className={classes.offerTitle}>
          {t('pages.Upgrade.selectOffer')}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_502_1109)">
              <path
                d="M9.84911 16.6377C9.82885 16.6377 9.80858 16.6369 9.78808 16.6357L0.978014 16.0857C0.642808 16.0649 0.342027 15.8733 0.181383 15.5786C0.0209821 15.2839 0.0229352 14.9272 0.186998 14.6343C0.23314 14.5517 1.34349 12.594 3.45799 10.8865C6.31542 8.57933 9.6372 7.83103 13.064 8.72264C13.3484 8.79661 13.5842 8.99485 13.7063 9.26219C13.8284 9.52952 13.8235 9.83763 13.6929 10.1008L10.7239 16.0947C10.5583 16.4287 10.2183 16.6377 9.84911 16.6377Z"
                fill="#FF5B5B"
              />
              <path
                d="M16.9479 31.9996C16.7873 31.9996 16.6267 31.96 16.4809 31.8804C16.1862 31.7198 15.9946 31.4193 15.9736 31.0841L15.4238 22.274C15.3994 21.8817 15.6125 21.5128 15.9648 21.3382L21.9587 18.369C22.2221 18.2386 22.53 18.2337 22.7973 18.3558C23.0649 18.4776 23.2629 18.7137 23.3369 18.9981C24.2287 22.4249 23.4804 25.7466 21.173 28.6041C19.4658 30.7186 17.5078 31.8287 17.4252 31.8751C17.277 31.9581 17.1125 31.9996 16.9479 31.9996Z"
                fill="#FF193D"
              />
              <path
                d="M30.4957 7.87964L24.1832 1.56714C23.9303 1.31421 23.5592 1.21997 23.2161 1.32129C18.9903 2.57129 15.5213 4.53174 12.9049 7.14795C12.3592 7.69385 11.835 8.2854 11.3472 8.90601C9.92878 10.7095 8.74397 12.8418 7.826 15.2439C7.24128 16.7749 6.76521 18.415 6.41096 20.1187C5.79231 23.0945 5.7591 25.2273 5.75788 25.3167C5.75471 25.5796 5.85774 25.833 6.04377 26.0191C6.22688 26.2022 6.47541 26.3049 6.7342 26.3049H6.74617C6.83576 26.304 8.96858 26.2708 11.9442 25.6521C13.6478 25.2979 15.2879 24.8218 16.8192 24.2368C19.221 23.3189 21.3533 22.1343 23.1563 20.7161C23.7774 20.228 24.369 19.7039 24.9149 19.158C27.5313 16.5415 29.4918 13.0725 30.7415 8.84668C30.8431 8.50366 30.7486 8.13257 30.4957 7.87964Z"
                fill="#E1F1FA"
              />
              <path
                d="M12.6153 24.1795L7.88583 19.45C7.53671 19.1008 6.98177 19.0674 6.5931 19.3721C6.2911 19.6089 6.00375 19.8633 5.7391 20.1279C2.44832 23.4187 2.34652 28.5176 2.34383 28.7329C2.34041 28.9961 2.44344 29.2495 2.62972 29.4356C2.81283 29.6189 3.06112 29.7217 3.31991 29.7217C3.32406 29.7217 3.32821 29.7217 3.33236 29.7214C3.54769 29.7188 8.64681 29.617 11.9373 26.3262C12.202 26.0615 12.4564 25.7742 12.6932 25.4722C12.9979 25.0835 12.9644 24.5286 12.6153 24.1795Z"
                fill="#FFF261"
              />
              <path
                d="M7.89868 15.0469L4.99707 17.9487C4.81396 18.1316 4.71094 18.3799 4.71094 18.6389C4.71094 18.8977 4.81396 19.146 4.99707 19.3291L12.7293 27.0613C12.9121 27.2444 13.1604 27.3472 13.4194 27.3472C13.6785 27.3472 13.9265 27.2444 14.1096 27.0613L17.0112 24.1597L7.89868 15.0469Z"
                fill="#1B3B6B"
              />
              <path
                d="M20.5706 14.4719C19.7751 14.4719 19.0271 14.1621 18.4646 13.5996C17.3035 12.4385 17.3035 10.5493 18.4646 9.38794C19.0271 8.82544 19.7751 8.51562 20.5706 8.51562C21.366 8.51562 22.114 8.82544 22.6765 9.38794C23.239 9.95044 23.5488 10.6982 23.5488 11.4939C23.5488 12.2893 23.239 13.0371 22.6765 13.5996C22.114 14.1621 21.366 14.4719 20.5706 14.4719Z"
                fill="#06CCAB"
              />
              <path
                d="M20.5715 15.4263C19.5209 15.4263 18.5334 15.0171 17.7907 14.2744C16.2573 12.741 16.2573 10.2459 17.7907 8.7124C18.5334 7.96973 19.5212 7.56055 20.5715 7.56055C21.622 7.56055 22.6096 7.96973 23.3525 8.7124C24.0954 9.45532 24.5043 10.4429 24.5043 11.4934C24.5043 12.5439 24.0954 13.5315 23.3525 14.2744C22.6096 15.0171 21.622 15.4263 20.5715 15.4263ZM20.5715 9.51294C20.0427 9.51294 19.5451 9.719 19.1711 10.093C18.3991 10.8652 18.3991 12.1216 19.1711 12.8938C19.5451 13.2678 20.0427 13.4739 20.5715 13.4739C21.1005 13.4739 21.5978 13.2678 21.9719 12.8938C22.3459 12.5198 22.5519 12.0225 22.5519 11.4934C22.5519 10.9644 22.3459 10.467 21.9719 10.093C21.5978 9.719 21.1005 9.51294 20.5715 9.51294Z"
                fill="#1B3B6B"
              />
              <path
                d="M31.7786 0.286069C31.5923 0.0997892 31.3374 -0.00494715 31.0757 0.000179805C30.9309 0.00188879 27.48 0.0599943 23.2188 1.32025L30.7441 8.84564C32.0044 4.58441 32.0625 1.13348 32.0642 0.988706C32.0676 0.725522 31.9646 0.472104 31.7786 0.286069Z"
                fill="#FF5B5B"
              />
              <path
                d="M30.4988 7.87891L27.3425 4.72266L6.04688 26.0183C6.22998 26.2014 6.47827 26.3042 6.73706 26.3042H6.74927C6.83862 26.3032 8.97144 26.27 11.9473 25.6514C13.6509 25.2971 15.2908 24.8211 16.822 24.2361C19.2241 23.3181 21.3565 22.1336 23.1594 20.7153C23.7803 20.2273 24.3721 19.7031 24.9177 19.1572C27.5342 16.5408 29.4946 13.0718 30.7444 8.84595C30.8462 8.50293 30.7517 8.13184 30.4988 7.87891Z"
                fill="#CDEBFC"
              />
              <path
                d="M12.6108 24.1812L10.2461 21.8164L2.625 29.4373V29.4375C2.80835 29.6206 3.05664 29.7234 3.31543 29.7234H3.32764C3.54321 29.7207 8.64209 29.6187 11.9329 26.3279C12.1975 26.0632 12.4519 25.7759 12.6885 25.4739C12.9934 25.0852 12.9602 24.5303 12.6108 24.1812Z"
                fill="#FDBF00"
              />
              <path
                d="M12.4592 19.6035L8.86719 23.1956L12.7334 27.0618C12.9163 27.2446 13.1646 27.3477 13.4236 27.3477C13.6826 27.3477 13.9307 27.2446 14.1138 27.0618L17.0154 24.1599L12.4592 19.6035Z"
                fill="#001035"
              />
              <path
                d="M22.6729 9.38867C22.6726 9.38867 22.6726 9.38867 22.6726 9.38867L18.4609 13.6003H18.4612C19.0234 14.1628 19.7715 14.4727 20.5669 14.4727C21.3623 14.4727 22.1104 14.1628 22.6729 13.6003C23.2354 13.0378 23.5452 12.29 23.5452 11.4946C23.5449 10.6992 23.2354 9.95117 22.6729 9.38867Z"
                fill="#1CADB5"
              />
              <path
                d="M23.3511 8.71289L21.9705 10.0935C22.3445 10.4675 22.5505 10.9648 22.5505 11.4939C22.5505 12.023 22.3445 12.5203 21.9705 12.8943C21.5964 13.2683 21.0991 13.4744 20.5701 13.4744C20.041 13.4744 19.5437 13.2683 19.1697 12.8943C19.1697 12.8943 19.1697 12.8943 19.1697 12.894L17.7891 14.2747V14.2749C18.532 15.0176 19.5195 15.4268 20.5701 15.4268C21.6206 15.4268 22.6082 15.0176 23.3511 14.2749C24.0938 13.532 24.5029 12.5444 24.5029 11.4939C24.5029 10.4434 24.0938 9.45581 23.3511 8.71289Z"
                fill="#001035"
              />
              <path
                d="M31.7734 0.285156L26.9766 5.08228L30.7393 8.84497C31.9995 4.58374 32.0576 1.13281 32.0593 0.988037C32.0627 0.724854 31.9597 0.471436 31.7734 0.285156Z"
                fill="#FF193D"
              />
            </g>
            <defs>
              <clipPath id="clip0_502_1109">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Typography>

        {user?.isShopifyAccount ? (
          <div className={classes.switchButtonContainer}>
            <ButtonBase
              className={`${classes.switchButton}  ${
                !switchButton ? 'active' : ''
              }`}
              onClick={() => {
                setSwitchButton(false);
              }}
            >
              {t('pages.Upgrade.switchButtonYearly')}
            </ButtonBase>
            <ButtonBase
              className={`${classes.switchButton} ${
                switchButton ? 'active' : ''
              }`}
              onClick={() => {
                setSwitchButton(true);
              }}
            >
              {t('pages.Upgrade.switchButtonMonthly')}
            </ButtonBase>
          </div>
        ) : !subscriptionType.includes('year') ? (
          <div className={classes.switchButtonContainer}>
            <ButtonBase
              className={`${classes.switchButton}  ${
                !switchButton ? 'active' : ''
              }`}
              onClick={() => {
                setSwitchButton(false);
              }}
            >
              {t('pages.Upgrade.switchButtonYearly')}
            </ButtonBase>
            <ButtonBase
              className={`${classes.switchButton} ${
                switchButton ? 'active' : ''
              }`}
              onClick={() => {
                setSwitchButton(true);
              }}
            >
              {t('pages.Upgrade.switchButtonMonthly')}
            </ButtonBase>
          </div>
        ) : (
          <></>
        )}

        <div className={classes.offerCardContainer}>
          {/* <div
            className={`${classes.offerCard}`}
            style={{
              background: `url(${backgroundOffer})`,
              backgroundRepeat: 'no-repeat',
              overflow: 'hidden',
              backgroundSize: 'contain',
            }}
            onMouseEnter={() => {
              if (totalVideosHosted > 0 || totalVideo >= 5 || totalPage >= 3) {
                setHoveredCardStartUp(true);
              }
            }}
            onMouseLeave={() => {
              setHoveredCardStartUp(false);
            }}
          >
            <div
              className={`${classes.overlay} ${
                hoveredCardStartUp ? 'active' : ''
              }`}
            >
              <div className={classes.titleTypography}>
                {t('pages.Upgrade.notAllowed')}
              </div>

              <div style={{ marginBottom: '20px' }}>
                {t('pages.Upgrade.notAllowedDescription')}
              </div>

              {totalVideosHosted > 0 && (
                <div>
                  {t('pages.Upgrade.startup.hostedVideos', {
                    totalVideosHosted,
                  })}
                </div>
              )}
              {totalVideo > 5 && (
                <div>
                  {t('pages.Upgrade.startup.publicVideos', {
                    totalVideo,
                  })}
                </div>
              )}
              {totalPage > 3 && (
                <div>
                  {t('pages.Upgrade.startup.numberOfPages', {
                    totalPage,
                  })}
                </div>
              )}
            </div>

            <Typography
              className={classes.offerCardTitle}
              style={
                totalVideosHosted > 0 || totalVideo > 5 || totalPage > 3
                  ? { opacity: '0.5' }
                  : {}
              }
            >
              {t('pages.Upgrade.startup.name')}
            </Typography>

            <div className={classes.priceContainer}>
              {switchButton ? (
                <>
                  <Typography className={classes.priceTypography}>
                    {t('pages.Upgrade.startup.priceMonth')}
                  </Typography>
                  <Typography className={classes.perMonth}>
                    {user?.isShopifyAccount
                      ? t('pages.Upgrade.perMonthShopify')
                      : t('pages.Upgrade.perMonth')}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className={classes.priceTypography}>
                    {t('pages.Upgrade.startup.priceYear')}
                  </Typography>
                  <Typography className={classes.perMonth}>
                    {user?.isShopifyAccount
                      ? t('pages.Upgrade.perMonthShopify')
                      : t('pages.Upgrade.perMonth')}
                  </Typography>
                </>
              )}
            </div>

            <div
              className={classes.informationContainer}
              style={
                totalVideosHosted > 0 || totalVideo > 5 || totalPage > 3
                  ? { opacity: '0.5' }
                  : {}
              }
            >
              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.startup.information1')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.startup.information2')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.startup.information3')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.startup.information4')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.startup.information5')}
                </Typography>
              </div>
            </div>

            <ButtonBase
              className={classes.selectButton}
              onClick={() => {
                if (user?.isShopifyAccount) {
                  subscribeWithShopify(
                    switchButton ? 'Startup month' : 'Startup year',
                  );
                } else {
                  if (user?.subscriptionId) {
                    changeSubscriptionFunction(
                      'startup',
                      switchButton ? 'month' : 'year',
                    );
                  } else {
                    switchButton ? pushBuyButton(2) : pushBuyButton(3);
                  }
                }
              }}
              disabled={
                totalVideosHosted > 0 ||
                totalVideo > 5 ||
                totalPage > 3 ||
                (subscriptionType.includes('startup') &&
                  subscriptionType.includes('year') &&
                  !switchButton) ||
                (subscriptionType.includes('startup') &&
                  subscriptionType.includes('month') &&
                  switchButton)
              }
              style={
                totalVideosHosted > 0 ||
                totalVideo > 5 ||
                totalPage > 3 ||
                (subscriptionType.includes('startup') &&
                  subscriptionType.includes('year') &&
                  !switchButton) ||
                (subscriptionType.includes('startup') &&
                  subscriptionType.includes('month') &&
                  switchButton)
                  ? { opacity: '0.5' }
                  : {}
              }
            >
              {subscriptionType.includes('startup') &&
              subscriptionType.includes('year') &&
              !switchButton
                ? t('pages.Upgrade.currentOffer')
                : subscriptionType.includes('startup') &&
                  subscriptionType.includes('month') &&
                  switchButton
                ? t('pages.Upgrade.currentOffer')
                : t('pages.Upgrade.selectOffer')}
            </ButtonBase>
          </div> */}

          <div
            className={`${classes.offerCard}`}
            style={{
              background: `url(${backgroundOffer4})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
            onMouseEnter={() => {
              if (
                totalVideosHosted > 5 ||
                publicTotalVideo > 30 ||
                totalPage > 15
              )
                setHoveredCardRegular(true);
            }}
            onMouseLeave={() => {
              setHoveredCardRegular(false);
            }}
          >
            <div
              className={`${classes.overlay} ${
                hoveredCardRegular ? 'active' : ''
              }`}
            >
              <div className={classes.titleTypography}>
                {t('pages.Upgrade.notAllowed')}
              </div>

              <div style={{ marginBottom: '20px' }}>
                {t('pages.Upgrade.notAllowedDescription')}
              </div>

              {totalVideosHosted > 5 && (
                <div>
                  {t('pages.Upgrade.regular.hostedVideos', {
                    totalVideosHosted,
                  })}
                </div>
              )}
              {publicTotalVideo > 30 && (
                <div>
                  {t('pages.Upgrade.regular.publicVideos', {
                    publicTotalVideo,
                  })}
                </div>
              )}
              {totalPage > 15 && (
                <div>
                  {t('pages.Upgrade.regular.numberOfPages', {
                    totalPage,
                  })}
                </div>
              )}
            </div>

            <Typography
              className={classes.offerCardTitle}
              style={
                totalVideosHosted > 5 || publicTotalVideo > 30 || totalPage > 15
                  ? { opacity: '0.5' }
                  : {}
              }
            >
              {t('pages.Upgrade.regular.name')}
            </Typography>

            <div className={classes.priceContainer}>
              {switchButton ? (
                <>
                  <Typography className={classes.priceTypography}>
                    {t('pages.Upgrade.regular.priceMonth')}
                  </Typography>
                  <Typography className={classes.perMonth}>
                    {user?.isShopifyAccount
                      ? t('pages.Upgrade.perMonthShopify')
                      : t('pages.Upgrade.perMonth')}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className={classes.priceTypography}>
                    {t('pages.Upgrade.regular.priceYear')}
                  </Typography>
                  <Typography className={classes.perMonth}>
                    {user?.isShopifyAccount
                      ? t('pages.Upgrade.perMonthShopify')
                      : t('pages.Upgrade.perMonth')}
                  </Typography>
                </>
              )}
            </div>

            <div
              className={classes.informationContainer}
              style={
                totalVideosHosted > 5 || publicTotalVideo > 30 || totalPage > 15
                  ? { opacity: '0.5' }
                  : {}
              }
            >
              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.regular.information1')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.regular.information2')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.regular.information3')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.regular.information4')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.regular.information5')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.regular.information6')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.regular.information7')}
                </Typography>
              </div>
            </div>

            <ButtonBase
              className={classes.selectButton}
              onClick={() => {
                if (user?.isShopifyAccount) {
                  subscribeWithShopify(
                    switchButton ? 'Regular month' : 'Regular year',
                  );
                } else {
                  if (user?.subscriptionId) {
                    changeSubscriptionFunction(
                      'regular',
                      switchButton ? 'month' : 'year',
                    );
                  } else {
                    switchButton ? pushBuyButton(0) : pushBuyButton(1);
                  }
                }
              }}
              disabled={
                totalVideosHosted > 5 ||
                publicTotalVideo > 30 ||
                totalPage > 15 ||
                (subscriptionType.includes('regular') &&
                  subscriptionType.includes('year') &&
                  !switchButton) ||
                (subscriptionType.includes('regular') &&
                  subscriptionType.includes('month') &&
                  switchButton)
              }
              style={
                totalVideosHosted > 5 ||
                publicTotalVideo > 30 ||
                totalPage > 15 ||
                (subscriptionType.includes('regular') &&
                  subscriptionType.includes('year') &&
                  !switchButton) ||
                (subscriptionType.includes('regular') &&
                  subscriptionType.includes('month') &&
                  switchButton)
                  ? { opacity: '0.5' }
                  : {}
              }
            >
              {subscriptionType.includes('regular') &&
              subscriptionType.includes('year') &&
              !switchButton
                ? t('pages.Upgrade.currentOffer')
                : subscriptionType.includes('regular') &&
                  subscriptionType.includes('month') &&
                  switchButton
                ? t('pages.Upgrade.currentOffer')
                : subscriptionType.includes('regular') &&
                  subscriptionType.includes('month') &&
                  !switchButton
                ? t('pages.Upgrade.upgradeOffer')
                : subscriptionType.includes('startup')
                ? t('pages.Upgrade.upgradeOffer')
                : t('pages.Upgrade.selectOffer')}
            </ButtonBase>
          </div>

          <div
            className={classes.offerCard}
            style={{
              background: `url(${backgroundOffer3})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
            <Typography className={classes.offerCardTitle}>
              {t('pages.Upgrade.premium.name')}
            </Typography>

            <div className={classes.priceContainer}>
              {switchButton ? (
                <>
                  <Typography className={classes.priceTypography}>
                    {t('pages.Upgrade.premium.priceMonth')}
                  </Typography>
                  <Typography className={classes.perMonth}>
                    {user?.isShopifyAccount
                      ? t('pages.Upgrade.perMonthShopify')
                      : t('pages.Upgrade.perMonth')}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className={classes.priceTypography}>
                    {t('pages.Upgrade.premium.priceYear')}
                  </Typography>
                  <Typography className={classes.perMonth}>
                    {user?.isShopifyAccount
                      ? t('pages.Upgrade.perMonthShopify')
                      : t('pages.Upgrade.perMonth')}
                  </Typography>
                </>
              )}
            </div>

            <div className={classes.informationContainer}>
              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.premium.information1')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.premium.information2')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.premium.information3')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.premium.information4')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.premium.information5')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.premium.information6')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.premium.information7')}
                </Typography>
              </div>

              <div className={classes.informationDetails}>
                {doneIcon}
                <Typography className={classes.informationDetailsText}>
                  {t('pages.Upgrade.premium.information8')}
                </Typography>
              </div>
            </div>

            <ButtonBase
              className={classes.selectButton}
              onClick={() => {
                if (user?.isShopifyAccount) {
                  subscribeWithShopify(
                    switchButton ? 'Premium month' : 'Premium year',
                  );
                } else {
                  if (user?.subscriptionId) {
                    changeSubscriptionFunction(
                      'premium',
                      switchButton ? 'month' : 'year',
                    );
                  } else {
                    switchButton ? pushBuyButton(4) : pushBuyButton(5);
                  }
                }
              }}
              disabled={
                (subscriptionType.includes('premium') &&
                  subscriptionType.includes('year') &&
                  !switchButton) ||
                (subscriptionType.includes('premium') &&
                  subscriptionType.includes('month') &&
                  switchButton)
              }
              style={
                (subscriptionType.includes('premium') &&
                  subscriptionType.includes('year') &&
                  !switchButton) ||
                (subscriptionType.includes('premium') &&
                  subscriptionType.includes('month') &&
                  switchButton)
                  ? { opacity: '0.5' }
                  : {}
              }
            >
              {subscriptionType.includes('premium') &&
              subscriptionType.includes('year') &&
              !switchButton
                ? t('pages.Upgrade.currentOffer')
                : subscriptionType.includes('premium') &&
                  subscriptionType.includes('month') &&
                  switchButton
                ? t('pages.Upgrade.currentOffer')
                : t('pages.Upgrade.upgradeOffer')}
            </ButtonBase>
          </div>

          {/* {!user?.isShopifyAccount && (
            <div
              className={classes.offerCard}
              style={{
                background: `url(${backgroundOffer2})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
            >
              <Typography className={classes.offerCardTitle}>
                {t('pages.Upgrade.company.name')}
              </Typography>

              <div className={classes.priceContainer}>
                <Typography
                  className={classes.priceTypography}
                  style={{ fontSize: '16px' }}
                >
                  {t('pages.Upgrade.company.price')}
                </Typography>
              </div>

              <div className={classes.informationContainer}>
                <div className={classes.informationDetails}>
                  {doneIcon}
                  <Typography className={classes.informationDetailsText}>
                    {t('pages.Upgrade.company.information1')}
                  </Typography>
                </div>

                <div className={classes.informationDetails}>
                  {doneIcon}
                  <Typography className={classes.informationDetailsText}>
                    {t('pages.Upgrade.company.information2')}
                  </Typography>
                </div>

                <div className={classes.informationDetails}>
                  {doneIcon}
                  <Typography className={classes.informationDetailsText}>
                    {t('pages.Upgrade.company.information3')}
                  </Typography>
                </div>

                <div className={classes.informationDetails}>
                  {doneIcon}
                  <Typography className={classes.informationDetailsText}>
                    {t('pages.Upgrade.company.information4')}
                  </Typography>
                </div>
              </div>

              <ButtonBase
                className={classes.selectButton}
                onClick={() => {
                  window.open(
                    'https://calendly.com/kudeo-demo/kudeo',
                    '_blank',
                  );
                }}
              >
                {t('pages.Upgrade.contactUs')}
              </ButtonBase>
            </div>
          )} */}
        </div>
      </div>
    </Dialog>
  );
};

export default UpgradeDialog;
