import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { signUp } from 'src/slices/auth';
import { DisplayNotification } from 'src/helpers/notifications';
import { useSnackbar } from 'notistack';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DoneIcon from '@mui/icons-material/Done';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = createUseStyles({
  registerForm: {},
  registerFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  textfieldWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  typographyStyle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    paddingLeft: '8px',
  },
  registerEmail: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      border: '1px solid #CED3E8',
    },
    '& .fieldset': {
      border: 'none',
    },
  },
  registerEmailPassword: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      border: '1px solid #CED3E8',
    },
    '& .fieldset': {
      border: 'none',
    },
    marginBottom: '8px',
  },

  registerSubmitButton: {
    background: '#273EAC',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.4px',
    marginBottom: '25px',
  },

  codeSubmitButton: {
    background: '#273EAC',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.4px',
    flex: 0.3,
  },

  verificationContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '22px',
  },

  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
    alignItems: 'center',
    flex: 1,
  },
  typographyClasse: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
    fontSize: '10px',
    color: '#7E7E7E',
  },
  icon: {
    fontSize: '12px',
    color: '#7E7E7E',
  },
  iconDone: {
    fontSize: '12px',
    color: 'rgb(106,228,179)',
  },

  haveAccountContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '19px',
    justifyContent: 'center',
  },
  haveAccount: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
  },
  returnBack: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#273EAC',
    textDecoration: 'none',
  },
  acceptTerms: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '140%',
    color: '#7E7E7E',
    fontFeatureSettings: "clig' off, 'liga' off",
    marginBottom: '13px',
    textAlign: 'center',
  },
});

const SignUpForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [error, setError] = useState<string | undefined>(undefined);

  const [showPassword, setShowPassword] = useState(false);

  const [isUpperCase, setIsUpperCase] = useState(false);

  const [isLowerCase, setIsLowerCase] = useState(false);

  const [isNumber, setIsNumber] = useState(false);

  const [minLength, setMinLength] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minLengthCode, setMinLengthCode] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxLengthCode, setMaxLengthCode] = useState(false);

  const [showCode, setShowCode] = useState(false);

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    code: false,
    codeApplied: '',
  };

  const submitForm = async (values: {
    email: string;
    password: string;
    code: boolean;
    codeApplied: string;
  }) => {
    const { email, password, code, codeApplied } = values;
    if (error) {
      setError(undefined);
    }
    const resultAction = await dispatch(
      signUp({
        email,
        password,
        code,
        codeApplied,
        locale: i18n.language,
      }),
    );
    if (signUp.rejected.match(resultAction)) {
      setError(resultAction.error.message);
      const message = t('forms.errorSignUp');
      const key = enqueueSnackbar(message, {
        content: (
          <DisplayNotification
            closeNotification={() => closeSnackbar(key)}
            message={message}
            variant={false}
          />
        ),
      });
      formik.resetForm();
      formik.setValues(initialValues);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitForm,
  });

  const { handleSubmit, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (formik.values.password.match(/[A-Z]/g)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
    if (formik.values.password.match(/[a-z]/g)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
    if (formik.values.password.match(/[0-9]/g)) {
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }
    if (formik.values.password.length >= 8) {
      setMinLength(true);
    } else {
      setMinLength(false);
    }
  }, [formik.values.password]);

  useEffect(() => {
    if (formik.values.codeApplied.length >= 17) {
      setMinLengthCode(true);
    } else {
      setMinLengthCode(false);
    }
    if (formik.values.codeApplied.length <= 18) {
      setMaxLengthCode(true);
    } else {
      setMaxLengthCode(false);
    }
  }, [formik.values.codeApplied]);

  return (
    <FormikProvider value={formik}>
      <Form
        className={classes.registerForm}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className={classes.registerFormContainer}>
          <div
            className={classes.textfieldWrapper}
            style={{ marginBottom: '16px' }}
          >
            <Typography className={classes.typographyStyle}>
              {t('common.email')}
            </Typography>
            <TextField
              className={classes.registerEmail}
              fullWidth
              type="email"
              {...getFieldProps('email')}
              autoComplete="new-password"
            />
          </div>

          <div
            className={classes.textfieldWrapper}
            style={{ marginBottom: '8px' }}
          >
            <Typography className={classes.typographyStyle}>
              {t('common.password')}
            </Typography>
            <TextField
              className={classes.registerEmailPassword}
              fullWidth
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword((show) => !show);
                      }}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="new-password"
            />
          </div>

          <div className={`${classes.verificationContainer}`}>
            <div className={classes.iconContainer}>
              {isUpperCase ? (
                <DoneIcon className={classes.iconDone} />
              ) : (
                <RadioButtonUncheckedIcon className={classes.icon} />
              )}

              <Typography
                className={`${classes.typographyClasse}`}
                style={isUpperCase ? { color: 'rgb(106,228,179)' } : {}}
              >
                {t('pages.SignUp.uppercase')}
              </Typography>
            </div>
            <div className={classes.iconContainer}>
              {isLowerCase ? (
                <DoneIcon className={classes.iconDone} />
              ) : (
                <RadioButtonUncheckedIcon className={classes.icon} />
              )}
              <Typography
                className={classes.typographyClasse}
                style={isLowerCase ? { color: 'rgb(106,228,179)' } : {}}
              >
                {t('pages.SignUp.lowercase')}
              </Typography>
            </div>
            <div className={classes.iconContainer}>
              {isNumber ? (
                <DoneIcon className={classes.iconDone} />
              ) : (
                <RadioButtonUncheckedIcon className={classes.icon} />
              )}
              <Typography
                className={classes.typographyClasse}
                style={isNumber ? { color: 'rgb(106,228,179)' } : {}}
              >
                {t('pages.SignUp.number')}
              </Typography>
            </div>
            <div className={classes.iconContainer}>
              {minLength ? (
                <DoneIcon className={classes.iconDone} />
              ) : (
                <RadioButtonUncheckedIcon className={classes.icon} />
              )}
              <Typography
                className={classes.typographyClasse}
                style={minLength ? { color: 'rgb(106,228,179)' } : {}}
              >
                {t('pages.SignUp.minLength')}
              </Typography>
            </div>
          </div>

          <Typography
            className={classes.typographyStyle}
            style={
              !showCode
                ? {
                    marginBottom: '15px',
                    textDecoration: 'underline',
                    color: '#273EAC',
                    cursor: 'pointer',
                  }
                : {}
            }
            onClick={() => {
              setShowCode(true);
            }}
          >
            {!showCode ? t('common.haveCode') : t('common.code')}
          </Typography>

          {showCode && (
            <div className={classes.textfieldWrapper}>
              <TextField
                className={classes.registerEmailPassword}
                fullWidth
                type={'text'}
                autoComplete="new-password"
                {...getFieldProps('codeApplied')}
              />
            </div>
          )}

          <LoadingButton
            className={classes.registerSubmitButton}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isUpperCase || !isLowerCase || !isNumber || !minLength}
          >
            {t('common.register')}
          </LoadingButton>

          <div className={classes.haveAccountContainer}>
            <Typography className={classes.haveAccount}>
              {t('pages.SignUp.haveAccount')}
            </Typography>
            <RouterLink to="/" className={classes.returnBack}>
              {t('pages.SignUp.login')}
            </RouterLink>
          </div>

          <Link
            href="https://www.kudeo.co/terms-conditions"
            className={classes.acceptTerms}
            target="_blank"
          >
            {t('pages.SignUp.acceptTerms')}
          </Link>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default SignUpForm;
