import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/constants/theme';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { logout } from 'src/slices/auth';
import { Link } from 'react-router-dom';

const InactiveAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
      }}
    >
      {user?.stats.numberOfConnections === 1 ? (
        <div>{t('common.inactiveAccountFirstTime')}</div>
      ) : (
        <div>{t('common.inactiveAccount')}</div>
      )}
      <Button
        style={{ marginTop: theme.spacing(4) }}
        variant="contained"
        color="error"
        onClick={() => dispatch(logout())}
      >
        {t('common.logout')}
      </Button>
      {user?.isAdmin ? <Link to={'/preview'}>Preview</Link> : <></>}
    </div>
  );
};

export default InactiveAccount;
