import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slices/auth';
import testimonialReducer, { testimonialsAdapter } from '../slices/testimonial';
import testimonialPluginReducer, {
  tesitmonialsPluginAdapter,
} from '../slices/testimonialPlugin';

/* Reducers */
const rootReducer = combineReducers({
  auth: authReducer,
  testimonials: testimonialReducer,
  testimonialsPlugin: testimonialPluginReducer,
});

export const store = configureStore({ reducer: rootReducer });

/* Infer the `RootState` and `AppDispatch` types from the store itself */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const testimonialsSelector = testimonialsAdapter.getSelectors<RootState>(
  (state) => state.testimonials,
);

export const testimonialsPluginSelector =
  tesitmonialsPluginAdapter.getSelectors<RootState>(
    (state) => state.testimonialsPlugin.testimonials,
  );
