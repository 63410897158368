import React, { useState } from 'react';
import { Stack, Container, Typography, Box } from '@mui/material';
import { AuthenticationStyle } from 'src/components/authentication/AuthenticationStyles';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/constants/theme';
import { createUseStyles } from 'react-jss';
import { TextFieldWrapper } from '../components/basic/TextFieldWrapper';
import * as Yup from 'yup';
import { sendMailRedirection, signIn } from '../slices/auth';
import { DisplayNotification } from '../helpers/notifications';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from '../hooks/store';

const useStyles = createUseStyles({
  signInContainer: {
    position: 'relative',
    width: '542px',
    height: '610px',
    left: '18%',
    top: '207px',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      left: '0%',
    },
  },
  logo: {
    position: 'absolute',
    left: ' 3.47%',
    right: '28.4%',
    top: '13.38%',
    bottom: '13.38%',
    mixBlendMode: 'multiply',
    opacity: '0.1',
    zIndex: 1,
  },
  signInToText: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '39px',
    marginBottom: '30px',
  },
  welcomeBackText: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '25px',
  },
  connectingText: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '25px',
  },
  emailTextField: {
    '& .MuiInputBase-root': {
      borderRadius: '80px',
    },
  },
  submitButton: {
    background: '#273EAC',
    borderRadius: '80px',
  },
});
export const Redirection = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [error, setError] = useState<string | undefined>(undefined);
  const [fulfilled, setFulfilled] = useState<boolean>(false);
  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('forms.rules.email'))
      .required(t('forms.rules.required')),
  });

  const initialValues = {
    email: '',
  };

  const submitForm = async (values: { email: string }) => {
    if (error) {
      setError(undefined);
    }
    const resultAction = await dispatch(
      sendMailRedirection({ email: values.email, locale: i18n.language }),
    );
    if (signIn.rejected.match(resultAction)) {
      setError(resultAction.error.message);
      const message = t('forms.errorSignIn');
      const key = enqueueSnackbar(message, {
        content: (
          <DisplayNotification
            closeNotification={() => closeSnackbar(key)}
            message={message}
            variant={false}
          />
        ),
      });
      formik.resetForm();
      formik.setValues(initialValues);
    }

    setFulfilled(true);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: SignInSchema,
    onSubmit: submitForm,
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <AuthenticationStyle title="Redirection | Kudeo">
      <div
        style={{
          display: 'flex',
        }}
      >
        <Box component="img" src="/Logo-kudeo.png" className={classes.logo} />

        <Container className={classes.signInContainer}>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" className={classes.signInToText}>
              {t('pages.Redirection.betterExperience')}
            </Typography>
            <Typography
              sx={{ color: 'text.secondary' }}
              className={classes.welcomeBackText}
            >
              {t('pages.Redirection.sendMail')}
            </Typography>
          </Stack>
          <Stack spacing={3}>
            {!fulfilled ? (
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <TextFieldWrapper
                    className={classes.emailTextField}
                    fullWidth
                    type="email"
                    label={t('common.email')}
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    style={{ marginBottom: '20px' }}
                  />

                  <LoadingButton
                    className={classes.submitButton}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={
                      !(formik.isValid && formik.dirty) || formik.isSubmitting
                    }
                  >
                    {t('common.send')}
                  </LoadingButton>
                </Form>
              </FormikProvider>
            ) : (
              <Typography
                sx={{ color: 'text.secondary' }}
                className={classes.welcomeBackText}
              >
                {t('pages.Redirection.receiveEmail')}
              </Typography>
            )}
          </Stack>
        </Container>
      </div>
    </AuthenticationStyle>
  );
};
