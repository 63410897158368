import React from 'react';
import { createUseStyles } from 'react-jss';

import { CircularProgress } from '@mui/material';
import { useTheme } from '@emotion/react';

const useStyles = createUseStyles({
  fullScreenSpinContainer: {
    position: 'absolute',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: '999',
    backgroundColor: 'white',
  },
  progressSpin: {
    width: '30vw',
    height: '30vw',
  },
});

export const FullscreenSpin: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.fullScreenSpinContainer}>
      <CircularProgress className={classes.progressSpin} />
    </div>
  );
};
