import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoggedLayout } from 'src/containers/LoggedLayout';
import Testimonials from 'src/pages/Testimonials';
import SideBarProvider from 'src/hooks/SideBarProvider';
import Settings from 'src/pages/Settings';
import { useAppSelector } from 'src/hooks/store';
import Admin from 'src/pages/Admin';
import SuccessPayment from 'src/pages/SuccessPayment';
import AddTestimonialAndContact from 'src/pages/AddTestimonialAndContact';
import InactiveAccount from 'src/pages/InactiveAccount';
import { ActivateAccount } from '../pages/ActivateAccount';
import Website from '../pages/Website';
import { Dashboard } from 'src/pages/Dashboard';
import { SignUpShopify } from 'src/pages/SignUpShopify';

export const LoggedSwitch: React.FC = () => {
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  return user?.isActiveAccount ? (
    <>
      <SideBarProvider>
        <LoggedLayout>
          <Routes>
            <Route path="/" element={<Website />} />
            <Route path="/website" element={<Website />} />
            <Route path="/testimonials/save=:save" element={<Testimonials />} />
            <Route path="/successPayment" element={<SuccessPayment />} />
            <Route path="/settings/profil/:profil" element={<Settings />} />
            <Route path="/settings" element={<Settings />} />
            {user?.isAdmin ? <Route path="/admin" element={<Admin />} /> : null}
            <Route path="/testimonials" element={<Testimonials />} />
            <Route
              path="/testimonials/user/:userId"
              element={<AddTestimonialAndContact />}
            />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/shopify" element={<SignUpShopify />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </LoggedLayout>
      </SideBarProvider>
    </>
  ) : (
    <Routes>
      <Route path="/" element={<InactiveAccount />} />
      <Route path="/activateAccount" element={<ActivateAccount />} />
    </Routes>
  );
};
