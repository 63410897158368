import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  createNewTestimonial,
  updateTestimonial,
} from 'src/slices/testimonial';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PreviewTestimonial from 'src/components/testimonials/PreviewTestimonial';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  SideBarHandleContext,
  TestimonialEditionHandleContext,
} from 'src/hooks/context';
import { TestimonialStatuses } from 'src/constants/statuses';
import TestimonialEditionBody from 'src/components/testimonials/TestimonialEditionBody';
import TestimonialEditionFooter from 'src/components/testimonials/TestimonialEditionFooter';
import { ButtonBase, Dialog, Drawer, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = createUseStyles({
  sideBar: {
    width: '100%',
    flexShrink: 0,
    zIndex: 1800,
    '& .MuiDrawer-paper': {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
      overflowY: 'hidden',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar ': {
        display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
      },
      height: '100%',
    },
  },
  sideBarHeader: {},
  sideBarTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.4em',
  },
  sideBarContainer: {
    height: '100%',
    overflow: 'scroll',
  },
  sideBarFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#EDF0FF',
    height: '100px',
    width: '100%',
    justifyContent: 'center',
    position: 'sticky',
    bottom: '0px',
  },
  sideBarBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '70px',
    position: 'relative',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#F7F8FD',
      borderRadius: '60px',
    },
  },
  sideBarBodyRight: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    top: '80px',
    right: '20px',
    borderRadius: '50%',
    color: '#414354',
    zIndex: '1',
  },

  closeButtonDialog: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    borderRadius: '50%',
    color: '#414354',
    zIndex: '1',
  },

  closeDialog: {
    zIndex: 1850,
    '& .MuiDialog-paper': {
      width: 'fit-content',
      height: 'fit-content',
      textAlign: 'center',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      gap: '45px',
      alignItems: 'center',
      padding: '40px 10px 40px 10px',
    },
  },

  dialogTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: '#060640',
  },

  dialogSubtitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '29px',
    textAlign: 'center',
    color: '#060640',
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
  },

  continueDialogAction: {
    width: '200px',
    height: '60px',
    background: '#273EAC',
    borderRadius: '8px',
    color: 'white',
    gap: '10px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '15px',
  },
  quitDialogAction: {
    width: '200px',
    height: '60px',
    border: '1px solid #273EAC',
    color: '#273EAC',
    borderRadius: '8px',
    background: 'white',
    gap: '10px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '15px',
  },
});

const TestimonialEdition: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const sidebarContext = useContext(SideBarHandleContext);
  const classes = useStyles();
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const testimonialEditionContext = useContext(TestimonialEditionHandleContext);

  const validationSchema = yup.object().shape(
    {
      content: yup.string().required(t('forms.rules.required')),
      tags: yup.array().of(yup.string()),
      newTag: yup.string(),
      detailsInput: yup.string(),
      callToActionText: yup.string(),
      callToActionUrl: yup.string().url(),
      information: yup.string().optional(),
      picture: yup.string().optional(),
      logo: yup.string().optional(),
      testimonialType: yup.string(),
      color: yup.string().optional(),
      title: yup.string().optional(),
      titlePosition: yup.string().optional(),
      titleColor: yup.string().optional(),
      videoImage: yup.string().optional(),
      titleEmbedded: yup.string().optional(),
      titleColorEmbedded: yup.string().optional(),
      titleEmbeddedPosition: yup.string().optional(),
    },
    [['callToActionText', 'callToActionUrl']],
  );

  type initialShowValuesType = {
    displayPreferences: {
      [key: string]: boolean;
    };
  };

  type initialStatisticsValuesType = {
    statistics: {
      [key: string]: number;
    };
  };

  const fields = [
    'companyLogo',
    'customerPicture',
    'Date',
    'MoreInfos',
    'vertical',
  ];

  const fieldStatistics = ['clickedNumber', 'hoverNumber'];

  const initialShowValues: initialShowValuesType = {
    displayPreferences: fields.reduce((accumulator, field) => {
      const fieldShow = field.charAt(0).toUpperCase() + field.slice(1);
      if (field === 'Date') {
        return {
          ...accumulator,
          [`show${fieldShow}`]: false,
        };
      } else if (field === 'companyLogo') {
        return {
          ...accumulator,
          [`show${fieldShow}`]: false,
        };
      } else if (field === 'customerPicture') {
        return {
          ...accumulator,
          [`show${fieldShow}`]: false,
        };
      } else if (field === 'vertical') {
        return {
          ...accumulator,
          [`show${fieldShow}`]: false,
        };
      } else {
        return {
          ...accumulator,
          [`show${fieldShow}`]: true,
        };
      }
    }, {}),
  };
  const initialStatisticsValues: initialStatisticsValuesType = {
    statistics: fieldStatistics.reduce((accumulator, fieldStatistics) => {
      return {
        ...accumulator,
        [`${fieldStatistics}`]: 0,
      };
    }, {}),
  };

  const initialValues = {
    content: '',
    tags: [] as string[],
    newTag: '',
    detailsInput: '<p></p>',
    callToActionUrl: '',
    callToActionText: '',
    picture: '',
    logo: '',
    information: '',
    testimonialType: 'written',
    color: '',
    title: '',
    titlePosition: 'inside',
    titleColor: '#ffffff',
    videoImage: '',
    titleEmbedded: '',
    titleColorEmbedded: '#ffffff',
    titleEmbeddedPosition: 'center',
    variantProductId: '',
    ...initialShowValues,
    ...initialStatisticsValues,
  };

  const formikOnSubmit = async (values: {
    content: string;
    tags: string[];
    newTag: string;
    detailsInput: string;
    callToActionUrl: string;
    callToActionText: string;
    displayPreferences: {
      [key: string]: boolean;
    };
    statistics: {
      [key: string]: number;
    };
    picture: string;
    logo: string;
    testimonialType: string;
    information: string;
    color: string;
    title: string;
    titlePosition: string;
    titleColor: string;
    videoImage: string;
    titleEmbedded: string;
    titleColorEmbedded: string;
    titleEmbeddedPosition: string;
    variantProductId: string;
  }) => {
    const basicValues = {
      content: values.content,
      displayPreferences: {
        showCompanyLogo: values.displayPreferences['showCompanyLogo'],
        showCustomerPicture: values.displayPreferences['showCustomerPicture'],
        showDate: values.displayPreferences['showDate'],
        showMoreInfos: values.displayPreferences['showMoreInfos'],
        showVertical: values.displayPreferences['showVertical'],
        showSquare: values.displayPreferences['showSquare'],
      },
      tags: values.tags,
      callToActionUrl: values.callToActionUrl,
      callToActionText: values.callToActionText,
      details: values.detailsInput,
      statistics: {
        clickedNumber: values.statistics['clickedNumber'],
        hoverNumber: values.statistics['hoverNumber'],
      },
      picture: values.picture,
      logo: values.logo,
      testimonialType: values.testimonialType,
      information: values.information,
      color: values.color,
      title: values.title,
      titlePosition: values.titlePosition,
      titleColor: values.titleColor,
      videoImage: values.videoImage,
      titleEmbedded: values.titleEmbedded,
      titleColorEmbedded: values.titleColorEmbedded,
      titleEmbeddedPosition: values.titleEmbeddedPosition,
      variantProductId: values.variantProductId,
    };
    if (testimonialEditionContext?.testimonial) {
      dispatch(
        updateTestimonial({
          _id: testimonialEditionContext?.testimonial._id,
          content: basicValues.content,
          displayPreferences: basicValues.displayPreferences,
          tags: basicValues.tags,
          callToActionUrl: basicValues.callToActionUrl,
          callToActionText: basicValues.callToActionText,
          details: basicValues.details,
          status: testimonialEditionContext.testimonial.status,
          testimonialType: basicValues.testimonialType,
          picture: basicValues.picture,
          information: basicValues.information,
          logo: basicValues.logo,
          color: basicValues.color,
          title: basicValues.title,
          titlePosition: basicValues.titlePosition,
          titleColor: basicValues.titleColor,
          videoImage: basicValues.videoImage,
          titleEmbedded: basicValues.titleEmbedded,
          titleColorEmbedded: basicValues.titleColorEmbedded,
          titleEmbeddedPosition: basicValues.titleEmbeddedPosition,
          variantProductId: basicValues.variantProductId,
        }),
      );
    } else {
      dispatch(
        createNewTestimonial({
          content: basicValues.content,
          details: basicValues.details,
          displayPreferences: basicValues.displayPreferences,
          tags: basicValues.tags,
          information: basicValues.information,
          status: TestimonialStatuses.UNPROCESSED,
          callToActionText: basicValues.callToActionText,
          callToActionUrl: basicValues.callToActionUrl,
          statistics: {
            clickedNumber: basicValues.statistics['clickedNumber'],
            hoverNumber: basicValues.statistics['hoverNumber'],
          },
          testimonialType: basicValues.testimonialType,
          picture: basicValues.picture,
          logo: basicValues.logo,
          color: basicValues.color,
          title: basicValues.title,
          titlePosition: basicValues.titlePosition,
          titleColor: basicValues.titleColor,
          videoImage: basicValues.videoImage,
          titleEmbedded: basicValues.titleEmbedded,
          titleColorEmbedded: basicValues.titleColorEmbedded,
          titleEmbeddedPosition: basicValues.titleEmbeddedPosition,
          variantProductId: basicValues.variantProductId,
        }),
      );
    }
    closeTestimionialEditionSidebar();

    testimonialEditionContext?.setTutorialClicked(true);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: formikOnSubmit,
  });

  const resetForm = () => {
    formik.resetForm();
    formik.setValues({
      content: '',
      tags: [],
      newTag: '',
      detailsInput: '<p></p>',
      callToActionUrl: '',
      callToActionText: '',
      picture: '',
      logo: '',
      testimonialType: 'written',
      information: '',
      color: '',
      title: '',
      titlePosition: 'inside',
      titleColor: '#ffffff',
      videoImage: '',
      titleEmbedded: '',
      titleColorEmbedded: '#ffffff',
      titleEmbeddedPosition: 'center',
      variantProductId: '',
      ...initialShowValues,
      ...initialStatisticsValues,
    });
  };

  useEffect(() => {
    if (testimonialEditionContext?.testimonial) {
      return formik.resetForm({
        values: {
          content: testimonialEditionContext?.testimonial.content,
          tags: testimonialEditionContext?.testimonial.tags.map((tag) => tag),
          newTag: '',
          detailsInput:
            testimonialEditionContext?.testimonial.details ?? '<p></p>',
          callToActionUrl:
            testimonialEditionContext?.testimonial.callToActionUrl ?? '',
          callToActionText:
            testimonialEditionContext?.testimonial.callToActionText ?? '',
          displayPreferences: {
            ...testimonialEditionContext?.testimonial.displayPreferences,
          },
          statistics: {
            ...testimonialEditionContext?.testimonial.statistics,
          },
          picture: testimonialEditionContext.testimonial.picture ?? '',
          logo: testimonialEditionContext.testimonial.logo ?? '',
          testimonialType:
            testimonialEditionContext.testimonial.testimonialType ?? '',
          information: testimonialEditionContext.testimonial.information ?? '',
          color: testimonialEditionContext.testimonial.color ?? '',
          title: testimonialEditionContext.testimonial.title ?? '',
          titlePosition:
            testimonialEditionContext.testimonial.titlePosition ?? 'inside',
          titleColor:
            testimonialEditionContext.testimonial.titleColor ?? '#ffffff',
          videoImage: testimonialEditionContext.testimonial.videoImage ?? '',
          titleEmbedded:
            testimonialEditionContext.testimonial.titleEmbedded ?? '',
          titleColorEmbedded:
            testimonialEditionContext.testimonial.titleColorEmbedded ??
            '#ffffff',
          titleEmbeddedPosition:
            testimonialEditionContext.testimonial.titleEmbeddedPosition ??
            'center',
          variantProductId:
            testimonialEditionContext.testimonial.variantProductId ?? '',
        },
      });
    }
  }, [testimonialEditionContext?.testimonial]);

  useEffect(() => {
    if (testimonialEditionContext?.testimonialType) {
      return formik.resetForm({
        values: {
          content: '',
          tags: [] as string[],
          newTag: '',
          detailsInput: '<p></p>',
          callToActionUrl: '',
          callToActionText: '',
          picture: '',
          logo: '',
          information: '',
          testimonialType: testimonialEditionContext.testimonialType,
          color: '',
          title: '',
          titlePosition: 'inside',
          titleColor: '#ffffff',
          videoImage: '',
          titleEmbedded: '',
          titleColorEmbedded: '#ffffff',
          titleEmbeddedPosition: 'center',
          variantProductId: '',
          ...initialShowValues,
          ...initialStatisticsValues,
        },
      });
    }
  }, [testimonialEditionContext?.testimonialType]);

  useEffect(() => {
    if (testimonialEditionContext?.testimonial) {
      return formik.resetForm({
        values: {
          content:
            testimonialEditionContext?.testimonial?.content ??
            formik.values.content,
          tags:
            testimonialEditionContext?.testimonial?.tags.map((tag) => tag) ??
            formik.values.tags.filter((tag) => tag !== ''),
          newTag: '',
          detailsInput:
            testimonialEditionContext?.testimonial?.details ??
            formik.values.detailsInput,
          callToActionUrl:
            testimonialEditionContext?.testimonial?.callToActionUrl ??
            formik.values.callToActionUrl,
          callToActionText:
            testimonialEditionContext?.testimonial?.callToActionText ??
            formik.values.callToActionText,
          displayPreferences: testimonialEditionContext?.testimonial
            ? {
                ...testimonialEditionContext?.testimonial?.displayPreferences,
              }
            : formik.values.displayPreferences,
          statistics: testimonialEditionContext?.testimonial
            ? {
                ...testimonialEditionContext?.testimonial?.statistics,
              }
            : formik.values.statistics,

          picture:
            testimonialEditionContext.testimonial?.picture ??
            formik.values.picture,
          logo:
            testimonialEditionContext.testimonial?.logo ?? formik.values.logo,
          testimonialType:
            testimonialEditionContext.testimonial?.testimonialType ??
            formik.values.testimonialType,
          information:
            testimonialEditionContext.testimonial?.information ??
            formik.values.information,
          color:
            testimonialEditionContext.testimonial?.color ?? formik.values.color,

          title:
            testimonialEditionContext.testimonial?.title ?? formik.values.title,

          titlePosition:
            testimonialEditionContext.testimonial?.titlePosition ??
            formik.values.titlePosition,

          titleColor:
            testimonialEditionContext.testimonial?.titleColor ??
            formik.values.titleColor,

          videoImage:
            testimonialEditionContext.testimonial?.videoImage ??
            formik.values.videoImage,

          titleEmbedded:
            testimonialEditionContext.testimonial?.titleEmbedded ??
            formik.values.titleEmbedded,

          titleColorEmbedded:
            testimonialEditionContext.testimonial?.titleColorEmbedded ??
            formik.values.titleColorEmbedded,

          titleEmbeddedPosition:
            testimonialEditionContext.testimonial?.titleEmbeddedPosition ??
            formik.values.titleEmbeddedPosition,

          variantProductId:
            testimonialEditionContext.testimonial?.variantProductId ??
            formik.values.variantProductId,
        },
      });
    }
  }, [testimonialEditionContext?.testimonial]);

  useEffect(() => {
    if (testimonialEditionContext?.tag) {
      formik.setFieldValue('tags', [testimonialEditionContext?.tag]);
    }
  }, [testimonialEditionContext?.tag]);

  const closeTestimionialEditionSidebar = () => {
    resetForm();
    sidebarContext?.setOpenTestimonialEdition(false);
    testimonialEditionContext?.setNewTestimonial(false);
    testimonialEditionContext?.setTestimonial(undefined);
    testimonialEditionContext?.setTestimonialType('');
    testimonialEditionContext?.setTag(undefined);
    testimonialEditionContext?.setDisplayLocation(undefined);
  };

  const [openPublishDialog, setOpenPublishDialog] = useState<boolean>(false);

  const [openCloseDialog, setOpenCloseDialog] = useState<boolean>(false);

  const previewTestimonial = testimonialEditionContext && (
    <PreviewTestimonial
      content={formik.values.content}
      tags={formik.values.tags}
      displayPreferences={{
        showCompanyLogo: formik.values.displayPreferences[`showCompanyLogo`],
        showCustomerPicture:
          formik.values.displayPreferences[`showCustomerPicture`],
        showDate: formik.values.displayPreferences['showDate'],
        showMoreInfos: formik.values.displayPreferences['showMoreInfos'],
        showVertical: formik.values.displayPreferences['showVertical'],
        showSquare: formik.values.displayPreferences['showSquare'],
      }}
      callToActionText={formik.values.callToActionText}
      details={formik.values.detailsInput}
      createdAt={testimonialEditionContext?.testimonial?.createdAt}
      testimonialType={formik.values.testimonialType}
      information={formik.values.information}
      picture={formik.values.picture}
      logo={formik.values.logo}
      color={formik.values.color}
      title={formik.values.title}
      titlePosition={formik.values.titlePosition}
      titleColor={formik.values.titleColor}
      displayLocation={testimonialEditionContext?.displayLocation ?? 'plugin'}
      tag={testimonialEditionContext?.tag}
      titleEmbedded={formik.values.titleEmbedded}
      titleColorEmbedded={formik.values.titleColorEmbedded}
      titleEmbeddedPosition={formik.values.titleEmbeddedPosition}
    />
  );

  return (
    <>
      <Drawer
        className={classes.sideBar}
        variant="temporary"
        anchor="right"
        open={sidebarContext?.isTestimonialEditionOpened}
        onClose={() => closeTestimionialEditionSidebar()}
      >
        <ButtonBase
          className={classes.closeButton}
          onClick={() => {
            setOpenCloseDialog(true);
          }}
        >
          <CloseIcon />
        </ButtonBase>
        <div className={classes.sideBarBodyContainer}>
          <div className={`${classes.sideBarContainer}`}>
            <TestimonialEditionBody
              formik={formik}
              previewTestimonial={previewTestimonial}
              user={user ? user : undefined}
              displayLocation={
                testimonialEditionContext?.displayLocation ?? 'plugin'
              }
            />
          </div>
          {!(formik.isValid && formik.dirty) || formik.isSubmitting ? (
            <></>
          ) : (
            <div className={`${classes.sideBarFooter}`}>
              <TestimonialEditionFooter
                formik={formik}
                setOpenPublishDialog={setOpenPublishDialog}
                openPublishDialog={openPublishDialog}
                user={user ? user : undefined}
                tag={testimonialEditionContext?.tag}
              />
            </div>
          )}
        </div>

        <Dialog
          open={openCloseDialog}
          onClose={() => {
            setOpenCloseDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.closeDialog}
        >
          <ButtonBase
            className={classes.closeButtonDialog}
            onClick={() => {
              setOpenCloseDialog(false);
            }}
          >
            <CloseIcon />
          </ButtonBase>

          <Typography className={classes.dialogTitle}>
            {t('pages.Testimonials.askCloseDialog')}
          </Typography>

          <div className={classes.buttonContainer}>
            <ButtonBase
              onClick={() => {
                setOpenCloseDialog(false);
              }}
              className={classes.continueDialogAction}
            >
              <CheckIcon />
              {t('common.continue')}
            </ButtonBase>

            <ButtonBase
              onClick={() => {
                closeTestimionialEditionSidebar();
                setOpenCloseDialog(false);
              }}
              className={classes.quitDialogAction}
            >
              {t('common.quit')}
            </ButtonBase>
          </div>
        </Dialog>
      </Drawer>
    </>
  );
};

export default TestimonialEdition;
