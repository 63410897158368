export enum ContactStatuses {
  NEW = 'new',
  CONTACTED_ONCE = 'contactedOnce',
  CONTACTED_MORE_THAN_ONCE = 'contactedMoreThanOnce',
  DONE = 'done',
}

export enum TestimonialStatuses {
  UNPROCESSED = 'unprocessed',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}
