import {
  Autocomplete,
  ButtonBase,
  Chip,
  Dialog,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { Testimonial } from 'src/slices/testimonial';
import ReplyIcon from '@mui/icons-material/Reply';
import { User } from '../../slices/auth';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = createUseStyles({
  testimonialEditionActions: {
    background: '#273EAC',
    borderRadius: '8px',
    width: '200px',
    height: '60px',
    gap: '10px',
    color: 'white',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
  },
  testimonialEditionActionsSaveAndPublish: {},
  buttonContainer: {
    display: 'flex',
    gap: '30px',
  },
  iconSave: {
    color: '273EAC',
  },
  iconShare: {
    transform: 'rotateY(180deg)',
  },
  publishDialog: {
    zIndex: 1850,
    '& .MuiDialog-paper': {
      width: '578px',
      height: '397px',
      textAlign: 'center',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      gap: '45px',
      alignItems: 'center',
    },
    '& .MuiBackdrop-root': {
      background: '#101F41',
      opacity: '0.80 !important',
    },
  },
  textFieldTagSelector: {
    width: '351px',
    height: '50px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #8F93B8',
        borderRadius: '80px',
      },
      '& .MuiChip-root': {
        color: 'blue',
        border: '1px solid #273EAC',
        background: 'white',
      },
      '& .MuiSvgIcon-root': {
        color: '#273EAC',
      },
    },
  },
  buttonPublishAction: {
    width: '200px',
    height: '60px',
    background: '#273EAC',
    borderRadius: '80px',
    color: 'white',
    gap: '10px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '18px',
  },
  dialogTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    borderRadius: '50%',
  },
  popper: {
    maxHeight: '150px',
    overflow: 'scroll',
    zIndex: 4000,
    boxShadow: '10px 10px 30px 10px rgba(3, 3, 37, 0.10)',
    borderRadius: '0 0 8px 8px',
  },
  chipTagSelector: {
    marginRight: '5px',
    marginBottom: '5px',
    background: 'white',
    border: '1px solid #273EAC',
    color: '#273EAC',
    '& .MuiSvgIcon-root': {
      color: '#273EAC',
    },
  },
  chipContainer: {
    paddingLeft: '65px',
    paddingRight: '65px',
  },
});

interface FormValues {
  content: string;
  tags: string[];
  newTag: string;
  detailsInput: string;
  callToActionUrl: string;
  callToActionText: string;
  displayPreferences: {
    [key: string]: boolean;
  };
  statistics: {
    [key: string]: number;
  };
  information: string;
  picture: string;
  logo: string;
  testimonialType: string;
  color: string;
  title: string;
  titlePosition: string;
  titleColor: string;
  videoImage: string;
  titleEmbedded: string;
  titleColorEmbedded: string;
  titleEmbeddedPosition: string;
  variantProductId: string;
}

interface Props {
  formik: FormikProps<FormValues>;
  testimonial?: Testimonial;
  setOpenPublishDialog: any;
  openPublishDialog: boolean;
  user?: User;
  tag?: string;
}

const TestimonialEditionFooter: React.FC<Props> = ({
  formik,
  setOpenPublishDialog,
  openPublishDialog,
  user,
  tag,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [listTag, setListTag] = useState<string[]>([]);

  useEffect(() => {
    user?.settings.pluginSettings.tagsList.map((tag) => {
      setListTag((current) => {
        const updatedList = [...current, tag.tag];
        return Array.from(new Set(updatedList));
      });
    });
  }, [user]);

  const filterOptions = (options: string[], {}: any) => {
    // Filtrer les options qui ne sont pas encore sélectionnées
    return options.filter(
      (option) => !formik.values.tags.some((tag: string) => tag === option),
    );
  };

  const handleRemove = (option: any) => {
    formik.setFieldValue(
      'tags',
      formik.values.tags.filter((selected) => selected !== option),
    );
  };

  const handleSelect = (_: any, value: any) => {
    formik.setFieldValue('tags', value);
  };

  const saveTestimonial = async () => {
    await formik.submitForm();
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <ButtonBase
          className={classes.testimonialEditionActions}
          onClick={() => {
            if (tag) {
              formik.setFieldValue('tags', [tag]);
              saveTestimonial();
            } else {
              setOpenPublishDialog(true);
            }
          }}
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
          style={
            !(formik.isValid && formik.dirty) || formik.isSubmitting
              ? { color: 'grey', background: 'none', border: '1px solid grey' }
              : {}
          }
        >
          <ReplyIcon className={classes.iconShare} />
          {t('pages.Testimonials.actions.publish')}
        </ButtonBase>
      </div>
      <Dialog
        open={openPublishDialog}
        onClose={() => {
          setOpenPublishDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.publishDialog}
      >
        <ButtonBase
          className={classes.closeIcon}
          onClick={() => {
            setOpenPublishDialog(false);
          }}
        >
          <CloseIcon />
        </ButtonBase>

        <Typography className={classes.dialogTitle}>
          {t('pages.Testimonials.addLinkedPage')}
        </Typography>

        <div>
          <Autocomplete
            multiple
            id="tags-standard"
            options={listTag ?? []}
            value={formik.values.tags}
            getOptionLabel={(option) => option}
            filterOptions={filterOptions}
            onChange={handleSelect}
            renderTags={() => <></>}
            style={{ marginBottom: '20px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Home page.."
                className={classes.textFieldTagSelector}
              />
            )}
            PopperComponent={(popperProps) => (
              <Popper
                {...popperProps}
                className={classes.popper}
                placement="bottom-start"
              />
            )}
            disablePortal
          />
          <div className={classes.chipContainer}>
            {formik.values.tags.map((option: any, index: number) => (
              <Chip
                key={index}
                label={option}
                onDelete={() => handleRemove(option)}
                className={classes.chipTagSelector}
              />
            ))}
          </div>
        </div>
        <ButtonBase
          onClick={() => {
            saveTestimonial();
            setOpenPublishDialog(false);
          }}
          className={classes.buttonPublishAction}
        >
          <ReplyIcon className={classes.iconShare} />
          {t('pages.Testimonials.actions.publish')}
        </ButtonBase>
      </Dialog>
    </>
  );
};

export default TestimonialEditionFooter;
