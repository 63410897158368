import React, { useEffect, useState } from 'react';
import { FullscreenSpin } from 'src/components/basic/FullscreenSpin';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { sendAuthorizationCode } from 'src/slices/auth';
import i18n from 'src/locales/i18n';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useNavigate } from 'react-router-dom';

export const SignUpShopify = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shopifyApp = useAppBridge();
  const [executed, setIsExecuted] = useState(false);

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authorizationCode = await shopifyApp.idToken();
        const shop = shopifyApp.config.shop;

        if (
          authorizationCode &&
          shop &&
          !executed &&
          (!user || user?.email != shop)
        ) {
          setIsExecuted(true);
          dispatch(
            sendAuthorizationCode({
              authorizationCode,
              shop,
              locale: i18n.language,
            }),
          ).then((e) => {
            if (e.meta.requestStatus === 'fulfilled') {
              navigate('/website');
            }
          });
        } else {
          navigate('/website');
        }
      } catch (error) {
        // Handle error
        console.log('Error while fetching authorization code', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div
      title="Register | Kudeo"
      style={{
        height: '100%',
        backgroundImage: `url('/background.svg')`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FullscreenSpin />
    </div>
  );
};
