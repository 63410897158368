import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link as LinkRouter, useLocation } from 'react-router-dom';
import {
  Box,
  ListItemText,
  List,
  ListItem,
  TextField,
  Typography,
  Avatar,
  Autocomplete,
  Dialog,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  adminGetAllUsers,
  fetchUserById,
  logout,
  updatePluginSettings,
  updateRememberMeLater,
  updateUserStep,
  updateWhyUseKudeo,
} from 'src/slices/auth';
import TestimonialEdition from './testimonials/TestimonialEdition';
import Page from 'src/components/basic/Page';
import TestimonialsListSelector from './testimonials/TestimonialsListSelector';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ButtonBase from '@mui/material/ButtonBase';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import UpgradeDialog from 'src/components/UpgradeDialog';
import { paymentPortal } from 'src/slices/payment';
import PaymentIcon from '@mui/icons-material/Payment';
import { useAppBridge } from '@shopify/app-bridge-react';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

const useStyles = createUseStyles({
  navbarContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#060640',
    padding: '24px 16px 32px 16px',
  },

  navbarLogo: {
    width: '139px',
    height: '37px',
  },

  navbarListItems: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  topMenuItem: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  botMenuItem: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },

  warningScriptNotIntegratedContainer: {
    display: 'flex',
    width: '210px',
    padding: '16px',
    background: 'rgb(255, 184, 77)',
    borderRadius: '8px',
    flexDirection: 'column',
    gap: '14px',
  },
  scriptNotIntegratedTypographyTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '15px',
    color: '#060640',
    textAlign: 'center',
  },
  scriptNotIntegratedButton: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: 'white',
    background: '#273EAC',
    borderRadius: '8px',
    padding: '10px',
  },
  scriptNotIntegratedTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    color: '#060640',
  },

  navbarItem: {
    borderRadius: '10px',
    height: '50px',
    position: 'relative',
    width: '210px',
    paddingLeft: '16px',
  },

  helpItems: {
    borderRadius: '10px',
    height: '50px',
    position: 'relative',
    width: '210px',
    paddingLeft: '16px',
    background: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#060640',
  },

  statsItems: {
    borderRadius: '10px',
    left: '40px',
    height: '50px',
    position: 'relative',
    width: '170px',
    paddingLeft: '16px',
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      background: 'white',
    },
  },
  navbarItemText: {
    '& span': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '14px',
    },
  },
  navbarActiveItem: {
    background: 'white',
    '&:hover': {
      background: 'white',
    },
  },
  navbarItemLogout: {
    '&:before': {
      display: 'none',
    },
  },

  loggedLayoutContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'rgba(39, 62, 172, 0.08)',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  pageContainer: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  loggedLayoutNavbar: {
    height: '100%',
    display: 'flex',
    position: 'sticky',
    zIndex: 2,
  },
  loggedLayoutContent: {
    height: '100%',
    width: '100%',
    // paddingTop: '32px',
    // paddingLeft: '10px',
    // paddingBottom: '10px',
    overflow: 'hidden',
  },
  loggedLayoutBox: {
    flexDirection: 'column',
    width: '100%',
  },
  iconMenu: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  headerLayoutNavbar: {
    height: '72px',
    width: '100%',
    zIndex: 2000,
    position: 'sticky',
    boxShadow: '0px 0px 4px rgba(13, 17, 51, 0.2)',
    backgroundColor: 'white',
    display: 'flex',
    padding: '15px 40px 15px 40px',
    gap: '135px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoContainer: {
    flex: 1,
    maxWidth: '150px',
  },
  userContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '490px',
    border: 'solid 1px',
  },
  upgradeContainer: {
    height: '50px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlignLast: 'center',
    background: '#060640',
    padding: '10px 30px 10px 20px',
    flex: 1,
  },
  notificationContainer: {
    position: 'absolute',
    right: '515px',
    top: '14px',
    background: '#273EAC',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  usersDrowDownMenuButton: {
    position: 'absolute',
    left: '200px',
    top: '4px',
  },
  shareButton: {
    width: '190px',
    height: '60px',
    color: '#273EAC',
    borderRadius: '20px',
    border: '1px solid #273EAC',
  },
  iconStep: {
    marginRight: '9px',
    color: '#273EAC',
  },
  disableLink: {
    pointerEvents: 'none',
  },

  upgradePageContainer: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  timeRemainingContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    gap: '8px',
  },
  typographyTimeRemaining: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '13px',
    color: 'white',
    '& span': {
      fontWeight: '700',
    },
  },
  subscribeButton: {
    borderRadius: '8px',
    alignItems: 'center',
    background: '#273EAC',
    flex: 1,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    textDecoration: 'none',
  },
  typographySubscribeButton: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '14px',
    color: 'white',
    textDecoration: 'none',
    letterSpacing: '-0.4px',
  },
  tutorialDialog: {
    zIndex: 1600,
    '& .MuiDialog-paper': {
      width: '100%',
      borderRadius: '8px',
      background: 'white',
    },
  },
  tutorialContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#060640',
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginBottom: '22px',
    marginRight: '-37px',
  },
  subTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '140%',
    color: '#060640',
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginBottom: '40px',
  },
  dialogInformation: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 16px 16px 16px',
    background: 'rgba(47, 128, 237, 0.15)',
    borderRadius: '8px',
    marginBottom: '50px',
  },
  informationText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
  },
  addDomainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  addDomainTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
    marginRight: '16px',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  tutorialContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingLeft: '72px',
    paddingRight: '72px',
  },
  textFieldAddDomain: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },

    '& .MuiOutlinedInput-root': {
      width: '100%',
      height: '40px',
      borderRadius: '8px',
      border: '1px solid #CED3E8',
      background: 'white',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #CED3E8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #CED3E8',
    },
    '& fieldset': {
      border: 'none',
    },
  },

  textFieldAddDomainWarning: {
    '& .MuiOutlinedInput-root': {
      border: '1px solid #FF5B5B',
      borderRadius: '8px',
    },
  },

  needHelp: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: '150px',
    backgroundColor: 'rgba(47, 128, 237, 0.15)',
    borderRadius: '8px',
    padding: '12px 0px 12px 16px',
    alignSelf: 'flex-end',
    marginBottom: '16px',
  },
  CTAContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    alignItems: 'center',
  },
  finishButton: {
    width: '100%',
    height: '60px',
    background: '#273EAC',
    borderRadius: '8px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    textDecoration: 'none',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
  },
  remindmeLater: {
    color: '#273EAC',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    marginBottom: '95px',
  },

  whyUseKudeoTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#060640',
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginBottom: '32px',
    textAlign: 'center',
  },

  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    marginBottom: '32px',
  },
  optionCard: {
    borderRadius: '14px',
    border: '1px solid #D9D9D9',
    paddingTop: '24px',
    paddingBottom: '24px',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '14px',
    paddingRight: '26px',
    cursor: 'pointer',
    alignItems: 'center',
    gap: '16px',
  },

  optionDialog: {
    zIndex: 1600,
    '& .MuiDialog-paper': {
      width: '100%',
      borderRadius: '8px',
      background: 'white',
    },
  },
  optionDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 40px 50px 40px',
  },

  optionCardTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#060640',
  },
  optionCardDescription: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#060640',
  },
  doneContainer: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionCardActive: {
    border: '1px solid #EDF0FF',
    background: '#EDF0FF',
  },

  optionContainerTypography: {
    display: 'flex',
    flexDirection: 'column',
  },

  optionCardInactive: {
    paddingLeft: '70px',
  },

  linkDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px',
  },
});

interface Props {
  children: React.ReactNode;
}

declare global {
  interface Window {
    mainFunction?: (params: any) => void;
  }
}

export const LoggedLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [timeRemaining, setTimeRemaining] = useState(7);
  const { user, isAdmin, rememberMeLaterState } = useAppSelector(
    ({ auth: { user, isAdmin, rememberMeLaterLogin } }) => ({
      user,
      isAdmin,
      rememberMeLaterState: rememberMeLaterLogin,
    }),
  );

  const createPortal = async () => {
    const result = await dispatch(paymentPortal());
    const url = result.payload as string;
    window.location.href = url;
  };

  useEffect(() => {
    if (rememberMeLaterState) {
      setOpenCongratulation(true);
    }
  }, [rememberMeLaterState]);

  const [step, setStep] = useState(user?.step ?? 3);

  useEffect(() => {
    if (user?.step) {
      setStep(user.step);
    }
  }, [user?.step]);

  const [openCongratulationDialog, setOpenCongratulation] =
    useState<boolean>(false);

  const [openWhyUseKudeoDialog, setOpenWhyUseKudeoDialog] =
    useState<boolean>(false);

  const [openOfferDialog, setOpenOfferDialog] = useState<boolean>(false);

  const [openAddScriptDialog, setOpenAddScriptDialog] =
    useState<boolean>(false);

  const [openLinkDialog, setOpenLinkDialog] = useState<boolean>(false);

  useEffect(() => {
    if (step === 0) {
      setOpenCongratulation(true);
    }
    if (step === 1) {
      setOpenWhyUseKudeoDialog(true);
    }
  }, [step]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "jrolwop8",
      name: ${JSON.stringify(user?.firstName)},
      user_id: ${JSON.stringify(user?._id)}, 
      email: ${JSON.stringify(user?.email)}, 
      created_at: ${JSON.stringify(user?.createdAt)}
    };

    (function(){
      var w=window;
      var ic=w.Intercom;
      if(typeof ic==="function"){
        ic('reattach_activator');
        ic('update',w.intercomSettings);
      } else {
        var d=document;
        var i=function(){i.c(arguments);};
        i.q=[];i.c=function(args){i.q.push(args);};
        w.Intercom=i;
        var l=function(){
          var s=d.createElement('script');
          s.type='text/javascript';
          s.async=true;
          s.src='https://widget.intercom.io/widget/jrolwop8';
          var x=d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s,x);
        };
        if(document.readyState==='complete'){
          l();
        } else if(w.attachEvent){
          w.attachEvent('onload',l);
        } else {
          w.addEventListener('load',l,false);
        }
      }
    })();

    window.Intercom('boot', {
      app_id: 'jrolwop8',
    });
  `;

    document.body.appendChild(script);

    return () => {
      script.remove();
    };
  }, [user]);

  const [copiedUrl, setCopiedUrl] = useState(false);

  const link = '<script async src="https://plugin.kudeo.co/shim.js"></script>';

  const [copiedUrlForm, setCopiedUrlForm] = useState(false);

  const linkForm = `${process.env.REACT_APP_FRONTEND_URL}/testimonials/user/${user?._id}`;

  const [newDomain, setNewDomain] = useState('');

  const [onHoveredOptionContainer, setOnHoveredOptionContainer] = useState<
    boolean[]
  >([false, false, false]);

  const clickedHelp = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    Intercom('show');`;
    document.body.appendChild(script);
    script.onload = () => {
      script.remove();
    };
  };

  const clickedLogout = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    Intercom('hide');`;
    document.body.appendChild(script);
    script.onload = () => {
      script.remove();
    };
  };

  const [onClickedOptionContainer, setOnClickedOptionContainer] = useState<
    boolean[]
  >([false, false, false]);

  const remindMeLater = (remindMeLater: boolean) => {
    if (user?._id) {
      setOpenCongratulation(false);

      if (user?.step === 0) {
        dispatch(updateUserStep({ id: user?._id, step: 1 }));
      }

      dispatch(
        updateRememberMeLater({
          id: user._id,
          rememberMeLater: remindMeLater,
        }),
      );
    }
  };

  const sendWhyUseKudeo = () => {
    if (onClickedOptionContainer[0]) {
      dispatch(
        updateWhyUseKudeo({
          id: user?._id,
          whyUseKudeo: 'VideoTestimonials',
        }),
      );
    } else if (onClickedOptionContainer[1]) {
      dispatch(
        updateWhyUseKudeo({ id: user?._id, whyUseKudeo: 'egcRecruitment' }),
      );
    } else if (onClickedOptionContainer[2]) {
      dispatch(
        updateWhyUseKudeo({ id: user?._id, whyUseKudeo: 'egcECommerce' }),
      );
    }
    dispatch(updateUserStep({ id: user?._id, step: 1 }));
  };

  const finishAction = () => {
    if (user?._id) {
      dispatch(updatePluginSettings({ websiteUrl: newDomain }));
    }
  };

  const Linked = ({
    to,
    style,
    children,
  }: {
    to: string;
    style: any;
    children: React.ReactNode;
  }) => {
    return (
      <LinkRouter to={to} style={style}>
        {children}
      </LinkRouter>
    ) as unknown as JSX.Element;
  };

  useEffect(() => {
    if (user?.createdAt) {
      const currentDate = new Date();
      const expirationDate = new Date(user.createdAt);
      expirationDate.setDate(expirationDate.getDate() + 8);
      const timeRemaining = expirationDate.getTime() - currentDate.getTime();
      const daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      setTimeRemaining(daysRemaining);
    }
  }, []);

  const { users } = useAppSelector(({ auth: { users } }) => ({
    users,
  }));

  useEffect(() => {
    if (isAdmin) {
      dispatch(adminGetAllUsers());
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openT = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickUser = async (id: string) => {
    dispatch(fetchUserById(id));
  };

  const [invalidDomain, setInvalidDomain] = useState(true);

  const [warningTextfield, setWarningTextfield] = useState(false);

  useEffect(() => {
    if (!invalidDomain) {
      setWarningTextfield(false);
    }
  }, [invalidDomain]);

  useEffect(() => {
    if (newDomain) {
      const regex = new RegExp(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      );
      if (regex.test(newDomain)) {
        setInvalidDomain(false);
      } else {
        setInvalidDomain(true);
      }
    }
  }, [newDomain]);

  const stepsTop = user?.isShopifyAccount
    ? ['dashboard', 'website']
    : ['dashboard', 'website', 'testimonials'];
  // const stepsTop = ['dashboard', 'website'];

  const [hideOffer, setHideOffer] = useState(false);

  useEffect(() => {
    if (user?.hideOffer) {
      setHideOffer(true);
    }
    if (user?._id === '654cff6144abb7a43e4a0a9b') {
      setHideOffer(true);
    }
  }, [user]);

  const stepsBot = hideOffer ? ['settings'] : ['settings', 'offer'];

  const friendlyStepsName: { [key: string]: string } = {
    website: t('common.website'),
    testimonials: t('common.testimonials'),
    settings: t('common.settings'),
    dashboard: t('common.dashboard'),
    offer: t('common.offer'),
  };

  const iconStep = (step: string, isHere: boolean) => (
    <div className={classes.iconStep}>
      {step === 'website' ? (
        <img src={`/static/icons/website${isHere ? 'Blue' : 'White'}.svg`} />
      ) : step === 'testimonials' ? (
        <img
          src={`/static/icons/testimonials${isHere ? 'Blue' : 'White'}.svg`}
        />
      ) : step === 'settings' ? (
        <img src={`/static/icons/settings${isHere ? 'Blue' : 'White'}.svg`} />
      ) : step === 'dashboard' ? (
        <img src={`/static/icons/dashboard${isHere ? 'Blue' : 'White'}.svg`} />
      ) : step === 'offer' ? (
        <img src={`/static/icons/offers${isHere ? 'Blue' : 'White'}.svg`} />
      ) : null}
    </div>
  );

  const [isHoveredHelpButton, setIsHoveredHelpButton] = useState(false);

  const drawer = (
    <div className={classes.navbarContainer}>
      <List className={classes.navbarListItems}>
        <div className={classes.topMenuItem}>
          {stepsTop.map((stepLocation, index) => {
            const pathname =
              location.pathname === '/'
                ? 'website'
                : location.pathname.slice(1);

            return (
              <Linked
                to={`/${stepLocation}`}
                style={{ textDecoration: 'none' }}
                key={index}
              >
                <ListItem
                  className={[
                    classes.navbarItem,
                    pathname === stepLocation && classes.navbarActiveItem,
                  ].join(' ')}
                  button
                >
                  {iconStep(stepLocation, pathname === stepLocation)}
                  <ListItemText
                    className={classes.navbarItemText}
                    primary={friendlyStepsName[stepLocation]}
                    sx={
                      pathname === stepLocation
                        ? { '& span': { color: '#060640' } }
                        : { '& span': { color: 'white' } }
                    }
                  />
                </ListItem>
              </Linked>
            );
          })}
        </div>

        {user?.isAdmin ? (
          <LinkRouter to="/admin" style={{ textDecoration: 'none' }}>
            <ListItem
              className={[
                classes.navbarItem,
                location.pathname === '/admin' && classes.navbarActiveItem,
              ].join(' ')}
              sx={
                location.pathname === '/admin'
                  ? { '& span': { color: '#060640' } }
                  : { '& span': { color: 'white' } }
              }
              button
            >
              <AdminPanelSettingsOutlinedIcon
                className={classes.iconStep}
                style={location.pathname === '/admin' ? {} : { color: 'white' }}
              />
              <ListItemText
                className={classes.navbarItemText}
                primary={'Admin'}
              />
            </ListItem>
          </LinkRouter>
        ) : null}
        {user?.isScriptIntegrated === false && (
          <div className={classes.warningScriptNotIntegratedContainer}>
            <Typography
              className={classes.scriptNotIntegratedTypographyTitle}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 8 8"
                fill="none"
                style={{ marginRight: '8px', textAlign: 'center' }}
              >
                <circle cx="4" cy="4" r="4" fill="red" />
              </svg>
              {t('common.contentNotPublished')}
            </Typography>
            <Typography className={classes.scriptNotIntegratedTypography}>
              {t('common.finishInstallation')}
            </Typography>
            <ButtonBase
              className={classes.scriptNotIntegratedButton}
              onClick={() => {
                setOpenAddScriptDialog(true);
              }}
            >
              {t('common.finishInstallationButton')}
            </ButtonBase>
          </div>
        )}

        <div className={classes.botMenuItem}>
          {stepsBot.map((stepLocation, index) => {
            const pathname =
              location.pathname === '/'
                ? 'website'
                : location.pathname.slice(1);

            if (stepLocation === 'offer') {
              return (
                <ButtonBase
                  style={{ textDecoration: 'none' }}
                  key={index}
                  onClick={() => {
                    setOpenOfferDialog(true);
                  }}
                >
                  <ListItem
                    className={[
                      classes.navbarItem,
                      pathname === stepLocation && classes.navbarActiveItem,
                    ].join(' ')}
                    button
                  >
                    {iconStep(stepLocation, pathname === stepLocation)}
                    <ListItemText
                      className={classes.navbarItemText}
                      primary={friendlyStepsName[stepLocation]}
                      sx={
                        pathname === stepLocation
                          ? { '& span': { color: '#060640' } }
                          : { '& span': { color: 'white' } }
                      }
                    />
                  </ListItem>
                </ButtonBase>
              );
            } else {
              return (
                <Linked
                  to={`/${stepLocation}`}
                  style={{ textDecoration: 'none' }}
                  key={index}
                >
                  <ListItem
                    className={[
                      classes.navbarItem,
                      pathname === stepLocation && classes.navbarActiveItem,
                    ].join(' ')}
                    button
                  >
                    {iconStep(stepLocation, pathname === stepLocation)}
                    <ListItemText
                      className={classes.navbarItemText}
                      primary={friendlyStepsName[stepLocation]}
                      sx={
                        pathname === stepLocation
                          ? { '& span': { color: '#060640' } }
                          : { '& span': { color: 'white' } }
                      }
                    />
                  </ListItem>
                </Linked>
              );
            }
          })}

          <ButtonBase
            onClick={() => {
              clickedHelp();
            }}
            style={{ textDecoration: 'none' }}
          >
            <ListItem
              className={classes.helpItems}
              button
              style={
                isHoveredHelpButton
                  ? { gap: '8px', color: 'white' }
                  : { gap: '8px' }
              }
              onMouseEnter={() => {
                setIsHoveredHelpButton(true);
              }}
              onMouseLeave={() => {
                setIsHoveredHelpButton(false);
              }}
            >
              <img src={`/static/icons/helpIcon.svg`} />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="4" cy="4" r="4" fill="#FFC001" />
                <circle cx="4" cy="4" r="4" fill="#08E8AF" />
              </svg>

              {t('common.help')}
            </ListItem>
          </ButtonBase>
        </div>
      </List>
    </div>
  );

  const drawerHeader = (
    <>
      <div className={classes.logoContainer}>
        <LinkRouter to="/">
          <Box component="img" src="/static/icons/Logo.svg" />
        </LinkRouter>
      </div>

      {user?.subscriptionType === 'freetrial' && (
        <div className={classes.upgradeContainer}>
          <div className={classes.timeRemainingContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22.9727 18.7084L13.8454 3.12654C13.02 1.71563 10.98 1.71563 10.1509 3.12654L1.02361 18.7084C0.187245 20.1338 1.21634 21.9302 2.87088 21.9302H21.1291C22.78 21.9302 23.8091 20.1375 22.9727 18.7084ZM11.9982 6.88291C12.7945 6.88291 13.4272 7.55563 13.3836 8.352L12.98 15.2175H11.0163L10.6127 8.352C10.5691 7.55563 11.2018 6.88291 11.9982 6.88291ZM11.9982 19.6284C11.1872 19.6284 10.5291 18.9702 10.5291 18.1593C10.5291 17.3484 11.1872 16.6902 11.9982 16.6902C12.8091 16.6902 13.4672 17.3484 13.4672 18.1593C13.4672 18.9702 12.8091 19.6284 11.9982 19.6284Z"
                fill="#FFC001"
              />
            </svg>
            <Typography className={classes.typographyTimeRemaining}>
              {t('common.trialLeft')}{' '}
              <span>
                {timeRemaining} {t('common.days')}
              </span>{' '}
              {t('common.trialLeft2')}
            </Typography>
          </div>

          <ButtonBase
            className={classes.subscribeButton}
            onClick={() => {
              setOpenOfferDialog(true);
            }}
          >
            <Typography className={classes.typographySubscribeButton}>
              {t('common.subscribeToKudeo')}
            </Typography>
          </ButtonBase>
        </div>
      )}

      {isAdmin && (
        <Autocomplete
          className={classes.usersDrowDownMenuButton}
          disablePortal
          id="combo-box-demo"
          getOptionLabel={(option) => {
            if (option?.company) {
              return option.company;
            } else {
              return option?.email;
            }
          }}
          options={users}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Choose a user" />
          )}
          onChange={(event, value) => {
            if (value?._id) {
              clickUser(value?._id);
            }
          }}
        />
      )}

      <div className={classes.userContainer}>
        <Avatar
          alt="avatar"
          src={user?.profilePictureUrl}
          aria-label="avatar"
          className={classes.avatar}
        />
        <ButtonBase
          aria-controls={openT ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openT ? 'true' : undefined}
          onClick={handleClick}
        >
          <Typography
            style={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '14px',
              color: '#060640',
            }}
          >
            {user?.firstName} {user?.lastName}
          </Typography>

          <ArrowDropDownIcon />
        </ButtonBase>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={openT}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: '2000' }}
      >
        <MenuItem onClick={handleClose}>
          <LinkRouter
            to={'/settings/profil/true'}
            style={{
              textDecoration: 'none',
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontFamily: 'Poppins',
              fontSize: '16px',
            }}
          >
            <AccountCircleIcon style={{ marginRight: '2px' }} />
            {t('pages.Settings.userSettings')}
          </LinkRouter>
        </MenuItem>
        {user?.stripeCustomerId && (
          <MenuItem
            onClick={() => {
              createPortal();
              handleClose();
            }}
          >
            <ButtonBase
              style={{
                textDecoration: 'none',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontFamily: 'Poppins',
                fontSize: '16px',
              }}
            >
              <PaymentIcon style={{ marginRight: '2px' }} />
              {t('common.billing')}
            </ButtonBase>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setOpenLinkDialog(true);
          }}
          style={{
            textDecoration: 'none',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontFamily: 'Poppins',
            fontSize: '16px',
          }}
        >
          <FormatAlignJustifyIcon />
          {t('common.formLink')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(logout());
            setAnchorEl(null);
            clickedLogout();
          }}
          style={{
            textDecoration: 'none',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontFamily: 'Poppins',
            fontSize: '16px',
          }}
        >
          <LogoutIcon />
          {t('common.logout')}
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <Page title="Kudeo">
        <div className={classes.pageContainer}>
          {!user?.isShopifyAccount && (
            <div
              className={classes.headerLayoutNavbar}
              style={step < 3 ? { zIndex: '1500' } : {}}
            >
              {drawerHeader}
            </div>
          )}

          <div className={classes.loggedLayoutContainer}>
            <div className={classes.loggedLayoutNavbar}>{drawer}</div>

            <div className={`${classes.loggedLayoutBox}`}>
              <div className={classes.loggedLayoutContent}>{children}</div>
            </div>
          </div>
        </div>

        <TestimonialEdition />
        <TestimonialsListSelector />
      </Page>

      <Dialog
        open={openCongratulationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.tutorialDialog}
      >
        <div className={classes.tutorialContainer}>
          <div className={classes.title}>
            {t('common.congratulation')}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <g clipPath="url(#clip0_494_1609)">
                <path
                  d="M9.84911 16.6377C9.82885 16.6377 9.80858 16.6369 9.78808 16.6357L0.978014 16.0857C0.642808 16.0649 0.342027 15.8733 0.181383 15.5786C0.0209821 15.2839 0.0229352 14.9272 0.186998 14.6343C0.23314 14.5517 1.34349 12.594 3.45799 10.8865C6.31542 8.57933 9.6372 7.83103 13.064 8.72264C13.3484 8.79661 13.5842 8.99485 13.7063 9.26219C13.8284 9.52952 13.8235 9.83763 13.6929 10.1008L10.7239 16.0947C10.5583 16.4287 10.2183 16.6377 9.84911 16.6377Z"
                  fill="#FF5B5B"
                />
                <path
                  d="M16.9518 31.9996C16.7912 31.9996 16.6306 31.96 16.4848 31.8804C16.1901 31.7198 15.9985 31.4193 15.9775 31.0841L15.4277 22.274C15.4033 21.8817 15.6164 21.5128 15.9687 21.3382L21.9626 18.369C22.226 18.2386 22.5339 18.2337 22.8012 18.3558C23.0688 18.4776 23.2668 18.7137 23.3408 18.9981C24.2326 22.4249 23.4843 25.7466 21.1769 28.6041C19.4697 30.7186 17.5117 31.8287 17.4291 31.8751C17.2809 31.9581 17.1164 31.9996 16.9518 31.9996Z"
                  fill="#FF193D"
                />
                <path
                  d="M30.4957 7.87964L24.1832 1.56714C23.9303 1.31421 23.5592 1.21997 23.2161 1.32129C18.9903 2.57129 15.5213 4.53174 12.9049 7.14795C12.3592 7.69385 11.835 8.2854 11.3472 8.90601C9.92878 10.7095 8.74397 12.8418 7.826 15.2439C7.24128 16.7749 6.76521 18.415 6.41096 20.1187C5.79231 23.0945 5.7591 25.2273 5.75788 25.3167C5.75471 25.5796 5.85774 25.833 6.04377 26.0191C6.22688 26.2022 6.47541 26.3049 6.7342 26.3049H6.74617C6.83576 26.304 8.96858 26.2708 11.9442 25.6521C13.6478 25.2979 15.2879 24.8218 16.8192 24.2368C19.221 23.3189 21.3533 22.1343 23.1563 20.7161C23.7774 20.228 24.369 19.7039 24.9149 19.158C27.5313 16.5415 29.4918 13.0725 30.7415 8.84668C30.8431 8.50366 30.7486 8.13257 30.4957 7.87964Z"
                  fill="#E1F1FA"
                />
                <path
                  d="M12.6114 24.1795L7.88192 19.45C7.5328 19.1008 6.97787 19.0674 6.5892 19.3721C6.28719 19.6089 5.99984 19.8633 5.73519 20.1279C2.44442 23.4187 2.34261 28.5176 2.33993 28.7329C2.33651 28.9961 2.43954 29.2495 2.62581 29.4356C2.80892 29.6189 3.05721 29.7217 3.316 29.7217C3.32015 29.7217 3.3243 29.7217 3.32845 29.7214C3.54378 29.7188 8.64291 29.617 11.9334 26.3262C12.1981 26.0615 12.4525 25.7742 12.6893 25.4722C12.994 25.0835 12.9605 24.5286 12.6114 24.1795Z"
                  fill="#FFF261"
                />
                <path
                  d="M7.90259 15.0469L5.00098 17.9487C4.81787 18.1316 4.71484 18.3799 4.71484 18.6389C4.71484 18.8977 4.81787 19.146 5.00098 19.3291L12.7332 27.0613C12.916 27.2444 13.1643 27.3472 13.4233 27.3472C13.6824 27.3472 13.9304 27.2444 14.1135 27.0613L17.0151 24.1597L7.90259 15.0469Z"
                  fill="#1B3B6B"
                />
                <path
                  d="M20.5706 14.4719C19.7751 14.4719 19.0271 14.1621 18.4646 13.5996C17.3035 12.4385 17.3035 10.5493 18.4646 9.38794C19.0271 8.82544 19.7751 8.51562 20.5706 8.51562C21.366 8.51562 22.114 8.82544 22.6765 9.38794C23.239 9.95044 23.5488 10.6982 23.5488 11.4939C23.5488 12.2893 23.239 13.0371 22.6765 13.5996C22.114 14.1621 21.366 14.4719 20.5706 14.4719Z"
                  fill="#06CCAB"
                />
                <path
                  d="M20.5676 15.4263C19.517 15.4263 18.5295 15.0171 17.7868 14.2744C16.2534 12.741 16.2534 10.2459 17.7868 8.7124C18.5295 7.96973 19.5173 7.56055 20.5676 7.56055C21.6181 7.56055 22.6057 7.96973 23.3486 8.7124C24.0915 9.45532 24.5004 10.4429 24.5004 11.4934C24.5004 12.5439 24.0915 13.5315 23.3486 14.2744C22.6057 15.0171 21.6181 15.4263 20.5676 15.4263ZM20.5676 9.51294C20.0388 9.51294 19.5412 9.719 19.1672 10.093C18.3952 10.8652 18.3952 12.1216 19.1672 12.8938C19.5412 13.2678 20.0388 13.4739 20.5676 13.4739C21.0966 13.4739 21.5939 13.2678 21.968 12.8938C22.342 12.5198 22.548 12.0225 22.548 11.4934C22.548 10.9644 22.342 10.467 21.968 10.093C21.5939 9.719 21.0966 9.51294 20.5676 9.51294Z"
                  fill="#1B3B6B"
                />
                <path
                  d="M31.7786 0.286069C31.5923 0.0997892 31.3374 -0.00494715 31.0757 0.000179805C30.9309 0.00188879 27.48 0.0599943 23.2188 1.32025L30.7441 8.84564C32.0044 4.58441 32.0625 1.13348 32.0642 0.988706C32.0676 0.725522 31.9646 0.472104 31.7786 0.286069Z"
                  fill="#FF5B5B"
                />
                <path
                  d="M30.4949 7.87891L27.3386 4.72266L6.04297 26.0183C6.22607 26.2014 6.47437 26.3042 6.73315 26.3042H6.74536C6.83472 26.3032 8.96753 26.27 11.9434 25.6514C13.647 25.2971 15.2869 24.8211 16.8181 24.2361C19.2202 23.3181 21.3525 22.1336 23.1555 20.7153C23.7764 20.2273 24.3682 19.7031 24.9138 19.1572C27.5303 16.5408 29.4907 13.0718 30.7405 8.84595C30.8423 8.50293 30.7478 8.13184 30.4949 7.87891Z"
                  fill="#CDEBFC"
                />
                <path
                  d="M12.6108 24.1812L10.2461 21.8164L2.625 29.4373V29.4375C2.80835 29.6206 3.05664 29.7234 3.31543 29.7234H3.32764C3.54321 29.7207 8.64209 29.6187 11.9329 26.3279C12.1975 26.0632 12.4519 25.7759 12.6885 25.4739C12.9934 25.0852 12.9602 24.5303 12.6108 24.1812Z"
                  fill="#FDBF00"
                />
                <path
                  d="M12.4592 19.6035L8.86719 23.1956L12.7334 27.0618C12.9163 27.2446 13.1646 27.3477 13.4236 27.3477C13.6826 27.3477 13.9307 27.2446 14.1138 27.0618L17.0154 24.1599L12.4592 19.6035Z"
                  fill="#001035"
                />
                <path
                  d="M22.6768 9.38867C22.6765 9.38867 22.6765 9.38867 22.6765 9.38867L18.4648 13.6003H18.4651C19.0273 14.1628 19.7754 14.4727 20.5708 14.4727C21.3662 14.4727 22.1143 14.1628 22.6768 13.6003C23.2393 13.0378 23.5491 12.29 23.5491 11.4946C23.5488 10.6992 23.2393 9.95117 22.6768 9.38867Z"
                  fill="#1CADB5"
                />
                <path
                  d="M23.3511 8.71289L21.9705 10.0935C22.3445 10.4675 22.5505 10.9648 22.5505 11.4939C22.5505 12.023 22.3445 12.5203 21.9705 12.8943C21.5964 13.2683 21.0991 13.4744 20.5701 13.4744C20.041 13.4744 19.5437 13.2683 19.1697 12.8943C19.1697 12.8943 19.1697 12.8943 19.1697 12.894L17.7891 14.2747V14.2749C18.532 15.0176 19.5195 15.4268 20.5701 15.4268C21.6206 15.4268 22.6082 15.0176 23.3511 14.2749C24.0938 13.532 24.5029 12.5444 24.5029 11.4939C24.5029 10.4434 24.0938 9.45581 23.3511 8.71289Z"
                  fill="#001035"
                />
                <path
                  d="M31.7773 0.285156L26.9805 5.08228L30.7432 8.84497C32.0034 4.58374 32.0615 1.13281 32.0632 0.988037C32.0667 0.724854 31.9636 0.471436 31.7773 0.285156Z"
                  fill="#FF193D"
                />
              </g>
              <defs>
                <clipPath id="clip0_494_1609">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div className={classes.subTitle}>{t('common.canUseKudeo')}</div>

          <div className={classes.tutorialContent}>
            <div className={classes.dialogInformation}>
              <img
                src={`/static/icons/infoIcon.svg`}
                width={'16px'}
                height={'16px'}
                style={{ marginRight: '16px' }}
              />
              <div>
                <Typography className={classes.informationText}>
                  {t('common.mustConfigureKudeo')}
                </Typography>
                <Typography className={classes.informationText}>
                  {t('common.pleaseComplete')}
                </Typography>
              </div>
            </div>

            <div
              className={classes.addDomainContainer}
              style={{ marginBottom: '38px' }}
            >
              <Typography className={classes.addDomainTypography}>
                <img
                  src={`/static/icons/1.svg`}
                  width={'20px'}
                  height={'20px'}
                />
                {t('common.addDomainName')}
              </Typography>
              <TextField
                className={`${classes.textFieldAddDomain} ${
                  warningTextfield ? classes.textFieldAddDomainWarning : ''
                }`}
                placeholder={'company.com'}
                onChange={(e) => {
                  setNewDomain(e.target.value);
                }}
                onBlur={() => {
                  if (invalidDomain) {
                    setWarningTextfield(true);
                  }
                }}
              />
            </div>

            <div
              className={classes.addDomainContainer}
              style={{ marginBottom: '8px' }}
            >
              <Typography className={classes.addDomainTypography}>
                <img
                  src={`/static/icons/2.svg`}
                  width={'20px'}
                  height={'20px'}
                />
                {t('common.copyLinkTuto')}
              </Typography>
              <TextField
                className={classes.textFieldAddDomain}
                value={link}
                onClick={() => {
                  setCopiedUrl(true);
                  navigator.clipboard.writeText(link);
                }}
                style={{ marginBottom: '25px' }}
                sx={
                  copiedUrl
                    ? {
                        '& .MuiOutlinedInput-root': {
                          border: '1px solid #273EAC !important',
                        },
                        '& input': {
                          color: '#273EAC !important',
                          cursor: 'pointer',
                        },
                      }
                    : {
                        '& input': {
                          cursor: 'pointer',
                        },
                      }
                }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        {copiedUrl ? (
                          <DoneIcon style={{ color: '#273EAC' }} />
                        ) : (
                          <ContentCopyIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            {/* <div className={classes.needHelp}>
              <img
                src={`/static/icons/infoIcon.svg`}
                width={'16px'}
                height={'16px'}
              />
              {t('common.needHelp')}
            </div> */}

            <div className={classes.CTAContainer}>
              <ButtonBase
                className={classes.finishButton}
                onClick={() => {
                  finishAction();
                  remindMeLater(false);
                }}
                disabled={invalidDomain}
                style={invalidDomain ? { opacity: '0.5' } : {}}
              >
                {t('common.finish')}
              </ButtonBase>
              <ButtonBase
                className={classes.remindmeLater}
                onClick={() => {
                  remindMeLater(true);
                }}
              >
                {t('common.remindmeLater')}
              </ButtonBase>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={openAddScriptDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.tutorialDialog}
        onClose={() => {
          setOpenAddScriptDialog(false);
        }}
      >
        {user?.isShopifyAccount ? (
          <div className={classes.tutorialContainer}>
            <div
              className={classes.title}
              style={{ marginBottom: '40px', marginRight: '0px' }}
            >
              {t('common.finishInstallationTitle')}
            </div>
            <div className={classes.tutorialContent}>
              <div
                className={classes.addDomainContainer}
                style={{ marginBottom: '8px' }}
              >
                <Typography
                  className={classes.addDomainTypography}
                  style={{ marginBottom: '25px' }}
                >
                  {t('common.addScriptTuto')}
                </Typography>
              </div>
              <div className={classes.CTAContainer}>
                <ButtonBase
                  className={classes.finishButton}
                  style={{ marginBottom: '25px' }}
                  onClick={() => {
                    const shopifyApp = useAppBridge();
                    const shop = shopifyApp.config.shop;
                    open(
                      `https://${shop}/admin/themes/current/editor?context=apps&activateAppId=e1c295f9-16a1-4507-83c3-a79aac629e47/plugin`,
                    );
                    setOpenAddScriptDialog(false);
                  }}
                >
                  {t('common.completeInstallation')}
                </ButtonBase>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.tutorialContainer}>
            <div
              className={classes.title}
              style={{ marginBottom: '40px', marginRight: '0px' }}
            >
              {t('common.finishInstallationTitle')}
            </div>
            <div className={classes.tutorialContent}>
              <div
                className={classes.addDomainContainer}
                style={{ marginBottom: '8px' }}
              >
                <Typography className={classes.addDomainTypography}>
                  {t('common.copyLinkTuto')}
                </Typography>
                <TextField
                  className={classes.textFieldAddDomain}
                  value={link}
                  onClick={() => {
                    setCopiedUrl(true);
                    navigator.clipboard.writeText(link);
                  }}
                  style={{ marginBottom: '25px' }}
                  sx={
                    copiedUrl
                      ? {
                          '& .MuiOutlinedInput-root': {
                            border: '1px solid #273EAC !important',
                          },
                          '& input': {
                            color: '#273EAC !important',
                            cursor: 'pointer',
                          },
                        }
                      : {
                          '& input': {
                            cursor: 'pointer',
                          },
                        }
                  }
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          {copiedUrl ? (
                            <DoneIcon style={{ color: '#273EAC' }} />
                          ) : (
                            <ContentCopyIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className={classes.CTAContainer}>
                <ButtonBase
                  className={classes.finishButton}
                  style={{ marginBottom: '25px' }}
                  onClick={() => {
                    setOpenAddScriptDialog(false);
                  }}
                >
                  {t('common.finish')}
                </ButtonBase>
              </div>
            </div>
          </div>
        )}
      </Dialog>

      <Dialog
        open={openWhyUseKudeoDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.optionDialog}
      >
        <div className={classes.optionDialogContainer}>
          <Typography className={classes.whyUseKudeoTitle}>
            {t('common.whyUseKudeo')}
          </Typography>

          <div className={classes.optionContainer}>
            <div
              className={`${classes.optionCard} ${
                onClickedOptionContainer[0] ? classes.optionCardActive : ''
              } ${
                !onClickedOptionContainer[0] ? classes.optionCardInactive : ''
              }`}
              onMouseEnter={() => {
                setOnHoveredOptionContainer([true, false, false]);
              }}
              onMouseLeave={() => {
                setOnHoveredOptionContainer([false, false, false]);
              }}
              style={
                onHoveredOptionContainer[0]
                  ? {
                      border: '1px solid #273EAC',
                      background: 'rgba(235,240,251,0.6)',
                    }
                  : {}
              }
              onClick={() => {
                setOnClickedOptionContainer([true, false, false]);
              }}
            >
              {onClickedOptionContainer[0] && (
                <div className={classes.doneContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M18.5445 28.08C18.362 28.2921 18.0464 28.3083 17.8431 28.1159L10.1585 20.8427C9.95517 20.6503 9.94633 20.3266 10.1386 20.1233L12.587 17.5362C12.7793 17.3329 13.103 17.3241 13.3063 17.5164L17.5041 21.4892C17.7074 21.6815 18.0231 21.6654 18.2056 21.4532L26.4066 11.9244C26.5891 11.7123 26.9121 11.688 27.1241 11.8707L29.8236 14.1941C30.0357 14.3766 30.06 14.6996 29.8773 14.9118L18.5445 28.08Z"
                      fill="#FFC001"
                    />
                    <path
                      d="M18.5445 28.08C18.362 28.2921 18.0464 28.3083 17.8431 28.1159L10.1585 20.8427C9.95517 20.6503 9.94633 20.3266 10.1386 20.1233L12.587 17.5362C12.7793 17.3329 13.103 17.3241 13.3063 17.5164L17.5041 21.4892C17.7074 21.6815 18.0231 21.6654 18.2056 21.4532L26.4066 11.9244C26.5891 11.7123 26.9121 11.688 27.1241 11.8707L29.8236 14.1941C30.0357 14.3766 30.06 14.6996 29.8773 14.9118L18.5445 28.08Z"
                      fill="#08E8AF"
                    />
                  </svg>
                </div>
              )}
              <div className={classes.optionContainerTypography}>
                <Typography className={classes.optionCardTitle}>
                  {t('common.videoTestimonials')}
                </Typography>
                <Typography className={classes.optionCardDescription}>
                  {t('common.videoTestimonialsDescription')}
                </Typography>
              </div>
            </div>

            <div
              className={`${classes.optionCard} ${
                onClickedOptionContainer[1] ? classes.optionCardActive : ''
              } ${
                !onClickedOptionContainer[1] ? classes.optionCardInactive : ''
              }`}
              onMouseEnter={() => {
                setOnHoveredOptionContainer([false, true, false]);
              }}
              onMouseLeave={() => {
                setOnHoveredOptionContainer([false, false, false]);
              }}
              style={
                onHoveredOptionContainer[1]
                  ? {
                      border: '1px solid #273EAC',
                      background: 'rgba(235,240,251,0.6)',
                    }
                  : {}
              }
              onClick={() => {
                setOnClickedOptionContainer([false, true, false]);
              }}
            >
              {onClickedOptionContainer[1] && (
                <div className={classes.doneContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M18.5445 28.08C18.362 28.2921 18.0464 28.3083 17.8431 28.1159L10.1585 20.8427C9.95517 20.6503 9.94633 20.3266 10.1386 20.1233L12.587 17.5362C12.7793 17.3329 13.103 17.3241 13.3063 17.5164L17.5041 21.4892C17.7074 21.6815 18.0231 21.6654 18.2056 21.4532L26.4066 11.9244C26.5891 11.7123 26.9121 11.688 27.1241 11.8707L29.8236 14.1941C30.0357 14.3766 30.06 14.6996 29.8773 14.9118L18.5445 28.08Z"
                      fill="#FFC001"
                    />
                    <path
                      d="M18.5445 28.08C18.362 28.2921 18.0464 28.3083 17.8431 28.1159L10.1585 20.8427C9.95517 20.6503 9.94633 20.3266 10.1386 20.1233L12.587 17.5362C12.7793 17.3329 13.103 17.3241 13.3063 17.5164L17.5041 21.4892C17.7074 21.6815 18.0231 21.6654 18.2056 21.4532L26.4066 11.9244C26.5891 11.7123 26.9121 11.688 27.1241 11.8707L29.8236 14.1941C30.0357 14.3766 30.06 14.6996 29.8773 14.9118L18.5445 28.08Z"
                      fill="#08E8AF"
                    />
                  </svg>
                </div>
              )}
              <div className={classes.optionContainerTypography}>
                <Typography className={classes.optionCardTitle}>
                  {t('common.egcRecruitment')}
                </Typography>
                <Typography className={classes.optionCardDescription}>
                  {t('common.egcRecruitmentDescription')}
                </Typography>
              </div>
            </div>

            <div
              className={`${classes.optionCard} ${
                onClickedOptionContainer[2] ? classes.optionCardActive : ''
              } ${
                !onClickedOptionContainer[2] ? classes.optionCardInactive : ''
              }`}
              onMouseEnter={() => {
                setOnHoveredOptionContainer([false, false, true]);
              }}
              onMouseLeave={() => {
                setOnHoveredOptionContainer([false, false, false]);
              }}
              style={
                onHoveredOptionContainer[2]
                  ? {
                      border: '1px solid #273EAC',
                      background: 'rgba(235,240,251,0.6)',
                    }
                  : {}
              }
              onClick={() => {
                setOnClickedOptionContainer([false, false, true]);
              }}
            >
              {onClickedOptionContainer[2] && (
                <div className={classes.doneContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M18.5445 28.08C18.362 28.2921 18.0464 28.3083 17.8431 28.1159L10.1585 20.8427C9.95517 20.6503 9.94633 20.3266 10.1386 20.1233L12.587 17.5362C12.7793 17.3329 13.103 17.3241 13.3063 17.5164L17.5041 21.4892C17.7074 21.6815 18.0231 21.6654 18.2056 21.4532L26.4066 11.9244C26.5891 11.7123 26.9121 11.688 27.1241 11.8707L29.8236 14.1941C30.0357 14.3766 30.06 14.6996 29.8773 14.9118L18.5445 28.08Z"
                      fill="#FFC001"
                    />
                    <path
                      d="M18.5445 28.08C18.362 28.2921 18.0464 28.3083 17.8431 28.1159L10.1585 20.8427C9.95517 20.6503 9.94633 20.3266 10.1386 20.1233L12.587 17.5362C12.7793 17.3329 13.103 17.3241 13.3063 17.5164L17.5041 21.4892C17.7074 21.6815 18.0231 21.6654 18.2056 21.4532L26.4066 11.9244C26.5891 11.7123 26.9121 11.688 27.1241 11.8707L29.8236 14.1941C30.0357 14.3766 30.06 14.6996 29.8773 14.9118L18.5445 28.08Z"
                      fill="#08E8AF"
                    />
                  </svg>
                </div>
              )}
              <div className={classes.optionContainerTypography}>
                <Typography className={classes.optionCardTitle}>
                  {t('common.egcEcommerce')}
                </Typography>
                <Typography className={classes.optionCardDescription}>
                  {t('common.egcEcommerceDescription')}
                </Typography>
              </div>
            </div>
          </div>

          <ButtonBase
            className={classes.finishButton}
            onClick={() => {
              setOpenWhyUseKudeoDialog(false);
              sendWhyUseKudeo();
            }}
            disabled={!onClickedOptionContainer.includes(true)}
            style={
              !onClickedOptionContainer.includes(true)
                ? {
                    opacity: '0.5',
                    background: 'transparent',
                    color: 'grey',
                    border: '1px solid grey',
                  }
                : {}
            }
          >
            {t('common.validate')}
          </ButtonBase>
        </div>
      </Dialog>
      {!hideOffer && (
        <UpgradeDialog
          openOfferDialog={openOfferDialog}
          setOpenOfferDialog={setOpenOfferDialog}
        />
      )}

      <Dialog
        open={openLinkDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.optionDialog}
        onClose={() => {
          setOpenLinkDialog(false);
          setCopiedUrlForm(false);
        }}
      >
        <div className={classes.linkDialogContainer}>
          <Typography className={classes.whyUseKudeoTitle}>
            {t('common.generateLink')}
          </Typography>

          <TextField
            className={classes.textFieldAddDomain}
            value={linkForm}
            onClick={() => {
              setCopiedUrlForm(true);
              navigator.clipboard.writeText(linkForm);
            }}
            style={{ marginBottom: '25px', width: '100%' }}
            sx={
              copiedUrlForm
                ? {
                    '& .MuiOutlinedInput-root': {
                      border: '1px solid #273EAC !important',
                    },
                    '& input': {
                      color: '#273EAC !important',
                      cursor: 'pointer',
                    },
                  }
                : {
                    '& input': {
                      cursor: 'pointer',
                    },
                  }
            }
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    {copiedUrlForm ? (
                      <DoneIcon style={{ color: '#273EAC' }} />
                    ) : (
                      <ContentCopyIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Dialog>
    </>
  );
};
