import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  EntityId,
} from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { TestimonialStatuses, ContactStatuses } from '../constants/statuses';
import UserIcon from '../assets/icon-user.svg';
import { theme } from '../constants/theme';

export interface Company {
  _id: string;
  name: string;
  logo?: string;
}

export interface Contact {
  _id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string;
  email?: string;
  company?: Company;
  linkedInUrl?: string;
  status?: ContactStatuses;
  createdAt?: string;
  createTestimonialToken?: boolean;
  position: string;
}

export interface DisplayPreferences {
  showCompanyName: boolean;
  showCompanyLogo: boolean;
  showCustomerPicture: boolean;
  showCustomerFirstName: boolean;
  showCustomerLastName: boolean;
  showDate: boolean;
  showMoreInfos: boolean;
  showCustomerPosition: boolean;
  showVertical: boolean;
}

export interface Statistics {
  clickedNumber: number;
  hoverNumber: number;
}

export interface IndexPosition {
  index: number;
  tag: string;
}

export interface Testimonial {
  id: string;
  content: string;
  details?: string;
  displayPreferences: DisplayPreferences;
  createdAt?: string;
  status: TestimonialStatuses;
  tags: string[];
  contact: Contact;
  callToActionText?: string;
  callToActionUrl?: string;
  linkedPages?: string[];
  statistics: Statistics;
  indexPosition: IndexPosition[];
  testimonialType?: string;
}

interface PluginTestimonialResponse {
  testimonials: Testimonial[];
  mainColor?: string;
  secondaryColor?: string;
  enableOnMobile?: boolean;
  tags: string[];
}

interface PluginParamsDto {
  origin: string;
  href: string;
  hostname: string;
}

export const getPublishedByWebsite = createAsyncThunk(
  'testimonial/published',
  async (pluginParamsDto: PluginParamsDto) => {
    return await apiRequest<PluginTestimonialResponse>(
      'GET',
      `/testimonial/published/`,
      {
        origin: pluginParamsDto.origin,
        href: pluginParamsDto.href,
        hostname: pluginParamsDto.hostname,
      },
    );
  },
);

export const updateTestimonial = createAsyncThunk(
  'testimonial/updateTestimonial',
  async (obj: { id: string; whichValues: string }) => {
    const _id = obj.id;
    const whichValues = obj.whichValues;
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/guest/${_id}/${whichValues}`,
      undefined,
    );
    return result;
  },
);

export const tesitmonialsPluginAdapter = createEntityAdapter<
  Testimonial,
  EntityId
>({
  selectId: (testimonial: Testimonial) => testimonial.id,
});

const testimonialPluginSlice = createSlice({
  name: 'testimonialPlugin',
  initialState: {
    enableOnMobile: false,
    mainColor: '',
    secondaryColor: '',
    testimonials: tesitmonialsPluginAdapter.getInitialState(),
    tags: [''],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublishedByWebsite.fulfilled, (state, { payload }) => {
        // Parsing
        payload.testimonials.forEach((testimonial) => {
          testimonial.contact.profilePictureUrl =
            testimonial.displayPreferences.showCustomerPicture &&
            testimonial.contact.profilePictureUrl
              ? testimonial.contact.profilePictureUrl
              : UserIcon;
          if (testimonial.contact.company) {
            testimonial.contact.company.logo =
              testimonial.contact.company.logo ??
              testimonial.contact.profilePictureUrl;
          }
        });
        tesitmonialsPluginAdapter.setAll(
          state.testimonials,
          payload.testimonials,
        );
        state.mainColor = payload.mainColor ?? theme.palette.primary.main;
        state.secondaryColor =
          payload.secondaryColor ?? theme.palette.primary.dark;
        state.enableOnMobile = payload.enableOnMobile ?? false;
        state.tags = payload.tags ?? [];
      })
      .addCase(updateTestimonial.fulfilled, (state, { payload }) => {
        tesitmonialsPluginAdapter.updateOne(state.testimonials, {
          id: payload.id,
          changes: payload,
        });
      });
  },
});

export default testimonialPluginSlice.reducer;
