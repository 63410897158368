import { createUseStyles } from 'react-jss';

const idleButtonWidth = '56px';
const boxShadow = '0 2px 16px rgba(0, 0, 0, 0.15)';

export const useStyles = createUseStyles({
  commonBackground: {
    boxShadow,
    background: 'rgba(255, 255, 255, 0.97)',
    backdropFilter: 'blur(20px)',
    borderRadius: 16,
  },
  commonBackgroundVideo: {
    marginBottom: '4px',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  idleButton: {
    width: idleButtonWidth,
    height: idleButtonWidth,
    position: 'relative',
    flexShrink: 0,
    backgroundColor: 'white',
    padding: '3px',
  },
  idleButtonDefault: {
    '& img': {
      width: '25px',
      height: '25px',
    },
  },
  contentVertical: {
    width: '243px',
  },
  content: {
    width: '352px',
  },
  contentVideo: {
    alignItems: 'stretch',
    height: '100%',
  },
  top: {
    padding: '12px',
    marginBottom: '8px',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  quoteIconWrapperBig: {
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    '& svg': {
      width: '36px',
      height: '36px',
    },
  },
  logoContainer: {
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    width: '70px',
    height: '70px',
  },
  logoCompanyDefault: {
    '& img': {
      width: '28px',
      height: '28px',
    },
  },
  date: {
    fontSize: '14px',
    color: 'gray',
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '1000px',
    padding: '2px 8px',
    display: 'inline-block',
    marginLeft: '16px',
  },
  quote: {
    fontSize: '16px',
    padding: '8px',
    lineHeight: '1.4',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '8px',
    objectFit: 'cover',
    background: 'transparent',
  },
  avatarImage: {
    background: 'white',
  },
  contactName: {
    fontSize: '14px',
    padding: '6px 12px',
    borderRadius: 16,
  },
  CTAWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  CTA: {
    fontSize: '12px',
    boxShadow: 'none',
    padding: '4px 12px',
    borderRadius: '1000px',
    transition: '0.15s',
    '&:hover': {
      opacity: '0.7',
    },
  },
  companyName: {
    fontWeight: 'bold',
  },
  position: {
    opacity: 0.7,
  },
  volumeIcon: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    top: '81%',
    right: '89%',
    opacity: '0.8',
  },
  volumeIconVertical: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    top: '87%',
    right: '78%',
    opacity: '0.8',
  },
  volumeIconSquare: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    top: '87%',
    right: '86%',
    opacity: '0.8',
  },
  companyLogo: {
    borderRadius: '50%',
    width: '70px',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
  },
  CTAVideo: {
    position: 'absolute',
    top: '80%',
    right: '5%',
  },
  CTAVideoSquare: {
    position: 'absolute',
    top: '87%',
    right: '5%',
  },
  contentSquare: {
    width: '300px',
  },
});
