import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  SideBarHandleContext,
  TestimonialListHandleContext,
} from 'src/hooks/context';
import { ButtonBase, Dialog, Drawer, Typography } from '@mui/material';
import TestimonialsListSelectorBody from '../../components/testimonials/TestimonialsListSelectorBody';
import {
  fetchAllTestimonials,
  publishTestimonialOnTagsAndUrl,
  Testimonial,
} from '../../slices/testimonial';
import { Limitation, User } from '../../slices/auth';
import { testimonialsSelector } from '../../store';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';
import UpgradeDialog from 'src/components/UpgradeDialog';

const drawerWidth = '476px';

const useStyles = createUseStyles({
  sideBar: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: '476px',
      },
      overflowY: 'scroll',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar ': {
        display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
      },
    },
    zIndex: '2200 !important',
    '& .MuiBackdrop-root': {
      background: '#101F41',
      opacity: '0.80 !important',
    },
  },
  sideBarHeader: {},
  sideBarTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.4em',
  },
  sideBarContainer: {
    flex: 1,
    background: '#F7F8FD',
    paddingTop: '40px',
    paddingLeft: '24px',
    paddingRight: '32px',
    overflowY: 'scroll',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar ': {
      display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    },
  },
  sideBarFooter: {
    height: '80px',
    background: '#EDF0FF',
    padding: '16px 48px 16px 48px',
    flexShrink: 0,
  },
  publishButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#273EAC',
    borderRadius: '8px',
    color: 'white',
    width: '100%',
    height: '100%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
  },
  publishIcon: {
    transform: 'rotateY(180deg)',
  },
  mustUpgradeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  mustUpgradeTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '35px',
    color: '#060640',
  },
  mustUpgradeDescription: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#060640',
    textAlign: 'start',
    maxWidth: '500px',
    alignSelf: 'self-start',
  },
  mustUpgradeButton: {
    borderRadius: '8px',
    background: '#273EAC',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    width: '233px',
    height: '40px',
    alignSelf: 'center',
  },
  offerDialog: {
    zIndex: 3000,
    '& .MuiDialog-paper': {
      width: 'auto',
      borderRadius: '8px',
      background: 'white',
      paddingTop: '50px',
      paddingBottom: '30px',
      paddingLeft: '58px',
      paddingRight: '58px',
      maxWidth: '100%',
    },
  },
});

const TestimonialsListSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const sidebarContext = useContext(SideBarHandleContext);
  const testimonialListContext = useContext(TestimonialListHandleContext);
  const [checkedTestimonials, setCheckedTestimonials] = useState<Testimonial[]>(
    [],
  );
  const { user, limitation }: { user: User | null; limitation: Limitation } =
    useAppSelector(({ auth: { user, limitation } }) => ({
      user,
      limitation,
    }));

  const allTestimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );

  const [hideOffer, setHideOffer] = useState(false);

  useEffect(() => {
    if (user?.hideOffer) {
      setHideOffer(true);
    }
    if (user?._id === '654cff6144abb7a43e4a0a9b') {
      setHideOffer(true);
    }
  }, [user]);

  useEffect(() => {
    dispatch(fetchAllTestimonials());
  }, []);

  const [testimonials, setTestimonials] = React.useState<Testimonial[]>([]);

  const closeTestimionialsSelectorSidebar = () => {
    sidebarContext?.setOpenTestimonialList(false);
    testimonialListContext?.setTestimonials(undefined);
    testimonialListContext?.setTag(undefined);
    setCheckedTestimonials([]);
  };

  useEffect(() => {
    if (user?.subscriptionType === 'landing') {
      if (testimonialListContext?.testimonials) {
        setTestimonials(
          testimonialListContext?.testimonials?.filter((testimonial) => {
            return testimonial.testimonialType != 'video';
          }),
        );
      }
    } else {
      if (testimonialListContext?.testimonials) {
        setTestimonials(testimonialListContext?.testimonials);
      }
    }
  }, [testimonialListContext?.testimonials]);

  const saveToPublish = async () => {
    checkedTestimonials.map((testimonial) => {
      if (testimonialListContext?.tag != undefined) {
        dispatch(
          publishTestimonialOnTagsAndUrl({
            idTestimonial: testimonial._id,
            tag: testimonialListContext.tag,
          }),
        );
        setCheckedTestimonials([]);
      }
    });
  };
  const [openOfferDialog, setOpenOfferDialog] = useState<boolean>(false);

  const [openMustUpgradeDialog, setOpenMustUpgradeDialog] = useState(false);

  const [totalVideo, setTotalVideo] = useState<number>(0);

  useEffect(() => {
    setTotalVideo(
      allTestimonials.filter(
        (testimonial) => testimonial.testimonialType === 'video',
      ).length,
    );
  }, [testimonials]);

  return testimonialListContext ? (
    <Drawer
      className={classes.sideBar}
      variant="temporary"
      anchor="right"
      open={sidebarContext?.isTestimonialListOpened}
      onClose={() => closeTestimionialsSelectorSidebar()}
    >
      <div className={`${classes.sideBarContainer}`}>
        <TestimonialsListSelectorBody
          testimonials={testimonials}
          checkedTestimonials={checkedTestimonials}
          setCheckedTestimonials={setCheckedTestimonials}
          tag={testimonialListContext.tag}
          defaultTab={testimonialListContext.defaultTab}
          totalVideo={totalVideo}
          setOpenMustUpgradeDialog={setOpenMustUpgradeDialog}
          limitation={limitation}
          displayLocation={testimonialListContext.displayLocation}
        />
      </div>
      <div className={`${classes.sideBarFooter}`}>
        <ButtonBase
          className={classes.publishButton}
          onClick={() => {
            saveToPublish();
            closeTestimionialsSelectorSidebar();
          }}
        >
          {t('pages.Testimonials.actions.publish')}
        </ButtonBase>
      </div>

      {!hideOffer && (
        <UpgradeDialog
          openOfferDialog={openOfferDialog}
          setOpenOfferDialog={setOpenOfferDialog}
        />
      )}

      <Dialog
        open={openMustUpgradeDialog}
        onClose={() => {
          setOpenMustUpgradeDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.offerDialog}
      >
        <div className={classes.mustUpgradeContainer}>
          <Typography className={classes.mustUpgradeTitle}>
            {t('common.mustUpgradeTitle')}
          </Typography>
          <Typography className={classes.mustUpgradeDescription}>
            {t('common.maxVideoLength')}
          </Typography>
          <ButtonBase
            className={classes.mustUpgradeButton}
            onClick={() => {
              setOpenMustUpgradeDialog(false);
              setOpenOfferDialog(true);
            }}
          >
            {t('common.upgradeNow')}
          </ButtonBase>
        </div>
      </Dialog>
    </Drawer>
  ) : (
    <></>
  );
};

export default TestimonialsListSelector;
