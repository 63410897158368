import { Card } from '@mui/material';
import { styled } from '@mui/system';
import Page from '../basic/Page';

export const AuthenticationStyle = styled(Page)(() => ({}));

export const AuthenticationSectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '30vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

export const AuthenticationContentStyle = styled('div')(() => ({
  position: 'absolute',
  width: '542px',
  height: '610px',
  left: '735px',
  top: '207px',
}));
