import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ButtonBase, IconButton } from '@mui/material';
import { createUseStyles } from 'react-jss';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = createUseStyles({
  uploadContainer: {},
  inputUpload: {
    display: 'none',
  },
  uploadIconButton: {},
});

interface Props {
  children?: React.ReactNode;
  onChange?: (event: any) => void;
  onClick?: (event: any) => void;
  id: string;
  file: string;
  className?: any;
  colorIcon?: string;
  type?: string;
}

export const Upload: React.FC<Props> = ({
  children,
  onChange,
  id,
  file,
  className,
  colorIcon,
  onClick,
  type,
}) => {
  const classes = useStyles();
  return (
    <ButtonBase
      className={`${classes.uploadContainer} ${className}`}
      onClick={onClick}
    >
      {type === 'button' ? (
        <>
          {children || (
            <IconButton
              className={classes.uploadIconButton}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <DeleteIcon
                style={{ color: `${colorIcon}`, width: '42px', height: '42px' }}
              />
            </IconButton>
          )}
        </>
      ) : (
        <label htmlFor={id}>
          <input
            accept={file === 'image' ? 'image/*' : 'video/*'}
            id={id}
            type="file"
            name="file"
            className={classes.inputUpload}
            onChange={onChange}
          />
          {children || (
            <IconButton
              className={classes.uploadIconButton}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CloudUploadIcon
                style={{ color: `${colorIcon}`, width: '42px', height: '42px' }}
              />
            </IconButton>
          )}
        </label>
      )}
    </ButtonBase>
  );
};
