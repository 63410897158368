import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { Testimonial } from '../../slices/testimonial';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import Tabs from '../Tabs';
import TestimonialCardWebsite from './TestimonialCardWebsite';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { ButtonBase } from '@mui/material';
import {
  SideBarHandleContext,
  TestimonialEditionHandleContext,
} from '../../hooks/context';
import { Limitation } from 'src/slices/auth';

const useStyles = createUseStyles({
  testimonialEditionForm: {},

  testimonialMenuPreferences: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
  },
  rightMenuPreferences: {},
  testimonialPreferencesContainer: {
    marginTop: theme.spacing(4),
  },
  testimonialPreferencesGrid: {
    padding: '10px',
  },
  testimonialContactInformation: {
    padding: '10px',
    marginBottom: theme.spacing(4),
  },
  testimonialContactActions: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(4),
  },
  testimonialContactChange: {},
  testimonialContactEdit: {
    margin: theme.spacing(0, 1),
  },
  testimonialEditionContentBox: {
    marginBottom: theme.spacing(3),
  },
  testimonialEditionContent: {},
  testimonialFormBox: {
    margin: '10px 0',
    '& .companyInput, .lastNameInput, .firstNameInput': {
      width: '-webkit-fill-available',
    },
    '& .showCompanyName, .showCompanyLogo, .showCustomerLastName, .showCustomerFirstName, .showCustomerPicture':
      {
        marginLeft: '10px',
      },
  },
  testimonialFlexImageBox: {
    alignItems: 'center',
  },
  testimonialImageContent: {
    width: '-webkit-fill-available',
    alignItems: 'center',
  },
  testimonialImageContentTitle: {
    marginRight: theme.spacing(1),
  },
  testimonialEditTagsBox: {
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  testimonialEditionTagsTitle: {
    marginRight: '10px',
    alignSelf: 'center',
  },
  testimonialEditionAddTags: {
    marginTop: '10px',
  },
  testimonialEditionAddTagsInput: {
    marginRight: '5px',
  },
  testimonialEditionAddTagsButton: {},
  testimonialEditionDetailsContainer: {},
  testimonialEditionDetailsTitle: {
    marginBottom: theme.spacing(1),
  },
  testimonialEditionDetailsContent: {
    '& .ql-container': {
      minHeight: '200px',
      fontSize: 'inherit',
    },
  },
  profilePictureUrlAvatar: {
    margin: theme.spacing(0, 2),
  },
  testimonialsNoContent: {
    margin: 'auto',
    color: '#637381',
    textAlign: 'center',
  },
  testimonialsCreation: {
    gap: '40px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
    width: '100%',
  },
  testimonialsSelector: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    width: '100%',
  },
  testimonialSelectorTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '14px',
    color: '#060640',
  },
  testimonialsListSelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '25px',
    height: 'fit-content',
  },
  testimonialCardSelector: {
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  testimonialCardSelectorContainer: {
    display: 'flex',
    gap: '22px',
    alignItems: 'center',
  },
  testimonialCreationContainerButton: {
    display: 'flex',
    gap: '25px',
  },
  buttonCreationTestimonial: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px',
    gap: '10px',
    width: '185px',
    height: ' 50px',
    border: '1px solid #273EAC',
    borderRadius: '8px',
    color: '#060640',
  },
  typographyTestimonialCreation: {
    color: '#060640',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.4px',
  },
  iconCreationTestimonial: {
    width: '30px',
    height: '30px',
  },
});

interface Props {
  testimonials: Testimonial[];
  checkedTestimonials: Testimonial[];
  setCheckedTestimonials: Dispatch<SetStateAction<Testimonial[]>>;
  tag?: string;
  defaultTab?: string;
  totalVideo: number;
  setOpenMustUpgradeDialog: (value: boolean) => void;
  limitation: Limitation;
  displayLocation?: string;
}

const TestimonialsListSelectorBody: React.FC<Props> = ({
  testimonials,
  checkedTestimonials,
  setCheckedTestimonials,
  tag,
  defaultTab,
  limitation,
  setOpenMustUpgradeDialog,
  totalVideo,
  displayLocation,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<
    { testimonial: Testimonial; value: boolean }[]
  >(() => {
    const temp: { testimonial: Testimonial; value: boolean }[] = [];
    testimonials.map((testimonial) => {
      temp.push({ testimonial: testimonial, value: false });
    });
    return temp;
  });
  const [value, setValue] = useState(() => {
    if (defaultTab === 'video') {
      if (displayLocation === 'embedded') {
        return 0;
      } else {
        return 2;
      }
    } else if (defaultTab === 'text') {
      return 1;
    } else {
      return 0;
    }
  });

  const changeTab = (newValue: number) => {
    setValue(newValue);
  };
  const tabs =
    displayLocation === 'embedded' ? ['video'] : ['all', 'written', 'video'];

  const sidebarContext = useContext(SideBarHandleContext);
  const testimonialEditionContext = useContext(TestimonialEditionHandleContext);

  const editTestimonial = (type: string) => {
    if (testimonialEditionContext) {
      testimonialEditionContext.setNewTestimonial(true);
      if (tag) {
        testimonialEditionContext.setTag(tag);
      }
      sidebarContext?.setOpenTestimonialEdition(true);
      testimonialEditionContext.setTestimonialType(type);
      sidebarContext?.setOpenTestimonialList(false);
    }
  };

  useEffect(() => {
    if (defaultTab === 'video') {
      if (
        testimonials.filter((testimonial) => {
          return testimonial.testimonialType === 'video';
        }).length === 0
      ) {
        if (testimonialEditionContext) {
          testimonialEditionContext.setNewTestimonial(true);
          if (tag) {
            testimonialEditionContext.setTag(tag);
          }
          sidebarContext?.setOpenTestimonialEdition(true);
          testimonialEditionContext.setTestimonialType('video');
          sidebarContext?.setOpenTestimonialList(false);
        }
      }
    } else if (defaultTab === 'write') {
      if (
        testimonials.filter((testimonial) => {
          return testimonial.testimonialType != 'video';
        }).length === 0
      ) {
        if (testimonialEditionContext) {
          testimonialEditionContext.setNewTestimonial(true);
          if (tag) {
            testimonialEditionContext.setTag(tag);
          }
          sidebarContext?.setOpenTestimonialEdition(true);
          testimonialEditionContext.setTestimonialType('written');
          sidebarContext?.setOpenTestimonialList(false);
        }
      }
    }
  }, [defaultTab]);

  useEffect(() => {
    testimonials.map((testimonial, index) => {
      if (checked[index].value == true) {
        if (!checkedTestimonials.includes(testimonial)) {
          setCheckedTestimonials((prev) => [...prev, testimonial]);
        }
      } else if (checked[index].value == false) {
        if (checkedTestimonials.includes(testimonial)) {
          setCheckedTestimonials((prev) =>
            prev.filter((item) => item !== testimonial),
          );
        }
      }
    });
  }, [checked]);

  const setCheckTempTestimonial = (
    testimonial: Testimonial,
    isChecked: boolean,
  ) => {
    const newChecked = checked.map((temp) => {
      if (temp.testimonial === testimonial) {
        temp.value = isChecked;
        return temp;
      } else {
        return temp;
      }
    });
    setChecked(newChecked);
  };

  return (
    <div className={classes.testimonialsListSelectorContainer}>
      <div className={classes.testimonialsCreation}>
        <Typography className={classes.testimonialSelectorTitle}>
          {t('pages.Testimonials.edition.addContent')}
        </Typography>
        <div className={classes.testimonialCreationContainerButton}>
          <ButtonBase
            className={classes.buttonCreationTestimonial}
            onClick={() => {
              editTestimonial('written');
            }}
            disabled={displayLocation === 'embedded'}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <g clipPath="url(#clip0_116_1074)">
                <path
                  d="M8.9375 7.5C9.45528 7.5 9.875 7.08028 9.875 6.5625C9.875 6.04472 9.45528 5.625 8.9375 5.625C8.41972 5.625 8 6.04472 8 6.5625C8 7.08028 8.41972 7.5 8.9375 7.5ZM12.6875 7.5C13.2053 7.5 13.625 7.08028 13.625 6.5625C13.625 6.04472 13.2053 5.625 12.6875 5.625C12.1697 5.625 11.75 6.04472 11.75 6.5625C11.75 7.08028 12.1697 7.5 12.6875 7.5ZM19.8125 7.5H19.25V9.375H19.8125C21.3633 9.375 22.625 10.6367 22.625 12.1875V15.9375C22.625 17.4883 21.3635 18.75 19.8125 18.75H18.875V20.462L16.3504 18.75H12.6875C11.1367 18.75 9.875 17.4883 9.875 15.9375V15H8V15.9375C8 18.5222 10.1028 20.625 12.6875 20.625H15.7746L20.75 24V20.5309C22.8869 20.0955 24.5 18.2013 24.5 15.9375V12.1875C24.5 9.60281 22.3972 7.5 19.8125 7.5ZM6.125 6.5625C6.125 6.04472 5.70528 5.625 5.1875 5.625C4.66972 5.625 4.25 6.04472 4.25 6.5625C4.25 7.08028 4.66972 7.5 5.1875 7.5C5.70528 7.5 6.125 7.08028 6.125 6.5625ZM9.25002 13.125H12.6875C15.2722 13.125 17.375 11.0222 17.375 8.4375V4.6875C17.375 2.10281 15.2722 0 12.6875 0H5.1875C2.60281 0 0.5 2.10281 0.5 4.6875V8.4375C0.5 10.7012 2.11311 12.5952 4.25 13.0308V16.875L9.25002 13.125ZM2.375 8.4375V4.6875C2.375 3.13669 3.63669 1.875 5.1875 1.875H12.6875C14.2383 1.875 15.5 3.13669 15.5 4.6875V8.4375C15.5 9.98831 14.2383 11.25 12.6875 11.25H8.62498L6.125 13.125V11.25H5.1875C3.63669 11.25 2.375 9.98831 2.375 8.4375Z"
                  fill={displayLocation === 'embedded' ? 'grey' : '#273EAC'}
                />
              </g>
              <defs>
                <clipPath id="clip0_116_1074">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <Typography
              className={classes.typographyTestimonialCreation}
              style={displayLocation === 'embedded' ? { color: 'grey' } : {}}
            >
              {t('pages.Testimonials.written')}
            </Typography>
          </ButtonBase>
          <ButtonBase
            className={classes.buttonCreationTestimonial}
            onClick={() => {
              if (
                limitation.maxNumberOfVideoHosted &&
                limitation.maxNumberOfVideoHosted +
                  limitation.maxNumberOfVideos <=
                  totalVideo + 1
              ) {
                setOpenMustUpgradeDialog(true);
              } else {
                editTestimonial('video');
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <g clipPath="url(#clip0_116_996)">
                <path
                  d="M19.5 24H5.5C4.17441 23.9984 2.90356 23.4711 1.96622 22.5338C1.02888 21.5964 0.501588 20.3256 0.5 19L0.5 5C0.501588 3.67441 1.02888 2.40356 1.96622 1.46622C2.90356 0.528882 4.17441 0.00158786 5.5 0L19.5 0C20.8256 0.00158786 22.0964 0.528882 23.0338 1.46622C23.9711 2.40356 24.4984 3.67441 24.5 5V19C24.4984 20.3256 23.9711 21.5964 23.0338 22.5338C22.0964 23.4711 20.8256 23.9984 19.5 24ZM5.5 2C4.70435 2 3.94129 2.31607 3.37868 2.87868C2.81607 3.44129 2.5 4.20435 2.5 5V19C2.5 19.7956 2.81607 20.5587 3.37868 21.1213C3.94129 21.6839 4.70435 22 5.5 22H19.5C20.2956 22 21.0587 21.6839 21.6213 21.1213C22.1839 20.5587 22.5 19.7956 22.5 19V5C22.5 4.20435 22.1839 3.44129 21.6213 2.87868C21.0587 2.31607 20.2956 2 19.5 2H5.5ZM9.842 17.005C9.42513 17.0036 9.01601 16.8922 8.656 16.682C8.30034 16.4784 8.00515 16.184 7.80063 15.8289C7.59611 15.4738 7.4896 15.0708 7.492 14.661V9.339C7.49166 8.92914 7.59911 8.52641 7.80358 8.1712C8.00805 7.81599 8.30234 7.52079 8.65692 7.31524C9.0115 7.10968 9.4139 7.00099 9.82376 7.00007C10.2336 6.99915 10.6365 7.10604 10.992 7.31L16.27 9.945C16.6366 10.1429 16.9436 10.4353 17.1592 10.7918C17.3748 11.1483 17.4912 11.556 17.4962 11.9726C17.5012 12.3892 17.3946 12.7995 17.1877 13.1611C16.9807 13.5227 16.6808 13.8223 16.319 14.029L10.943 16.716C10.6077 16.9073 10.228 17.0069 9.842 17.005ZM9.817 9.005C9.76189 9.00498 9.70774 9.01946 9.66 9.047C9.60814 9.07585 9.5651 9.11826 9.5355 9.1697C9.50591 9.22114 9.49087 9.27966 9.492 9.339V14.661C9.49234 14.7195 9.50788 14.7768 9.53709 14.8274C9.5663 14.8781 9.60818 14.9202 9.65861 14.9498C9.70905 14.9794 9.7663 14.9953 9.82475 14.996C9.8832 14.9968 9.94084 14.9823 9.992 14.954L15.368 12.266C15.408 12.2347 15.4399 12.1941 15.4608 12.1478C15.4817 12.1015 15.491 12.0507 15.488 12C15.4893 11.9405 15.4742 11.8818 15.4444 11.8304C15.4146 11.7789 15.3712 11.7365 15.319 11.708L10.045 9.073C9.97624 9.03082 9.89763 9.00737 9.817 9.005Z"
                  fill="#273EAC"
                />
              </g>
              <defs>
                <clipPath id="clip0_116_996">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <Typography className={classes.typographyTestimonialCreation}>
              {t('pages.Testimonials.video')}
            </Typography>
          </ButtonBase>
        </div>
      </div>
      <div className={classes.testimonialsSelector}>
        <Typography className={classes.testimonialSelectorTitle}>
          {t('common.testmonialsToPublish')}
        </Typography>
        <Tabs
          value={value}
          changeTab={(_, newValue) => changeTab(newValue)}
          tabsValue={tabs.map(
            (tab) =>
              `${t(`pages.Testimonials.types.${tab}`)} (${
                testimonials.filter((testimonial) => {
                  if (tab === 'all') {
                    return true;
                  } else if (tab === 'video') {
                    return testimonial.testimonialType === 'video';
                  } else if (tab === 'written') {
                    return testimonial.testimonialType != 'video';
                  }
                }).length
              })`,
          )}
        />
        <div className={classes.testimonialCardSelector}>
          {testimonials
            .filter((testimonial) => {
              if (tabs[value] === 'written') {
                return testimonial.testimonialType != 'video';
              } else if (tabs[value] === 'video') {
                return testimonial.testimonialType === 'video';
              } else if (tabs[value] === 'all') {
                return true;
              }
            })
            .map((testimonial, index) => (
              <div
                key={index}
                className={classes.testimonialCardSelectorContainer}
              >
                <Checkbox
                  icon={
                    <RadioButtonUncheckedIcon style={{ color: '#273EAC' }} />
                  }
                  checkedIcon={
                    <RadioButtonCheckedIcon style={{ color: '#273EAC' }} />
                  }
                  onChange={(event) => {
                    setCheckTempTestimonial(testimonial, event.target.checked);
                  }}
                  checked={
                    checked?.find((s) => {
                      return s.testimonial === testimonial;
                    })?.value
                  }
                />
                <TestimonialCardWebsite
                  key={testimonial._id}
                  testimonial={testimonial}
                  onClick={() => {
                    setCheckTempTestimonial(testimonial, !checked[index].value);
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsListSelectorBody;
