import * as Yup from 'yup';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Link,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { theme } from '../../constants/theme';
import { useAppDispatch } from 'src/hooks/store';
import { signIn } from 'src/slices/auth';
import { DisplayNotification } from 'src/helpers/notifications';
import { useSnackbar } from 'notistack';

const useStyles = createUseStyles({
  signInForm: {},
  signInFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  textfieldWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  typographyStyle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    paddingLeft: '8px',
  },
  signInEmail: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      border: '1px solid #CED3E8',
    },
    '& .fieldset': {
      border: 'none',
    },
  },
  signInPassword: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      border: '1px solid #CED3E8',
    },
    '& .fieldset': {
      border: 'none',
    },
    marginBottom: '8px',
  },
  signInActionsContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
  },
  signInActionsRemember: {},
  signInActionsForgotPassword: {
    display: 'flex',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.4px',
    marginBottom: '24px',
    color: '#273EAC',
    justifyContent: 'flex-end',
  },
  signInSubmitButton: {
    background: '#273EAC',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.4px',
    marginBottom: '25px',
  },
  noAccountContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '58px',
    justifyContent: 'center',
  },
  noAccount: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
  },
  createAccount: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#273EAC',
    textDecoration: 'none',
  },
});

// ----------------------------------------------------------------------

const SignInForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [error, setError] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('forms.rules.email'))
      .required(t('forms.rules.required')),
    password: Yup.string().required(t('forms.rules.required')),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const submitForm = async (values: { email: string; password: string }) => {
    if (error) {
      setError(undefined);
    }
    const resultAction = await dispatch(
      signIn({ email: values.email, password: values.password }),
    );
    if (signIn.rejected.match(resultAction)) {
      setError(resultAction.error.message);
      const message = t('forms.errorSignIn');
      const key = enqueueSnackbar(message, {
        content: (
          <DisplayNotification
            closeNotification={() => closeSnackbar(key)}
            message={message}
            variant={false}
          />
        ),
      });
      formik.resetForm();
      formik.setValues(initialValues);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: SignInSchema,
    onSubmit: submitForm,
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form
        className={classes.signInForm}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className={classes.signInFormContainer}>
          <div
            className={classes.textfieldWrapper}
            style={{ marginBottom: '16px' }}
          >
            <Typography className={classes.typographyStyle}>
              {t('common.email')}
            </Typography>
            <TextField
              className={classes.signInEmail}
              fullWidth
              type="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </div>

          <div
            className={classes.textfieldWrapper}
            style={{ marginBottom: '8px' }}
          >
            <Typography className={classes.typographyStyle}>
              {t('common.password')}
            </Typography>
            <TextField
              className={classes.signInPassword}
              fullWidth
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </div>
        </div>

        <Link
          className={classes.signInActionsForgotPassword}
          component={RouterLink}
          to="/lostPassword"
        >
          {t('pages.SignIn.lostPassword')}
        </Link>

        <LoadingButton
          className={classes.signInSubmitButton}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        >
          {t('common.logIn')}
        </LoadingButton>

        <div className={classes.noAccountContainer}>
          <Typography className={classes.noAccount}>
            {t('pages.SignIn.noAccount')}
          </Typography>
          <RouterLink to="/signUp" className={classes.createAccount}>
            {t('pages.SignIn.createAccount')}
          </RouterLink>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default SignInForm;
