import React from 'react';
import { Testimonial } from 'src/slices/testimonial';

/** Sidebars **/

interface SideBarProps {
  isTestimonialEditionOpened: boolean;
  setOpenTestimonialEdition: React.Dispatch<React.SetStateAction<boolean>>;

  isTestimonialListOpened: boolean;
  setOpenTestimonialList: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideBarHandleContext = React.createContext<
  SideBarProps | undefined
>(undefined);

interface TestimonialEditionSideBarProps {
  isNewTestimonial: boolean;
  setNewTestimonial: React.Dispatch<React.SetStateAction<boolean>>;

  testimonial: Testimonial | undefined;
  setTestimonial: React.Dispatch<React.SetStateAction<Testimonial | undefined>>;

  testimonialType: string | '';
  setTestimonialType: React.Dispatch<React.SetStateAction<string | ''>>;

  tag: string | undefined;
  setTag: React.Dispatch<React.SetStateAction<string | undefined>>;

  tutorialClicked: boolean | undefined;
  setTutorialClicked: React.Dispatch<React.SetStateAction<boolean | undefined>>;

  displayLocation: string | undefined;
  setDisplayLocation: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const TestimonialEditionHandleContext = React.createContext<
  TestimonialEditionSideBarProps | undefined
>(undefined);

interface TestimonialListSideBarProps {
  testimonials: Testimonial[] | undefined;
  setTestimonials: React.Dispatch<
    React.SetStateAction<Testimonial[] | undefined>
  >;
  tag: string | undefined;
  setTag: React.Dispatch<React.SetStateAction<string | undefined>>;
  defaultTab: string | undefined;
  setDefaultTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  displayLocation: string | undefined;
  setDisplayLocation: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const TestimonialListHandleContext = React.createContext<
  TestimonialListSideBarProps | undefined
>(undefined);

/** Actions **/

interface TestimonialsActionsProps {
  publishTestimonial: (testimonialId: string) => void;
  unpublishTestimonial: (testimonialId: string) => void;
  archiveTestimonial: (testimonialId: string) => void;
  unarchiveTestimonial: (testimonialId: string) => void;
  deleteTestimonial?: (testimonialId: string) => void;
}

export const TestimonialActionsHandleContext = React.createContext<
  TestimonialsActionsProps | undefined
>(undefined);
