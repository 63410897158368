import queryString from 'query-string';
import { Mutex } from 'async-mutex';
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosProgressEvent,
} from 'axios';
import { fetchTimeout } from './fetchTimeout';

const API_URL = process.env.REACT_APP_API_URL;

export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'accessToken';
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refreshToken';

export type HTTPMethod =
  | 'CONNECT'
  | 'DELETE'
  | 'GET'
  | 'HEAD'
  | 'OPTIONS'
  | 'PATCH'
  | 'PUT'
  | 'POST'
  | 'TRACE';

type AccessTokenRefreshToken = {
  accessToken: string;

  refreshToken?: string;
};

export class ApiError extends Error {
  statusCode: number;

  constructor(errorMessage: string, statusCode: number) {
    super(errorMessage);

    this.statusCode = statusCode;
  }
}

const refreshTokenMutex = new Mutex();

/* Use it because cannot import store directly (circular dependencies) */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let dispatch: any | undefined;
export const setDispatch = (dispatchFn: any) => {
  dispatch = dispatchFn;
};

export const apiRequest = async <T>(
  method: HTTPMethod,
  endpoint: string,
  params?: Record<string, string>,
  body?: FormData | Record<string, unknown>,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  let query = API_URL + endpoint;

  if (params) {
    query += '?' + queryString.stringify(params);
  }

  const axiosConfig: AxiosRequestConfig = {
    method,
    url: query,
    onUploadProgress, // Ajoutez la prise en charge du suivi du progrès
    headers: {},
  };

  if (accessToken) {
    axiosConfig.headers = { Authorization: `Bearer ${accessToken}` };
  }

  if (body) {
    if (body instanceof FormData) {
      axiosConfig.data = body;
    } else {
      axiosConfig.data = body;
      axiosConfig.headers = axiosConfig.headers || {};
      axiosConfig.headers['Content-Type'] = 'application/json';
    }
  }

  try {
    const response: AxiosResponse<T> = await axios(axiosConfig);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Gestion des erreurs Axios
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401 && refreshToken) {
          const tokens = await (refreshTokenMutex.isLocked()
            ? refreshTokenMutex.waitForUnlock().then(() => ({
                accessToken: localStorage.getItem(
                  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
                ),
                refreshToken: localStorage.getItem(
                  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
                ),
              }))
            : useRefreshToken(refreshToken));

          if (tokens?.accessToken) {
            // Mettre à jour le token dans les headers et réessayer la requête
            axiosConfig.headers = axiosConfig.headers || {};
            axiosConfig.headers.Authorization = `Bearer ${tokens.accessToken}`;
            const retryResponse: AxiosResponse<T> = await axios(axiosConfig);
            return retryResponse.data;
          }
        }

        throw new ApiError(data.contacts ?? data.message, status);
      } else {
        throw new ApiError('Network error', 0);
      }
    } else {
      throw error;
    }
  }
};

const useRefreshToken = async (
  refreshToken: string,
): Promise<AccessTokenRefreshToken | null> => {
  const release = await refreshTokenMutex.acquire();

  try {
    const response = await fetchTimeout(API_URL + '/auth/refresh', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });
    const bodyResult = await response.json();

    if (response.ok) {
      localStorage.setItem(
        LOCAL_STORAGE_ACCESS_TOKEN_KEY,
        bodyResult.accessToken,
      );

      if (bodyResult.refreshToken) {
        localStorage.setItem(
          LOCAL_STORAGE_REFRESH_TOKEN_KEY,
          bodyResult.refreshToken,
        );
      }
      return bodyResult;
    }
  } finally {
    release();
  }
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  return null;
};
