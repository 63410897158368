import {
  Avatar,
  Typography,
  Divider,
  ButtonBase,
  Menu,
  Dialog,
  TextField,
} from '@mui/material';
import React, { LegacyRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { theme } from 'src/constants/theme';
import { TextFieldWrapper } from '../basic/TextFieldWrapper';
import { Limitation, User } from 'src/slices/auth';
import { useAppSelector } from 'src/hooks/store';
import {
  uploadFile,
  UploadResultVideo,
  UploadResultImage,
  removeFile,
  uploadFileImage,
} from '../../slices/file';
import { useAppDispatch } from 'src/hooks/store';
import { Upload } from '../basic/Upload';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Testimonial } from '../../slices/testimonial';
import { testimonialsSelector } from '../../store';
import Tabs from '../Tabs';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '../../assets/delete-icon.png';
import CompanyIcon from '../../assets/company.svg';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgressWithLabel } from '../basic/CirculatProgressWithLabel';
import ReactPlayer from 'react-player';
import UpgradeDialog from '../UpgradeDialog';
import { HexColorPicker } from 'react-colorful';
import { useDebouncedCallback } from 'use-debounce';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { useAppBridge } from '@shopify/app-bridge-react';
import MyEditor from '../basic/MyEditor';

const useStyles = createUseStyles({
  testimonialEditionForm: {
    display: 'flex',
    flexDirection: 'row-reverse',
    background: 'white',
    height: '100%',
    overflow: 'hidden',
  },
  testimonialMenuPreferences: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#060640',
  },
  formatContainer: {
    display: 'flex',
    gap: '20px',
    width: '100%',
    flexDirection: 'column',
  },
  rightMenuPreferences: {},

  switchPreferenceVideo: {
    bottom: '9px',
  },

  switchPreference: {
    '&.Mui-checked': {
      color: 'white',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      opacity: '1',
      backgroundColor: '#273EAC',
      color: '#273EAC',
    },
  },

  testimonialPreferencesContainer: {},
  testimonialPreferencesGrid: {
    padding: '10px',
  },
  testimonialContactInformation: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
  },
  textFieldContactInformation: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #CED3E8',
        borderRadius: '8px',
      },
    },
  },
  addContactTitleTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#060640',
  },
  addContactProfilContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  addContactCompanyContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  testimonialContactActions: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(4),
  },
  testimonialContactChange: {},
  testimonialContactEdit: {
    margin: theme.spacing(0, 1),
  },
  testimonialEditionContent: {
    '& .MuiOutlinedInput-root': {
      '& textArea': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#060640',
      },
    },
  },
  testimonialFormBox: {
    margin: '10px 0',
    '& .companyInput, .lastNameInput, .firstNameInput': {
      width: '-webkit-fill-available',
    },
    '& .showCompanyName, .showCompanyLogo, .showCustomerLastName, .showCustomerFirstName, .showCustomerPicture':
      {
        marginLeft: '10px',
      },
  },
  testimonialFlexImageBox: {
    alignItems: 'center',
  },
  testimonialImageContent: {
    width: '-webkit-fill-available',
    alignItems: 'center',
  },
  testimonialImageContentTitle: {
    marginRight: theme.spacing(1),
  },
  testimonialEditTagsBox: {
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  testimonialEditionTagsTitle: {
    marginRight: '10px',
    alignSelf: 'center',
  },
  testimonialEditionAddTags: {
    marginTop: '10px',
  },
  testimonialEditionAddTagsInput: {
    marginRight: '5px',
  },
  testimonialEditionAddTagsButton: {},
  testimonialEditionDetailsContainer: {
    width: '100%',
  },
  testimonialEditionColorPicker: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  testimonialEditionDetailsTitle: {
    marginBottom: theme.spacing(1),
  },
  testimonialEditionDetailsContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldEdition: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },

    '& .MuiOutlinedInput-root': {
      height: '40px',
      borderRadius: '8px',
      border: '1px solid #CED3E8',
      background: 'white',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #CED3E8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #CED3E8',
    },
    '& fieldset': {
      border: 'none',
    },
  },

  selectorMenu: {
    height: '40px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#060640',
  },
  profilePictureUrlAvatar: {
    '& img': {
      height: 'auto',
      width: 'auto',
    },
  },

  buttonSelectorType: {
    width: '48%',
    height: '100px',
    marginRight: '1%',
  },
  testimonialAddContainer: {
    marginBottom: '1%',
    marginLeft: '1%',
    marginTop: '1%',
    alignItems: 'center',
  },
  testimonialsEditionBodyRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '30px 30px 30px 30px',
    background: 'white',
    width: '50vw',
    overflowY: 'scroll',
    overflowX: 'hidden',
    flex: 1,
    gap: '50px',
  },
  testimonialsEditionBodyLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: '20px',
    padding: '30px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    flex: 1,
    placeContent: 'center',
  },
  tabContainer: {
    justifySelf: 'center',
    width: '100%',
    marginBottom: '10px',
  },
  testimonialsEditionBodyDivider: {},
  testimonialTypographyTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '20px',
    color: '#060640',
  },
  testimonialTypographySubTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#060640',
  },
  clickToUploadTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#273EAC',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  dragAndDropTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#060640',
  },
  typographyUploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },
  videoUploadButton: {
    width: '100%',
    height: '140px',
    background: '#F7F8FD',
    border: '1px dashed #8F93B8',
    borderRadius: '8px',
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    justifyContent: 'center',
  },
  dragActive: {
    background: 'rgba(235,240,251)',
  },
  videoUploadLabel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
  },
  videoUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px',
    gap: '18px',
    width: '100%',
    height: '140px',
    justifyContent: 'center',
  },
  uploadVideoContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '100%',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
  videoTypographyContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  typographyVideo: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#060640',
  },
  addButton: {
    width: '213px',
    height: '40px',
    background: '#FFFFFF',
    padding: '10px 20px',
    gap: '5px',
    border: '1px solid #273EAC',
    borderRadius: '8px',
    color: '#273EAC',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '18px',
  },
  displayPreferenceTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '17px',
    lineHeight: '17px',
    color: '#060640',
    marginBottom: '20px',
  },
  previewContainer: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'fit-content',
    width: 'fit-content',
  },
  buttonTypeSelector: {
    width: '300px',
    height: '50px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1px solid #CED3E8',
      },
    },
  },
  inputUpload: {
    display: 'none',
  },
  typographyTitleSelector: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#060640',
  },
  selectorContainerCTA: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  addButtonContainer: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
  },
  textActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
  },
  deleteIconButton: {
    width: '44px',
    height: '44px',
    border: '1px solid #273EAC',
    borderRadius: '50%',
    color: '#273EAC',
    marginTop: '5px',
  },
  menuItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '308px',
  },
  menuList: {
    zIndex: 2500,
  },
  changeVideoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
  },
  mustUpgradeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  mustUpgradeTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '35px',
    color: '#060640',
  },
  mustUpgradeDescription: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#060640',
    textAlign: 'start',
    maxWidth: '500px',
    alignSelf: 'self-start',
  },
  mustUpgradeButton: {
    borderRadius: '8px',
    background: '#273EAC',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    width: '233px',
    height: '40px',
    alignSelf: 'center',
  },
  offerDialog: {
    zIndex: 3000,
    '& .MuiDialog-paper': {
      width: 'auto',
      borderRadius: '8px',
      background: 'white',
      paddingTop: '50px',
      paddingBottom: '30px',
      paddingLeft: '58px',
      paddingRight: '58px',
      maxWidth: '100%',
    },
  },
  colorSelector: {
    alignSelf: 'center',
  },
  changeColorButton: {
    background: '#FFFFFF',
    color: '#273EAC',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    textDecoration: 'underline',
    alignSelf: 'flex-start',
  },
  menu: {
    zIndex: 9000,
    '& .MuiMenu-paper': {
      background: 'none',
      padding: '10px',
      border: 'none',
      boxShadow: 'none',
      overflow: 'visible',
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '140%',
      color: '#060640',
    },
  },
});

interface FormValues {
  content: string;
  tags: string[];
  newTag: string;
  detailsInput: string;
  callToActionUrl: string;
  callToActionText: string;
  displayPreferences: {
    [key: string]: boolean;
  };
  statistics: {
    [key: string]: number;
  };
  information: string;
  picture: string;
  logo: string;
  testimonialType: string;
  color: string;
  title: string;
  titlePosition: string;
  titleColor: string;
  videoImage: string;
  titleEmbedded: string;
  titleColorEmbedded: string;
  titleEmbeddedPosition: string;
  variantProductId: string;
}

interface Props {
  formik: FormikProps<FormValues>;
  previewTestimonial: any;
  user?: User;
  displayLocation?: string;
}

const TestimonialEditionBody: React.FC<Props> = ({
  formik,
  previewTestimonial,
  user,
  displayLocation,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [switchAddButton, setSwitchAddButton] = useState<boolean>(
    formik.values.callToActionUrl !== '' ||
      formik.values.callToActionText !== '' ||
      formik.values.detailsInput !== '',
  );

  const { limitation }: { limitation: Limitation } = useAppSelector(
    ({ auth: { limitation } }) => ({
      limitation,
    }),
  );

  const testimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );

  const [anchorElColor, setAnchorElColor] = React.useState<null | HTMLElement>(
    null,
  );
  const openColorPicker = Boolean(anchorElColor);
  const handleClickColorPicker = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElColor(event.currentTarget);
  };
  const handleCloseColorPicker = () => {
    setAnchorElColor(null);
  };

  const mainColorDebouncedTitle = useDebouncedCallback(
    (color, target) => formik.setFieldValue(target, color),
    0,
  );

  const [videoHosted, setVideoHosted] = useState<number>(0);

  useEffect(() => {
    setVideoHosted(
      testimonials.filter((testimonial) => {
        if (testimonial.content === formik.values.content) {
          return false;
        } else if (
          testimonial.content.startsWith(
            'https://kudeo-documents.s3.eu-west-3.amazonaws.com',
          )
        ) {
          return true;
        }
      }).length,
    );
  }, [testimonials]);

  const uploadOnChangeLogo = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    if (formik.values.logo !== '') {
      removeFileFunction(formik.values.logo, 'logo');
    }
    try {
      const result = await dispatch(
        uploadFileImage({
          formData,
        }),
      );

      if (result.meta.requestStatus === 'fulfilled') {
        const payload: UploadResultImage = result.payload as UploadResultImage;

        if (payload) {
          formik.setFieldValue('logo', payload.url);
          formik.setFieldValue(`displayPreferences.showCompanyLogo`, true);
        } else {
          console.error('Error: Missing "url" in payload:', payload);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const removeFileFunction = async (key: string, type: string) => {
    try {
      await dispatch(removeFile(key)).then(() => {
        if (type === 'logo') {
          formik.setFieldValue('logo', '');
          formik.setFieldValue(`displayPreferences.showCompanyLogo`, false);
        } else if (type === 'picture') {
          formik.setFieldValue('picture', '');
          formik.setFieldValue(`displayPreferences.showCustomerPicture`, false);
        } else if (type === 'video') {
          formik.setFieldValue('content', '');
        }
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const uploadOnChange = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const result = await dispatch(
        uploadFileImage({
          formData,
        }),
      );
      if (result.meta.requestStatus === 'fulfilled') {
        const payload: UploadResultImage = result.payload as UploadResultImage;

        if (formik.values.picture !== '') {
          removeFileFunction(formik.values.picture, 'picture');
        }

        if (payload) {
          formik.setFieldValue('picture', payload.url);
          formik.setFieldValue(`displayPreferences.showCustomerPicture`, true);
        } else {
          console.error('Error: Missing "url" in payload:', payload);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const [anchorEl2, setAnchorEl2] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const open2 = Boolean(anchorEl2);

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpen2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const [fileToHeavy, setFileToHeavy] = useState<boolean>(false);

  const [percentageUploadedVideo, setPercentageUploadedVideo] = useState<
    number | null
  >(() => {
    if (formik.values.content) {
      return 100;
    }
    return null;
  });

  const onUploadProgress = () => (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setPercentageUploadedVideo(percentCompleted);
  };

  const uploadOnChangeVideo = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    // HERE
    if (file.size > 104857600) {
      setFileToHeavy(true);
    } else {
      setFileToHeavy(false);
      try {
        const result = await dispatch(
          uploadFile({
            formData,
            onUploadProgress: onUploadProgress(),
          }),
        );

        if (result.meta.requestStatus === 'fulfilled') {
          // Assurez-vous que le résultat est du type attendu (UploadResult)
          const payload: UploadResultVideo =
            result.payload as UploadResultVideo;

          // Assurez-vous que 'url' existe sur le résultat avant de l'assigner
          if (payload) {
            formik.setFieldValue('content', payload.urlVideo);
            formik.setFieldValue('videoImage', payload.urlImage);
          } else {
            console.error('Error: Missing "url" in payload:', payload);
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const [buttonType, setButtonType] = useState<string>('');

  const [changeColor, setChangeColor] = useState<boolean>(false);

  useEffect(() => {
    if (
      formik.values.detailsInput != '' &&
      formik.values.displayPreferences['showMoreInfos']
    ) {
      setButtonType('moreInfos');
    } else if (formik.values.callToActionText != '') {
      setButtonType('CTA');
    }
  }, []);

  const [value, setValue] = useState(() => {
    if (user?._id === '654cff6144abb7a43e4a0a9b') {
      return 1;
    } else if (user?.subscriptionType === 'startup') {
      return 1;
    } else {
      return 0;
    }
  });

  const changeTab = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (buttonType === 'CTA') {
      formik.setFieldValue(`displayPreferences.showMoreInfos`, false);
    } else if (buttonType === 'moreInfos') {
      formik.setFieldValue(`displayPreferences.showMoreInfos`, true);
    }
  }, [buttonType]);

  const deleteButton = () => {
    formik.setFieldValue('callToActionUrl', '');
    formik.setFieldValue('callToActionText', '');
    formik.setFieldValue('detailsInput', '');
    setSwitchAddButton(false);
  };

  const [displayPreferencesVideoFormat, setDisplayPreferencesVideoFormat] =
    useState<string>(() => {
      if (displayLocation === 'embedded') {
        return 'Vertical';
      } else {
        return 'Horizontal';
      }
    });

  useEffect(() => {
    if (displayLocation === 'embedded') {
      setDisplayPreferencesVideoFormat('Vertical');
    } else {
      if (formik.values.displayPreferences.showVertical) {
        setDisplayPreferencesVideoFormat('Vertical');
      } else if (formik.values.displayPreferences.showSquare) {
        setDisplayPreferencesVideoFormat('Square');
      } else {
        setDisplayPreferencesVideoFormat('Horizontal');
      }
    }
  }, [formik.values.displayPreferences]);

  const [hideOffer, setHideOffer] = useState(false);

  useEffect(() => {
    if (user?.hideOffer) {
      setHideOffer(true);
    }
    if (user?._id === '654cff6144abb7a43e4a0a9b') {
      setHideOffer(true);
    }
  }, [user]);

  const [openMustUpgradeDialog, setOpenMustUpgradeDialog] = useState(false);
  const [openOfferDialog, setOpenOfferDialog] = useState<boolean>(false);

  const [dragActive, setDragActive] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>();

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    if (limitation.maxNumberOfVideoHosted != undefined) {
      if (limitation.maxNumberOfVideoHosted <= videoHosted) {
        setOpenMustUpgradeDialog(true);
      } else {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          getVideoSize(e.target.files[0]);
          uploadOnChangeVideo(e.dataTransfer.files[0]);
        }
      }
    } else {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        getVideoSize(e.target.files[0]);
        uploadOnChangeVideo(e.dataTransfer.files[0]);
      }
    }
  };

  const getVideoSize = (videoFile: File) => {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';

    videoElement.onloadedmetadata = () => {
      const videoWidth = videoElement.videoWidth;
      const videoHeight = videoElement.videoHeight;
      const aspectRatio = videoWidth / videoHeight;
      if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
        formik.setFieldValue(`displayPreferences.showVertical`, false);
        formik.setFieldValue(`displayPreferences.showSquare`, false);
      } else if (Math.abs(aspectRatio - 16 / 10) < 0.01) {
        formik.setFieldValue(`displayPreferences.showVertical`, false);
        formik.setFieldValue(`displayPreferences.showSquare`, false);
      } else if (Math.abs(aspectRatio - 1) < 0.01) {
        formik.setFieldValue(`displayPreferences.showVertical`, false);
        formik.setFieldValue(`displayPreferences.showSquare`, true);
      } else if (Math.abs(aspectRatio - 4 / 3) < 0.01) {
        formik.setFieldValue(`displayPreferences.showVertical`, false);
        formik.setFieldValue(`displayPreferences.showSquare`, false);
      } else if (Math.abs(aspectRatio - 9 / 16) < 0.01) {
        formik.setFieldValue(`displayPreferences.showVertical`, true);
        formik.setFieldValue(`displayPreferences.showSquare`, false);
      } else {
        formik.setFieldValue(`displayPreferences.showVertical`, false);
        formik.setFieldValue(`displayPreferences.showSquare`, false);
      }
    };

    videoElement.src = URL.createObjectURL(videoFile);
  };

  const handleChangePositionTitle = (
    event: SelectChangeEvent,
    target: string,
  ) => {
    formik.setFieldValue(target, event.target.value as string);
  };

  const handleChange = function (e: any) {
    if (limitation.maxNumberOfVideoHosted != undefined) {
      if (limitation.maxNumberOfVideoHosted <= videoHosted) {
        setOpenMustUpgradeDialog(true);
      } else {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
          getVideoSize(e.target.files[0]);
          uploadOnChangeVideo(e.target.files[0]);
        }
      }
    } else {
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        getVideoSize(e.target.files[0]);
        uploadOnChangeVideo(e.target.files[0]);
      }
    }
  };

  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const mainColorDebounced = useDebouncedCallback(
    (color) => formik.setFieldValue('color', color),
    0,
  );

  const productPicker = async () => {
    if (user?.isShopifyAccount) {
      const shopifyApp = useAppBridge();
      try {
        const variantList = await shopifyApp.resourcePicker({
          type: 'variant',
          action: 'select',
          multiple: false,
          filter: {
            variants: true,
          },
        });
        if (variantList) {
          console.log(
            variantList[0].id.split('/')[
              variantList[0].id.split('/').length - 1
            ],
          );
          // const url =
          //   'https://' +
          //   shopifyApp.config.shop +
          //   '/cart/' +
          //   variantList[0].id.split('/')[
          //     variantList[0].id.split('/').length - 1
          //   ] +
          //   ':1?storefront=true';
          formik.setFieldValue('callToActionUrl', '');
          formik.setFieldValue(
            'variantProductId',
            variantList[0].id.split('/')[
              variantList[0].id.split('/').length - 1
            ],
          );
        }
      } catch (error) {
        // Handle error
        console.log('Error while fetching authorization code', error);
      }
    }
  };

  return (
    <form
      className={classes.testimonialEditionForm}
      onSubmit={formik.handleSubmit}
    >
      <div className={classes.testimonialsEditionBodyRightContainer}>
        <Typography className={classes.testimonialTypographyTitle}>
          {t('pages.Testimonials.edition.addContent')}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {formik.values.testimonialType === 'video' ? (
            <>
              <Typography
                className={classes.testimonialTypographySubTitle}
                style={{ fontWeight: 600 }}
              >
                1. {t('pages.Testimonials.edition.downloadVideo')}
              </Typography>
              <Tabs
                value={value}
                changeTab={(_, newValue) => {
                  if (user?._id !== '654cff6144abb7a43e4a0a9b')
                    changeTab(newValue);
                }}
                tabsValue={
                  user?._id === '654cff6144abb7a43e4a0a9b'
                    ? ['url']
                    : ['upload', 'url']
                }
                className={classes.tabContainer}
              />
              <div className={classes.videoUploadContainer}>
                {value === 0 ? (
                  <div className={classes.uploadVideoContent}>
                    {percentageUploadedVideo ? (
                      <div style={{ alignSelf: 'center' }}>
                        {percentageUploadedVideo === 100 ? (
                          <div className={classes.changeVideoContainer}>
                            <ReactPlayer
                              url={formik.values.content}
                              playing={false}
                              loop={false}
                              volume={0}
                              width={'144px'}
                              height={'82px'}
                              style={{
                                background: 'grey',
                                borderRadius: '8px',
                                overflow: 'hidden',
                              }}
                            />
                            <ButtonBase
                              className={classes.addButton}
                              onClick={() => {
                                removeFileFunction(
                                  formik.values.content,
                                  'video',
                                );
                                setPercentageUploadedVideo(null);
                              }}
                            >
                              {t('pages.Testimonials.edition.changeVideo')}
                            </ButtonBase>
                          </div>
                        ) : (
                          <CircularProgressWithLabel
                            value={percentageUploadedVideo}
                          />
                        )}
                      </div>
                    ) : (
                      <form
                        className={`${classes.videoUploadButton} ${
                          dragActive ? classes.dragActive : ''
                        }`}
                        onDragEnter={handleDrag}
                        onSubmit={(e) => e.preventDefault()}
                        id={'videoUpload'}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      >
                        <input
                          id="videoUpload"
                          accept={'video/*'}
                          type="file"
                          name="file"
                          className={classes.inputUpload}
                          onChange={handleChange}
                          ref={inputRef as LegacyRef<HTMLInputElement>}
                        />
                        <label
                          htmlFor={'videoUpload'}
                          className={classes.typographyUploadContainer}
                        >
                          <Typography
                            className={classes.clickToUploadTypography}
                            onClick={onButtonClick}
                          >
                            {t('pages.Testimonials.edition.clickToUpload')}
                          </Typography>
                          <Typography className={classes.dragAndDropTypography}>
                            {t('pages.Testimonials.edition.orDnd')}
                          </Typography>
                        </label>

                        <Typography
                          className={classes.typographyVideo}
                          style={
                            !fileToHeavy
                              ? { color: '#8F93B8', fontSize: '10px' }
                              : { color: '#FF0000', fontSize: '10px' }
                          }
                        >
                          {t(
                            'pages.Testimonials.edition.uploadMyVideoInformation',
                          )}
                        </Typography>
                      </form>
                    )}
                  </div>
                ) : (
                  <TextFieldWrapper
                    id={`content`}
                    placeholder={t('pages.Testimonials.edition.addUrl')}
                    variant="outlined"
                    className={classes.textFieldEdition}
                    autoComplete="off"
                    fullWidth
                    InputLabelProps={{ required: false }}
                    {...formik.getFieldProps('content')}
                    error={
                      formik.touched.content && Boolean(formik.errors.content)
                    }
                    helperText={formik.touched.content && formik.errors.content}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <Typography
                className={classes.testimonialTypographySubTitle}
                style={{ marginBottom: '15px', fontWeight: 600 }}
              >
                1. {t('pages.Testimonials.edition.addContent')}
              </Typography>
              <TextFieldWrapper
                variant="outlined"
                multiline
                minRows={4}
                autoComplete="off"
                className={classes.testimonialEditionContent}
                label={t('pages.Testimonials.edition.addContent', {
                  length: formik.values.content.length,
                })}
                required
                InputLabelProps={{ required: false }}
                fullWidth
                {...formik.getFieldProps('content')}
                error={formik.touched.content && Boolean(formik.errors.content)}
                helperText={formik.touched.content && formik.errors.content}
                inputProps={{
                  maxLength: 500,
                }}
              />
            </>
          )}
        </div>

        {formik.values.testimonialType === 'video' && (
          <>
            <div className={classes.formatContainer}>
              <Typography
                className={classes.typographyVideo}
                style={{ fontWeight: 600 }}
              >
                2. {t('pages.Testimonials.edition.selectFormat')}
              </Typography>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={displayPreferencesVideoFormat}
                  renderValue={(p) => {
                    if (p === 'Square') {
                      return 'Carré';
                    } else if (p === 'Vertical') {
                      return 'Vertical';
                    } else if (p === 'Horizontal') {
                      return 'Horizontal';
                    }
                  }}
                  onClick={handleOpen}
                  className={classes.selectorMenu}
                  disabled={displayLocation === 'embedded'}
                >
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    className={classes.menuList}
                  >
                    <MenuItem
                      onClick={() => {
                        formik.setFieldValue(
                          `displayPreferences.showVertical`,
                          false,
                        );
                        formik.setFieldValue(
                          `displayPreferences.showSquare`,
                          false,
                        );
                        setDisplayPreferencesVideoFormat('Horizontal');
                        handleClose();
                      }}
                    >
                      <div className={classes.menuItemContainer}>
                        <div
                          style={
                            formik.values.displayPreferences['showVertical'] ||
                            formik.values.displayPreferences['showSquare']
                              ? { color: 'grey' }
                              : { color: '#273EAC' }
                          }
                        >
                          {t('pages.Testimonials.edition.horizontalVideo')}
                        </div>
                        <div
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            color: 'gray',
                          }}
                        >
                          {t(
                            'pages.Testimonials.edition.recommandedHorizental',
                          )}
                        </div>
                      </div>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        formik.setFieldValue(
                          `displayPreferences.showVertical`,
                          true,
                        );
                        formik.setFieldValue(
                          `displayPreferences.showSquare`,
                          false,
                        );
                        setDisplayPreferencesVideoFormat('Vertical');
                      }}
                    >
                      <div className={classes.menuItemContainer}>
                        <div
                          style={
                            formik.values.displayPreferences['showVertical']
                              ? { color: '#273EAC' }
                              : { color: 'grey' }
                          }
                        >
                          {t('pages.Testimonials.edition.verticalVideo')}
                        </div>
                        <div
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            color: 'gray',
                          }}
                        >
                          {t('pages.Testimonials.edition.recommandedVertical')}
                        </div>
                      </div>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        formik.setFieldValue(
                          `displayPreferences.showVertical`,
                          false,
                        );
                        formik.setFieldValue(
                          `displayPreferences.showSquare`,
                          true,
                        );
                        setDisplayPreferencesVideoFormat('Square');
                      }}
                    >
                      <div className={classes.menuItemContainer}>
                        <div
                          style={
                            formik.values.displayPreferences['showSquare']
                              ? { color: '#273EAC' }
                              : { color: 'grey' }
                          }
                        >
                          {t('pages.Testimonials.edition.squareVideo')}
                        </div>
                        <div
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            color: 'gray',
                          }}
                        >
                          {t('pages.Testimonials.edition.recommandedSquare')}
                        </div>
                      </div>
                    </MenuItem>
                  </Menu>
                </Select>
              </FormControl>
            </div>
          </>
        )}

        <div className={classes.testimonialContactInformation}>
          <div className={classes.addContactProfilContainer}>
            <Typography
              className={classes.addContactTitleTypography}
              style={{ fontWeight: 600 }}
            >
              {formik.values.testimonialType === 'video' ? '3. ' : '2. '}
              {t('pages.Testimonials.edition.addTitle')}
            </Typography>
            <TextFieldWrapper
              id={`information`}
              placeholder={t('common.title')}
              autoComplete="off"
              className={classes.textFieldEdition}
              fullWidth
              InputLabelProps={{ required: false }}
              {...formik.getFieldProps('information')}
              error={
                formik.touched.information && Boolean(formik.errors.information)
              }
              helperText={
                formik.touched.information && formik.errors.information
              }
            />
          </div>
          {displayLocation === 'plugin' && (
            <div className={classes.addContactCompanyContainer}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '189px',
                  gap: '15px',
                  alignItems: 'center',
                }}
              >
                {formik.values.logo !== '' ? (
                  <>
                    <div style={{ position: 'relative' }}>
                      <Avatar
                        className={`${
                          formik.values.logo != ''
                            ? ''
                            : classes.profilePictureUrlAvatar
                        }`}
                        src={
                          formik.values.logo != ''
                            ? formik.values.logo
                            : CompanyIcon
                        }
                        style={{
                          color: 'white',
                          backgroundColor: '#FFD600',
                          cursor: 'auto',
                        }}
                      />
                      <CloseIcon
                        onClick={() => {
                          removeFileFunction(formik.values.logo, 'logo');
                        }}
                        style={{
                          color: 'white',
                          position: 'absolute',
                          top: '-2px',
                          right: '2px',
                          cursor: 'pointer',
                          background: '#060640',
                          borderRadius: '50%',
                          width: '12px',
                          height: '12px',
                        }}
                      />
                    </div>
                    <Upload
                      onChange={(e) => {
                        uploadOnChangeLogo(e);
                      }}
                      id="contactsUpload"
                      file="image"
                    >
                      <Typography
                        className={classes.typographyVideo}
                        style={{ cursor: 'pointer' }}
                      >
                        {t('pages.Testimonials.edition.changeCompanyLogo')}
                      </Typography>
                    </Upload>
                  </>
                ) : (
                  <Upload
                    onChange={(e) => {
                      if (formik.values.logo === '') {
                        uploadOnChangeLogo(e);
                      }
                    }}
                    id="logoUpload"
                    file="image"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        cursor: 'pointer',
                        width: '189px',
                        gap: '5px',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        className={`${
                          formik.values.logo != ''
                            ? ''
                            : classes.profilePictureUrlAvatar
                        }`}
                        src={
                          formik.values.logo != ''
                            ? formik.values.logo
                            : CompanyIcon
                        }
                        style={
                          formik.values.logo != ''
                            ? {}
                            : { color: 'white', backgroundColor: '#FFD600' }
                        }
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '189px',
                          gap: '5px',
                          alignSelf: 'center',
                        }}
                      >
                        <Typography className={classes.typographyVideo}>
                          {t('pages.Testimonials.edition.addCompanyLogo')}
                        </Typography>
                        <Typography
                          className={classes.typographyVideo}
                          style={{ color: '#8F93B8', fontSize: '10px' }}
                        >
                          {t('pages.Testimonials.edition.addInformation')}
                        </Typography>
                      </div>
                    </div>
                  </Upload>
                )}
              </div>

              <div className={classes.addContactCompanyContainer}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '189px',
                    gap: '15px',
                    alignItems: 'center',
                  }}
                >
                  {formik.values.picture !== '' ? (
                    <>
                      <div style={{ position: 'relative' }}>
                        <Avatar
                          className={`${
                            formik.values.picture != ''
                              ? ''
                              : classes.profilePictureUrlAvatar
                          }`}
                          alt="avatar"
                          src={formik.values.picture ?? ''}
                          aria-label="avatar"
                          style={{ color: 'white', backgroundColor: '#FFD600' }}
                        />
                        <CloseIcon
                          onClick={() => {
                            removeFileFunction(
                              formik.values.picture,
                              'picture',
                            );
                          }}
                          style={{
                            color: 'white',
                            position: 'absolute',
                            top: '-2px',
                            right: '2px',
                            cursor: 'pointer',
                            background: '#060640',
                            borderRadius: '50%',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                      </div>
                      <Upload
                        onChange={(e) => {
                          uploadOnChange(e);
                        }}
                        id="contactsUpload"
                        file="image"
                      >
                        <Typography
                          className={classes.typographyVideo}
                          style={{ cursor: 'pointer' }}
                        >
                          {t('pages.Testimonials.edition.changeProfilePicture')}
                        </Typography>
                      </Upload>
                    </>
                  ) : (
                    <Upload
                      onChange={(e) => {
                        if (formik.values.picture === '') {
                          uploadOnChange(e);
                        }
                      }}
                      id="contactsUpload"
                      file="image"
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          cursor: 'pointer',
                          width: '189px',
                          gap: '5px',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          className={`${
                            formik.values.picture != ''
                              ? ''
                              : classes.profilePictureUrlAvatar
                          }`}
                          alt="avatar"
                          src={formik.values.picture ?? ''}
                          aria-label="avatar"
                          style={
                            formik.values.picture != ''
                              ? {}
                              : { color: 'white', backgroundColor: '#FFD600' }
                          }
                        />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '189px',
                            gap: '5px',
                            alignSelf: 'center',
                          }}
                        >
                          <Typography className={classes.typographyVideo}>
                            {t('pages.Testimonials.edition.addProfilePicture')}
                          </Typography>
                          <Typography
                            className={classes.typographyVideo}
                            style={{ color: '#8F93B8', fontSize: '10px' }}
                          >
                            {t('pages.Testimonials.edition.addInformation')}
                          </Typography>
                        </div>
                      </div>
                    </Upload>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {displayLocation === 'plugin' ? (
          <div className={classes.testimonialContactInformation}>
            <Typography
              className={classes.addContactTitleTypography}
              style={{ fontWeight: 600 }}
            >
              {formik.values.testimonialType === 'video' ? '4. ' : '3. '}
              {t('pages.Testimonials.edition.addTitleBubble')}
            </Typography>

            <div className={classes.testimonialEditionColorPicker}>
              <TextField
                id="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.textFieldEdition}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                variant="outlined"
                sx={{ width: 'fit-content' }}
              />
              <ButtonBase
                onClick={handleClickColorPicker}
                style={{
                  color: `${formik.values.titleColor}`,
                  stroke: 'black',
                }}
              >
                <ColorLensIcon />
              </ButtonBase>

              <Menu
                id="basic-menu"
                anchorEl={anchorElColor}
                open={openColorPicker}
                onClose={handleCloseColorPicker}
                className={classes.menu}
              >
                <HexColorPicker
                  color={formik.values.titleColor}
                  onChange={(color) =>
                    mainColorDebouncedTitle(color, 'titleColor')
                  }
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="mainColor"
                  type="text"
                  className={classes.textFieldEdition}
                  name="mainColor"
                  fullWidth
                  value={formik.values.titleColor}
                  onChange={(e) => {
                    mainColorDebouncedTitle(e.target.value, 'titleColor');
                  }}
                  sx={{ margin: '1px' }}
                />
              </Menu>
            </div>
          </div>
        ) : (
          <div className={classes.testimonialContactInformation}>
            <Typography
              className={classes.addContactTitleTypography}
              style={{ fontWeight: 600 }}
            >
              {formik.values.testimonialType === 'video' ? '4. ' : '3. '}
              {t('pages.Testimonials.edition.addTitleVideo')}
            </Typography>

            <div className={classes.testimonialEditionColorPicker}>
              <TextField
                id="titleEmbedded"
                value={formik.values.titleEmbedded}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.textFieldEdition}
                error={
                  formik.touched.titleEmbedded &&
                  Boolean(formik.errors.titleEmbedded)
                }
                helperText={
                  formik.touched.titleEmbedded && formik.errors.titleEmbedded
                }
                variant="outlined"
                sx={{ width: 'fit-content' }}
              />
              <ButtonBase
                onClick={handleClickColorPicker}
                style={{
                  color: `${formik.values.titleColorEmbedded}`,
                  stroke: 'black',
                }}
              >
                <ColorLensIcon />
              </ButtonBase>

              <Menu
                id="basic-menu"
                anchorEl={anchorElColor}
                open={openColorPicker}
                onClose={handleCloseColorPicker}
                className={classes.menu}
              >
                <HexColorPicker
                  color={formik.values.titleColorEmbedded}
                  onChange={(color) =>
                    mainColorDebouncedTitle(color, 'titleColorEmbedded')
                  }
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="mainColor"
                  type="text"
                  className={classes.textFieldEdition}
                  name="mainColor"
                  fullWidth
                  value={formik.values.titleColorEmbedded}
                  onChange={(e) => {
                    mainColorDebouncedTitle(
                      e.target.value,
                      'titleColorEmbedded',
                    );
                  }}
                  sx={{ margin: '1px' }}
                />
              </Menu>
            </div>
          </div>
        )}

        {displayLocation === 'plugin' ? (
          <div className={classes.testimonialContactInformation}>
            <Typography
              className={classes.addContactTitleTypography}
              style={{ fontWeight: 600 }}
            >
              {formik.values.testimonialType === 'video' ? '5. ' : '4. '}
              {t('pages.Testimonials.edition.positionTitleBubble')}
            </Typography>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.titlePosition}
                onChange={(e) => {
                  handleChangePositionTitle(e, 'titlePosition');
                }}
                className={classes.selectorMenu}
                MenuProps={{ disablePortal: true }}
              >
                <MenuItem value={'inside'}>
                  {t('pages.Testimonials.edition.insideBubble')}
                </MenuItem>
                <MenuItem value={'bottom'}>
                  {t('pages.Testimonials.edition.outsideBubble')}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : (
          <div className={classes.testimonialContactInformation}>
            <Typography
              className={classes.addContactTitleTypography}
              style={{ fontWeight: 600 }}
            >
              {formik.values.testimonialType === 'video' ? '5. ' : '4. '}
              {t('pages.Testimonials.edition.positionTitleVideo')}
            </Typography>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.titleEmbeddedPosition}
                onChange={(e) => {
                  handleChangePositionTitle(e, 'titleEmbeddedPosition');
                }}
                className={classes.selectorMenu}
                MenuProps={{ disablePortal: true }}
              >
                <MenuItem value={'center'}>
                  {t('pages.Testimonials.edition.positionCenterVideo')}
                </MenuItem>
                <MenuItem value={'top'}>
                  {t('pages.Testimonials.edition.positionTopVideo')}
                </MenuItem>
                <MenuItem value={'bottom'}>
                  {t('pages.Testimonials.edition.positionBotVideo')}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        )}

        <div className={classes.addContactProfilContainer}>
          <Typography
            className={classes.typographyVideo}
            style={{ fontWeight: 600 }}
          >
            {formik.values.testimonialType === 'video' ? '6. ' : '5. '}
            {t('pages.Testimonials.edition.addButton')}
          </Typography>
          {switchAddButton ? (
            <div className={classes.addButtonContainer}>
              <div className={classes.textActionContainer}>
                <TextFieldWrapper
                  id={`callToActionText`}
                  placeholder={
                    buttonType === 'moreInfos'
                      ? t('pages.Testimonials.edition.moreInfosButton')
                      : t('pages.Testimonials.callToActionText')
                  }
                  className={classes.textFieldEdition}
                  fullWidth
                  InputLabelProps={{ required: false }}
                  {...formik.getFieldProps('callToActionText')}
                  error={
                    formik.touched.callToActionText &&
                    Boolean(formik.errors.callToActionText)
                  }
                  disabled={buttonType === 'moreInfos'}
                  style={{ flex: 1 }}
                />
                <ButtonBase
                  className={classes.deleteIconButton}
                  onClick={deleteButton}
                >
                  <img src={DeleteIcon} />
                </ButtonBase>
              </div>
              <Typography className={classes.typographyTitleSelector}>
                {t('pages.Testimonials.edition.linkTo')}
              </Typography>
              <div className={classes.selectorContainerCTA}>
                <FormControl className={classes.buttonTypeSelector}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={buttonType}
                    onClick={handleOpen2}
                    renderValue={(p) => {
                      if (p === 'CTA') {
                        return 'URL';
                      } else if (p === 'shopifyAddCart') {
                        return t('pages.Testimonials.edition.shopifyAddCart');
                      } else if (p === 'moreInfos') {
                        return t('pages.Testimonials.edition.moreInfosButton');
                      }
                    }}
                    className={classes.selectorMenu}
                  >
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl2}
                      open={open2}
                      onClose={handleClose2}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      className={classes.menuList}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose2();
                          setButtonType('CTA');
                        }}
                      >
                        URL
                      </MenuItem>

                      {formik.values.testimonialType != 'video' && (
                        <MenuItem
                          onClick={() => {
                            handleClose2();
                            setButtonType('moreInfos');
                          }}
                        >
                          Poppin
                        </MenuItem>
                      )}
                      {user?.isShopifyAccount && (
                        <MenuItem
                          onClick={() => {
                            handleClose2();
                            setButtonType('shopifyAddCart');
                          }}
                        >
                          {t('pages.Testimonials.edition.shopifyAddCart')}
                        </MenuItem>
                      )}
                    </Menu>
                  </Select>
                </FormControl>
                {buttonType === 'CTA' && (
                  <TextFieldWrapper
                    id={`callToActionUrl`}
                    placeholder={t('pages.Testimonials.callToActionUrl')}
                    className={classes.textFieldEdition}
                    fullWidth
                    InputLabelProps={{ required: false }}
                    error={
                      formik.touched.callToActionUrl &&
                      Boolean(formik.errors.callToActionUrl)
                    }
                    {...formik.getFieldProps('callToActionUrl')}
                  />
                )}

                {buttonType === 'moreInfos' && (
                  <div className={classes.testimonialEditionDetailsContainer}>
                    <MyEditor
                      value={formik.values.detailsInput}
                      onChange={(value) =>
                        formik.setFieldValue('detailsInput', value)
                      }
                    />
                  </div>
                )}

                {buttonType === 'shopifyAddCart' && user?.isShopifyAccount && (
                  <ButtonBase
                    className={classes.addButton}
                    onClick={() => {
                      productPicker();
                    }}
                  >
                    {t('pages.Testimonials.edition.choseProduct')}
                  </ButtonBase>
                )}
              </div>

              {changeColor ? (
                <div className={classes.colorSelector}>
                  <HexColorPicker
                    color={formik.values.color}
                    onChange={(color) => mainColorDebounced(color)}
                  />
                  <TextFieldWrapper
                    variant="outlined"
                    margin="normal"
                    id="mainColor"
                    type="text"
                    className={classes.textFieldEdition}
                    name="mainColor"
                    fullWidth
                    value={formik.values.color}
                    onChange={(e) => {
                      mainColorDebounced(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <ButtonBase
                  className={classes.changeColorButton}
                  onClick={() => {
                    setChangeColor(true);
                  }}
                >
                  {t('pages.Testimonials.edition.changeColor')}
                </ButtonBase>
              )}
            </div>
          ) : (
            <ButtonBase
              className={classes.addButton}
              onClick={() => {
                setSwitchAddButton(true);
              }}
            >
              <AddIcon />
              {t('pages.Testimonials.addButton')}
            </ButtonBase>
          )}
        </div>
      </div>

      <div className={classes.testimonialsEditionBodyDivider}>
        <Divider orientation={'vertical'} />
      </div>

      <div className={classes.testimonialsEditionBodyLeftContainer}>
        <div className={classes.previewContainer}>{previewTestimonial}</div>
      </div>

      {!hideOffer && (
        <UpgradeDialog
          openOfferDialog={openOfferDialog}
          setOpenOfferDialog={setOpenOfferDialog}
        />
      )}

      <Dialog
        open={openMustUpgradeDialog}
        onClose={() => {
          setOpenMustUpgradeDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.offerDialog}
      >
        <div className={classes.mustUpgradeContainer}>
          <Typography className={classes.mustUpgradeTitle}>
            {t('common.mustUpgradeTitle')}
          </Typography>
          <Typography className={classes.mustUpgradeDescription}>
            {t('common.maxVideoLength')}
          </Typography>
          <ButtonBase
            className={classes.mustUpgradeButton}
            onClick={() => {
              setOpenMustUpgradeDialog(false);
              setOpenOfferDialog(true);
            }}
          >
            {t('common.upgradeNow')}
          </ButtonBase>
        </div>
      </Dialog>
    </form>
  );
};

export default TestimonialEditionBody;
