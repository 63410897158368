import React from 'react';
import { Tabs as TabsMUI, Tab } from '@mui/material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tabsContainer: {
    marginBottom: '5px',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      overflowX: 'scroll',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar ': {
        display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
      },
      '@media (max-width: 700px)': {
        justifyContent: 'flex-start',
      },
    },
  },
  tabComponent: {
    flex: 1,
    justifyContent: 'flex-start',
    color: '#273EAC !important',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '-0.03em',
  },
  indicator: {
    backgroundColor: '#273EAC',
  },
});

interface Props {
  value: number;
  changeTab: (event: React.SyntheticEvent, value: any) => void;
  tabsValue: string[];
  className?: string;
  secondClassName?: string;
}

const Tabs: React.FC<Props> = ({
  value,
  changeTab,
  tabsValue,
  className,
  secondClassName,
}) => {
  const classes = useStyles();

  return (
    <TabsMUI
      value={value}
      onChange={changeTab}
      centered
      className={`${classes.tabsContainer} ${className ? className : ''}`}
      classes={{ indicator: classes.indicator }}
    >
      {tabsValue.map((tab) => (
        <Tab
          className={`${classes.tabComponent} ${
            secondClassName ? secondClassName : ''
          }`}
          key={tab}
          label={tab}
        />
      ))}
    </TabsMUI>
  );
};

export default Tabs;
