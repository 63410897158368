const breakpoints = {
  values: {
    xs: 0,
    sm: 700,
    md: 1000,
    lg: 1200,
    xl: 1536,
    xxl: 2000,
    xxxl: 2500,
  },
};

export default breakpoints;
