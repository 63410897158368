import { ButtonBase } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#273EAC',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    marginBottom: '10px',
    gap: '10px',
  },
  iconContainer: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
    height: '50px',
    backgroundColor: '#273EAC',
    color: 'white',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '16px',
    borderRadius: '10px',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#273EAC',
    },
  },
});

const SuccessPayment = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const doneIcon = (
    <div className={classes.iconContainer}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M13.9084 21.0595C13.7715 21.2186 13.5348 21.2307 13.3823 21.0865L7.61884 15.6315C7.46638 15.4873 7.45975 15.2445 7.60395 15.092L9.44022 13.1517C9.5845 12.9992 9.82727 12.9926 9.97974 13.1368L13.1281 16.1164C13.2805 16.2607 13.5173 16.2485 13.6542 16.0894L19.8049 8.94279C19.9418 8.7837 20.1841 8.76554 20.3431 8.9025L22.3677 10.6451C22.5268 10.782 22.545 11.0242 22.408 11.1833L13.9084 21.0595Z"
          fill="#FFC001"
        />
        <path
          d="M13.9084 21.0595C13.7715 21.2186 13.5348 21.2307 13.3823 21.0865L7.61884 15.6315C7.46638 15.4873 7.45975 15.2445 7.60395 15.092L9.44022 13.1517C9.5845 12.9992 9.82727 12.9926 9.97974 13.1368L13.1281 16.1164C13.2805 16.2607 13.5173 16.2485 13.6542 16.0894L19.8049 8.94279C19.9418 8.7837 20.1841 8.76554 20.3431 8.9025L22.3677 10.6451C22.5268 10.782 22.545 11.0242 22.408 11.1833L13.9084 21.0595Z"
          fill="#08E8AF"
        />
      </svg>
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <div className={classes.container}>
        {doneIcon}
        {t('common.successPayment')}
      </div>
      <div className={classes.container} style={{ fontSize: '20px' }}>
        {t('common.subscriptionThankYou')}
      </div>
      <ButtonBase className={classes.button}>
        <Link
          to="/website
        "
          style={{ textDecoration: 'none', color: 'white' }}
        >
          {t('common.manageWebsite')}
        </Link>
      </ButtonBase>
    </div>
  );
};

export default SuccessPayment;
