import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Flex } from 'src/components/basic/Flex';
import ProfileCard from 'src/components/basic/ProfileCard';
import { theme } from 'src/constants/theme';

import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  activateAccount,
  addAdminAccount,
  adminGetAllUsers,
  deleteAccount,
  updateUserCompanyRole,
  changeSubscriptionType,
  changePluginPositionThunk,
  updateUserEmailAndPassword,
  updateUserLimitation,
  updateZindex,
  updateUserStep,
  adminSetUserToParent,
  User,
  addChildrenToParent,
  undoAdminAccount,
  UpdateHideKudeoButton,
  updateEarlyAccess,
  updateEngagement,
} from 'src/slices/auth';
import { handleNotification } from 'src/helpers/notifications';
import { useSnackbar } from 'notistack';
import TestimonialComponent from 'src/components/testimonials/TestimonialComponent';
import { Testimonial } from 'src/slices/testimonial';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const useStyles = createUseStyles({
  adminContainer: {
    height: '833px',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  usersPageDataGrid: {
    cursor: 'default',
    boxShadow:
      'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, \
     &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
      {
        outline: 'none',
      },
  },
  userPageActions: {
    marginTop: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },

  userSelectorContainer: {
    display: 'flex',
    gap: '22px',
    flexDirection: 'column',
  },
});

const Admin = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openTestimonialsDialog, setOpenTestimonialsDialog] = useState(false);
  const { t } = useTranslation();
  const [subscriptionType, setSubscriptionType] = useState<string>('none');
  const [pluginPosition, setPluginPosition] = useState<string>('bottom');
  const { users } = useAppSelector(({ auth: { users } }) => ({
    users,
  }));
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState<string>('');
  const [confirmChangeSubmit, setConfirmChangeSubmit] = useState<string>('');
  const [confirmChangeSubmitType, setConfirmChangeSubmitType] =
    useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openChangeSubscriptionDialog, setOpenChangeSubscriptionDialog] =
    useState<boolean>(false);
  const [openChangePositionPlugin, setOpenChangePositionPlugin] =
    useState<boolean>(false);
  const [openChangeEmailAndPassword, setOpenChangeEmailAndPassword] =
    useState<boolean>(false);

  const [openChangeLimitUser, setOpenChangeLimitUser] =
    useState<boolean>(false);
  const [confirmChangeLimitUser, setConfirmChangeLimitUser] =
    useState<string>('');

  const [openChangeZindexDialog, setOpenChangeZindexDialog] =
    useState<boolean>(false);

  const [confirmChangeZindex, setConfirmChangeZindex] = useState<string>('');

  const [openChangeStepTutorial, setOpenChangeStepTutorial] =
    useState<boolean>(false);

  const [confirmChangeStepTutorial, setConfirmChangeStepTutorial] =
    useState<string>('');

  const [confirmAddAccountToParent, setConfirmAddAccountToParent] =
    useState<string>('');

  const [step, setStep] = useState<number>(0);

  const [openAddAccountToParentDialog, setOpenAddAccountToParentDialog] =
    useState<boolean>(false);

  const [openAddChildrenToParentDialog, setOpenAddChildrenToParentDialog] =
    useState<boolean>(false);

  const [confirmAddChildrenToParent, setConfirmAddChildrenToParent] =
    useState<string>('');

  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  useEffect(() => {
    dispatch(adminGetAllUsers());
  }, []);

  useEffect(() => {
    if (confirmChangeSubmit != '') {
      const tempUser = users.find((user) => {
        return user._id === confirmChangeSubmit;
      })?.email;
      if (tempUser) {
        setEmail(tempUser);
      }
    }
  }, [confirmChangeSubmitType]);

  const handleOpenTestimonialsDialog = (testimonials: Testimonial[]) => {
    setTestimonials(testimonials);
    setOpenTestimonialsDialog(true);
  };

  const handleOpenProfilCardDialog = () => {
    setDialogOpened(false);
  };

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: t('common.firstName'), flex: 1 },
    { field: 'lastName', headerName: t('common.lastName'), flex: 1 },
    { field: 'email', headerName: 'E-mail', flex: 1 },
    { field: 'company', headerName: t('pages.Admin.company'), flex: 1 },
    {
      field: 'subscriptionType',
      headerName: t('common.subscription'),
      flex: 1,
      renderCell: (cellValues) => (
        <div>
          {cellValues.row.subscriptionType === 'none' ? (
            <div>None {cellValues.row.isAdmin ? '- Admin' : ''}</div>
          ) : cellValues.row.subscriptionType === 'freetrial' ? (
            <div>Free trial {cellValues.row.isAdmin ? '- Admin' : ''}</div>
          ) : cellValues.row.subscriptionType === 'startup' ? (
            <div>Startup {cellValues.row.isAdmin ? '- Admin' : ''}</div>
          ) : cellValues.row.subscriptionType === 'regular' ? (
            <div>Regular {cellValues.row.isAdmin ? '- Admin' : ''}</div>
          ) : cellValues.row.subscriptionType === 'premium' ? (
            <div>Premium {cellValues.row.isAdmin ? '- Admin' : ''}</div>
          ) : cellValues.row.subscriptionType === 'custom' ||
            cellValues.row.subscriptionType === 'company' ? (
            <div>Custom {cellValues.row.isAdmin ? '- Admin' : ''}</div>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      field: 'paymentInterval',
      headerName: t('pages.Admin.engagement'),
      flex: 1,
      renderCell: (cellValues) => (
        <div>
          {cellValues.row.subscriptionType === 'freetrial' ? (
            <div></div>
          ) : cellValues.row.subscriptionType === 'custom' ||
            cellValues.row.subscriptionType === 'company' ? (
            <div>Custom</div>
          ) : cellValues.row.paymentInterval === 'year' ? (
            <div>Annuel</div>
          ) : cellValues.row.paymentInterval === 'month' ? (
            <div>Mensuel</div>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      field: 'createdAt',
      headerName: t('pages.Admin.date'),
      flex: 1,
      renderCell: (cellValues) => (
        <div>
          {new Date(cellValues.row.createdAt).toLocaleDateString()} -{' '}
          {new Date(cellValues.row.createdAt).toLocaleTimeString()}
        </div>
      ),
    },
    {
      field: 'lastConnection',
      headerName: t('pages.Admin.lastConnection'),
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div>
            {new Date(cellValues.row.lastConnection).toLocaleDateString()} -{' '}
            {new Date(cellValues.row.lastConnection).toLocaleTimeString()}
          </div>
        );
      },
    },
    {
      field: 'numberOfConnections',
      headerName: t('pages.Admin.numberOfConnections'),
      flex: 1,
    },
  ];

  const adminDeleteAccount = (userId: string) => {
    setDeleteUserDialog(userId);
    setOpenDeleteDialog(true);
  };

  const dialogAdminDeleteAccount = async () => {
    if (deleteUserDialog !== '') {
      await dispatch(deleteAccount(deleteUserDialog));
      setOpenDeleteDialog(false);
      setDeleteUserDialog('');
    }
  };

  const [maxLimitPage, setMaxLimitePage] = useState<number>(0);
  const [maxLimitVideos, setMaxLimitVideos] = useState<number>(0);
  const [maxLimitDomainUrl, setMaxLimitDomainUrl] = useState<number>(0);
  const [maxVideoHosted, setMaxVideoHosted] = useState<number>(0);
  const [
    maxNumberOfDisplayedTestimonials,
    setMaxNumberOfDisplayedTestimonials,
  ] = useState<number>(0);

  const [zindex, setZindex] = useState<number>(0);

  const dialogChangeLimitUser = async () => {
    if (confirmChangeLimitUser !== '') {
      await dispatch(
        updateUserLimitation({
          userId: confirmChangeLimitUser,
          maxNumberOfPages: maxLimitPage,
          maxNumberOfVideos: maxLimitVideos,
          maxDomainUrl: maxLimitDomainUrl,
          maxNumberOfVideoHosted: maxVideoHosted,
          maxNumberOfDisplayedTestimonials: maxNumberOfDisplayedTestimonials,
        }),
      );
      setOpenChangeLimitUser(false);
      setConfirmChangeLimitUser('');
    }
  };

  const dialogChangeZindex = async () => {
    if (confirmChangeZindex !== '') {
      await dispatch(
        updateZindex({
          id: confirmChangeZindex,
          zIndex: zindex,
        }),
      );
      setOpenChangeZindexDialog(false);
      setConfirmChangeZindex('');
    }
  };

  const dialogChangeStepTutorial = async () => {
    if (confirmChangeStepTutorial !== '') {
      await dispatch(
        updateUserStep({ step: step, id: confirmChangeStepTutorial }),
      );
      setOpenChangeStepTutorial(false);
      setConfirmChangeStepTutorial('');
    }
  };

  const dialogSetAccountToParent = async () => {
    if (confirmAddAccountToParent !== '') {
      await dispatch(adminSetUserToParent(confirmAddAccountToParent));
      setOpenAddAccountToParentDialog(false);
      setConfirmAddAccountToParent('');
    }
  };

  const dialogChangeSubscription = async () => {
    if (confirmChangeSubmit != '') {
      let hasValidSubscription: boolean;
      if (subscriptionType == 'none') {
        hasValidSubscription = false;
      } else {
        hasValidSubscription = true;
      }
      await dispatch(
        changeSubscriptionType({
          userId: confirmChangeSubmit,
          updateUserDtoSubscriptionType: {
            subscriptionType,
            hasValidSubscription,
          },
        }),
      );
      setConfirmChangeSubmit('');
      setOpenChangeSubscriptionDialog(false);
    }
  };

  const confirmChangePasswordAndEmail = async () => {
    if (confirmChangeSubmitType != '') {
      await dispatch(
        updateUserEmailAndPassword({
          userId: confirmChangeSubmitType,
          email: email,
          password: password,
        }),
      );
      setConfirmChangeSubmit('');
      setOpenChangeEmailAndPassword(false);
    }
  };

  const dialogChangePluginPosition = async () => {
    if (confirmChangeSubmitType != '') {
      await dispatch(
        changePluginPositionThunk({
          userId: confirmChangeSubmitType,
          position: pluginPosition,
        }),
      );
      setConfirmChangeSubmit('');
      setOpenChangePositionPlugin(false);
    }
  };

  const addAdmin = (userId: string) => {
    dispatch(addAdminAccount(userId));
  };

  const undoAdminAccountFunction = (userId: string) => {
    dispatch(undoAdminAccount(userId));
  };

  const hideKudeoButtonFunction = (userId: string, value: boolean) => {
    dispatch(
      UpdateHideKudeoButton({ userId: userId, hideKudeoButton: value }),
    ).then(() => {
      window.location.reload();
    });
  };

  const adminActivateAccount = (userId: string) => {
    dispatch(activateAccount(userId));
  };

  const updateEarlyAccessFunction = (userId: string, earlyAccess: boolean) => {
    dispatch(updateEarlyAccess({ userId, earlyAccess })).then((e) => {
      if (e.meta.requestStatus === 'fulfilled') {
        window.location.reload();
      }
    });
  };

  const [openEngagementDialog, setOpenEngagementDialog] = useState(false);

  const [engagement, setEngagement] = useState<string>('');

  const openEngagementDialogFunction = (
    userId: string,
    paymentInterval?: string,
  ) => {
    setConfirmChangeSubmit(userId);
    setOpenEngagementDialog(true);
    setEngagement(paymentInterval ?? '');
  };

  const updateEngagementFunction = async () => {
    if (confirmChangeSubmit != '') {
      await dispatch(
        updateEngagement({
          userId: confirmChangeSubmit,
          paymentInterval: engagement,
        }),
      );

      setConfirmChangeSubmit('');
      setOpenEngagementDialog(false);
    }
  };

  const changeSubscriptionTypeFunction = async (
    userId: string,
    subcriptionType: string,
  ) => {
    setConfirmChangeSubmit(userId);
    setOpenChangeSubscriptionDialog(true);
    setSubscriptionType(subcriptionType);
  };

  const changePluginPosition = async (
    userId: string,
    pluginPosition: string,
  ) => {
    setConfirmChangeSubmitType(userId);
    setOpenChangePositionPlugin(true);
    setPluginPosition(pluginPosition);
  };

  const changeUserEmailAndPassword = async (userId: string) => {
    setConfirmChangeSubmitType(userId);
    setOpenChangeEmailAndPassword(true);
  };

  const changeUserLimit = async (userId: string) => {
    setConfirmChangeLimitUser(userId);
    setOpenChangeLimitUser(true);
  };

  const changeStepTutorial = async (userId: string) => {
    setConfirmChangeStepTutorial(userId);
    setOpenChangeStepTutorial(true);
  };

  const changeAccountType = async (userId: string) => {
    setConfirmAddAccountToParent(userId);
    setOpenAddAccountToParentDialog(true);
  };

  const addChildrenToParentProfileCard = async (userId: string) => {
    setConfirmAddChildrenToParent(userId);
    setOpenAddChildrenToParentDialog(true);
  };

  const changeZindex = async (userId: string, index: number) => {
    setConfirmChangeZindex(userId);
    setOpenChangeZindexDialog(true);
    setZindex(index);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setSubscriptionType(event.target.value as string);
  };

  const handleChangeEngagement = (event: SelectChangeEvent) => {
    setEngagement(event.target.value as string);
  };

  const handleChangePluginPosition = (event: SelectChangeEvent) => {
    setPluginPosition(event.target.value as string);
  };

  const updateCompanyRole = async (
    userId: string,
    values: { company?: string; position?: string },
  ) => {
    const updatedValues = {
      company: values.company,
      position: values.position,
    };
    await handleNotification(
      dispatch(
        updateUserCompanyRole({
          userId: userId,
          ...updatedValues,
        }),
      ),
      t('pages.Admin.successUpdateUser'),
      t('pages.Admin.errorUpdateUser'),
      enqueueSnackbar,
      closeSnackbar,
    );
  };

  const [checked, setChecked] = useState<{ user: User; value: boolean }[]>(
    () => {
      const temp: { user: User; value: boolean }[] = [];

      users.map((user: User) => {
        temp.push({ user: user, value: false });
      });
      return temp;
    },
  );

  const [sortedUsers, setSortedUsers] = useState<User[]>([]);

  useEffect(() => {
    const temp = [...users];
    setSortedUsers(
      temp.sort((a: User, b: User) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : undefined;
        const dateB = b.createdAt ? new Date(b.createdAt) : undefined;
        if (dateA && dateB) {
          return dateB.getTime() - dateA.getTime();
        } else if (a.createdAt) {
          return -1;
        } else {
          return 1;
        }
      }),
    );
  }, [users]);

  useEffect(() => {
    const temp: { user: User; value: boolean }[] = [];

    users.map((user) => {
      temp.push({ user: user, value: false });
    });
    setChecked(temp);
  }, [users]);

  const dialogAddChildrenAccountToParent = async () => {
    if (confirmAddChildrenToParent !== '') {
      checked.map((user) => {
        if (user.value) {
          dispatch(
            addChildrenToParent({
              userId: confirmAddChildrenToParent,
              childrenId: user.user._id,
            }),
          );
        }
      });

      setOpenAddChildrenToParentDialog(false);
      setConfirmAddChildrenToParent('');
    }
  };

  const setCheckTempUser = (user: User, isChecked: boolean) => {
    const newChecked = checked.map((temp) => {
      if (temp.user === user) {
        temp.value = isChecked;
        return temp;
      } else {
        return temp;
      }
    });
    setChecked(newChecked);
  };

  return (
    <div className={classes.adminContainer}>
      <DataGrid
        className={classes.usersPageDataGrid}
        loading={false}
        rows={sortedUsers.map((user) => ({
          id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          company: user?.company ?? '',
          email: user.email,
          subscriptionType: user?.subscriptionType ?? '',
          paymentInterval: user?.paymentInterval ?? '',
          createdAt: user.createdAt,
          lastConnection: user.stats?.lastConnection ?? user.createdAt,
          numberOfConnections: user.stats?.numberOfConnections ?? 0,
          isAdmin: user?.isAdmin ?? false,
        }))}
        columns={columns}
        checkboxSelection
        autoPageSize
        onRowSelectionModelChange={(gridUsers: any) =>
          setSelectedUsers(
            gridUsers.map((gridUser: any) => gridUser.toString()),
          )
        }
        sx={{
          '& .MuiDataGrid-cell': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          '& .MuiDataGrid-columnHeader': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
        }}
      />
      <Flex className={classes.userPageActions}>
        <Button
          variant="contained"
          onClick={() => {
            setDialogOpened(true);
          }}
          disabled={selectedUsers.length === 0}
        >
          {t('pages.Admin.showUsers')}
        </Button>
      </Flex>
      <Dialog
        open={isDialogOpened && selectedUsers.length !== 0}
        onClose={() => setDialogOpened(false)}
      >
        <DialogContent>
          <Grid container spacing={2}>
            {users
              .filter((user: User) =>
                selectedUsers.find((selectedUser) => user._id === selectedUser),
              )
              .map((user) => {
                return (
                  <Grid item xs={12} key={user._id}>
                    <ProfileCard
                      user={user}
                      addAdmin={addAdmin}
                      undoAdminAccountFunction={undoAdminAccountFunction}
                      adminDeleteAccount={adminDeleteAccount}
                      updateCompanyRole={updateCompanyRole}
                      activateAccount={adminActivateAccount}
                      updateEarlyAccessFunction={updateEarlyAccessFunction}
                      openEngagementDialogFunction={
                        openEngagementDialogFunction
                      }
                      changeSubscription={changeSubscriptionTypeFunction}
                      changePluginPosition={changePluginPosition}
                      changeUserEmailAndPassword={changeUserEmailAndPassword}
                      changeUserLimit={changeUserLimit}
                      setMaxLimitePages={setMaxLimitePage}
                      setMaxLimiteVideos={setMaxLimitVideos}
                      setMaxLimiteDomainUrl={setMaxLimitDomainUrl}
                      handleOpenTestimonialsDialog={
                        handleOpenTestimonialsDialog
                      }
                      handleOpenProfilCardDialog={handleOpenProfilCardDialog}
                      changeZindex={changeZindex}
                      changeStepTutorial={changeStepTutorial}
                      changeAccountType={changeAccountType}
                      addChildrenToParentProfileCard={
                        addChildrenToParentProfileCard
                      }
                      setMaxVideoHosted={setMaxVideoHosted}
                      setMaxNumberOfDisplayedTestimonials={
                        setMaxNumberOfDisplayedTestimonials
                      }
                      hideKudeoButtonFunction={hideKudeoButtonFunction}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDeleteUserDialog('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('pages.Admin.confirmDeleteUserTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('pages.Admin.confirmDeleteUser')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setDeleteUserDialog('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogAdminDeleteAccount()} autoFocus>
            {t('common.delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangeSubscriptionDialog}
        onClose={() => {
          setOpenChangeSubscriptionDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('pages.Admin.changeSubscription')}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={subscriptionType}
              label="Subscription"
              onChange={handleChange}
            >
              <MenuItem value={'none'}>None</MenuItem>
              <MenuItem value={'freetrial'}>Free trial</MenuItem>
              <MenuItem value={'startup'}>Startup</MenuItem>
              <MenuItem value={'regular'}>Regular</MenuItem>
              <MenuItem value={'premium'}>Premium</MenuItem>
              <MenuItem value={'company'}>Company</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenChangeSubscriptionDialog(false);
              setConfirmChangeSubmit('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogChangeSubscription()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangePositionPlugin}
        onClose={() => {
          setOpenChangePositionPlugin(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Modifier la position du plugin'}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pluginPosition}
              label="Plugin position"
              onChange={handleChangePluginPosition}
            >
              <MenuItem value={'bottom'}>Bottom</MenuItem>
              <MenuItem value={'top'}>Top</MenuItem>
              <MenuItem value={'right'}>Right</MenuItem>
              <MenuItem value={'left'}>Left</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenChangePositionPlugin(false);
              setConfirmChangeSubmitType('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogChangePluginPosition()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangeEmailAndPassword}
        onClose={() => {
          setOpenChangeEmailAndPassword(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('pages.Admin.changeSubscription')}
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex' }}>
            <TextField
              defaultValue={email}
              placeholder={'example@example.com'}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
            <TextField
              defaultValue={password}
              placeholder={'***'}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              type="password"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenChangeEmailAndPassword(false);
              setConfirmChangeSubmit('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => confirmChangePasswordAndEmail()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangeLimitUser}
        onClose={() => {
          setOpenChangeLimitUser(false);
          setConfirmChangeLimitUser('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ marginBottom: '5px' }}>
          Modifier les limites du compte
        </DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <TextField
            id="outlined-number"
            label="Max Pages"
            type="number"
            value={maxLimitPage}
            onChange={(e) => {
              setMaxLimitePage(+e.currentTarget.value);
            }}
          />
          <TextField
            id="outlined-number"
            label="Max Videos"
            type="number"
            value={maxLimitVideos}
            onChange={(e) => {
              setMaxLimitVideos(+e.currentTarget.value);
            }}
          />
          <TextField
            id="outlined-number"
            label="Max Domain URL"
            type="number"
            value={maxLimitDomainUrl}
            onChange={(e) => {
              setMaxLimitDomainUrl(+e.currentTarget.value);
            }}
          />
          <TextField
            id="outlined-number"
            label="Max video hosted on Kudeo"
            type="number"
            value={maxVideoHosted}
            onChange={(e) => {
              setMaxVideoHosted(+e.currentTarget.value);
            }}
          />
          <TextField
            id="outlined-number"
            label="Max displayed testimonials"
            type="number"
            value={maxNumberOfDisplayedTestimonials}
            onChange={(e) => {
              setMaxNumberOfDisplayedTestimonials(+e.currentTarget.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenChangeLimitUser(false);
              setConfirmChangeLimitUser('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogChangeLimitUser()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openTestimonialsDialog}
        onClose={() => {
          setOpenTestimonialsDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ top: '80px' }}
      >
        <DialogContent>
          <>
            <Grid
              container
              direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              spacing={1}
            >
              {testimonials.map((testimonial, index) => (
                <Grid key={index} item xs={12} md={12} xl={4}>
                  <TestimonialComponent
                    key={testimonial._id}
                    testimonial={testimonial}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenTestimonialsDialog(false);
            }}
          >
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangeZindexDialog}
        onClose={() => {
          setOpenChangeZindexDialog(false);
          setConfirmChangeZindex('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ marginBottom: '5px' }}>
          Modifier le Z-INDEX
        </DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <TextField
            id="outlined-number"
            label="Z-INDEX"
            type="number"
            value={zindex}
            onChange={(e) => {
              setZindex(+e.currentTarget.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenChangeZindexDialog(false);
              setConfirmChangeZindex('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogChangeZindex()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangeStepTutorial}
        onClose={() => {
          setOpenChangeStepTutorial(false);
          setConfirmChangeStepTutorial('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ marginBottom: '5px' }}>
          Modifier l&apos;étape du tutoriel
        </DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <TextField
            id="outlined-number"
            label="Z-INDEX"
            type="number"
            value={step}
            onChange={(e) => {
              setStep(+e.currentTarget.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenChangeStepTutorial(false);
              setConfirmChangeStepTutorial('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogChangeStepTutorial()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddAccountToParentDialog}
        onClose={() => {
          setOpenAddAccountToParentDialog(false);
          setConfirmAddAccountToParent('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ marginBottom: '5px' }}>
          Voulez vous vraiment modifier le type de compte en type parent ?
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => {
              setOpenAddAccountToParentDialog(false);
              setConfirmAddAccountToParent('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogSetAccountToParent()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddChildrenToParentDialog}
        onClose={() => {
          setOpenAddChildrenToParentDialog(false);
          setConfirmAddChildrenToParent('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ marginBottom: '5px' }}>
          Ajouter un compte enfant à ce compte parent ?
        </DialogTitle>
        <DialogContent>
          <div className={classes.userSelectorContainer}>
            {users
              .filter((user) => {
                const userParent = users.find((u) => {
                  return u._id === confirmAddChildrenToParent;
                });
                if (userParent) {
                  if (userParent._id.toString() === user._id.toString()) {
                    return false;
                  }
                  const children = userParent.accountType
                    ?.children as unknown as User[];
                  if (userParent.accountType?.type === 'parent') {
                    if (children.length) {
                      let isHere = true;
                      children.map((child: any) => {
                        if (child.toString() === user._id.toString()) {
                          isHere = false;
                        } else {
                          isHere = true;
                        }
                      });
                      return isHere;
                    } else {
                      return true;
                    }
                  }
                } else {
                  return true;
                }
              })
              .map((user, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    icon={
                      <RadioButtonUncheckedIcon style={{ color: '#273EAC' }} />
                    }
                    checkedIcon={
                      <RadioButtonCheckedIcon style={{ color: '#273EAC' }} />
                    }
                    onChange={(event) => {
                      setCheckTempUser(user, event.target.checked);
                    }}
                    checked={
                      checked?.find((s) => {
                        return s.user === user;
                      })?.value
                    }
                  />
                  <div>
                    {user.firstName} {user.lastName} : {user.email}
                  </div>
                </div>
              ))}
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpenAddChildrenToParentDialog(false);
              setConfirmAddChildrenToParent('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogAddChildrenAccountToParent()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEngagementDialog}
        onClose={() => {
          setOpenEngagementDialog(false);
          setConfirmAddAccountToParent('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ marginBottom: '5px' }}>
          Modifier l&apos;engagement
        </DialogTitle>

        <DialogContent>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={engagement}
              label="Engagement"
              onChange={handleChangeEngagement}
            >
              <MenuItem value={'month'}>Month</MenuItem>
              <MenuItem value={'year'}>Year</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEngagementDialog(false);
              setConfirmChangeSubmit('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => updateEngagementFunction()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Admin;
