import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Link as MuiLink,
  Container,
  Box,
  ButtonBase,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { Flex } from 'src/components/basic/Flex';
import { TextFieldWrapper } from 'src/components/basic/TextFieldWrapper';
import { Upload } from 'src/components/basic/Upload';
import { theme } from 'src/constants/theme';
import { useAppDispatch } from 'src/hooks/store';
import { getCompanyLogo } from 'src/slices/auth';
import {
  removeFile,
  uploadFileImage,
  UploadResultImage,
} from 'src/slices/file';
import { AddNewTestimonial } from 'src/slices/testimonial';
import * as yup from 'yup';

const useStyles = createUseStyles({
  testimonialContainer: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  testimonialTitle: {
    marginBottom: theme.spacing(4),
    fontSize: 'xx-large',
    fontWeight: 'bold',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  testimonialContent: {},
  contactLinkedInUrl: {
    margin: theme.spacing(3, 0),
  },
  profilePictureUrl: {
    alignItems: 'center',
  },
  profilePictureUrlAvatar: {
    margin: theme.spacing(0, 2),
  },
  sendButton: {
    marginTop: theme.spacing(3),
  },
  stackInputs: {
    marginTop: theme.spacing(2.5),
  },

  sentTitle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '600',
  },

  sentTitleDescription: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    marginBottom: '20px',
  },

  discoverTestimonialTitle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '10px',
  },

  discoverTestimonialButton: {
    color: 'white',
    background: '#273EAC',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '8px',
    padding: '10px',
  },
  registerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
    borderRadius: '8px',
    paddingTop: '40px',
    width: 'fit-content',
    paddingBottom: '40px',
  },
  logo: {
    width: '138px',
    height: '42px',
    marginBottom: '24px',
    alignSelf: 'center',
  },
});

const AddTestimonialAndContact = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [alreadySent, setAlreadySent] = useState<boolean>(false);
  const { userId } = useParams();
  const validationSchema = yup.object({
    testimonial: yup.string().required(t('forms.rules.required')),
    firsName: yup.string().optional(),
    lastName: yup.string().optional(),
    email: yup.string().optional(),
    company: yup.string().optional(),
    position: yup.string().optional(),
    picture: yup.string(),
    terms: yup.bool().oneOf([true], t('forms.rules.required')),
  });

  const [companyLogo, setCompanyLogo] = useState('/Logo.svg');

  useEffect(() => {
    if (userId)
      dispatch(getCompanyLogo(userId)).then((e) => {
        console.log(e.payload);
        if (e.payload) {
          setCompanyLogo(e.payload as string);
        }
      });
  }, [userId]);

  const initialValues = {
    testimonial: '',
    firstName: '',
    lastName: '',
    company: '',
    position: '',
    picture: '',
    terms: false,
  };

  const formikOnSubmit = async (values: {
    testimonial: string;
    firstName: string;
    lastName: string;
    company: string;
    position: string;
    picture: string;
    terms: boolean;
  }) => {
    // Add testimonial
    const information =
      values.firstName +
      ' ' +
      values.lastName +
      ' - ' +
      values.position +
      ' | ' +
      values.company;
    await dispatch(
      AddNewTestimonial({
        content: values.testimonial,
        information: information,
        picture: values.picture,
        userId: userId,
      }),
    );
    setAlreadySent(true);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: formikOnSubmit,
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, []);

  const uploadOnChange = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await dispatch(
        uploadFileImage({
          formData,
        }),
      );

      if (result.meta.requestStatus === 'fulfilled') {
        const payload: UploadResultImage = result.payload as UploadResultImage;

        if (payload) {
          formik.setFieldValue('picture', payload);
        } else {
          console.error('Error: Missing "url" in payload:', payload);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const removeFileFunction = async (key: string) => {
    try {
      await dispatch(removeFile(key)).then(() => {
        formik.setFieldValue('picture', '');
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div
      title="Add testimonial | Kudeo"
      style={{
        height: '100%',
        backgroundImage: `url('/background.svg')`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Container className={classes.registerContainer}>
          <Box component="img" src={companyLogo} className={classes.logo} />
          {!alreadySent && userId ? (
            <>
              <div className={classes.testimonialTitle}>
                {t('pages.Testimonials.writeTestimonial')}
              </div>

              <TextFieldWrapper
                variant="outlined"
                multiline
                minRows={3}
                className={classes.testimonialContent}
                label={t('common.testimonial', {
                  length: formik.values.testimonial.length,
                })}
                required
                InputLabelProps={{ required: false, shrink: true }}
                fullWidth
                {...formik.getFieldProps('testimonial')}
                error={
                  formik.touched.testimonial &&
                  Boolean(formik.errors.testimonial)
                }
                helperText={
                  formik.touched.testimonial && formik.errors.testimonial
                }
                inputProps={{ maxLength: 200 }}
              />
              <Stack
                className={classes.stackInputs}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
              >
                <TextFieldWrapper
                  variant="outlined"
                  label={t('common.firstName')}
                  fullWidth
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <TextFieldWrapper
                  variant="outlined"
                  label={t('common.lastName')}
                  fullWidth
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Stack>

              <Stack
                className={classes.stackInputs}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
              >
                <TextFieldWrapper
                  variant="outlined"
                  label={t('common.company')}
                  fullWidth
                  name="company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.company && Boolean(formik.errors.company)
                  }
                  helperText={formik.touched.company && formik.errors.company}
                />
                <TextFieldWrapper
                  variant="outlined"
                  label={t('common.position')}
                  fullWidth
                  name="position"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.position && Boolean(formik.errors.position)
                  }
                  helperText={formik.touched.position && formik.errors.position}
                />
              </Stack>

              <Flex className={classes.profilePictureUrl}>
                <div>{t('common.profilePicture')} :</div>
                <Avatar
                  className={classes.profilePictureUrlAvatar}
                  alt="avatar"
                  src={formik.values.picture ?? ''}
                  aria-label="avatar"
                />
                <Upload
                  onChange={(e) => {
                    if (formik.values.picture === '') {
                      uploadOnChange(e);
                    }
                  }}
                  onClick={() => {
                    if (formik.values.picture !== '') {
                      removeFileFunction(formik.values.picture);
                    }
                  }}
                  id="contactsUpload"
                  file="image"
                  type={formik.values.picture !== '' ? 'button' : undefined}
                />
              </Flex>

              <Stack
                direction="row"
                spacing={2}
                style={{ marginTop: theme.spacing(3), alignItems: 'center' }}
              >
                <FormControlLabel
                  control={<Checkbox required />}
                  label={`${t('pages.Testimonials.acceptTerms')} `}
                  checked={formik.values.terms}
                  {...formik.getFieldProps(`terms`)}
                />
                <MuiLink href="https://kudeo.co/terms" target="_blank">
                  https://kudeo.co/terms
                </MuiLink>
              </Stack>

              <Button
                variant="contained"
                className={classes.sendButton}
                onClick={() => formik.submitForm()}
              >
                {t('common.submit')}
              </Button>
            </>
          ) : (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <div className={classes.sentTitle}>
                {t('pages.Testimonials.sentTestimonial')}
              </div>
              <div className={classes.sentTitleDescription}>
                {t('pages.Testimonials.sentTestimonialDescription')}
              </div>

              <div className={classes.discoverTestimonialTitle}>
                {t('pages.Testimonials.discoverKudeo')}
              </div>
              <MuiLink href="https://kudeo.co" target="_blank">
                <ButtonBase className={classes.discoverTestimonialButton}>
                  {t('pages.Testimonials.discoverKudeoButton')}
                </ButtonBase>
              </MuiLink>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default AddTestimonialAndContact;
