import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/store';
import { User } from 'src/slices/auth';
import { DisplayPreferences } from 'src/slices/testimonial';
import TestimonialCard from './TestimonialCard/TestimonialCard';
import ReactPlayer from 'react-player';
import { Button, ButtonBase, IconButton } from '@mui/material';
import { QuoteIcon } from 'src/constants/svg';
import UserIcon from '../../assets/icon-user.svg';
import { theme } from 'src/constants/theme';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
  testimonialPreview: {
    height: 'fit-content',
    padding: '10px',
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'center',
    gap: '30px',
  },
  video: {
    overflow: 'hidden',
    '& video': {
      objectFit: 'cover',
    },
    '& iframe': {
      transform: 'scale(1.9)',
    },
  },
  iframeShort: {
    '& iframe': {
      transform: 'translate(10px,-58px) scale(3.25) !important',
    },
  },
  bubbleContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: '4px',
  },
  title: {
    position: 'absolute',
    display: 'flex',
    textAlign: 'center',
    padding: '1px',
    fontSize: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    textShadow:
      '0.75px 0.75px 0 #000, -0.75px -0.75px 0 #000,0.75px -0.75px 0 #000,-0.75px 0.75px 0 #000',
    '&.inside': {
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
    },
    '&.bottom': {
      transform: 'translate(-50%)',
      top: '107%',
      left: '50%',
    },
  },
  idleButton: {
    border: '0',
    appearance: 'none',
    background: 'transparent',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    padding: '0 !important',
    margin: '0 !important',
    transform: 'scale(0.96)',
    transition: 'all .20s',
    pointerEvents: 'none',
  },
  idleButtonImageWrapper: {
    width: '100%',
    height: '100%',
    background: 'white',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    padding: '6px',
  },
  idleButtonImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
  },
  quoteIconWrapper: {
    zIndex: '999',
    position: 'absolute',
    float: 'left',
    top: '0',
    left: '0',
    background: 'white',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    transition: 'all .15s',
    '& svg': {
      width: '70%',
      margin: 'auto',
      marginTop: '-2px',
    },
  },
  embeddedContainer: {
    position: 'relative',
  },
  commonBackground: {
    boxShadow: '0 2px 16px rgba(0, 0, 0, 0.15)',
    background: 'rgba(255, 255, 255, 0.97)',
    backdropFilter: 'blur(20px)',
    borderRadius: 16,
  },
  CTAWrapper: {
    position: 'absolute',
    opacity: '0.8',
    bottom: '10px',
    right: '10px',
  },
  CTA: {
    fontSize: '12px',
    boxShadow: 'none',
    padding: '4px 12px',
    borderRadius: '1000px',
    transition: '0.15s',
    '&:hover': {
      opacity: '0.7',
    },
  },
  volumeIconVertical: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    opacity: '0.8',
    bottom: '10px',
    left: '10px',
  },
  typography: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontStyle: 'normal',
    position: 'absolute',
  },
});

interface Props {
  content?: string;
  displayPreferences: DisplayPreferences;
  createdAt?: string;
  tags?: string[];
  details: string;
  callToActionText: string;
  testimonialType?: string;
  information?: string;
  logo?: string;
  picture?: string;
  color?: string;
  title?: string;
  titlePosition?: string;
  titleColor?: string;
  displayLocation?: string;
  tag?: string;
  titleEmbedded: string;
  titleColorEmbedded: string;
  titleEmbeddedPosition: string;
}

const PreviewTestimonial: React.FC<Props> = (context) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user }: { user: User | null } = useAppSelector(
    ({ auth: { user } }) => ({
      user,
    }),
  );

  const [size, setSize] = React.useState(56);

  const [policeSize, setPoliceSize] = React.useState(16);

  const containerRef = useRef<HTMLDivElement>(null);

  const titleRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (containerRef.current && titleRef.current) {
      const hauteurDiv = containerRef.current.offsetHeight;
      const largeurDiv = containerRef.current.offsetWidth;
      const hauteurTexte = titleRef.current.offsetHeight;
      const largeurTexte = titleRef.current.offsetWidth;

      const ratioHauteur = hauteurDiv / hauteurTexte;
      const ratioLargeur = largeurDiv / largeurTexte;

      const nouveauRatio = Math.min(ratioHauteur, ratioLargeur);
      if (policeSize * nouveauRatio > 16) {
        setPoliceSize(16);
      } else {
        setPoliceSize((prevSize) => prevSize * nouveauRatio);
      }
    }
  }, [containerRef, titleRef, context.title]);

  const [bubbleSize, setBubbleSize] = React.useState('small');

  const [borderRadiusBubble, setBorderRadiusBubble] = React.useState(50);

  const [embeddedSize, setEmbeddedSize] = React.useState(432);

  useEffect(() => {
    user?.settings.pluginSettings.testimonialBubbleDimension === 'large'
      ? setBubbleSize('large')
      : user?.settings.pluginSettings.testimonialBubbleDimension === 'medium'
      ? setBubbleSize('medium')
      : setBubbleSize('small');
    if (user?.settings.pluginSettings.testimonialBubbleRadius) {
      setBorderRadiusBubble(
        user?.settings.pluginSettings.testimonialBubbleRadius,
      );
    }
  }, [user]);

  useEffect(() => {
    user?.settings.pluginSettings.tagsList.find((t) => {
      return t.tag === context.tag;
    })?.embeddedSettings?.videoSize === 'small'
      ? setEmbeddedSize(300)
      : user?.settings.pluginSettings.tagsList.find((t) => {
          return t.tag === context.tag;
        })?.embeddedSettings?.videoSize === 'medium'
      ? setEmbeddedSize(380)
      : setEmbeddedSize(432);
  }, [user]);

  useEffect(() => {
    if (bubbleSize === 'small') {
      setSize(56);
    } else if (bubbleSize === 'medium') {
      setSize(63);
    } else if (bubbleSize === 'large') {
      setSize(70);
    }
  }, [bubbleSize]);

  const [onClickVideo, setOnClickVideo] = React.useState<boolean>(false);

  return (
    <div className={classes.testimonialPreview}>
      {context.displayLocation === 'embedded' ? (
        <div
          className={`${classes.embeddedContainer} ${classes.commonBackground}`}
          style={{
            height: `${embeddedSize}px`,
            width: `${(embeddedSize * 9) / 16}px`,
          }}
        >
          <ReactPlayer
            url={context.content}
            playing={true}
            loop={true}
            volume={0}
            width={'100%'}
            height={'100%'}
            style={{
              borderRadius: '16px',
              overflow: 'hidden',
              backgroundColor: '#efefef',
            }}
          />

          {context.displayPreferences.showMoreInfos &&
          context.details &&
          context.details.length ? (
            <div className={`${classes.CTAWrapper}`}>
              <Button
                variant="contained"
                className={`${classes.CTA}`}
                size="small"
                style={{
                  background:
                    context.color ??
                    user?.settings.pluginSettings.mainColor ??
                    theme.palette.primary.main,
                }}
              >
                {t('pages.Testimonials.edition.moreInformationCallToAction')}
              </Button>
            </div>
          ) : (
            context.callToActionText && (
              <div className={`${classes.CTAWrapper}`}>
                <Button
                  variant="contained"
                  className={classes.CTA}
                  size="small"
                  style={{
                    background:
                      context.color ??
                      user?.settings.pluginSettings.mainColor ??
                      theme.palette.primary.main,
                  }}
                >
                  {context.callToActionText}
                </Button>
              </div>
            )
          )}
          <IconButton
            className={classes.volumeIconVertical}
            onClick={() => {
              setOnClickVideo((onClickVideo) => !onClickVideo);
            }}
          >
            {onClickVideo ? <VolumeUpIcon /> : <VolumeOffIcon />}
          </IconButton>

          <div
            className={classes.typography}
            style={
              context.titleEmbeddedPosition === 'top'
                ? {
                    top: '40px',
                    transform: 'translateX(50%)',
                    right: '50%',
                    color: `${context.titleColorEmbedded}`,
                  }
                : context.titleEmbeddedPosition === 'bottom'
                ? {
                    bottom: '40px',
                    transform: 'translateX(50%)',
                    right: '50%',
                    color: `${context.titleColorEmbedded}`,
                  }
                : context.titleEmbeddedPosition === 'center'
                ? {
                    transform: 'translate(50%, 50%)',
                    right: '50%',
                    top: '50%',
                    color: `${context.titleColorEmbedded}`,
                  }
                : {
                    bottom: '10px',
                    transform: 'translateX(50%)',
                    right: '50%',
                    color: `${context.titleColorEmbedded}`,
                  }
            }
          >
            {context.titleEmbedded}
          </div>
        </div>
      ) : (
        <>
          <TestimonialCard
            information={context.information}
            logo={context.logo}
            picture={context.picture}
            displayPreferences={context.displayPreferences}
            createdAt={context.createdAt}
            content={context.content ?? ''}
            details={context.details}
            callToActionText={context.callToActionText}
            mainColor={user?.settings.pluginSettings.mainColor}
            secondaryColor={user?.settings.pluginSettings.secondaryColor}
            testimonialType={context.testimonialType}
            color={context.color}
          />
          <div className={classes.bubbleContainer}>
            {context.testimonialType === 'video' ? (
              <ButtonBase
                style={
                  user?.settings.pluginSettings.outlineBubble != ''
                    ? {
                        width: `${size - 4}px`,
                        height: `${size - 4}px`,
                        pointerEvents: 'none',
                      }
                    : {
                        width: `${size}px`,
                        height: `${size}px`,
                        pointerEvents: 'none',
                      }
                }
              >
                <ReactPlayer
                  url={context.content}
                  playing={true}
                  loop={true}
                  volume={0}
                  className={`${classes.video}  ${
                    context.content?.includes('short')
                      ? classes.iframeShort
                      : ''
                  }`}
                  width={'100%'}
                  height={'100%'}
                  style={
                    user?.settings.pluginSettings.outlineBubble != ''
                      ? {
                          borderRadius: `${borderRadiusBubble}%`,
                          outline: `2px solid ${user?.settings.pluginSettings.outlineBubble}`,
                          outlineOffset: '2px',
                        }
                      : {
                          borderRadius: `${borderRadiusBubble}%`,
                        }
                  }
                />
                {context.title && (
                  <div
                    className={`${classes.title} ${
                      context.titlePosition === 'inside' ? 'inside' : 'bottom'
                    }`}
                    style={
                      context.titlePosition === 'inside'
                        ? {
                            height: '100%',
                            width: '100%',
                            maxHeight: `100%`,
                            maxWidth: `100%`,
                            padding: '5px',
                            lineHeight: '1',
                            transformOrigin: 'center',
                            color: `${context.titleColor}`,
                          }
                        : {
                            height: 'fit-content',
                            width: 'fit-content',
                            maxHeight: `${bubbleSize}px`,
                            maxWidth: `${bubbleSize}px`,
                            lineHeight: '1',
                            borderRadius: `0px`,
                            color: `${context.titleColor}`,
                          }
                    }
                    ref={containerRef}
                  >
                    <p ref={titleRef} style={{ fontSize: `${policeSize}px` }}>
                      {context.title}
                    </p>
                  </div>
                )}
              </ButtonBase>
            ) : (
              <button
                className={`${classes.idleButton}`}
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                }}
              >
                <div
                  className={classes.idleButtonImageWrapper}
                  style={{
                    borderRadius: `${user?.settings.pluginSettings.testimonialBubbleRadius}%`,
                  }}
                >
                  <img
                    className={classes.idleButtonImage}
                    src={
                      context?.logo &&
                      context.displayPreferences.showCompanyLogo
                        ? context.logo
                        : context.picture
                        ? context.picture
                        : UserIcon
                    }
                    alt="Company logo"
                  />
                </div>
                <div
                  className={`${classes.quoteIconWrapper}`}
                  style={{
                    background: user?.settings.pluginSettings.secondaryColor,
                  }}
                >
                  <QuoteIcon />
                </div>

                {context.title && (
                  <div
                    className={`${classes.title} ${
                      context.titlePosition === 'inside' ? 'inside' : 'bottom'
                    }`}
                    style={
                      context.titlePosition === 'inside'
                        ? {
                            height: 'fit-content',
                            width: 'fit-content',
                            maxHeight: `100%`,
                            maxWidth: `100%`,
                            padding: '5px',
                            lineHeight: '1',
                            transformOrigin: 'center',
                            color: `${context.titleColor}`,
                          }
                        : {
                            height: 'fit-content',
                            width: 'fit-content',
                            maxHeight: `${bubbleSize}px`,
                            maxWidth: `${bubbleSize}px`,
                            lineHeight: '1',
                            borderRadius: `0px`,
                            color: `${context.titleColor}`,
                          }
                    }
                  >
                    {context.title}
                  </div>
                )}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PreviewTestimonial;
