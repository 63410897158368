import React, { useEffect } from 'react';
import { Container, Typography, CircularProgress } from '@mui/material';
import {
  AuthenticationContentStyle,
  AuthenticationSectionStyle,
  AuthenticationStyle,
} from 'src/components/authentication/AuthenticationStyles';
import MHidden from 'src/components/extended/MHidden';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useActivationAccount } from '../slices/auth';
import { useAppDispatch } from '../hooks/store';

export const ActivateAccount = () => {
  const { search } = useLocation();
  const { token }: any = queryString.parse(search);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      try {
        dispatch(useActivationAccount(token)).then(() => {
          window.location.href = '/';
        });
      } catch (error) {}
    }
  }, []);

  return token ? (
    <AuthenticationStyle title="LostPassword | Kudeo">
      <MHidden width="mdDown">
        <AuthenticationSectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            KUDEO
          </Typography>
          <img src="/static/images/illustration_register.png" alt="login" />
        </AuthenticationSectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <AuthenticationContentStyle>
          <CircularProgress />
        </AuthenticationContentStyle>
      </Container>
    </AuthenticationStyle>
  ) : (
    <div>Invalid token</div>
  );
};
