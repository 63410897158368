import React, { useEffect, useState } from 'react';
import { ButtonBase } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  fetchCurrentUser,
  updatePluginSettings,
  updateUser,
  UpdateUserDto,
} from 'src/slices/auth';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  DisplayNotification,
  handleNotification,
} from 'src/helpers/notifications';
import { useSnackbar } from 'notistack';
import UserConfiguration from 'src/containers/settings/UserConfiguration';
import PluginConfiguration from 'src/containers/settings/PluginConfiguration';
import Tabs from 'src/components/Tabs';
import { useParams } from 'react-router-dom';

const useStyles = createUseStyles({
  settingsPage: {
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  settingsContainer: {
    maxHeight: 'calc(100% - 70px)',
    paddingLeft: '20%',
    paddingRight: '20%',
    paddingBottom: '10px',
    overflowY: 'scroll',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  buttonWrapper: {
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    flexDirection: 'row',
    background: 'white',
    position: 'sticky',
    zIndex: 1,
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
    // boxShadow: '0 -1px 30px 0 rgba(50, 50, 93, 0.05)',
    boxShadow:
      'rgba(255, 255, 255, 0.1) 0px -1px 1px 0px inset, rgba(50, 50, 93, 0.1) 0px -50px 100px -20px, rgba(0, 0, 0, 0.2) 0px -30px 60px -30px',
  },

  tooltipContainer: {
    display: 'flex',
    gap: '7px',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5px',
  },
  disableLink: {
    pointerEvents: 'none',
    filter: 'blur(4px)',
  },
  previousButton: {
    fontFamily: 'Montserrat',
    fontSize: '10px',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  button: {
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    gap: '10px',
    background: '#273EAC',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    flex: 1,
  },
});

const Settings = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { profil } = useParams();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { user, firstUserId } = useAppSelector(
    ({ auth: { user, firstUserId } }) => ({
      user,
      firstUserId,
    }),
  );

  const [disableAll, setDisableAll] = useState(false);

  useEffect(() => {
    if (user?._id != firstUserId) {
      setDisableAll(true);
    }
  });

  if (!user) {
    return <></>;
  }

  const [value, setValue] = useState<number>(() => {
    if (profil === 'true') {
      return 1;
    } else return 0;
  });
  const changeTab = (newValue: number) => {
    setValue(newValue);
  };

  const tabs = [
    t('pages.Settings.pluginSettings'),
    t('pages.Settings.userSettings'),
  ];

  const userSettingsValidationSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    company: yup.string(),
    password: yup.string().min(
      10,
      t('forms.rules.min2', {
        value: 10,
      }),
    ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('forms.errorConfirmPasswordNotMatch')),
    profilePictureUrl: yup.string(),
    phoneNumber: yup.string(),
  });

  const userSettingsInitialValues = {
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    company: user.company ?? '',
    position: user.position ?? '',
    password: '',
    confirmPassword: '',
    profilePictureUrl: user.profilePictureUrl ?? '',
    phoneNumber: user.phoneNumber ?? '',
    companyLogo: user.companyLogo ?? '',
  };

  const userSettingsFormikOnSubmit = async (values: {
    firstName: string;
    lastName: string;
    company: string;
    position: string;
    password: string;
    confirmPassword: string;
    profilePictureUrl: string;
    phoneNumber: string;
    companyLogo: string;
  }) => {
    if (userSettingsFormik.dirty) {
      const updatedValues: UpdateUserDto = {
        firstName:
          user.firstName !== values.firstName ? values.firstName : undefined,
        lastName:
          user.lastName !== values.lastName ? values.lastName : undefined,
        company: user.company !== values.company ? values.company : undefined,
        position:
          user.position !== values.position ? values.position : undefined,
        profilePictureUrl:
          user.profilePictureUrl !== values.profilePictureUrl
            ? values.profilePictureUrl
            : undefined,
        authProviders:
          values.password !== '' && values.password === values.confirmPassword
            ? { local: values.password }
            : undefined,
        phoneNumber:
          user.phoneNumber !== values.phoneNumber
            ? values.phoneNumber
            : undefined,
        companyLogo:
          user.companyLogo !== values.companyLogo
            ? values.companyLogo
            : undefined,
      };

      const isChangingValue = Object.keys(updatedValues).some(
        (k) => (updatedValues as any)[k] !== undefined,
      );

      isChangingValue &&
        (await handleNotification(
          dispatch(updateUser(updatedValues)),
          t('pages.Settings.successProfileUpdate'),
          t('pages.Settings.errorProfileUpdate'),
          enqueueSnackbar,
          closeSnackbar,
        ));

      return isChangingValue;
    }
  };

  const userSettingsFormik = useFormik({
    initialValues: userSettingsInitialValues,
    validationSchema: userSettingsValidationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: userSettingsFormikOnSubmit,
  });

  const pluginSettingsValidationSchema = yup.object({
    mainColor: yup.string(),
    secondaryColor: yup.string(),
    websiteUrl: yup.string(),
    enableTestimonials: yup.boolean(),
    enableOnMobile: yup.boolean(),
    enableTags: yup.boolean(),
    zIndex: yup.number(),
    secondaryWebsiteUrl: yup.array().of(yup.string()),
    position: yup.string(),
    testimonialBubbleRadius: yup.number(),
    testimonialBubbleDimension: yup.string(),
    outlineBubble: yup.string(),
    hideKudeoButton: yup.boolean(),
  });

  const pluginSettingsInitialValues = {
    enableOnMobile: user.settings.pluginSettings.enableOnMobile ?? false,
    enableTags: user.settings.pluginSettings.enableTags ?? true,
    mainColor:
      user.settings.pluginSettings.mainColor ?? theme.palette.primary.main,
    secondaryColor:
      user.settings.pluginSettings.secondaryColor ?? theme.palette.warning.main,
    websiteUrl: user.settings.pluginSettings.websiteUrl ?? '',
    enableTestimonials:
      user.settings.pluginSettings.enableTestimonials ?? false,
    zIndex: user.settings.pluginSettings.zIndex ?? 999,
    secondaryWebsiteUrl: user.settings.pluginSettings.secondaryWebsiteUrl ?? [],
    position: user.settings.pluginSettings.position ?? 'bottom',

    testimonialBubbleRadius:
      user.settings.pluginSettings.testimonialBubbleRadius ?? 50,
    testimonialBubbleDimension:
      user.settings.pluginSettings.testimonialBubbleDimension ?? 'small',
    outlineBubble: user.settings.pluginSettings.outlineBubble ?? 'transparent',

    hideKudeoButton: user.settings.pluginSettings.hideKudeoButton ?? false,
  };

  const pluginSettingsFormikOnSubmit = async (values: {
    mainColor: string;
    secondaryColor: string;
    websiteUrl: string;
    enableTestimonials: boolean;
    enableOnMobile: boolean;
    enableTags: boolean;
    zIndex: number;
    secondaryWebsiteUrl: string[];
    position: string;
    testimonialBubbleRadius: number;
    testimonialBubbleDimension: string;
    outlineBubble: string;
    hideKudeoButton: boolean;
  }) => {
    if (values?.secondaryColor && !values.secondaryColor.includes('#')) {
      const message = t('pages.Settings.colorFormatError');
      const key = enqueueSnackbar(message, {
        content: (
          <DisplayNotification
            closeNotification={() => closeSnackbar(key)}
            message={message}
            variant={false}
          />
        ),
      });
      return;
    }

    const updatedValues = {
      mainColor:
        values.mainColor !== pluginSettingsInitialValues.mainColor
          ? values.mainColor
          : undefined,
      secondaryColor:
        values.secondaryColor !== pluginSettingsInitialValues.secondaryColor &&
        values.secondaryColor.includes('#')
          ? values.secondaryColor
          : undefined,
      websiteUrl:
        values.websiteUrl !== pluginSettingsInitialValues.websiteUrl
          ? values.websiteUrl
          : undefined,
      enableTestimonials:
        values.enableTestimonials !==
        pluginSettingsInitialValues.enableTestimonials
          ? values.enableTestimonials
          : undefined,
      enableOnMobile:
        values.enableOnMobile !== pluginSettingsInitialValues.enableOnMobile
          ? values.enableOnMobile
          : undefined,
      enableTags:
        values.enableTags !== pluginSettingsInitialValues.enableTags
          ? values.enableTags
          : undefined,
      zIndex:
        values.zIndex !== pluginSettingsInitialValues.zIndex
          ? values.zIndex
          : undefined,
      secondaryWebsiteUrl:
        values.secondaryWebsiteUrl !==
        pluginSettingsInitialValues.secondaryWebsiteUrl
          ? values.secondaryWebsiteUrl
          : undefined,
      position:
        values.position !== pluginSettingsInitialValues.position
          ? values.position
          : undefined,
      testimonialBubbleRadius:
        values.testimonialBubbleRadius !==
        pluginSettingsInitialValues.testimonialBubbleRadius
          ? values.testimonialBubbleRadius
          : undefined,
      testimonialBubbleDimension:
        values.testimonialBubbleDimension !==
        pluginSettingsInitialValues.testimonialBubbleDimension
          ? values.testimonialBubbleDimension
          : undefined,
      outlineBubble:
        values.outlineBubble !== pluginSettingsInitialValues.outlineBubble
          ? values.outlineBubble
          : undefined,

      hideKudeoButton:
        values.hideKudeoButton !== pluginSettingsInitialValues.hideKudeoButton
          ? values.hideKudeoButton
          : false,
    };

    const isChangingValue = Object.keys(updatedValues).some(
      (k) => (updatedValues as any)[k] !== undefined,
    );

    isChangingValue &&
      (await handleNotification(
        dispatch(updatePluginSettings(updatedValues)),
        t('pages.Settings.successProfileUpdate'),
        t('pages.Settings.errorProfileUpdate'),
        enqueueSnackbar,
        closeSnackbar,
      ));

    return isChangingValue;
  };

  const pluginSettingsFormik = useFormik({
    initialValues: pluginSettingsInitialValues,
    validationSchema: pluginSettingsValidationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: pluginSettingsFormikOnSubmit,
  });

  const saveSettings = async (event: any) => {
    const userSettingsChanges = await userSettingsFormik.submitForm();
    const pluginUserSettingsChanges = await pluginSettingsFormik.submitForm();

    if (userSettingsChanges || pluginUserSettingsChanges) {
      dispatch(fetchCurrentUser());
    }
    event.preventDefault();
  };

  const tabComponents = [
    <PluginConfiguration
      key="plugin"
      pluginSettingsFormik={pluginSettingsFormik}
      disableAll={disableAll}
    />,
    <UserConfiguration
      key="profile"
      userSettingsFormik={userSettingsFormik}
      disableAll={disableAll}
    />,
  ];

  return user === null ? (
    <></>
  ) : (
    <div className={classes.settingsPage}>
      <div className={classes.settingsContainer}>
        <Tabs
          value={value}
          changeTab={(_, newValue) => changeTab(newValue)}
          tabsValue={tabs}
        />
        {tabComponents[value]}
      </div>
      <div
        className={`${classes.buttonWrapper}`}
        style={disableAll ? { pointerEvents: 'none' } : {}}
      >
        <ButtonBase
          onClick={() => {
            window.location.reload();
          }}
          className={`${classes.button}`}
          style={{
            background: 'white',
            color: '#273EAC',
            border: '1px solid #273EAC',
          }}
        >
          {t('common.cancel')}
        </ButtonBase>

        <ButtonBase onClick={saveSettings} className={classes.button}>
          {t('common.save')}
        </ButtonBase>
      </div>
    </div>
  );
};

export default Settings;
