import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import { apiRequest } from 'src/helpers/api';
import { TestimonialStatuses } from 'src/constants/statuses';

export interface DisplayPreferences {
  showCompanyLogo: boolean;
  showCustomerPicture: boolean;
  showDate: boolean;
  showMoreInfos: boolean;
  showVertical: boolean;
  showSquare: boolean;
}

export interface IndexPosition {
  index: number;
  tag: string;
}

export interface Stats {
  clickedNumberPerPeriode: number;
  hoverNumberPerPeriode: number;
  secondsPlayedVideo: number;
  secondsPlayedVideoOnMobile: number;
  hoverNumberPerPeriodeOnMobile: number;
  clickedNumberPerPeriodeOnMobile: number;
  videoPlayed: number;
  videoPlayedOnMobile: number;
  lastUpdate: Date;
}

export interface Testimonial {
  _id: string;
  content: string;
  details?: string;
  displayPreferences: DisplayPreferences;
  createdAt?: string;
  status: TestimonialStatuses;
  tags: string[];
  callToActionText: string;
  callToActionUrl: string;
  linkedPages?: string[];
  displayProperties: DisplayProperties[];
  indexPosition: IndexPosition[];
  testimonialType: string;
  statistics: Statistics;
  stats?: Stats[];
  information?: string;
  logo?: string;
  picture?: string;
  color?: string;
  title?: string;
  titlePosition?: string;
  titleColor?: string;
  videoImage?: string;
  titleEmbedded?: string;
  titleColorEmbedded?: string;
  titleEmbeddedPosition?: string;
  variantProductId?: string;
}

export interface Statistics {
  clickedNumber: number;
  hoverNumber: number;
}

export interface DisplayProperties {
  clickedNumber: number;
  hoverNumber: number;
  tagAssociated: string;
  onMobile: boolean;
  onDesktop: boolean;
  embedded: boolean;
}

interface CreateTestimonialDto {
  content: string;
  details?: string;
  displayPreferences: DisplayPreferences;
  displayProperties?: DisplayProperties[];
  tags: string[];
  information: string;
  logo?: string;
  picture?: string;
  status: TestimonialStatuses;
  callToActionText?: string;
  callToActionUrl?: string;
  testimonialType: string;
  statistics: Statistics;
  color?: string;
  title?: string;
  titlePosition?: string;
  titleColor?: string;
  videoImage?: string;
  titleEmbedded?: string;
  titleColorEmbedded?: string;
  titleEmbeddedPosition?: string;
  variantProductId?: string;
}

interface CreateTestimonialFromGuestDto {
  content: string;
  information: string;
  picture?: string;
  userId?: string;
}

interface publishTestimonialTagDto {
  idTestimonial: string;
  tag: string;
}

interface UpdateTestimonialDto {
  _id: string;
  content?: string;
  details?: string;
  displayPreferences?: DisplayPreferences;
  tags?: string[];
  linkedPages?: string[];
  information?: string;
  logo?: string;
  picture?: string;
  status?: TestimonialStatuses;
  callToActionText?: string;
  callToActionUrl?: string;
  indexPosition?: number;
  testimonialType?: string;
  displayProperties?: DisplayProperties[];
  color?: string;
  title?: string;
  titlePosition?: string;
  titleColor?: string;
  videoImage?: string;
  titleEmbedded?: string;
  titleColorEmbedded?: string;
  titleEmbeddedPosition?: string;
  variantProductId?: string;
}

interface UpdateIndexPositionDto {
  testimonialsId: string;
  indexPosition: number;
  tag: string;
}

interface UpdateDisplayPropertiesDto {
  tagAssociated: string;
  onMobile: boolean;
  onDesktop: boolean;
  id: string;
}

export const fetchAllTestimonials = createAsyncThunk(
  'testimonial/fetchAllTestimonials',
  async () => {
    const results: Testimonial[] = await apiRequest<Testimonial[]>(
      'GET',
      '/testimonial',
    );
    return results;
  },
);

export const AddNewTestimonial = createAsyncThunk(
  'testimonial/addNewTestimonialFromGuest',
  async (createTestimonialDto: CreateTestimonialFromGuestDto) => {
    const results: Testimonial[] = await apiRequest<Testimonial[]>(
      'POST',
      '/testimonial/addFromGuest',
      undefined,
      {
        ...createTestimonialDto,
      },
    );
    return results;
  },
);

export const fetchAllTestimonialsByUser = createAsyncThunk(
  'testimonial/fetchAllTestimonialsByUser',
  async (userId: string) => {
    const results: Testimonial[] = await apiRequest<Testimonial[]>(
      'GET',
      `/testimonial/${userId}`,
    );
    return results;
  },
);

export const updateTestimonial = createAsyncThunk(
  'testimonial/updateTestimonial',
  async (updateTestimonialDto: UpdateTestimonialDto) => {
    const { _id, ...payload } = updateTestimonialDto;
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/${_id}`,
      undefined,
      { ...payload },
    );
    return result;
  },
);

export const updateTestimonialIndexPosition = createAsyncThunk(
  'testimonial/updateIndexPosition',
  async (updateIndexPositionDto: UpdateIndexPositionDto) => {
    const result = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/order/${updateIndexPositionDto.testimonialsId}`,
      undefined,
      {
        indexPosition: updateIndexPositionDto.indexPosition,
        tag: updateIndexPositionDto.tag,
      },
    );
    return result;
  },
);

export const createNewTestimonial = createAsyncThunk(
  'testimonial/createNewTestimonial',
  async (createTestimonialDto: CreateTestimonialDto) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'POST',
      '/testimonial',
      undefined,
      { ...createTestimonialDto },
    );
    return result;
  },
);

export const fetchTestimonialById = createAsyncThunk(
  'testimonial/fetchTestimonialById',
  async (testimonialId: string) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'GET',
      `/testimonial/${testimonialId}`,
    );
    return result;
  },
);

export const updateDisplayPropertiesDevice = createAsyncThunk(
  'testimonial/updateTestimonialDisplayProperties',
  async (updateDisplayProperty: UpdateDisplayPropertiesDto) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/updateDisplayProperties/${updateDisplayProperty.id}`,
      undefined,
      {
        tagAssociated: updateDisplayProperty.tagAssociated,
        onMobile: updateDisplayProperty.onMobile,
        onDesktop: updateDisplayProperty.onDesktop,
      },
    );
    return result;
  },
);

export const fetchTestimonialByContactId = createAsyncThunk(
  'testimonial/fetchTestimonialByContactId',
  async (contactId: string) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'GET',
      `/testimonial/contact/${contactId}`,
    );
    return result;
  },
);

export const fetchTestimonialsByStatus = createAsyncThunk(
  'testimonial/fetchTestimonialsByStatus',
  async (testimonialStatus: TestimonialStatuses) => {
    const result: Testimonial[] = await apiRequest<Testimonial[]>(
      'GET',
      `/testimonial/status/${testimonialStatus}`,
    );
    return result;
  },
);

export const deleteTestimonial = createAsyncThunk(
  'testimonial/deleteTestimonial',
  async (testimonialId: string) => {
    await apiRequest<Testimonial>('DELETE', `/testimonial/${testimonialId}`);

    return testimonialId;
  },
);

export const deleteTestimonialByContactIds = createAsyncThunk(
  'testimonial/deleteTestimonialByContactIds',
  async (contactIds: string[]) => {
    const result = await apiRequest<string[]>(
      'DELETE',
      '/testimonial/contacts',
      undefined,
      {
        contactIds: contactIds,
      },
    );

    return result;
  },
);

export const publishTestimonial = createAsyncThunk(
  'testimonial/updateAndPublish',
  async (testimonialId: string) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/${testimonialId}`,
      undefined,
      { status: TestimonialStatuses.PUBLISHED },
    );

    return result;
  },
);

export const publishTestimonialOnTagsAndUrl = createAsyncThunk(
  'testimonial/publishTestimonial',
  async (testimonialToPublish: publishTestimonialTagDto) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/updateAndPublish/${testimonialToPublish.idTestimonial}`,
      undefined,
      {
        tag: testimonialToPublish.tag,
      },
    );

    return result;
  },
);

export const unpublishTestimonial = createAsyncThunk(
  'testimonial/unpublishTestimonial',
  async (testimonialId: string) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/${testimonialId}`,
      undefined,
      { status: TestimonialStatuses.UNPROCESSED, tags: [] },
    );

    return result;
  },
);

export const archiveTestimonial = createAsyncThunk(
  'testimonial/archiveTestimonial',
  async (testimonialId: string) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/${testimonialId}`,
      undefined,
      { status: TestimonialStatuses.ARCHIVED },
    );

    return result;
  },
);

export const unarchiveTestimonial = createAsyncThunk(
  'testimonial/unarchiveTestimonial',
  async (testimonialId: string) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/${testimonialId}`,
      undefined,
      { status: TestimonialStatuses.UNPROCESSED },
    );

    return result;
  },
);

export const testimonialsAdapter = createEntityAdapter<Testimonial, EntityId>({
  selectId: (testimonial: Testimonial) => testimonial._id,
  sortComparer: (a, b) => {
    if (!a.createdAt || !b.createdAt) {
      return 0;
    }

    const compare =
      (new Date(a.createdAt).getTime() ?? 0) - new Date(b.createdAt).getTime();

    return compare < 0 ? 1 : compare === 0 ? 0 : -1;
  },
});

const upsertTesimonialReducer = (
  state: EntityState<Testimonial, EntityId>, // Add the missing type argument for EntityState
  action: PayloadAction<Testimonial>,
) => {
  testimonialsAdapter.upsertOne(state, action.payload);
};

const removeTestimonialReducer = (
  state: EntityState<Testimonial, EntityId>, // Add the missing type argument for EntityState
  action: PayloadAction<EntityId>,
) => {
  testimonialsAdapter.removeOne(state, action.payload);
};

const removeManyTestimonialsReducer = (
  state: EntityState<Testimonial, EntityId>,
  action: PayloadAction<EntityId[]>,
) => {
  testimonialsAdapter.removeMany(state, action.payload);
};

const testimonialsSlice = createSlice({
  name: 'testimonial',
  initialState: testimonialsAdapter.getInitialState(),
  reducers: {
    upsertTestimonial: upsertTesimonialReducer,
    removeTestimonial: removeTestimonialReducer,
    removeManyTestimonials: removeManyTestimonialsReducer,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTestimonials.fulfilled, testimonialsAdapter.setAll)
      .addCase(fetchAllTestimonialsByUser.fulfilled, testimonialsAdapter.setAll)
      .addCase(fetchTestimonialById.fulfilled, (state, { payload }) =>
        testimonialsAdapter.setAll(state, [payload]),
      )
      .addCase(fetchTestimonialsByStatus.fulfilled, testimonialsAdapter.setAll)
      .addCase(fetchTestimonialByContactId.fulfilled, (state, { payload }) =>
        testimonialsAdapter.setAll(state, [payload]),
      )
      .addCase(createNewTestimonial.fulfilled, upsertTesimonialReducer)
      .addCase(updateTestimonial.fulfilled, upsertTesimonialReducer)
      .addCase(
        updateTestimonialIndexPosition.fulfilled,
        upsertTesimonialReducer,
      )
      .addCase(publishTestimonial.fulfilled, testimonialsAdapter.upsertOne)
      .addCase(
        updateDisplayPropertiesDevice.fulfilled,
        testimonialsAdapter.upsertOne,
      )
      .addCase(unpublishTestimonial.fulfilled, testimonialsAdapter.upsertOne)
      .addCase(archiveTestimonial.fulfilled, testimonialsAdapter.upsertOne)
      .addCase(unarchiveTestimonial.fulfilled, testimonialsAdapter.upsertOne)
      .addCase(deleteTestimonial.fulfilled, removeTestimonialReducer)
      .addCase(
        deleteTestimonialByContactIds.fulfilled,
        removeManyTestimonialsReducer,
      )
      .addCase(
        publishTestimonialOnTagsAndUrl.fulfilled,
        testimonialsAdapter.upsertOne,
      );
  },
});

export const { upsertTestimonial, removeTestimonial, removeManyTestimonials } =
  testimonialsSlice.actions;

export default testimonialsSlice.reducer;
