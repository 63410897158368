import React, { useEffect, useRef, useState } from 'react';
import {
  ButtonBase,
  Dialog,
  FormControlLabel,
  InputAdornment,
  Menu,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Flex } from 'src/components/basic/Flex';
import { theme } from 'src/constants/theme';
import { HexColorPicker } from 'react-colorful';
import { TextFieldWrapper } from 'src/components/basic/TextFieldWrapper';
import { FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { ContactStatuses } from 'src/constants/statuses';
import { useDebouncedCallback } from 'use-debounce';
import { useAppSelector } from 'src/hooks/store';
import { User } from 'src/slices/auth';
import TestimonialCard from 'src/components/testimonials/TestimonialCard/TestimonialCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpgradeDialog from 'src/components/UpgradeDialog';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TestimonialsBubblePreview from 'src/components/testimonials/TestimonialsBubblePreview';
import Slider from '@mui/material/Slider';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import WebflowIcon from '../../assets/webflow.svg';
import ShopifyIcon from '../../assets/shopify.svg';
import ReactPlayer from 'react-player';

const useStyles = createUseStyles({
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  cardContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    borderRadius: '8px',
    gap: '5px',
  },
  section: {
    marginBottom: '20px',
  },
  typographyStyle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#060640',
    '&.title': {
      fontWeight: '600',
      fontSize: '20px',
      marginBottom: '10px',
    },
  },
  toggleCard: {
    padding: theme.spacing(2),
  },
  toggle: {
    marginLeft: 0,
    borderRadius: '1rem',
    transition: '0.15s',
    padding: theme.spacing(1, 1, 1, 2),
    width: '100%',
    '& span:nth-child(2)': {
      flex: 1,
      paddingRight: theme.spacing(1),
    },
    '&:hover:not(.is-disabled)': {
      background: theme.palette.grey[100],
    },
  },
  toggleBold: {
    '& span:nth-child(2)': {
      fontWeight: '500',
      fontFamily: 'Poppins',
      fontSize: '15px',
      lineHeight: '17px',
    },
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  settingsPluginColorPickerContainer: {
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(4),
    flexWrap: 'wrap',
  },
  settingsPluginColorPickerInputContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: '#273EAC',
    width: 'fit-content',
    height: 'fit-content',
    textDecoration: 'underline',
  },
  websiteTextContainer: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#060640',
    alignSelf: 'baseline',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    placeContent: 'baseline',
    maxWidth: '700px',
  },
  addUrlButton: {
    width: '190px',
    color: '#273EAC',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    placeContent: 'baseline',
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: '600',
  },
  urlContainer: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
    width: '100%',
  },
  textFieldStyle: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CED3E8',
      },
      '& input': {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '15px',
        fontStyle: 'normal',
      },
      '&:hover fieldset': {
        borderColor: '#273EAC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#273EAC',
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Poppins',
      fontWeight: '400',
      fontSize: '15px',
      fontStyle: 'normal',
      '&.Mui-focused': {
        color: '#273EAC',
      },
    },
    '& .fieldset': {
      border: 'none',
    },
    marginBottom: '8px',
  },
  urlIcon: {
    color: 'grey',
    cursor: 'pointer',
    width: '14px',
  },
  iconContainer: {
    display: 'flex',
    gap: '3px',
  },
  pluginPositionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '16px',
    paddingRight: '8px',
  },
  pluginPositionTypography: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '15px',
    fontStyle: 'normal',
  },
  pluginPositionSelector: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '15px',
    fontStyle: 'normal',
    width: '130px',
  },
  settingsContainer: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
  },
  menu: {
    '& .MuiMenu-paper': {
      background: 'none',
      padding: '10px',
      border: 'none',
      boxShadow: 'none',
      overflow: 'visible',
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '140%',
      color: '#060640',
    },
  },
  tutorialDialog: {
    zIndex: 3000,
    '& .MuiDialog-paper': {
      width: 'auto',
      borderRadius: '8px',
      background: 'white',
      paddingTop: '30px',
      paddingBottom: '30px',
      paddingLeft: '30px',
      paddingRight: '30px',
      maxWidth: '100%',
      maxHeight: '100%',
      minWidth: '1140px',
    },
  },
  tutorialContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cmsIcon: {
    flex: 1,
    border: '1px solid #273EAC',
    borderRadius: '8px',
    background: '#273EAC',
    transition: 'transform 0.3s',
  },
  cmsIconImg: {},
  videoPlayer: {
    borderRadius: '8px',
    background: 'black',
  },
  videoContainer: {
    borderRadius: '8px',
    overflow: 'hidden',
    width: 'fit-content',
    height: 'fit-content',
  },
});

const fakeTestimonialData = {
  content:
    "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500",
  contact: {
    _id: '1',
    company: { _id: '1', name: 'Google', logo: '' },
    createTestimonialToken: false,
    email: 'john.doe@gmail.com',
    firstName: 'John',
    lastName: 'Doe',
    status: ContactStatuses.NEW,
    position: 'CTO',
    createdAt: '2022-12-24',
    linkedInUrl: '',
    profilePictureUrl: '',
  },
  displayPreferences: {
    showCompanyLogo: true,
    showCompanyName: true,
    showCustomerFirstName: true,
    showCustomerLastName: true,
    showCustomerPicture: true,
    showDate: true,
    showMoreInfos: true,
    showCustomerPosition: true,
    showVertical: false,
    showSquare: false,
  },
  details: '',
  callToActionText: 'Lorem',
};

interface FormValues {
  testimonialBubbleRadius: number;
  testimonialBubbleDimension: string;
  outlineBubble: string;
  enableOnMobile: boolean;
  enableTags: boolean;
  mainColor: string;
  secondaryColor: string;
  websiteUrl: string;
  enableTestimonials: boolean;
  zIndex: number;
  secondaryWebsiteUrl: string[];
  position: string;
  hideKudeoButton: boolean;
}

interface Props {
  pluginSettingsFormik: FormikProps<FormValues>;
  disableAll?: boolean;
}

const PluginConfiguration: React.FC<Props> = ({
  pluginSettingsFormik,
  disableAll,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user }: { user: User | null } = useAppSelector(
    ({ auth: { user } }) => ({
      user,
    }),
  );

  const [openOfferDialog, setOpenOfferDialog] = useState<boolean>(false);

  const [openTutorialDialog, setOpenTutorialDialog] = useState<boolean>(false);

  const displayValue = isNaN(pluginSettingsFormik.values.zIndex)
    ? 1000
    : pluginSettingsFormik.values.zIndex;

  const [isEditingdWebSiteUrl, setIsEditingWebSiteUrl] =
    useState<boolean>(false);

  const [isEditingSecondaryWebsiteUrl, setIsEditingSecondaryWebsiteUrl] =
    useState<{ index: number; state: boolean }[]>([]);

  useEffect(() => {
    pluginSettingsFormik.values.secondaryWebsiteUrl.map((url, index) => {
      setIsEditingSecondaryWebsiteUrl((prevState) => [
        ...prevState,
        { index: index, state: false },
      ]);
    });
  }, []);

  useEffect(() => {
    if (
      pluginSettingsFormik.values.websiteUrl === undefined ||
      pluginSettingsFormik.values.websiteUrl === ''
    ) {
      setIsEditingWebSiteUrl(true);
    }
  }, []);

  const playerRef = useRef<ReactPlayer>(null);

  const mainColorDebounced = useDebouncedCallback(
    (color) => pluginSettingsFormik.setFieldValue('mainColor', color),
    0,
  );

  const mainColorDebouncedBubble = useDebouncedCallback(
    (color) => pluginSettingsFormik.setFieldValue('outlineBubble', color),
    0,
  );

  const handleChange = (event: SelectChangeEvent) => {
    pluginSettingsFormik.setFieldValue(
      'position',
      event.target.value as string,
    );
  };

  const handleChangeTestimonialSize = (event: SelectChangeEvent) => {
    pluginSettingsFormik.setFieldValue(
      'testimonialBubbleDimension',
      event.target.value as string,
    );
  };

  const [tutorialVideo, setTutorialVideo] = useState<string>('');

  const [disabledAddDomain, setDisabledAddDomain] = useState<boolean>(true);

  useEffect(() => {
    if (user?.limitation?.maxDomainUrl) {
      if (
        pluginSettingsFormik.values.websiteUrl === undefined ||
        pluginSettingsFormik.values.websiteUrl === '' ||
        user?.limitation?.maxDomainUrl <=
          pluginSettingsFormik.values.secondaryWebsiteUrl.length + 1
      ) {
        setDisabledAddDomain(true);
      } else {
        setDisabledAddDomain(false);
      }
    }
  }, [pluginSettingsFormik.values.secondaryWebsiteUrl]);

  const [hideOffer, setHideOffer] = useState(false);

  useEffect(() => {
    if (user?.hideOffer) {
      setHideOffer(true);
    }
    if (user?._id === '654cff6144abb7a43e4a0a9b') {
      setHideOffer(true);
    }
  }, [user]);

  const secondaryColorDebounced = useDebouncedCallback(
    (color) => pluginSettingsFormik.setFieldValue('secondaryColor', color),
    0,
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openColorPicker = Boolean(anchorEl);
  const handleClickColorPicker = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseColorPicker = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return <></>;
  }

  return (
    <div className={classes.componentContainer}>
      {!user?.isShopifyAccount && (
        <>
          <div
            className={`${classes.cardContainer}`}
            style={disableAll ? { pointerEvents: 'none' } : {}}
          >
            <div className={classes.section}>
              <Typography className={`${classes.typographyStyle} title`}>
                {t('pages.Settings.pluginKudeo')}
              </Typography>
              <Typography className={classes.typographyStyle}>
                {t('pages.Settings.explainPluginInjection')}
              </Typography>
              <Typography
                style={{ marginTop: '20px' }}
                className={classes.typographyStyle}
              >
                <code>
                  {
                    '<script async src="https://plugin.kudeo.co/shim.js"></script>'
                  }
                </code>
              </Typography>
            </div>
          </div>

          {user?.earlyAccess && (
            <div
              className={`${classes.cardContainer}`}
              style={
                disableAll
                  ? {
                      pointerEvents: 'none',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }
                  : {
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }
              }
            >
              <div className={classes.section}>
                <Typography className={`${classes.typographyStyle} title`}>
                  {t('pages.Settings.embedKudeo')}
                </Typography>
                <Typography className={classes.typographyStyle}>
                  {t('pages.Settings.explainEmbeddedInjection')}
                </Typography>
                <Typography
                  style={{ marginTop: '20px' }}
                  className={classes.typographyStyle}
                >
                  <code>
                    {
                      '<script async src="https://plugin.kudeo.co/embed.js"></script>'
                    }
                  </code>
                </Typography>
              </div>
              <ButtonBase
                className={classes.button}
                onClick={() => {
                  setOpenTutorialDialog(true);
                }}
              >
                {t('pages.Settings.showTutorial')}
              </ButtonBase>
            </div>
          )}

          <div
            className={`${classes.cardContainer}`}
            style={disableAll ? { pointerEvents: 'none' } : {}}
          >
            <div className={classes.section}>
              <Typography className={classes.typographyStyle}>
                {t('pages.Settings.AddUrl')}
              </Typography>
            </div>
            <Flex>
              <div className={classes.urlContainer}>
                {isEditingdWebSiteUrl ? (
                  <TextField
                    id="websiteUrl"
                    label={`${t('pages.Settings.websiteUrl')}`}
                    type="text"
                    fullWidth
                    className={classes.textFieldStyle}
                    name="websiteUrl"
                    value={pluginSettingsFormik.values.websiteUrl}
                    onChange={pluginSettingsFormik.handleChange}
                    onBlur={pluginSettingsFormik.handleBlur}
                    error={
                      pluginSettingsFormik.touched.websiteUrl &&
                      Boolean(pluginSettingsFormik.errors.websiteUrl)
                    }
                    helperText={
                      pluginSettingsFormik.touched.websiteUrl &&
                      pluginSettingsFormik.errors.websiteUrl
                    }
                    disabled={user?.isShopifyAccount ?? false}
                  />
                ) : (
                  <Typography className={classes.websiteTextContainer}>
                    {pluginSettingsFormik.values.websiteUrl}
                    {!user?.isShopifyAccount && (
                      <div className={classes.iconContainer}>
                        <ButtonBase
                          onClick={() => {
                            setIsEditingWebSiteUrl(true);
                          }}
                        >
                          <EditIcon className={classes.urlIcon} />
                        </ButtonBase>

                        <ButtonBase
                          onClick={() => {
                            if (
                              pluginSettingsFormik.values.secondaryWebsiteUrl
                                .length > 0
                            ) {
                              pluginSettingsFormik.setFieldValue(
                                'websiteUrl',
                                pluginSettingsFormik.values
                                  .secondaryWebsiteUrl[0],
                              );
                              const updatedItems = [
                                ...pluginSettingsFormik.values
                                  .secondaryWebsiteUrl,
                              ];
                              updatedItems.splice(0, 1);
                              pluginSettingsFormik.setFieldValue(
                                'secondaryWebsiteUrl',
                                updatedItems,
                              );
                            } else {
                              pluginSettingsFormik.setFieldValue(
                                'websiteUrl',
                                '',
                              );
                              setIsEditingWebSiteUrl(true);
                            }
                          }}
                        >
                          <DeleteIcon className={classes.urlIcon} />
                        </ButtonBase>
                      </div>
                    )}
                  </Typography>
                )}
                {!user?.isShopifyAccount && (
                  <>
                    {pluginSettingsFormik.values.secondaryWebsiteUrl &&
                      pluginSettingsFormik.values.secondaryWebsiteUrl.map(
                        (secondayUrl, index) => (
                          <React.Fragment key={index}>
                            {isEditingSecondaryWebsiteUrl.length > 0 &&
                            isEditingSecondaryWebsiteUrl[index].state ? (
                              <TextField
                                id="secondayWebsiteUrl"
                                label={`${t('pages.Settings.websiteUrl')}`}
                                type="text"
                                fullWidth
                                className={classes.textFieldStyle}
                                variant="outlined"
                                name="SecondaryWebsiteUrl"
                                value={secondayUrl}
                                onChange={(e) => {
                                  const updatedItems = [
                                    ...pluginSettingsFormik.values
                                      .secondaryWebsiteUrl,
                                  ];
                                  updatedItems[index] = e.target.value;
                                  pluginSettingsFormik.setFieldValue(
                                    'secondaryWebsiteUrl',
                                    updatedItems,
                                  );
                                }}
                                onBlur={pluginSettingsFormik.handleBlur}
                              />
                            ) : (
                              <Typography
                                className={classes.websiteTextContainer}
                              >
                                {secondayUrl}
                                <div className={classes.iconContainer}>
                                  <ButtonBase
                                    onClick={() => {
                                      setIsEditingSecondaryWebsiteUrl(
                                        (prevState) => {
                                          const newState = [...prevState];
                                          newState[index].state = true;
                                          return newState;
                                        },
                                      );
                                    }}
                                  >
                                    <EditIcon className={classes.urlIcon} />
                                  </ButtonBase>

                                  <ButtonBase
                                    onClick={() => {
                                      const updatedItems = [
                                        ...pluginSettingsFormik.values
                                          .secondaryWebsiteUrl,
                                      ];
                                      updatedItems.splice(index, 1);
                                      pluginSettingsFormik.setFieldValue(
                                        'secondaryWebsiteUrl',
                                        updatedItems,
                                      );
                                    }}
                                  >
                                    <DeleteIcon className={classes.urlIcon} />
                                  </ButtonBase>
                                </div>
                              </Typography>
                            )}
                          </React.Fragment>
                        ),
                      )}
                    <ButtonBase
                      className={classes.addUrlButton}
                      onClick={() => {
                        if (disabledAddDomain) {
                          setOpenOfferDialog(true);
                        } else {
                          pluginSettingsFormik.setFieldValue(
                            `secondaryWebsiteUrl`,
                            [
                              ...pluginSettingsFormik.values
                                .secondaryWebsiteUrl,
                              '',
                            ],
                          );
                          setIsEditingSecondaryWebsiteUrl((prevState) => [
                            ...prevState,
                            { index: prevState.length, state: true },
                          ]);
                        }
                      }}
                    >
                      <AddCircleOutlineIcon />
                      {t('pages.Settings.addDomain')}
                    </ButtonBase>
                  </>
                )}
              </div>
            </Flex>
          </div>
        </>
      )}

      <div
        className={`${classes.cardContainer} ${classes.toggleCard}`}
        style={disableAll ? { pointerEvents: 'none' } : {}}
      >
        <FormControlLabel
          control={<Switch />}
          label={`${t('pages.Settings.enableTestimonials')}`}
          labelPlacement="start"
          disabled={
            !pluginSettingsFormik.values.websiteUrl?.length ||
            user.subscriptionType === 'none' ||
            !user.subscriptionType
          }
          checked={pluginSettingsFormik.values.enableTestimonials}
          {...pluginSettingsFormik.getFieldProps(`enableTestimonials`)}
          className={`${classes.toggle} ${classes.toggleBold}`}
        />

        <FormControlLabel
          control={<Switch />}
          label={`${t('pages.Settings.enableOnMobile')}`}
          labelPlacement="start"
          disabled={!pluginSettingsFormik.values.enableTestimonials}
          checked={pluginSettingsFormik.values.enableOnMobile}
          {...pluginSettingsFormik.getFieldProps(`enableOnMobile`)}
          className={`${classes.toggle} ${classes.toggleBold} ${
            !pluginSettingsFormik.values.enableTestimonials ? 'is-disabled' : ''
          }`}
        />

        <FormControlLabel
          control={<Switch />}
          label={`${t('pages.Settings.hideKudeoButton')}`}
          labelPlacement="start"
          disabled={
            user?.subscriptionType !== 'premium' &&
            user?.subscriptionType !== 'custom'
          }
          checked={pluginSettingsFormik.values.hideKudeoButton}
          {...pluginSettingsFormik.getFieldProps(`hideKudeoButton`)}
          className={`${classes.toggle} ${classes.toggleBold} ${
            user?.subscriptionType !== 'premium' &&
            user?.subscriptionType !== 'custom'
              ? 'is-disabled'
              : ''
          }`}
        />

        <Tooltip title={<div>{t('common.zindex')}</div>}>
          <TextField
            id="z-index"
            label={'Z-Index'}
            fullWidth
            value={displayValue}
            name="Z-Index"
            className={classes.textFieldStyle}
            onChange={(e) => {
              pluginSettingsFormik.setFieldValue(
                'zIndex',
                parseInt(e.target.value),
              );
            }}
            onBlur={pluginSettingsFormik.handleBlur}
            error={
              pluginSettingsFormik.touched.zIndex &&
              Boolean(pluginSettingsFormik.errors.zIndex)
            }
            helperText={
              pluginSettingsFormik.touched.zIndex &&
              pluginSettingsFormik.errors.zIndex
            }
            style={{
              marginBottom: '10px',
              paddingRight: '8px',
            }}
          />
        </Tooltip>

        <div className={classes.pluginPositionContainer}>
          <Typography className={classes.pluginPositionTypography}>
            {t('pages.Settings.pluginPosition')}
          </Typography>
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pluginSettingsFormik.values.position || 'bottom'}
              onChange={handleChange}
              className={classes.pluginPositionSelector}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    '& li': {
                      fontFamily: 'Poppins',
                      fontWeight: '400',
                      fontSize: '15px',
                      fontStyle: 'normal',
                    },
                  },
                },
              }}
            >
              <MenuItem value={'bottom'}>{t('pages.Settings.bottom')}</MenuItem>
              <MenuItem value={'top'}>{t('pages.Settings.top')}</MenuItem>
              <MenuItem value={'right'}>{t('pages.Settings.right')}</MenuItem>
              <MenuItem value={'left'}>{t('pages.Settings.left')}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={`${classes.cardContainer}`}>
        <Typography variant="h6" className={classes.cardTitle}>
          {t('pages.Settings.pluginSettingsDisplay')}
        </Typography>
        <Flex
          className={classes.settingsPluginColorPickerContainer}
          style={{ gap: '10%' }}
        >
          <div className={classes.settingsContainer}>
            <Flex
              style={{
                gap: '10px',
                alignContent: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                className={classes.typographyStyle}
                style={{
                  alignSelf: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('pages.Settings.testimonialBubbleSize')}
              </Typography>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    pluginSettingsFormik.values.testimonialBubbleDimension ||
                    'small'
                  }
                  onChange={handleChangeTestimonialSize}
                  className={classes.pluginPositionSelector}
                  style={{ width: '130px' }}
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        '& li': {
                          fontFamily: 'Poppins',
                          fontWeight: '400',
                          fontSize: '15px',
                          fontStyle: 'normal',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={'small'}>
                    {t('pages.Settings.small')}
                  </MenuItem>
                  <MenuItem value={'medium'}>
                    {t('pages.Settings.medium')}
                  </MenuItem>
                  <MenuItem value={'large'}>
                    {t('pages.Settings.large')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Flex>

            <Flex
              style={{
                gap: '10px',
                alignContent: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                className={classes.typographyStyle}
                style={{
                  alignSelf: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('pages.Settings.testimonialBubbleForm')}
              </Typography>
              <Slider
                defaultValue={50}
                value={
                  pluginSettingsFormik.values.testimonialBubbleRadius ?? 50
                }
                onChange={(e) => {
                  if (e.target != null) {
                    const target = e.target as HTMLInputElement;
                    pluginSettingsFormik.setFieldValue(
                      'testimonialBubbleRadius',
                      target.value,
                    );
                  }
                }}
                min={0}
                max={50}
                style={{ width: '130px' }}
              />
            </Flex>

            <Flex
              style={{
                gap: '10px',
                alignContent: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                className={classes.typographyStyle}
                style={{
                  alignSelf: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('pages.Settings.testimonialBubbleOutline')}
              </Typography>

              <TextField
                id="outlineBubble"
                value={pluginSettingsFormik.values.outlineBubble}
                onChange={pluginSettingsFormik.handleChange}
                onBlur={pluginSettingsFormik.handleBlur}
                className={classes.textFieldStyle}
                error={
                  pluginSettingsFormik.touched.outlineBubble &&
                  Boolean(pluginSettingsFormik.errors.outlineBubble)
                }
                helperText={
                  pluginSettingsFormik.touched.outlineBubble &&
                  pluginSettingsFormik.errors.outlineBubble
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ButtonBase onClick={handleClickColorPicker}>
                        <ColorLensIcon />
                      </ButtonBase>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                sx={{ width: '130px' }}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openColorPicker}
                onClose={handleCloseColorPicker}
                className={classes.menu}
              >
                <HexColorPicker
                  color={pluginSettingsFormik.values.outlineBubble}
                  onChange={(color) => mainColorDebouncedBubble(color)}
                />
              </Menu>
            </Flex>
          </div>

          <TestimonialsBubblePreview
            borderRadiusBubble={
              pluginSettingsFormik.values.testimonialBubbleRadius
            }
            outlineBubble={pluginSettingsFormik.values.outlineBubble}
            bubbleSize={pluginSettingsFormik.values.testimonialBubbleDimension}
          />
        </Flex>
      </div>

      <div className={`${classes.cardContainer}`}>
        <Typography variant="h6" className={classes.cardTitle}>
          {t('pages.Settings.colors')}
        </Typography>
        <TestimonialCard
          {...fakeTestimonialData}
          mainColor={pluginSettingsFormik.values.mainColor}
          secondaryColor={pluginSettingsFormik.values.secondaryColor}
        />
        <Flex className={classes.settingsPluginColorPickerContainer}>
          <Flex className={classes.settingsPluginColorPickerInputContainer}>
            <HexColorPicker
              color={pluginSettingsFormik.values.mainColor}
              onChange={(color) => mainColorDebounced(color)}
            />
            <TextFieldWrapper
              margin="normal"
              id="mainColor"
              label={`${t('pages.Settings.mainColor')}`}
              type="text"
              fullWidth
              className={classes.textFieldStyle}
              variant="outlined"
              name="mainColor"
              value={pluginSettingsFormik.values.mainColor}
              onChange={pluginSettingsFormik.handleChange}
              onBlur={pluginSettingsFormik.handleBlur}
              error={
                pluginSettingsFormik.touched.mainColor &&
                Boolean(pluginSettingsFormik.errors.mainColor)
              }
              helperText={
                pluginSettingsFormik.touched.mainColor &&
                pluginSettingsFormik.errors.mainColor
              }
            />
          </Flex>
          <Flex className={classes.settingsPluginColorPickerInputContainer}>
            <HexColorPicker
              color={pluginSettingsFormik.values.secondaryColor}
              onChange={(color) => secondaryColorDebounced(color)}
            />
            <TextFieldWrapper
              margin="normal"
              id="secondaryColor"
              label={`${t('pages.Settings.secondaryColor')}`}
              type="text"
              fullWidth
              variant="outlined"
              className={classes.textFieldStyle}
              name="secondaryColor"
              value={pluginSettingsFormik.values.secondaryColor}
              onChange={pluginSettingsFormik.handleChange}
              onBlur={pluginSettingsFormik.handleBlur}
              error={
                pluginSettingsFormik.touched.secondaryColor &&
                Boolean(pluginSettingsFormik.errors.secondaryColor)
              }
              helperText={
                pluginSettingsFormik.touched.secondaryColor &&
                pluginSettingsFormik.errors.secondaryColor
              }
            />
          </Flex>
        </Flex>
      </div>

      {!hideOffer && (
        <UpgradeDialog
          openOfferDialog={openOfferDialog}
          setOpenOfferDialog={setOpenOfferDialog}
        />
      )}

      <Dialog
        open={openTutorialDialog}
        onClose={() => {
          setOpenTutorialDialog(false);
          setTutorialVideo('');
        }}
        className={classes.tutorialDialog}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {tutorialVideo === '' && (
            <Typography
              className={classes.typographyStyle}
              style={{ fontSize: '25px', color: '#060640', fontWeight: '700' }}
            >
              {t('pages.Settings.whatCmsUsing')}
            </Typography>
          )}

          <div className={classes.tutorialContainer}>
            {tutorialVideo === '' && (
              <div className={classes.iconContainer}>
                <ButtonBase
                  className={classes.cmsIcon}
                  onClick={() => {
                    setTutorialVideo('webflow');
                    if (playerRef.current) {
                      playerRef.current.seekTo(0);
                    }
                  }}
                >
                  <img src={WebflowIcon} className={classes.cmsIconImg}></img>
                </ButtonBase>
                <ButtonBase
                  className={classes.cmsIcon}
                  onClick={() => {
                    setTutorialVideo('shopify');
                    if (playerRef.current) {
                      playerRef.current.seekTo(0);
                    }
                  }}
                >
                  <img src={ShopifyIcon} className={classes.cmsIconImg}></img>
                </ButtonBase>
              </div>
            )}

            <Typography
              className={classes.typographyStyle}
              style={{
                marginBottom: '20px',
                fontSize: '25px',
                color: '#060640',
                fontWeight: '700',
                alignSelf: 'center',
              }}
            >
              {tutorialVideo === 'webflow'
                ? t('pages.Settings.tutorialEmbedWebflow')
                : tutorialVideo === 'shopify'
                ? t('pages.Settings.tutorialEmbedShopify')
                : ''}
            </Typography>

            <div className={classes.videoContainer}>
              {tutorialVideo != '' && (
                <ReactPlayer
                  ref={playerRef}
                  url={
                    tutorialVideo === 'shopify'
                      ? 'Shopify' + '.mp4'
                      : 'Webflow' + '.mp4'
                  }
                  width={'1080px'}
                  height={'607px'}
                  className={classes.videoPlayer}
                  controls={true}
                  playing={true}
                />
              )}
            </div>
            {tutorialVideo != '' && (
              <Typography
                className={classes.typographyStyle}
                style={{
                  marginTop: '20px',
                  fontSize: '20px',
                  color: '#060640',
                }}
              >
                {t('pages.Settings.ifNeedHelp')}
              </Typography>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PluginConfiguration;
