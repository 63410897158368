import React, { useEffect, useState } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { signInUpGoogle } from 'src/slices/auth';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';

const useStyles = createUseStyles({
  authSocialContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  authSocialButtons: {
    fontSize: 'large',
  },
  googleButton: {
    width: '100%',
    height: '60px',
    background: '#FFFFFF',
    border: '1px solid #273EAC',
    borderRadius: '8px',
    color: '#273EAC',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '100%',
    letterSpacing: '-0.4px',
    gap: '16px',
    marginBottom: '26px',
  },

  authSocialDivider: {
    color: '#D1D6EC',
    width: '100%',
    marginBottom: '23px',
  },
  authSocialOrDivider: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
    width: 'fit-content',
  },
});

// ----------------------------------------------------------------------

const AuthSocial: React.FC = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error),
  });

  const [user, setUser] = useState<any>();

  useEffect(() => {
    if (user) {
      setLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
            },
          },
        )
        .then(async (res) => {
          if (user) {
            await dispatch(
              signInUpGoogle({
                googleAccessToken: user.access_token,
                locale: i18n.language,
                user: {
                  id: res.data.id,
                  email: res.data.email,
                  firstName: res.data.given_name,
                  lastName: res.data.family_name,
                  pictureUrl: res.data.picture,
                },
              }),
            );
          }
        })
        .catch((err) => console.log(err));
      setLoading(false);
    }
  }, [user]);

  return (
    <div className={classes.authSocialContainer}>
      <Button
        onClick={() => login()}
        variant="outlined"
        fullWidth
        disabled={loading}
        className={classes.googleButton}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_2_602)">
            <path
              d="M5.31891 14.5027L4.4835 17.6213L1.43011 17.6859C0.517594 15.9934 0 14.057 0 11.9992C0 10.0093 0.483938 8.13277 1.34175 6.48047H1.34241L4.06078 6.97884L5.25159 9.68091C5.00236 10.4075 4.86652 11.1875 4.86652 11.9992C4.86661 12.88 5.02617 13.724 5.31891 14.5027Z"
              fill="#FBBB00"
            />
            <path
              d="M23.7921 9.75781C23.93 10.4837 24.0018 11.2334 24.0018 11.9996C24.0018 12.8587 23.9115 13.6967 23.7394 14.5051C23.1553 17.2558 21.6289 19.6578 19.5144 21.3576L19.5137 21.3569L16.0898 21.1822L15.6052 18.1572C17.0083 17.3343 18.1048 16.0466 18.6823 14.5051H12.2656V9.75781H18.776H23.7921Z"
              fill="#518EF8"
            />
            <path
              d="M19.5114 21.3577L19.5121 21.3584C17.4556 23.0113 14.8433 24.0004 11.9996 24.0004C7.42969 24.0004 3.45652 21.4461 1.42969 17.6872L5.31848 14.5039C6.33187 17.2085 8.94089 19.1338 11.9996 19.1338C13.3143 19.1338 14.546 18.7784 15.6029 18.158L19.5114 21.3577Z"
              fill="#28B446"
            />
            <path
              d="M19.6577 2.76262L15.7702 5.94525C14.6763 5.26153 13.3833 4.86656 11.9981 4.86656C8.87017 4.86656 6.21236 6.88017 5.24973 9.68175L1.3405 6.48131H1.33984C3.337 2.63077 7.36028 0 11.9981 0C14.9097 0 17.5794 1.03716 19.6577 2.76262Z"
              fill="#F14336"
            />
          </g>
          <defs>
            <clipPath id="clip0_2_602">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {t('pages.SignIn.loginWithGoogle')}
      </Button>

      <Divider className={classes.authSocialDivider}>
        <Typography
          variant="body2"
          className={classes.authSocialOrDivider}
          sx={{ textTransform: 'uppercase' }}
        >
          {t('common.or')}
        </Typography>
      </Divider>
    </div>
  );
};

export default AuthSocial;
