import React from 'react';
import {
  Remirror,
  ThemeProvider,
  useRemirror,
  Toolbar,
  useRemirrorContext,
} from '@remirror/react';
import {
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
  ImageExtension,
} from 'remirror/extensions';
import '@remirror/styles/all.css';
import {
  ToggleBoldButton,
  ToggleItalicButton,
  ToggleUnderlineButton,
} from '@remirror/react-components';
import { ButtonBase } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

interface MyEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const AddImageButton: React.FC = () => {
  const { commands } = useRemirrorContext();
  const addImage = () => {
    const url = prompt('Enter image URL');
    if (url) {
      commands.insertImage({ src: url });
    }
  };

  return (
    <ButtonBase
      className="remirror-button remirror-button--icon remirror-button--text"
      onMouseDown={(e) => {
        e.preventDefault();
        addImage();
      }}
    >
      <ImageIcon style={{ width: '16px', height: '16px' }} />
    </ButtonBase>
  );
};

const MyEditor: React.FC<MyEditorProps> = ({ value, onChange }) => {
  const { manager, state } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new ImageExtension({ enableResizing: true }),
    ],
    content: value,
    stringHandler: 'html',
  });

  return (
    <ThemeProvider>
      <Remirror
        manager={manager}
        initialContent={state}
        onChange={(parameter) => {
          const { helpers } = parameter;
          const html = helpers.getHTML();
          onChange(html);
        }}
        autoRender="end"
      >
        <Toolbar>
          <ToggleBoldButton />
          <ToggleItalicButton />
          <ToggleUnderlineButton />
          <AddImageButton />
        </Toolbar>
      </Remirror>
    </ThemeProvider>
  );
};

export default MyEditor;
