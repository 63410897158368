import React, { useEffect, useState } from 'react';
import { Testimonial } from 'src/slices/testimonial';
import {
  SideBarHandleContext,
  TestimonialEditionHandleContext,
  TestimonialListHandleContext,
} from './context';

interface Props {
  children: React.ReactNode;
}

const SideBarProvider: React.FC<Props> = ({ children }) => {
  /** SidebarHandler **/
  const [isTestimonialEditionOpened, setOpenTestimonialEdition] =
    useState<boolean>(false);

  const [isTestimonialListOpened, setOpenTestimonialList] =
    useState<boolean>(false);

  const [testimonialType, setTestimonialType] = useState<string>('');

  /** Testimonial Edition **/
  const [isNewTestimonial, setNewTestimonial] = useState<boolean>(false);
  const [testimonial, setTestimonial] = useState<Testimonial | undefined>(
    undefined,
  );
  const [tag, setTag] = useState<string | undefined>(undefined);

  const [defaultTab, setDefaultTab] = useState<string | undefined>(undefined);

  const [displayLocation, setDisplayLocation] = useState<string | undefined>(
    undefined,
  );

  /** Testimonial Selector **/
  const [testimonials, setTestimonials] = useState<Testimonial[] | undefined>(
    undefined,
  );

  const [tutorialClicked, setTutorialClicked] = useState<boolean | undefined>(
    false,
  );

  useEffect(() => {
    const handlePopState = () => {
      setOpenTestimonialList(false);
      setOpenTestimonialEdition(false);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <>
      <SideBarHandleContext.Provider
        value={{
          isTestimonialEditionOpened: isTestimonialEditionOpened,
          isTestimonialListOpened: isTestimonialListOpened,
          setOpenTestimonialEdition: setOpenTestimonialEdition,
          setOpenTestimonialList: setOpenTestimonialList,
        }}
      >
        <TestimonialEditionHandleContext.Provider
          value={{
            isNewTestimonial: isNewTestimonial,
            setNewTestimonial: setNewTestimonial,
            testimonial: testimonial,
            setTestimonial: setTestimonial,
            testimonialType: testimonialType,
            setTestimonialType: setTestimonialType,
            tag: tag,
            setTag: setTag,
            tutorialClicked: tutorialClicked,
            setTutorialClicked: setTutorialClicked,
            displayLocation: displayLocation,
            setDisplayLocation: setDisplayLocation,
          }}
        >
          <TestimonialListHandleContext.Provider
            value={{
              testimonials: testimonials,
              setTestimonials: setTestimonials,
              tag: tag,
              setTag: setTag,
              defaultTab: defaultTab,
              setDefaultTab: setDefaultTab,
              displayLocation: displayLocation,
              setDisplayLocation: setDisplayLocation,
            }}
          >
            {children}
          </TestimonialListHandleContext.Provider>
        </TestimonialEditionHandleContext.Provider>
      </SideBarHandleContext.Provider>
    </>
  );
};

export default SideBarProvider;
