import React, { useState } from 'react';
import { Avatar, Button, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QuoteIcon } from 'src/constants/svg';
import { DisplayPreferences } from 'src/slices/testimonial';
import { useStyles } from './TestimonialCard.style';
import { theme } from 'src/constants/theme';
import UserIcon from '../../../assets/icon-user.svg';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CompanyIcon from '../../../assets/company.svg';
import { ReactPlayerProps } from 'react-player/types/lib';
import { default as _ReactPlayer } from 'react-player';
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

interface Props {
  id?: string;
  content?: string;
  displayPreferences: DisplayPreferences;
  createdAt?: string;
  details: string;
  callToActionText?: string;
  mainColor?: string;
  secondaryColor?: string;
  statistics?: {
    hoverNumber: number;
    clickedNumber: number;
  };
  testimonialType?: string;
  information?: string;
  picture?: string;
  logo?: string;
  color?: string;
}

const TestimonialCard: React.FC<Props> = (testimonial) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const getDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
  };
  const [onClickVideo, setOnClickVideo] = useState<boolean>(false);

  const [onHover, setOnHover] = useState<boolean>(false);

  return (
    <div className={classes.wrapper}>
      {testimonial.testimonialType === 'video' ? (
        <>
          <div
            className={
              testimonial.displayPreferences.showVertical
                ? `${classes.contentVertical} ${classes.contentVideo}`
                : `${classes.content} ${classes.contentVideo} ${
                    testimonial.displayPreferences.showSquare
                      ? classes.contentSquare
                      : ''
                  }`
            }
          >
            {testimonial.displayPreferences.showVertical ? (
              <div
                className={`${classes.commonBackground} ${classes.commonBackgroundVideo}`}
              >
                <ReactPlayer
                  url={testimonial.content}
                  playing={onHover}
                  loop={true}
                  config={{
                    file: {
                      attributes: {
                        preload: 'metadata',
                      },
                    },
                  }}
                  volume={onClickVideo ? 1 : 0}
                  width="243px"
                  height="431px"
                  style={{
                    borderRadius: '16px',
                    overflow: 'hidden',
                    backgroundColor: '#efefef',
                  }}
                  onMouseEnter={() => {
                    setOnHover(true);
                  }}
                  onClick={() => {
                    setOnClickVideo((onClickVideo) => !onClickVideo);
                  }}
                />
                {testimonial.displayPreferences.showMoreInfos &&
                testimonial.details &&
                testimonial.details.length ? (
                  <div
                    className={`${classes.CTAWrapper} ${classes.CTAVideoSquare}`}
                  >
                    <Button
                      variant="contained"
                      className={`${classes.CTA}`}
                      size="small"
                      style={{
                        background:
                          testimonial.color ??
                          testimonial.mainColor ??
                          theme.palette.primary.main,
                      }}
                    >
                      {t(
                        'pages.Testimonials.edition.moreInformationCallToAction',
                      )}
                    </Button>
                  </div>
                ) : (
                  testimonial.callToActionText && (
                    <div
                      className={`${classes.CTAWrapper} ${classes.CTAVideoSquare}`}
                    >
                      <Button
                        variant="contained"
                        className={classes.CTA}
                        size="small"
                        style={{
                          background:
                            testimonial.color ??
                            testimonial.mainColor ??
                            theme.palette.primary.main,
                        }}
                      >
                        {testimonial.callToActionText}
                      </Button>
                    </div>
                  )
                )}
                <IconButton
                  className={classes.volumeIconVertical}
                  onClick={() => {
                    setOnClickVideo((onClickVideo) => !onClickVideo);
                  }}
                >
                  {onClickVideo ? <VolumeUpIcon /> : <VolumeOffIcon />}
                </IconButton>
                {testimonial.displayPreferences.showCompanyLogo && (
                  <Avatar
                    className={
                      !testimonial?.logo
                        ? `${classes.logoContainer} ${classes.logoCompanyDefault}`
                        : `${classes.logoContainer}`
                    }
                    src={testimonial?.logo ? testimonial?.logo : CompanyIcon}
                    style={
                      testimonial?.logo
                        ? {}
                        : { color: 'white', backgroundColor: '#FFD600' }
                    }
                  />
                )}
              </div>
            ) : testimonial.displayPreferences.showSquare ? (
              <div
                className={`${classes.commonBackground} ${classes.commonBackgroundVideo}`}
              >
                <ReactPlayer
                  url={testimonial.content}
                  playing={false}
                  loop={true}
                  volume={onClickVideo ? 1 : 0}
                  width="315px"
                  height="315px"
                  style={{
                    borderRadius: '16px',
                    overflow: 'hidden',
                    backgroundColor: '#efefef',
                  }}
                  onClick={() => {
                    setOnClickVideo((onClickVideo) => !onClickVideo);
                  }}
                />
                {testimonial.displayPreferences.showMoreInfos &&
                testimonial.details &&
                testimonial.details.length ? (
                  <div
                    className={`${classes.CTAWrapper} ${classes.CTAVideoSquare}`}
                  >
                    <Button
                      variant="contained"
                      className={`${classes.CTA}`}
                      size="small"
                      style={{
                        background:
                          testimonial.color ??
                          testimonial.mainColor ??
                          theme.palette.primary.main,
                      }}
                    >
                      {t(
                        'pages.Testimonials.edition.moreInformationCallToAction',
                      )}
                    </Button>
                  </div>
                ) : (
                  testimonial.callToActionText && (
                    <div
                      className={`${classes.CTAWrapper} ${classes.CTAVideoSquare}`}
                    >
                      <Button
                        variant="contained"
                        className={classes.CTA}
                        size="small"
                        style={{
                          background:
                            testimonial.color ??
                            testimonial.mainColor ??
                            theme.palette.primary.main,
                        }}
                      >
                        {testimonial.callToActionText}
                      </Button>
                    </div>
                  )
                )}
                <IconButton
                  className={classes.volumeIconSquare}
                  onClick={() => {
                    setOnClickVideo((onClickVideo) => !onClickVideo);
                  }}
                >
                  {onClickVideo ? <VolumeUpIcon /> : <VolumeOffIcon />}
                </IconButton>
                {testimonial.displayPreferences.showCompanyLogo && (
                  <Avatar
                    className={
                      !testimonial?.logo
                        ? `${classes.logoContainer} ${classes.logoCompanyDefault}`
                        : `${classes.logoContainer}`
                    }
                    src={testimonial?.logo ? testimonial?.logo : CompanyIcon}
                    style={
                      testimonial?.logo
                        ? {}
                        : { color: 'white', backgroundColor: '#FFD600' }
                    }
                  />
                )}
              </div>
            ) : (
              <div
                className={`${classes.commonBackground} ${classes.commonBackgroundVideo}`}
              >
                <ReactPlayer
                  url={testimonial.content}
                  playing={false}
                  loop={true}
                  volume={onClickVideo ? 1 : 0}
                  width="360px"
                  height="200px"
                  style={{
                    borderRadius: '16px',
                    overflow: 'hidden',
                    backgroundColor: '#efefef',
                  }}
                  onClick={() => {
                    setOnClickVideo((onClickVideo) => !onClickVideo);
                  }}
                />
                {testimonial.displayPreferences.showMoreInfos &&
                testimonial.details &&
                testimonial.details.length ? (
                  <div className={`${classes.CTAWrapper} ${classes.CTAVideo}`}>
                    <Button
                      variant="contained"
                      className={`${classes.CTA}`}
                      size="small"
                      style={{
                        background:
                          testimonial.color ??
                          testimonial.mainColor ??
                          theme.palette.primary.main,
                      }}
                    >
                      {t(
                        'pages.Testimonials.edition.moreInformationCallToAction',
                      )}
                    </Button>
                  </div>
                ) : (
                  testimonial.callToActionText && (
                    <div
                      className={`${classes.CTAWrapper} ${classes.CTAVideo}`}
                    >
                      <Button
                        variant="contained"
                        className={classes.CTA}
                        size="small"
                        style={{
                          background:
                            testimonial.color ??
                            testimonial.mainColor ??
                            theme.palette.primary.main,
                        }}
                      >
                        {testimonial.callToActionText}
                      </Button>
                    </div>
                  )
                )}
                <IconButton
                  className={classes.volumeIcon}
                  onClick={() => {
                    setOnClickVideo((onClickVideo) => !onClickVideo);
                  }}
                >
                  {onClickVideo ? <VolumeUpIcon /> : <VolumeOffIcon />}
                </IconButton>
                {testimonial.displayPreferences.showCompanyLogo && (
                  <Avatar
                    className={
                      !testimonial?.logo
                        ? `${classes.logoContainer} ${classes.logoCompanyDefault}`
                        : `${classes.logoContainer}`
                    }
                    src={testimonial?.logo ? testimonial?.logo : CompanyIcon}
                    style={
                      testimonial?.logo
                        ? {}
                        : { color: 'white', backgroundColor: '#FFD600' }
                    }
                  />
                )}
              </div>
            )}

            <div className={classes.bottom}>
              {testimonial.displayPreferences.showCustomerPicture && (
                <div
                  className={`${classes.avatar} ${classes.commonBackground}`}
                >
                  <Avatar
                    className={classes.avatarImage}
                    alt="Avatar"
                    src={
                      testimonial.displayPreferences.showCustomerPicture
                        ? testimonial?.picture
                        : UserIcon
                    }
                    aria-label="avatar"
                  />
                </div>
              )}

              {testimonial?.information && (
                <div
                  className={`${classes.contactName} ${classes.commonBackground}`}
                >
                  {testimonial.information}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.content}>
            <div className={`${classes.top} ${classes.commonBackground}`}>
              {testimonial.displayPreferences.showDate && (
                <div className={classes.date}>
                  {testimonial.createdAt != undefined
                    ? testimonial.createdAt
                        ?.split('T')[0]
                        .split('-')
                        .reverse()
                        .join('-')
                        ?.replaceAll('-', '/')
                    : getDate()}
                </div>
              )}
              <p className={classes.quote}>« {testimonial.content} »</p>
              {testimonial.displayPreferences.showMoreInfos &&
              testimonial.details &&
              testimonial.details.length ? (
                <div className={classes.CTAWrapper}>
                  <Button
                    variant="contained"
                    className={`${classes.CTA}`}
                    size="small"
                    style={{
                      background:
                        testimonial.color ??
                        testimonial.mainColor ??
                        theme.palette.primary.main,
                    }}
                  >
                    {t(
                      'pages.Testimonials.edition.moreInformationCallToAction',
                    )}
                  </Button>
                </div>
              ) : (
                testimonial.callToActionText && (
                  <div className={classes.CTAWrapper}>
                    <Button
                      variant="contained"
                      className={classes.CTA}
                      size="small"
                      style={{
                        background:
                          testimonial.color ??
                          testimonial.mainColor ??
                          theme.palette.primary.main,
                      }}
                    >
                      {testimonial.callToActionText}
                    </Button>
                  </div>
                )
              )}
              <div className={classes.quoteIconWrapperBig}>
                <QuoteIcon
                  fill={
                    testimonial.secondaryColor ?? theme.palette.secondary.main
                  }
                />
              </div>
            </div>
            <div className={classes.bottom}>
              {testimonial.displayPreferences.showCustomerPicture && (
                <div
                  className={`${classes.avatar} ${classes.commonBackground}`}
                >
                  <Avatar
                    className={classes.avatarImage}
                    alt="Avatar"
                    src={
                      testimonial.displayPreferences.showCustomerPicture
                        ? testimonial?.picture
                        : UserIcon
                    }
                    aria-label="avatar"
                  >
                    <img src={UserIcon} alt="User icon" />
                  </Avatar>
                </div>
              )}

              {testimonial?.information && (
                <div
                  className={`${classes.contactName} ${classes.commonBackground}`}
                >
                  {testimonial.information}
                </div>
              )}
            </div>
            {testimonial?.statistics ? (
              <div className={classes.bottom} style={{ marginTop: '2%' }}>
                <div
                  className={`${classes.contactName} ${classes.commonBackground}`}
                >
                  <Typography>
                    {t('pages.Testimonials.clickedNumber')}
                    {testimonial?.statistics?.clickedNumber}
                  </Typography>
                  <Typography>
                    {t('pages.Testimonials.hoverNumber')}
                    {testimonial?.statistics?.hoverNumber}
                  </Typography>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {testimonial.displayPreferences.showCompanyLogo ?? (
            <Avatar
              alt="Company logo"
              src={testimonial?.logo ? testimonial?.logo : CompanyIcon}
              style={
                testimonial?.logo
                  ? {}
                  : { color: 'white', backgroundColor: '#FFD600' }
              }
              aria-label="company logo"
              className={
                testimonial?.logo
                  ? `${classes.idleButton}`
                  : `${classes.idleButton} ${classes.idleButtonDefault}`
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default TestimonialCard;
