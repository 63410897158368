import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';

export type SessionIdResult = {
  sessionId: string;
};

interface PaymentData {
  originUrl: string;
  stripeSubscriptionType: number;
}

interface ChangeSubscriptionDto {
  subscription: string;
  type: string;
  originUrl: string;
}

interface PaymentShopifyDto {
  code: string;
  shop: string;
  planChoice?: string;
}

export const paymentShopify = createAsyncThunk(
  'payment/shopify',
  async (paymentDto: PaymentShopifyDto) => {
    try {
      return await apiRequest<SessionIdResult>(
        'POST',
        '/shopify/payment',
        undefined,
        {
          code: paymentDto.code,
          shop: paymentDto.shop,
          planChoice: paymentDto.planChoice,
        },
      );
    } catch (error) {
      return null;
    }
  },
);

export const paymentCheckout = createAsyncThunk(
  'payment/checkout',
  async (paymentData: PaymentData) => {
    try {
      return await apiRequest<string>(
        'POST',
        '/payment/subscriptionDatabaseAccess',
        undefined,
        {
          originUrl: paymentData.originUrl,
          stripeSubscriptionType: paymentData.stripeSubscriptionType,
        },
      );
    } catch (error) {
      return null;
    }
  },
);

export const paymentPortal = createAsyncThunk('payment/portal', async () => {
  try {
    return await apiRequest<string>(
      'POST',
      '/payment/subscriptionPortal',
      undefined,
    );
  } catch (error) {
    return null;
  }
});

export const changeSubscription = createAsyncThunk(
  'payment/changeSubscriptionPortal',
  async (changeSubscription: ChangeSubscriptionDto) => {
    try {
      return await apiRequest<string>(
        'POST',
        '/payment/changeSubscription',
        undefined,
        { ...changeSubscription },
      );
    } catch (error) {
      return null;
    }
  },
);

export const retrieveSubscription = createAsyncThunk(
  'payment/retrieveSubscription',
  async () => {
    try {
      return await apiRequest<{ subscription: string; date: Date }>(
        'GET',
        '/payment/retrieveSubscription',
        undefined,
      );
    } catch (error) {
      return null;
    }
  },
);

export const retrieveInvoices = createAsyncThunk(
  'payment/retrieveInvoices',
  async (idCustomer: string) => {
    try {
      return await apiRequest<string>(
        'POST',
        '/payment/retrieveInvoices',
        undefined,
        {
          idCustomer: idCustomer,
        },
      );
    } catch (error) {
      return null;
    }
  },
);
