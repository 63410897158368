import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';

import {
  apiRequest,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '../helpers/api';
import { Testimonial } from './testimonial';

export interface Stats {
  mailsSent: number;
  mailsRead: number;
  testimonialsViewed: number;
  lastConnection: Date;
  numberOfConnections: number;
}

interface MailConfiguration {
  smtpServer: string;
  email: string;
}

export interface ShopifyAuth {
  authorizationCode: string;
  shop: string;
  locale: string;
}

interface PluginSettings {
  mainColor?: string;
  secondaryColor?: string;
  enableTestimonials?: boolean;
  enableOnMobile?: boolean;
  enableTags?: boolean;
  websiteUrl?: string;
  tagsList: {
    tag: string;
    urls: string[];
    allPage: boolean;
    option?: {
      actif: boolean;
      key: { value: string; type: string }[];
    };
    statistics?: Statistics[];
    trigger?: Trigger;
    displayLocation: DisplayLocation;
    embeddedSettings: EmbeddedSettings;
  }[];
  testimonialsUrl?: {
    url: string;
    tags: string[];
    displayNoTags: boolean;
  }[];
  tags?: string[];
  position?: string;
  zIndex?: number;
  secondaryWebsiteUrl?: string[];
  testimonialBubbleRadius?: number;
  testimonialBubbleDimension?: string;
  outlineBubble?: string;
  hideKudeoButton?: boolean;
}

export interface Statistics {
  totalPluginViewed?: number;
  totalTestimonialHovered?: number;
  totalTestimonialClicked?: number;
  totalPluginViewedOnMobile?: number;
  totalTestimonialHoveredOnMobile?: number;
  totalTestimonialClickedOnMobile?: number;
  totalVideoPlayed?: number;
  totalVideoPlayedOnMobile?: number;
  lastUpdated?: Date;
}

export interface Trigger {
  openTestimonialOnDesktopOnLoad?: boolean;
  openTestimonialOnMobileOnLoad?: boolean;
  timeBeforeOpenOnDesktop?: number;
  timeBeforeOpenOnMobile?: number;
  timeBeforeOpenOnDesktopState?: boolean;
  timeBeforeOpenOnMobileState?: boolean;
  openOnScroll?: boolean;
  openOnScrollMobile?: boolean;
  openOnClick?: boolean;
}

export interface DisplayLocation {
  showOnPlugin?: boolean;
  showOnEmbedded?: boolean;
}

export interface EmbeddedSettings {
  videoSize?: string;
  videoOutline?: string;
}

interface UpdatePluginSettingsDto {
  mainColor?: string;
  secondaryColor?: string;
  enableTestimonials?: boolean;
  enableOnMobile?: boolean;
  enableTags?: boolean;
  websiteUrl?: string;
  tagsList?: {
    tag: string;
    urls: string[];
    allPage: boolean;
    option?: { actif: boolean; key: { value: string; type: string }[] };
    trigger?: Trigger;
    displayLocation?: DisplayLocation;
    embeddedSettings?: EmbeddedSettings;
  }[];
  position?: string;
  zIndex?: number;
  secondayWebsiteUrl?: string[];
  testimonialBubbleRadius?: number;
  testimonialBubbleDimension?: string;
  outlineBubble?: string;
  hideKudeoButton?: boolean;
}

interface UpdateHideKudeoButton {
  userId: string;
  hideKudeoButton: boolean;
}

interface UpdateTriggerAndDisplayLocation {
  userId: string;
  trigger: Trigger;
  tag: string;
}

interface UpdateEmbeddedsettingsDto {
  userId: string;
  embeddedSettings: EmbeddedSettings;
  tag: string;
}

interface Settings {
  isMailJetConfiguration: boolean;
  currentEmailConfiguration?: MailConfiguration;
  sendingConfigurations: MailConfiguration[];
  pluginSettings: PluginSettings;
}

interface AuthProvider {
  local?: string;
  google?: string;
}

export interface Limitation {
  maxNumberOfPages: number;
  maxNumberOfVideos: number;
  maxDomainUrl: number;
  showClick?: boolean;
  maxNumberOfVideoHosted?: number;
  maxNumberOfDisplayedTestimonials: number;
}

export interface UpdateZindexDto {
  id?: string;
  zIndex?: number;
}

export interface AddChildrenToParentDto {
  userId?: string;
  childrenId?: string;
}

export interface User {
  _id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  profilePictureUrl?: string;
  companyLogo?: string;
  email: string;
  company: string;
  position: string;
  locale: string;
  testimonials: Testimonial[] | string[];
  stats: Stats;
  settings: Settings;
  isAdmin: boolean;
  isActiveAccount: boolean;
  createdAt?: Date;
  hasValidSubscription?: boolean;
  subscriptionId?: string;
  stripeCustomerId?: string;
  subscriptionType: string;
  accountType?: AccountType;
  step?: number;
  limitation?: Limitation;
  statistics: Statistics[];
  whyUseKudeo?: string;
  rememberMeLater?: boolean;
  isScriptIntegrated?: boolean;
  codeApplied?: string;
  hideOffer?: boolean;
  isShopifyAccount: boolean;
  paymentInterval?: string;
  earlyAccess?: boolean;
}

export interface AccountType {
  type: string;
  children: User[] | string[];
}

export interface UpdateUserDto {
  firstName?: string;
  email?: string;
  lastName?: string;
  company?: string;
  position?: string;
  profilePictureUrl?: string;
  companyLogo?: string;
  locale?: string;
  stats?: Stats;
  settings?: Settings;
  authProviders?: AuthProvider;
  phoneNumber?: string;
  hasValidSubscription?: boolean;
  subscriptionId?: string;
  stripeCustomerId?: string;
  subscriptionType?: string;
  accountType?: AccountType;
}

export interface UpdateUserEmailAndPasswordDto {
  userId?: string;
  email?: string;
  password?: string;
}

export interface UpdateStepDto {
  id?: string;
  step?: number;
}

export interface UpdateLimitationDto {
  userId?: string;
  maxNumberOfPages?: number;
  maxNumberOfVideos?: number;
  maxDomainUrl?: number;
  maxNumberOfVideoHosted?: number;
  maxNumberOfDisplayedTestimonials: number;
}

export interface UpdateRememberMeLater {
  id?: string;
  rememberMeLater?: boolean;
}

export interface UpdateWhyUseKudeo {
  id?: string;
  whyUseKudeo?: string;
}

interface Tokens {
  accessToken: string;
  refreshToken?: string;
}

interface AuthState {
  user: User | null;
  users: User[];
  isLogged: boolean;
  isLoading: boolean;
  isInitialLoading: boolean;
  error: string | null;
  sendingEmails: string[];
  isAdmin: boolean;
  firstUserId: string;
  rememberMeLaterLogin?: boolean;
  limitation: Limitation;
}

const initialState: AuthState = {
  isLogged: false,
  isLoading: false,
  isInitialLoading: true,
  user: null,
  users: [],
  error: null,
  sendingEmails: [],
  isAdmin: false,
  firstUserId: '',
  limitation: {
    maxNumberOfPages: 0,
    maxNumberOfVideos: 0,
    maxDomainUrl: 0,
    showClick: false,
    maxNumberOfVideoHosted: 0,
    maxNumberOfDisplayedTestimonials: 0,
  },
};

export interface UpdateEarlyAccessDto {
  userId: string;
  earlyAccess: boolean;
}

export interface updateEngagementDto {
  userId: string;
  paymentInterval: string;
}

const setAccessRefreshTokenAndGetUser = (
  accessToken: string,
  refreshToken?: string,
) => {
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
  if (refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
  }

  return apiRequest<User>('GET', '/auth/user');
};

/* Thunks */

export const fetchCurrentUser = createAsyncThunk(
  'auth/fetchCurrentUser',
  async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return null;
    }

    try {
      return await apiRequest<User>('GET', '/auth/user');
    } catch (error) {
      return null;
    }
  },
);

export const fetchUserById = createAsyncThunk(
  'auth/fetchUserById',
  async (userId: string) => {
    const user = await apiRequest<User>('GET', `/user/${userId}`);
    return user;
  },
);

export const fetchCurrentUserStats = createAsyncThunk(
  'auth/fetchCurrentUserStats',
  async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return null;
    }

    try {
      return await apiRequest<User>('GET', '/auth/user/stats');
    } catch (error) {
      return null;
    }
  },
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (updateUserDto: UpdateUserDto) => {
    const result = await apiRequest<User>('PUT', '/auth/user', undefined, {
      ...updateUserDto,
    });

    return result;
  },
);

export const updateUserLimitation = createAsyncThunk(
  'auth/updateUser',
  async (updateLimitationDto: UpdateLimitationDto) => {
    const result = await apiRequest<User>(
      'PUT',
      '/user/update/limitation',
      undefined,
      {
        ...updateLimitationDto,
      },
    );

    return result;
  },
);

export const updateEarlyAccess = createAsyncThunk(
  'auth/updateEarlyAccess',
  async (update: UpdateEarlyAccessDto) => {
    const result = await apiRequest<User>(
      'PUT',
      '/user/update/earlyAccess',
      undefined,
      {
        ...update,
      },
    );

    return result;
  },
);

export const updateEngagement = createAsyncThunk(
  'auth/updateEngagement',
  async (update: updateEngagementDto) => {
    const result = await apiRequest<User>(
      'PUT',
      '/user/update/paymentInterval',
      undefined,
      {
        ...update,
      },
    );

    return result;
  },
);

export const updateWhyUseKudeo = createAsyncThunk(
  'auth/updateWhyUseKudeo',
  async (updateWhyUseKudeo: UpdateWhyUseKudeo) => {
    const result = await apiRequest<User>(
      'PUT',
      `/user/update/whyUseKudeo`,
      undefined,
      {
        ...updateWhyUseKudeo,
      },
    );

    return result;
  },
);

export const updateRememberMeLater = createAsyncThunk(
  'auth/updateRememberMeLater',
  async (updateRememberMeLater: UpdateRememberMeLater) => {
    const result = await apiRequest<User>(
      'PUT',
      `/user/update/rememberMeLater`,
      undefined,
      {
        ...updateRememberMeLater,
      },
    );

    return result;
  },
);

export const updateUserEmailAndPassword = createAsyncThunk(
  'user/updateUserEmailAndPassword',
  async (updateUserDto: UpdateUserEmailAndPasswordDto) => {
    const result = await apiRequest<User>(
      'PUT',
      '/user/update/user',
      undefined,
      {
        ...updateUserDto,
      },
    );

    return result;
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (payload: { email: string; password: string }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      '/auth/signIn',
      undefined,
      payload,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const sendMailRedirection = createAsyncThunk(
  'auth/sendMailRedirection',
  async (payload: { email: string; locale: string }) => {
    await apiRequest<{ status: boolean }>(
      'POST',
      '/auth/redirection',
      undefined,
      payload,
    );
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (payload: {
    email: string;
    password: string;
    code: boolean;
    codeApplied: string;
    locale: string;
  }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/auth/signUp',
      undefined,
      payload,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const signInUpGoogle = createAsyncThunk(
  'auth/signInUpGoogle',
  async (payload: { googleAccessToken: string; locale: string; user: any }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/auth/google',
      undefined,
      // eslint-disable-next-line camelcase
      {
        accessToken: payload.googleAccessToken,
        locale: payload.locale,
        user: payload.user,
      },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const sendLostPassword = createAsyncThunk(
  'auth/sendLostPassword',
  async (payload: { email: string }) => {
    await apiRequest<{ status: boolean }>(
      'POST',
      '/auth/lostPassword',
      undefined,
      payload,
    );
  },
);

export const updateUserStep = createAsyncThunk(
  'user/updateStep',
  async (updateStepDto: UpdateStepDto) => {
    const results: User = await apiRequest<User>(
      'PUT',
      `/user/update/step`,
      undefined,
      { id: updateStepDto.id, step: updateStepDto.step },
    );
    return results;
  },
);

export const useActivationAccount = createAsyncThunk(
  'auth/useActivationAccount',
  async (payload: any) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      `/auth/activateAccount/${encodeURIComponent(payload)}`,
      undefined,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const useLostPasswordToken = createAsyncThunk(
  'auth/useLostPasswordToken',
  async (payload: { token: string; password: string }) => {
    const { token, password } = payload;
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      `/auth/lostPassword/${encodeURIComponent(token)}`,
      undefined,
      { password },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const getSendingEmails = createAsyncThunk(
  'user/getSendingEmails',
  async () => {
    const emails = await apiRequest<string[]>(
      'GET',
      '/user/emailsConfigurations',
    );

    return emails;
  },
);

interface SendingEmailConfigurationDto {
  smtpServer: string;
  email: string;
  password: string;
  port: number;
  tls: boolean;
}

export const addSendingEmailConfiguration = createAsyncThunk(
  'user/addSendingEmailConfiguration',
  async (sendingEmailConfigurationDto: SendingEmailConfigurationDto) => {
    const result: User = await apiRequest<User>(
      'PATCH',
      '/user/emailsConfigurations',
      undefined,
      {
        ...sendingEmailConfigurationDto,
        port: sendingEmailConfigurationDto.port.toString(),
      },
    );

    return result;
  },
);

interface ChangeEmailConfigurationDto {
  usingMailjet: boolean;
  email?: string;
}

export const changeEmailConfiguration = createAsyncThunk(
  'user/changeEmailConfiguration',
  async (changeEmailConfiguration: ChangeEmailConfigurationDto) => {
    const result: User = await apiRequest<User>(
      'PATCH',
      '/user/changeEmailConfiguration',
      undefined,
      {
        usingMailjet: changeEmailConfiguration.usingMailjet,
        email: changeEmailConfiguration.email,
      },
    );

    return result;
  },
);

export const deleteEmailConfiguration = createAsyncThunk(
  'user/deleteEmailConfiguration',
  async (email: string) => {
    const result: User = await apiRequest<User>(
      'DELETE',
      '/user/emailsConfigurations',
      undefined,
      { email: email },
    );

    return result;
  },
);

export const updatePluginSettings = createAsyncThunk(
  'user/updatePluginSettings',
  async (updatePluginDto: UpdatePluginSettingsDto) => {
    const result = await apiRequest<User>('PUT', '/user/plugin', undefined, {
      ...updatePluginDto,
    });

    return result;
  },
);

export const UpdateHideKudeoButton = createAsyncThunk(
  'user/hideButtonKudeo',
  async (updatePluginDto: UpdateHideKudeoButton) => {
    const result = await apiRequest<User>(
      'PUT',
      '/user/plugin/hideButtonKudeo',
      undefined,
      {
        ...updatePluginDto,
      },
    );

    return result;
  },
);

export const updateTriggerAndDisplayLocationPluginSetting = createAsyncThunk(
  'user/updateTrigger',
  async (updatePluginDto: UpdateTriggerAndDisplayLocation) => {
    const result = await apiRequest<User>(
      'PUT',
      '/user/triggerAndDisplayLocation',
      undefined,
      {
        ...updatePluginDto,
      },
    );

    return result;
  },
);

export const updateEmbeddedSetting = createAsyncThunk(
  'user/updateEmbeddedSettings',
  async (updatePluginDto: UpdateEmbeddedsettingsDto) => {
    const result = await apiRequest<User>(
      'PUT',
      '/user/updateEmbeddedSettings',
      undefined,
      {
        ...updatePluginDto,
      },
    );

    return result;
  },
);

/** Admin thunks **/

export const adminGetAllUsers = createAsyncThunk(
  'auth/admin/getAllUsers',
  async () => {
    const result = await apiRequest<User[]>('GET', '/auth/admin/users');

    return result;
  },
);

export const addAdminAccount = createAsyncThunk(
  'auth/admin/addAdmin',
  async (userId: string) => {
    const result = await apiRequest<User>('POST', `/auth/admin/${userId}`);

    return result;
  },
);

export const undoAdminAccount = createAsyncThunk(
  'auth/admin/deleteAdmin',
  async (userId: string) => {
    const result = await apiRequest<User>(
      'POST',
      `/auth/admin/delete/admin/${userId}`,
    );

    return result;
  },
);

export const activateAccount = createAsyncThunk(
  'auth/admin/activateAccount',
  async (userId: string) => {
    const result = await apiRequest<User>(
      'PUT',
      `/auth/admin/${userId}/activate`,
    );

    return result;
  },
);

export const setUserToParent = createAsyncThunk(
  'auth/admin/setUserToParent',
  async (userId: string) => {
    const result = await apiRequest<User>(
      'PUT',
      `/auth/admin/${userId}/setUserToParent`,
    );

    return result;
  },
);

export interface UpdateSubscriptionType {
  subscriptionType: string;
  hasValidSubscription: boolean;
}

export interface UpdatePluginPosition {
  userId: string;
  position: string;
}

export const changeSubscriptionType = createAsyncThunk(
  'auth/admin/changeSubscriptionType',
  async ({
    userId,
    updateUserDtoSubscriptionType,
  }: {
    userId: string;
    updateUserDtoSubscriptionType: UpdateSubscriptionType;
  }) => {
    const result = await apiRequest<User[]>(
      'PUT',
      `/auth/admin/changeSubscriptionType/${userId}`,
      undefined,
      { ...updateUserDtoSubscriptionType },
    );

    return result;
  },
);

export const changePluginPositionThunk = createAsyncThunk(
  '/plugin/ChangePluginPosition',
  async (updatePluginPosition: UpdatePluginPosition) => {
    const result = await apiRequest<User>(
      'PUT',
      `/user/plugin/position`,
      undefined,
      {
        ...updatePluginPosition,
      },
    );

    return result;
  },
);

export interface UpdateUserCompanyRole {
  userId: string;
  company?: string;
  position?: string;
}

export const updateUserCompanyRole = createAsyncThunk(
  'auth/admin/updateUserCompanyRole',
  async (updateUserCompanyRole: UpdateUserCompanyRole) => {
    const result = await apiRequest<User>(
      'PUT',
      `/auth/admin/${updateUserCompanyRole.userId}`,
      undefined,
      {
        company: updateUserCompanyRole.company,
        position: updateUserCompanyRole.position,
      },
    );

    return result;
  },
);

export const deleteAccount = createAsyncThunk(
  'auth/admin/deleteAccount',
  async (userId: string) => {
    const result = await apiRequest<User>('DELETE', `/auth/admin/${userId}`);

    return result;
  },
);

export const updateZindex = createAsyncThunk(
  'auth/admin/updateZindex',
  async (updateZindexDto: UpdateZindexDto) => {
    const result = await apiRequest<User>(
      'PUT',
      `/user/update/zindex`,
      undefined,
      {
        id: updateZindexDto.id,
        zIndex: updateZindexDto.zIndex,
      },
    );

    return result;
  },
);

export const adminSetUserToParent = createAsyncThunk(
  'auth/admin/setUserToParent',
  async (userId: string) => {
    const result = await apiRequest<User>(
      'PUT',
      `/auth/admin/${userId}/setUserToParent`,
      undefined,
    );

    return result;
  },
);

export const addChildrenToParent = createAsyncThunk(
  'admin/:userId/setChildrenToParent',
  async (addChildrenDto: AddChildrenToParentDto) => {
    const result = await apiRequest<User>(
      'PUT',
      `/auth/admin/${addChildrenDto.userId}/setChildrenToParent`,
      undefined,
      {
        childrenId: addChildrenDto.childrenId,
      },
    );

    return result;
  },
);

export const getCompanyLogo = createAsyncThunk(
  '/user/companyLogo',
  async (userId: string) => {
    const result = await apiRequest<User>(
      'GET',
      `/user/companyLogo/${userId}`,
      undefined,
    );

    return result;
  },
);

export const sendAuthorizationCode = createAsyncThunk(
  'shopify/auth',
  async (shopifyAuth: ShopifyAuth) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/shopify/authorize',
      undefined,
      {
        code: shopifyAuth.authorizationCode,
        shop: shopifyAuth.shop,
        locale: shopifyAuth.locale,
      },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

/* Shared reducers */
const signInUpPendingReducer = (state: AuthState) => {
  state.isLogged = false;
  state.isLoading = true;
  state.error = null;
  state.user = null;
  state.rememberMeLaterLogin = false;
};

const setLimitation = (payload: User | null, state: AuthState) => {
  if (payload)
    if (payload.subscriptionType === 'freetrial') {
      state.limitation = {
        maxNumberOfPages: payload?.limitation?.maxNumberOfPages || 15,
        maxNumberOfVideos: payload?.limitation?.maxNumberOfVideos || 30,
        maxDomainUrl: payload?.limitation?.maxDomainUrl || 0,
        showClick: payload?.limitation?.showClick || false,
        maxNumberOfVideoHosted:
          payload?.limitation?.maxNumberOfVideoHosted || 5,
        maxNumberOfDisplayedTestimonials:
          payload?.limitation?.maxNumberOfDisplayedTestimonials || 30000,
      };
    } else if (payload.subscriptionType === 'startup') {
      state.limitation = {
        maxNumberOfPages: payload?.limitation?.maxNumberOfPages || 3,
        maxNumberOfVideos: payload?.limitation?.maxNumberOfVideos || 5,
        maxDomainUrl: payload?.limitation?.maxDomainUrl || 0,
        showClick: payload?.limitation?.showClick || false,
        maxNumberOfVideoHosted:
          payload?.limitation?.maxNumberOfVideoHosted || 0,
        maxNumberOfDisplayedTestimonials:
          payload?.limitation?.maxNumberOfDisplayedTestimonials || 30000,
      };
    } else if (payload.subscriptionType === 'regular') {
      state.limitation = {
        maxNumberOfPages: payload?.limitation?.maxNumberOfPages || 15,
        maxNumberOfVideos: payload?.limitation?.maxNumberOfVideos || 30,
        maxDomainUrl: payload?.limitation?.maxDomainUrl || 0,
        showClick: payload?.limitation?.showClick || false,
        maxNumberOfVideoHosted:
          payload?.limitation?.maxNumberOfVideoHosted || 5,
        maxNumberOfDisplayedTestimonials:
          payload?.limitation?.maxNumberOfDisplayedTestimonials || 30000,
      };
    } else if (payload.subscriptionType === 'premium') {
      state.limitation = {
        maxNumberOfPages: payload?.limitation?.maxNumberOfPages || 10000,
        maxNumberOfVideos: payload?.limitation?.maxNumberOfVideos || 10000,
        maxDomainUrl: payload?.limitation?.maxDomainUrl || 0,
        showClick: payload?.limitation?.showClick || false,
        maxNumberOfVideoHosted:
          payload?.limitation?.maxNumberOfVideoHosted || 10000,
        maxNumberOfDisplayedTestimonials:
          payload?.limitation?.maxNumberOfDisplayedTestimonials || 30000,
      };
    } else {
      state.limitation = {
        maxNumberOfPages: payload?.limitation?.maxNumberOfPages || 0,
        maxNumberOfVideos: payload?.limitation?.maxNumberOfVideos || 0,
        maxDomainUrl: payload?.limitation?.maxDomainUrl || 0,
        showClick: payload?.limitation?.showClick || false,
        maxNumberOfVideoHosted:
          payload?.limitation?.maxNumberOfVideoHosted || 0,
        maxNumberOfDisplayedTestimonials:
          payload?.limitation?.maxNumberOfDisplayedTestimonials || 30000,
      };
    }
};

const signInUpFulfilledReducer = (
  state: AuthState,
  { payload }: { payload: User },
) => {
  state.isLogged = true;
  state.isLoading = false;
  state.user = payload;
  state.isAdmin = payload?.isAdmin;
  state.firstUserId = payload?._id;
  state.rememberMeLaterLogin = payload?.rememberMeLater;
  setLimitation(payload, state);
};

const pendingReducer = (state: AuthState) => {
  state.isLoading = true;
  state.error = null;
};

const fulfilledReducer = (state: AuthState) => {
  state.isLoading = false;
};

const rejectedReducer = (
  state: AuthState,
  { error }: { error: SerializedError },
) => {
  state.isLoading = false;
  state.error = error.message || 'error';
};

/* Slice */

const authSlice = createSlice({
  name: 'auth',
  reducers: {
    logout(state: AuthState) {
      state.user = null;
      state.isLogged = false;
      state.rememberMeLaterLogin = false;
      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state: AuthState) => {
        state.isInitialLoading = true;
      })

      .addCase(fetchCurrentUser.fulfilled, (state: AuthState, { payload }) => {
        state.isInitialLoading = false;
        state.user = payload;
        state.isLogged = !!payload;
        state.isAdmin = payload?.isAdmin || false;
        state.firstUserId = payload?._id || '';
        setLimitation(payload, state);
      })
      .addCase(
        fetchCurrentUserStats.fulfilled,
        (state: AuthState, { payload }) => {
          state.user = payload;
        },
      )
      .addCase(signIn.pending, signInUpPendingReducer)
      .addCase(signIn.fulfilled, signInUpFulfilledReducer)
      .addCase(signIn.rejected, rejectedReducer)
      .addCase(sendAuthorizationCode.pending, signInUpPendingReducer)
      .addCase(sendAuthorizationCode.fulfilled, signInUpFulfilledReducer)
      .addCase(sendAuthorizationCode.rejected, rejectedReducer)
      .addCase(signUp.pending, signInUpPendingReducer)
      .addCase(signUp.fulfilled, signInUpFulfilledReducer)
      .addCase(signUp.rejected, rejectedReducer)
      .addCase(signInUpGoogle.pending, signInUpPendingReducer)
      .addCase(signInUpGoogle.fulfilled, signInUpFulfilledReducer)
      .addCase(signInUpGoogle.rejected, rejectedReducer)
      .addCase(sendLostPassword.pending, pendingReducer)
      .addCase(sendLostPassword.fulfilled, fulfilledReducer)
      .addCase(sendLostPassword.rejected, rejectedReducer)
      .addCase(useLostPasswordToken.pending, pendingReducer)
      .addCase(useLostPasswordToken.fulfilled, signInUpFulfilledReducer)
      .addCase(useLostPasswordToken.rejected, rejectedReducer)
      .addCase(useActivationAccount.pending, pendingReducer)
      .addCase(useActivationAccount.fulfilled, signInUpFulfilledReducer)
      .addCase(useActivationAccount.rejected, rejectedReducer)
      .addCase(getSendingEmails.fulfilled, (state, { payload }) => {
        state.sendingEmails = payload;
      })
      .addCase(addSendingEmailConfiguration.fulfilled, (state, { payload }) => {
        state.sendingEmails = payload.settings.sendingConfigurations.map(
          (config) => config.email,
        );
        state.user = payload;
      })
      .addCase(changeEmailConfiguration.fulfilled, (state, { payload }) => {
        state.user = payload;
      })

      .addCase(deleteEmailConfiguration.fulfilled, (state, { payload }) => {
        state.sendingEmails = payload.settings.sendingConfigurations.map(
          (config) => config.email,
        );
        state.user = payload;
      })
      .addCase(adminGetAllUsers.fulfilled, (state: AuthState, { payload }) => {
        state.users = payload;
      })
      .addCase(deleteAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.filter((user) => user._id !== payload._id);
      })
      .addCase(addAdminAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.map((user) =>
          user._id !== payload._id ? user : payload,
        );
      })
      .addCase(undoAdminAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.map((user) =>
          user._id !== payload._id ? user : payload,
        );
      })
      .addCase(
        updateUserCompanyRole.fulfilled,
        (state: AuthState, { payload }) => {
          state.users = state.users.map((user) =>
            user._id !== payload._id ? user : payload,
          );
        },
      )
      .addCase(
        changeSubscriptionType.fulfilled,
        (state: AuthState, { payload }) => {
          state.users = payload;
        },
      )
      .addCase(
        updatePluginSettings.fulfilled,
        (state: AuthState, { payload }) => {
          state.user = payload;
        },
      )
      .addCase(updateUserStep.fulfilled, (state: AuthState, { payload }) => {
        state.user = payload;
      })
      .addCase(activateAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.map((user) =>
          user._id !== payload._id ? user : payload,
        );
      })
      .addCase(fetchUserById.fulfilled, (state: AuthState, { payload }) => {
        state.user = payload;
      })
      .addCase(
        updateUserLimitation.fulfilled,
        (state: AuthState, { payload }) => {
          state.user = payload;
        },
      )
      .addCase(
        updateRememberMeLater.fulfilled,
        (state: AuthState, { payload }) => {
          state.user = payload;
          state.rememberMeLaterLogin = false;
        },
      )
      .addCase(updateWhyUseKudeo.fulfilled, (state: AuthState, { payload }) => {
        state.user = payload;
      });
  },
  initialState,
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
