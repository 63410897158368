import React, {
  useContext,
  useEffect,
  useState,
  lazy,
  Suspense,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import {
  deleteTestimonial,
  publishTestimonialOnTagsAndUrl,
  Testimonial,
  updateDisplayPropertiesDevice,
  updateTestimonial,
} from 'src/slices/testimonial';
import {
  SideBarHandleContext,
  TestimonialEditionHandleContext,
} from 'src/hooks/context';
import { theme } from '../../constants/theme';
import { User } from 'src/slices/auth';
import { useAppSelector } from 'src/hooks/store';
import { handleNotification } from '../../helpers/notifications';
import { useAppDispatch } from 'src/hooks/store';
import { useSnackbar } from 'notistack';
import { ButtonBase } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import ComputerIcon from '@mui/icons-material/Computer';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import WarningIcon from '@mui/icons-material/Warning';

import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/adapter/element';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/util/combine';
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/addon/closest-edge';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-indicator/box';
import VideoIcon from '../../assets/video-icon.png';

const useStyles = createUseStyles({
  menuPopOver: {
    zIndex: 1700,
    '& .MuiDialog-paper': {
      width: 'auto',
      borderRadius: '8px',
      background: 'white',
      paddingTop: '50px',
      paddingBottom: '30px',
      paddingLeft: '58px',
      paddingRight: '58px',
      maxWidth: '100%',
    },
  },
  testimonialCardMoreAction: {},
  testimonialCardMoreActionMenu: {},
  testimonialCardActionsEdit: {
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    '& .MuiButtonBase-root': {
      width: '25px',
      height: '25px',
    },
  },
  testimonialCardActionsPublish: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardActionsUnpublish: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardActionsArchive: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardActionsUnarchive: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardActionsDelete: {
    margin: theme.spacing(1),
    fontSize: 'inherit',
  },
  testimonialCardContainer: {
    width: '100%',
    height: '120px',
    borderRadius: '8px',
    background: 'rgba(237, 240, 255, 0.60)',
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 16px 8px 16px',
    justifyContent: 'space-between',
    position: 'relative',
  },
  iconStatisticsHover: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '6px',
    bottom: '36px',
    width: '94px',
    height: '48px',
    background: 'white',
    placeContent: 'center',
    borderRadius: '8px',
    alignSelf: 'center',
  },
  deleteDialog: {
    zIndex: 3000,
    '& .MuiDialog-paper': {
      textAlign: 'center',
      overflow: 'hidden',
      zIndex: '2000',
      padding: '50px',
    },
  },

  dialogTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '140%',
    color: '#060640',
    flex: 1,
  },
  dialogDeleteTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#060640',
    flex: 1,
  },
  dialogContainer: {
    position: 'relative',
    display: 'flex',
    width: '360px',
    alignSelf: 'center',
    flexDirection: 'column',
    paddingTop: '24px',
    gap: '25px',
    alignItems: 'center',
  },
  dialogContent: {
    top: '85px',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldEditTag: {
    marginRight: '10px',
    '& .MuiOutlinedInput-root': {
      width: '231px',
      height: '30px',
      borderRadius: '50px',
      border: '1px solid #8F93B8',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #8F93B8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #8F93B8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  textFieldDeletePage: {
    alignItems: 'center',
    flex: 1,
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },
    '& .MuiOutlinedInput-root': {
      width: '350px',
      borderRadius: '8px',
      border: '1px solid #8F93B8',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #8F93B8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #8F93B8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  dialogActionDeleteButton: {
    borderRadius: '8px',
    width: '123px',
    padding: '10px',
    flex: 1,
  },

  wrapper: {
    display: 'flex',
    '&.MuiContainer-root': {
      padding: 0,
    },
  },
  idleButton: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    height: '100%',
  },
  idleButtonImageWrapper: {
    width: '45px',
    height: '45px',
    borderRadius: '50%',
    boxShadow: '0 2px 16px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
  },
  idleButtonImage: {
    width: '45px',
    height: '45px',
    backgroundColor: '#FFD600',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  circleIconWrapper: {
    position: 'absolute',
    top: '57px',
    right: '0px',
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  date: {
    fontSize: '14px',
    color: 'gray',
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '1000px',
    padding: '2px 8px',
    display: 'inline-block',
    marginLeft: '16px',
    marginBottom: '8px',
  },
  video: {
    borderRadius: '16px',
    overflow: 'hidden',
    backgroundColor: '#F7F8FD',
    width: '90px',
    height: '75px',
    objectFit: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  videoIconContainer: {
    position: 'absolute',
    width: '25.8px',
    height: '25.8px',
    borderRadius: '50%',
    left: '58px',
  },
  quote: {
    paddingTop: '4px',
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '140%',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '8px',
    objectFit: 'cover',
    background: 'transparent',
  },
  avatarImage: {
    background: 'white',
  },
  contactName: {
    fontSize: '14px',
    padding: '6px 12px',
    borderRadius: 16,
  },
  CTAWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  CTA: {
    fontSize: '12px',
    boxShadow: 'none',
    padding: '4px 12px',
    borderRadius: '1000px',
    transition: '0.15s',
    '&:hover': {
      opacity: '0.7',
    },
  },
  companyName: {
    fontWeight: 'bold',
  },
  position: {
    opacity: 0.7,
  },
  contentTestimonial: {
    width: '201px',
    display: 'flex',
  },
  imgCompany: {
    width: '40px',
    height: '40px',
  },
  contactText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
  },
  contactContainer: {
    paddingTop: '4px',
    marginBottom: '8px',
  },
  typography: {
    fontSize: '15px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
  },
  videoIconImg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

interface Props {
  testimonial: Testimonial;
  deleteTag?: any;
  onHoverToDisplayContent?: boolean;
  index?: number;
  tag?: string;
  onClick?: () => void;
}

const TestimonialCardWebsite: React.FC<Props> = ({
  testimonial,
  deleteTag,
  onHoverToDisplayContent,
  index,
  tag,
  onClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const sidebarContext = useContext(SideBarHandleContext);
  const testimonialEditionContext = useContext(TestimonialEditionHandleContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const [deleteTemp, setDeleteTemp] = React.useState(false);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [isTooltipDesktopOpen, setIsTooltipDesktopOpen] = useState(false);

  const [isTooltipMobileOpen, setIsTooltipMobileOpen] = useState(false);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (type: string) => {
    if (type === 'desktop') {
      const id = setTimeout(() => {
        setIsTooltipDesktopOpen(true);
      }, 800);
      timeoutIdRef.current = id;
    } else if (type === 'mobile') {
      const id = setTimeout(() => {
        setIsTooltipMobileOpen(true);
      }, 800);
      timeoutIdRef.current = id;
    } else if (type === 'information') {
      const id = setTimeout(() => {
        setIsTooltipOpen(true);
      }, 800);
      timeoutIdRef.current = id;
    }
  };

  const handleMouseLeave = (type: string) => {
    if (type === 'desktop') {
      setIsTooltipDesktopOpen(false);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    } else if (type === 'mobile') {
      setIsTooltipMobileOpen(false);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    } else if (type === 'information') {
      setIsTooltipOpen(false);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    }
  };

  useEffect(() => {
    const element = ref.current;
    const id = testimonial._id;
    if (!element) return;

    return combine(
      draggable({
        element,
        getInitialData: () => ({ id, index, type: tag }),
      }),
      dropTargetForElements({
        element,
        getData: ({ input }) => {
          return attachClosestEdge(
            { id, index, tag },
            { input, element, allowedEdges: ['top', 'bottom'] },
          );
        },
        getIsSticky: () => true,
        canDrop: (args) => {
          return args.source.data.id !== id;
        },
        onDrag: ({ self, source, location }) => {
          if (element === source.element) {
            setClosestEdge(null);
            return;
          }

          const currentDropTarget = location.current.dropTargets[0];
          if (!currentDropTarget) return;

          const selfIndex = self.data.index;
          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== 'number') return;

          const isItemBeforeSource = selfIndex === sourceIndex - 1;
          const isItemAfterSource = selfIndex === sourceIndex + 1;
          const closestEdge = extractClosestEdge(currentDropTarget.data);

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === 'bottom') ||
            (isItemAfterSource && closestEdge === 'top');

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          if (currentDropTarget.data.id === id) {
            setClosestEdge(closestEdge);
          }
        },
        onDragLeave: () => {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [testimonial._id, index]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const editTestimonial = () => {
    if (testimonialEditionContext) {
      testimonialEditionContext.setNewTestimonial(false);
      sidebarContext?.setOpenTestimonialEdition(true);
      testimonialEditionContext.setTestimonial(testimonial);
      testimonialEditionContext.setDisplayLocation(
        user?.settings?.pluginSettings?.tagsList.find(
          (temp) => temp.tag === deleteTag,
        )?.displayLocation.showOnEmbedded
          ? 'embedded'
          : 'plugin',
      );
    }
  };

  const confirmDeleteTestimonial = async () => {
    setOpenDeleteDialog(false);
    await handleNotification(
      dispatch(deleteTestimonial(deleteTestimonialDialog)),
      t('pages.Testimonials.successDeleteTestimonial'),
      t('pages.Testimonials.errorDeleteTestimonial'),
      enqueueSnackbar,
      closeSnackbar,
    );

    setDeleteTestimonialDialog('');
  };

  const deleteTestimonialById = async (testimonialId: string) => {
    setOpenDeleteDialog(true);
    setDeleteTestimonialDialog(testimonialId);
  };

  const [deleteTestimonialDialog, setDeleteTestimonialDialog] =
    useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openPublishDialog, setOpenPublishDialog] = useState<boolean>(false);
  const [listTagSelected, setListTagSelected] = useState<
    {
      tag: string;
      urls: string[];
      allPage: boolean;
      option?:
        | {
            actif: boolean;
            key: {
              value: string;
              type: string;
            }[];
          }
        | undefined;
    }[]
  >([]);

  const [hoverNumber, setHoverNumber] = useState<number>(0);

  useEffect(() => {
    setHoverNumber(
      testimonial?.displayProperties?.find((temp) => {
        return temp.tagAssociated === deleteTag;
      })?.hoverNumber ?? 0,
    );
  }, [testimonial]);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      minWidth: 'fit-content',
      minHeight: 'fit-content',
      background: '#F7F8FD',
      boxShadow: '2px 2px 4px rgba(7, 7, 33, 0.14)',
      borderRadius: '20px',
      color: 'black',
      padding: '15px',
    },
  }));

  const removeTag = async () => {
    await handleNotification(
      dispatch(
        updateTestimonial({
          _id: testimonial._id,
          tags: testimonial.tags.filter((tag) => tag !== deleteTag),
          displayProperties: testimonial.displayProperties.filter((temp) => {
            return temp.tagAssociated !== deleteTag;
          }),
        }),
      ),
      t('pages.Testimonials.successUnpublishedTestimonial'),
      t('pages.Testimonials.errorUnpublishedTestimonial'),
      enqueueSnackbar,
      closeSnackbar,
    );
  };

  const saveToPublish = async () => {
    listTagSelected.map((tag) => {
      dispatch(
        publishTestimonialOnTagsAndUrl({
          idTestimonial: testimonial._id,
          tag: tag.tag,
        }),
      );
    });
  };

  const { user }: { user: User | null } = useAppSelector(
    ({ auth: { user } }) => ({
      user,
    }),
  );

  const [content, setContent] = useState<string>(
    testimonial.content ? testimonial.content : '',
  );

  const [clickedMobile, setClickedMobile] = useState<boolean>(true);

  const [warning, setWarning] = useState<boolean>(false);

  const [clickedDesktop, setClickedDesktop] = useState<boolean>(true);

  useEffect(() => {
    if (user?.settings.pluginSettings.enableOnMobile) {
      if (!clickedMobile && !clickedDesktop) {
        setWarning(true);
      } else {
        setWarning(false);
      }
    } else {
      if (!clickedDesktop) {
        setWarning(true);
      } else {
        setWarning(false);
      }
    }
  }, [clickedDesktop, clickedMobile]);

  useEffect(() => {
    if (testimonial.content) {
      if (testimonial.content.length > 100) {
        setContent(testimonial.content.slice(0, 100) + '...');
      }
    }
  }, [testimonial.content]);

  const onHoverAction = () => {
    if (testimonial.content) {
      setContent(testimonial.content);
    }
  };

  const onMouseLeaveAction = () => {
    if (testimonial.content) {
      setContent(testimonial.content.slice(0, 30) + '...');
    }
  };

  useEffect(() => {
    testimonial.displayProperties?.map((temp) => {
      if (temp.tagAssociated === deleteTag) {
        setClickedMobile(temp.onMobile);
        setClickedDesktop(temp.onDesktop);
      }
    });
  }, []);

  const updateDisplayDevice = async (
    mobileState: boolean,
    desktopState: boolean,
  ) => {
    if (testimonial._id && deleteTag) {
      dispatch(
        updateDisplayPropertiesDevice({
          id: testimonial._id,
          onDesktop: desktopState,
          onMobile: mobileState,
          tagAssociated: deleteTag,
        }),
      );
    }
  };

  const HtmlTooltip2 = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      minWidth: 'fit-content',
      minHeight: 'fit-content',
      background: 'white',
      boxShadow: '2px 2px 4px rgba(7, 7, 33, 0.14)',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'black',
      padding: '17px',
      gap: '15px',
    },
  }));

  // transformer testimonial.information en un début de testimonial.information suivi d'un ... si il dépasse les 30 caractere

  const cardActions = (
    <div className={classes.idleButton}>
      <ButtonBase
        className={classes.testimonialCardActionsEdit}
        onClick={handleOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12C16 13.1046 16.8954 14 18 14Z"
            fill="#060640"
          />
        </svg>
      </ButtonBase>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={classes.menuPopOver}
      >
        <MenuItem
          onClick={() => {
            editTestimonial();
            handleClose();
          }}
        >
          {t('pages.Testimonials.actions.edit')}
        </MenuItem>
        {deleteTag && (
          <MenuItem
            onClick={() => {
              removeTag();
              handleClose();
            }}
          >
            {t('pages.Testimonials.actions.unpublish')}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            deleteTestimonialById(testimonial._id);
            handleClose();
          }}
          style={{ color: 'red' }}
        >
          {t('common.delete')}
        </MenuItem>
      </Menu>

      <Tooltip
        open={isTooltipDesktopOpen}
        title={<div>{t('pages.Testimonials.displayOnDesktop')}</div>}
      >
        <ButtonBase
          onClick={() => {
            updateDisplayDevice(clickedMobile, !clickedDesktop);
            setClickedDesktop(!clickedDesktop);
          }}
          onMouseEnter={() => handleMouseEnter('desktop')}
          onMouseLeave={() => handleMouseLeave('desktop')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.5592 4.45203C20.2655 4.15078 19.9126 4 19.4999 4H4.5C4.08751 4 3.73448 4.15078 3.44057 4.45203C3.14681 4.75338 3 5.11542 3 5.53851V16C3 16.4232 3.14681 16.7852 3.44057 17.0866C3.73448 17.3879 4.08751 17.5384 4.5 17.5384H9.60007C9.60007 17.7819 9.55003 18.0322 9.45001 18.2884C9.35006 18.5448 9.25004 18.7691 9.15002 18.9613C9.05013 19.1538 9.00012 19.2946 9.00012 19.3843C9.00012 19.5509 9.05946 19.6955 9.17823 19.817C9.29693 19.9389 9.43757 20 9.60007 20H14.4001C14.5626 20 14.7032 19.9389 14.8219 19.817C14.9408 19.6955 15.0001 19.551 15.0001 19.3843C15.0001 19.3012 14.95 19.1617 14.85 18.9662C14.75 18.7708 14.65 18.543 14.5501 18.2836C14.4501 18.024 14.4001 17.7756 14.4001 17.5384H19.5002C19.9126 17.5384 20.2656 17.3879 20.5593 17.0866C20.8531 16.7853 21 16.4233 21 16V5.53851C21.0001 5.11542 20.8531 4.75338 20.5592 4.45203ZM19.8 13.5385C19.8 13.6218 19.7703 13.6938 19.7109 13.7548C19.6515 13.8156 19.5812 13.846 19.4999 13.846H4.5C4.41879 13.846 4.34846 13.8156 4.28909 13.7548C4.22975 13.6936 4.20004 13.6218 4.20004 13.5385V5.53851C4.20004 5.45516 4.22966 5.38309 4.28909 5.3222C4.34849 5.26141 4.41883 5.23087 4.5 5.23087H19.5001C19.5813 5.23087 19.6517 5.26131 19.7109 5.3222C19.7703 5.38312 19.8 5.45519 19.8 5.53851V13.5385Z"
              fill={clickedDesktop ? '#060640' : 'grey'}
            />
          </svg>
        </ButtonBase>
      </Tooltip>

      <Tooltip
        open={isTooltipMobileOpen}
        title={<div>{t('pages.Testimonials.displayOnMobile')}</div>}
      >
        <ButtonBase
          onClick={() => {
            updateDisplayDevice(!clickedMobile, clickedDesktop);
            setClickedMobile(!clickedMobile);
          }}
          onMouseEnter={() => handleMouseEnter('mobile')}
          onMouseLeave={() => handleMouseLeave('mobile')}
          disabled={!user?.settings.pluginSettings.enableOnMobile}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
          >
            <path
              d="M10.9556 0.534411C10.593 0.178006 10.1632 0 9.66665 0H2.33345C1.8368 0 1.40717 0.178153 1.04431 0.534411C0.681454 0.890472 0.5 1.31243 0.5 1.79991V16.2C0.5 16.6874 0.681454 17.1093 1.04431 17.4655C1.40717 17.8218 1.8368 18 2.33345 18H9.66665C10.1631 18 10.593 17.8216 10.9556 17.4655C11.3185 17.1093 11.5 16.6874 11.5 16.2V1.79991C11.5 1.31243 11.3187 0.890669 10.9556 0.534411ZM4.85424 1.79991H7.14611C7.29892 1.79991 7.37512 1.87492 7.37512 2.02495C7.37512 2.17493 7.29887 2.25004 7.14611 2.25004H4.85424C4.70148 2.25004 4.62508 2.17493 4.62508 2.02495C4.62503 1.87492 4.70143 1.79991 4.85424 1.79991ZM6.80954 16.9946C6.585 17.2147 6.3153 17.3249 6.0001 17.3249C5.68505 17.3249 5.41535 17.2147 5.19091 16.9946C4.96646 16.7741 4.85424 16.5091 4.85424 16.2C4.85424 15.8905 4.96661 15.6257 5.19091 15.4054C5.4152 15.1853 5.6851 15.0747 6.0001 15.0747C6.31535 15.0747 6.58505 15.1853 6.80954 15.4054C7.03394 15.6257 7.14606 15.8905 7.14606 16.2C7.14606 16.5095 7.03379 16.7741 6.80954 16.9946ZM10.1252 13.9501C10.1252 14.0718 10.0799 14.1777 9.98907 14.2665C9.89812 14.3555 9.79096 14.3998 9.667 14.3998H2.33345C2.20934 14.3998 2.10193 14.3554 2.01123 14.2665C1.92053 14.1777 1.87518 14.0718 1.87518 13.9501V4.05015C1.87518 3.92829 1.92048 3.82274 2.01123 3.73378C2.10198 3.64488 2.20934 3.60011 2.33345 3.60011H9.66665C9.79096 3.60011 9.89822 3.64468 9.98907 3.73378C10.0799 3.82279 10.125 3.92829 10.125 4.05015V13.9501H10.1252V13.9501Z"
              fill={
                !user?.settings.pluginSettings.enableOnMobile
                  ? 'grey'
                  : clickedMobile
                  ? '#060640'
                  : 'grey'
              }
            />
          </svg>
        </ButtonBase>
      </Tooltip>

      {warning && (
        <HtmlTooltip2
          title={
            <React.Fragment>
              <div className={classes.typography}>
                {t('pages.Testimonials.warning')}
              </div>
              <div className={classes.typography}>
                {t('pages.Testimonials.warningNotPublished')}
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginBottom: '-10px',
                }}
              >
                <div
                  className={classes.typography}
                  style={{ marginRight: '10px' }}
                >
                  {t('pages.Testimonials.askPublish')}
                </div>

                <ButtonBase
                  onClick={() => {
                    updateDisplayDevice(!clickedMobile, clickedDesktop);
                    setClickedMobile(!clickedMobile);
                  }}
                  disabled={!user?.settings.pluginSettings.enableOnMobile}
                  style={
                    clickedMobile &&
                    user?.settings.pluginSettings.enableOnMobile
                      ? {
                          borderRadius: '50%',
                          boxShadow: 'rgba(39,62,172, 0.2) 0px 0px 2px 0.1px',
                          height: '26px',
                          width: '26px',
                        }
                      : { borderRadius: '50%', height: '26px', width: '26px' }
                  }
                >
                  <SmartphoneIcon
                    style={
                      clickedMobile &&
                      user?.settings.pluginSettings.enableOnMobile
                        ? { color: '#273EAC', width: '22px', height: '22px' }
                        : { color: 'grey', width: '22px', height: '22px' }
                    }
                  />
                </ButtonBase>

                <ButtonBase
                  onClick={() => {
                    updateDisplayDevice(clickedMobile, !clickedDesktop);
                    setClickedDesktop(!clickedDesktop);
                  }}
                  style={
                    !clickedDesktop
                      ? { borderRadius: '50%', height: '26px', width: '26px' }
                      : {
                          borderRadius: '50%',
                          boxShadow: 'rgba(39,62,172, 0.2) 0px 0px 2px 0.1px',
                          height: '26px',
                          width: '26px',
                        }
                  }
                >
                  <ComputerIcon
                    style={
                      !clickedDesktop
                        ? { color: 'grey', width: '22px', height: '22px' }
                        : { color: '#273EAC', width: '22px', height: '22px' }
                    }
                  />
                </ButtonBase>
              </div>

              <div
                className={classes.typography}
                style={{ marginBottom: '-10px' }}
              >
                {t('pages.Testimonials.or')}
              </div>
              <ButtonBase
                style={{ color: '#cc1016' }}
                onClick={() => {
                  removeTag();
                }}
                className={classes.typography}
              >
                {t('pages.Testimonials.actions.unpublish')}
              </ButtonBase>
            </React.Fragment>
          }
        >
          <ButtonBase
            style={{
              width: '26px',
              height: '26px',
            }}
          >
            <WarningIcon
              style={{ color: '#cc1016', width: '20px', height: '20px' }}
            />
          </ButtonBase>
        </HtmlTooltip2>
      )}
    </div>
  );

  const [information, setInformation] = useState<string>(
    testimonial.information ? testimonial.information : '',
  );

  useEffect(() => {
    if (testimonial.information) {
      if (testimonial.information.length > 30) {
        setInformation(testimonial.information.slice(0, 30) + '...');
      }
    }
  }, [testimonial.information]);

  const LazyVideo = lazy(() => import('./LazyVideo'));

  return (
    <div
      key={testimonial._id}
      className={classes.testimonialCardContainer}
      ref={ref}
      onClick={onClick}
      style={onClick ? { cursor: 'pointer' } : {}}
    >
      <div className={classes.content}>
        {testimonial.information && (
          <>
            {testimonial.information?.length > 30 ? (
              <Tooltip
                open={isTooltipOpen}
                title={<div>{testimonial.information}</div>}
              >
                <div className={`${classes.contactContainer}`}>
                  <Typography
                    className={classes.contactText}
                    onMouseEnter={() => handleMouseEnter('information')}
                    onMouseLeave={() => handleMouseLeave('information')}
                    style={{ cursor: 'pointer' }}
                  >
                    {information}
                  </Typography>
                </div>
              </Tooltip>
            ) : (
              <div className={`${classes.contactContainer}`}>
                <Typography className={classes.contactText}>
                  {testimonial.information}
                </Typography>
              </div>
            )}
          </>
        )}

        <div className={`${classes.contentTestimonial}`}>
          {testimonial.testimonialType === 'video' ? (
            <div className={classes.video}>
              {testimonial.videoImage ? (
                <div style={{ position: 'relative' }}>
                  <img src={testimonial.videoImage} alt="Video Thumbnail" />
                  <img src={VideoIcon} className={classes.videoIconImg} />
                </div>
              ) : (
                <Suspense fallback={<CircularProgress />}>
                  <LazyVideo testimonial={testimonial} isDraggable={false} />
                </Suspense>
              )}
            </div>
          ) : (
            <p
              className={classes.quote}
              onMouseEnter={() => {
                if (onHoverToDisplayContent) {
                  onHoverAction();
                }
              }}
              onMouseLeave={() => {
                if (onHoverToDisplayContent) {
                  onMouseLeaveAction();
                }
              }}
            >
              {content}
            </p>
          )}
        </div>
      </div>
      <HtmlTooltip title={t('pages.Testimonials.hoverNumber') as string}>
        <div className={classes.iconStatisticsHover}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.7992 11.2814C19.8615 9.69108 18.6008 8.41327 17.0172 7.44801C15.4335 6.48271 13.7612 6 12 6C10.2388 6 8.56642 6.48252 6.9827 7.44801C5.39895 8.41327 4.13831 9.69108 3.20082 11.2814C3.06695 11.5244 3 11.764 3 12.0002C3 12.2364 3.06695 12.476 3.20082 12.7189C4.13831 14.3093 5.39895 15.5872 6.9827 16.5522C8.56642 17.5177 10.2388 18 12 18C13.7612 18 15.4335 17.5193 17.0172 16.5575C18.6008 15.5957 19.8618 14.3161 20.7992 12.7189C20.9331 12.476 21 12.2365 21 12.0002C21 11.764 20.9331 11.5244 20.7992 11.2814ZM9.84528 8.43232C10.4446 7.81082 11.1627 7.49996 11.9999 7.49996C12.1338 7.49996 12.2477 7.54871 12.3415 7.64587C12.4351 7.74307 12.4822 7.86117 12.4822 8.00004C12.4822 8.13901 12.4351 8.25704 12.3417 8.35409C12.248 8.4514 12.134 8.5 12.0001 8.5C11.4243 8.5 10.9319 8.71191 10.5235 9.13543C10.115 9.55922 9.91079 10.0696 9.91079 10.6668C9.91079 10.8058 9.86379 10.9238 9.7701 11.0211C9.67627 11.1184 9.56249 11.167 9.42859 11.167C9.29458 11.167 9.18076 11.1183 9.08697 11.0211C8.99314 10.9238 8.94628 10.8057 8.94628 10.6668C8.94628 9.79878 9.24603 9.05385 9.84528 8.43232ZM16.3645 15.4015C15.0218 16.245 13.567 16.667 12 16.667C10.433 16.667 8.97826 16.2453 7.63564 15.4015C6.29303 14.5576 5.17639 13.4239 4.28579 12.0002C5.30364 10.3612 6.57931 9.1354 8.11278 8.32304C7.70432 9.04517 7.50002 9.82651 7.50002 10.6667C7.50002 11.9516 7.94039 13.0503 8.82089 13.9637C9.70139 14.877 10.7612 15.3335 12 15.3335C13.2389 15.3335 14.2984 14.8768 15.1791 13.9637C16.0598 13.0506 16.5 11.9516 16.5 10.6667C16.5 9.82651 16.2957 9.04506 15.8872 8.32304C17.4206 9.1354 18.6963 10.3613 19.7141 12.0002C18.8237 13.4239 17.7071 14.5576 16.3645 15.4015Z"
              fill="black"
            />
          </svg>
          {hoverNumber + testimonial.statistics.hoverNumber}
        </div>
      </HtmlTooltip>

      {deleteTag && cardActions}

      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDeleteTestimonialDialog('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.deleteDialog}
      >
        <ButtonBase
          style={{ position: 'absolute', right: '10px', top: '10px' }}
          onClick={() => {
            setOpenDeleteDialog(false);
            setDeleteTestimonialDialog('');
          }}
        >
          <CloseIcon style={{ color: '#414354', borderRadius: '50%' }} />
        </ButtonBase>
        <div id="alert-dialog-title" className={classes.dialogContainer}>
          <Typography className={classes.dialogTitle}>
            {t('pages.Testimonials.confirmDeleteTestimonialTitle')}
          </Typography>

          <Typography className={classes.dialogDeleteTypography}>
            {t('pages.Testimonials.confirmDeleteTestimonial')}
          </Typography>
          <TextField
            onChange={(e) => {
              if (e.target.value == 'DELETE') {
                setDeleteTemp(true);
              } else {
                setDeleteTemp(false);
              }
            }}
            className={classes.textFieldDeletePage}
            placeholder={t('pages.Testimonials.titleOfPage')}
          ></TextField>
          <ButtonBase
            onClick={() => {
              confirmDeleteTestimonial();
            }}
            className={classes.dialogActionDeleteButton}
            disabled={deleteTemp ? false : true}
            style={
              deleteTemp
                ? {
                    background: '#273EAC',
                    color: 'white',
                    border: '1px solid #273EAC',
                  }
                : {
                    background: 'none',
                    color: 'grey',
                    border: '1px solid grey',
                  }
            }
          >
            <DoneIcon />
            {t('common.validate')}
          </ButtonBase>
        </div>
      </Dialog>

      <Dialog
        open={openPublishDialog}
        onClose={() => {
          setOpenPublishDialog(false);
          setListTagSelected([]);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('pages.Testimonials.addLinkedPage')}
        </DialogTitle>

        <DialogContent>
          <Autocomplete
            multiple
            id="tags-standard"
            options={
              user?.settings.pluginSettings.tagsList.filter((tag) => {
                return testimonial.tags.indexOf(tag.tag) === -1;
              })
                ? user?.settings.pluginSettings.tagsList.filter((tag) => {
                    return testimonial.tags.indexOf(tag.tag) === -1;
                  })
                : []
            }
            getOptionLabel={(option) => option.tag}
            onChange={(_, value) => {
              setListTagSelected(
                value as {
                  tag: string;
                  urls: string[];
                  allPage: boolean;
                  option?:
                    | {
                        actif: boolean;
                        key: {
                          value: string;
                          type: string;
                        }[];
                      }
                    | undefined;
                }[],
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Tag"
                placeholder="Home page.."
              />
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button
            color={'primary'}
            onClick={() => {
              saveToPublish();
              setOpenPublishDialog(false);
            }}
          >
            {t('pages.Testimonials.actions.publish')}
          </Button>
          <Button
            onClick={() => {
              setListTagSelected([]);
              setOpenPublishDialog(false);
            }}
          >
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      {closestEdge && <DropIndicator edge={closestEdge} gap="12px" />}
    </div>
  );
};

export default TestimonialCardWebsite;
