import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../hooks/store';
import { createUseStyles } from 'react-jss';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
  ButtonBase,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import backgroundContactUs from '../assets/backgroundContactUs.avif';
import { Testimonial } from 'src/slices/testimonial';
import { testimonialsSelector } from 'src/store';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = createUseStyles({
  item: {
    display: 'flex',
    backgroundColor: '#fff',
    textAlign: 'center',
    borderRadius: '8px',
    padding: '24px',
    gap: '20px',
    flexDirection: 'column',
  },
  subItem: {
    backgroundColor: 'rgba(237, 240, 255, 0.50)',
    paddingTop: '16px',
    paddingRight: '32px',
    paddingLeft: '32px',
    paddingBottom: '20px',
    alignItems: 'center',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  statisticsPage: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '30px',
    paddingLeft: '30px',
    paddingRight: '48px',
    paddingBottom: '30px',
    flexDirection: 'column',
    overflowY: 'scroll',
    '@media (max-height: 800px)': {
      paddingTop: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '@media (max-height: 700px)': {
      paddingTop: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  statisticsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    width: '100%',
    flex: 1,
  },

  iconContainer: {
    display: 'flex',
    gap: '22px',
    flexDirection: 'row',
    alignSelf: 'center',
    width: '100%',
  },
  titleTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    color: '#060640',
  },
  numberTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    color: '#060640',
  },
  descriptionTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
  },
  typographyPage: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    color: '#060640',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '25px',
    width: '100%',
  },
  statisticsItem: {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
  },
  addContentButton: {
    background: '#273EAC',
    borderRadius: '8px',
    display: 'flex',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    width: '100%',
    height: '48px',
    paddingLeft: '20px',
    paddingRight: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  namePageContainer: {
    display: 'flex',
    alignSelf: 'start',
    width: '100%',
    justifyContent: 'space-between',
  },
  linearProgress: {
    backgroundColor: 'white',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#273EAC',
      borderRadius: '80px',
    },
  },
  dateSelectorButton: {
    border: '1px solid #CED3E8',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#060640',
    width: '240px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    padding: '16px',
  },
  selectContainer: {
    display: 'flex',
    gap: '10px',
  },
  buttonContainer: {
    display: 'flex',
    border: '1px solid #060640',
    borderRadius: '4px',
    overflow: 'hidden',
    background: '#EDF0FF',
  },
  buttonSelector: {
    width: '50px',
    color: '#060640',
    transition: 'background-color 0.3s ease-in-out',
    '&.actif': {
      background: 'white',
    },
  },
  centerButton: {
    border: '1px solid #060640',
    borderTop: 'none',
    borderBottom: 'none',
  },
  buttonSelectorAll: {
    fontSize: '14px',
  },
});

export const Dashboard = () => {
  const classes = useStyles();
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const testimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );
  const { t } = useTranslation();

  const [anchorEle, setAnchorEle] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEle);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEle(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEle(null);
  };

  const [anchorElTopPage, setAnchorElTopPage] =
    React.useState<null | HTMLElement>(null);
  const openTopPage = Boolean(anchorElTopPage);
  const handleClickTopPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElTopPage(event.currentTarget);
  };
  const handleCloseTopPage = () => {
    setAnchorElTopPage(null);
  };

  const [rangeDate, setRangeDate] = useState<string>('lastWeek');

  const [rangeDateTopPage, setRangeDateTopPage] = useState<string>('lastWeek');

  const [startDateValue, setStartDateValue] = useState<Date>(() => {
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 14);
    return lastWeek;
  });

  const [startDateTopPageValue, setStartDateTopPageValue] = useState<Date>(
    () => {
      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 14);
      return lastWeek;
    },
  );

  const [rangeDateTypography, setRangeDateTypography] = useState<string>(
    t('pages.Dashboard.lastWeek'),
  );

  const [rangeDateTopPageTypography, setRangeDateTopPageTypography] =
    useState<string>(t('pages.Dashboard.lastWeek'));

  const [switchButton, setSwitchButton] = useState<string>('all');

  const [switchButtonTopPage, setSwitchButtonTopPage] = useState<string>('all');

  const [totalVideoPlayed, setTotalVideoPlayed] = useState(0);

  const [totalSecondPlayed, setTotalSecondPlayed] = useState(0);

  const [totalViewedPages, setTotalViewedPages] = useState<number>(0);

  const [totalPluginViewed, setTotalPluginViewed] = useState<number>(0);

  const [totalHoveredTestimonial, setTotalHoveredTestimonial] =
    useState<number>(0);

  const [totalVideoPlayedOnMobile, setTotalVideoPlayedOnMobile] = useState(0);

  const [totalSecondPlayedOnMobile, setTotalSecondPlayedOnMobile] = useState(0);

  const [totalViewedPagesOnMobile, setTotalViewedPagesOnMobile] =
    useState<number>(0);

  const [totalPluginViewedOnMobile, setTotalPluginViewedOnMobile] =
    useState<number>(0);

  const [totalHoveredTestimonialOnMobile, setTotalHoveredTestimonialOnMobile] =
    useState<number>(0);

  useEffect(() => {
    if (rangeDate === 'lastWeek') {
      setRangeDateTypography(t('pages.Dashboard.lastWeek'));
      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 14);
      setStartDateValue(lastWeek);
    } else if (rangeDate === 'lastMonth') {
      setRangeDateTypography(t('pages.Dashboard.lastMonth'));
      const lastMonth = new Date();
      lastMonth.setDate(lastMonth.getDate() - 30);
      setStartDateValue(lastMonth);
    } else if (rangeDate === 'lastTrimester') {
      setRangeDateTypography(t('pages.Dashboard.lastTrimester'));
      const lastQuarter = new Date();
      lastQuarter.setDate(lastQuarter.getDate() - 90);
      setStartDateValue(lastQuarter);
    } else if (rangeDate === 'lastYear') {
      setRangeDateTypography(t('pages.Dashboard.lastYear'));
      const lastYear = new Date();
      lastYear.setDate(lastYear.getDate() - 365);
      setStartDateValue(lastYear);
    }
  }, [rangeDate]);

  useEffect(() => {
    if (rangeDateTopPage === 'lastWeek') {
      setRangeDateTopPageTypography(t('pages.Dashboard.lastWeek'));
      setTopPages([]);
      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 14);
      setStartDateTopPageValue(lastWeek);
    } else if (rangeDateTopPage === 'lastMonth') {
      setTopPages([]);
      setRangeDateTopPageTypography(t('pages.Dashboard.lastMonth'));
      const lastMonth = new Date();
      lastMonth.setDate(lastMonth.getDate() - 30);
      setStartDateTopPageValue(lastMonth);
    } else if (rangeDateTopPage === 'lastTrimester') {
      setTopPages([]);
      setRangeDateTopPageTypography(t('pages.Dashboard.lastTrimester'));
      const lastQuarter = new Date();
      lastQuarter.setDate(lastQuarter.getDate() - 90);
      setStartDateTopPageValue(lastQuarter);
    } else if (rangeDateTopPage === 'lastYear') {
      setTopPages([]);
      setRangeDateTopPageTypography(t('pages.Dashboard.lastYear'));
      const lastYear = new Date();
      lastYear.setDate(lastYear.getDate() - 365);
      setStartDateTopPageValue(lastYear);
    }
  }, [rangeDateTopPage]);

  useEffect(() => {
    let totalViewedTemp = 0;
    let totalViewedMobileTemp = 0;
    let totalVideoPlayedTemp = 0;
    let totalVideoPlayedMobileTemp = 0;
    user?.statistics.map((statistic) => {
      if (statistic.lastUpdated) {
        const x = new Date(statistic.lastUpdated);
        const y = new Date(startDateValue);

        if (x > y) {
          const totalViewed = statistic.totalPluginViewed ?? 0;
          const totalViewedOnMobile = statistic.totalPluginViewedOnMobile ?? 0;

          const totalVideoPlayed = statistic.totalVideoPlayed ?? 0;
          const totalVideoPlayedOnMobile =
            statistic.totalVideoPlayedOnMobile ?? 0;
          if (statistic.totalPluginViewed != undefined) {
            totalViewedTemp += totalViewed;
            totalViewedMobileTemp += totalViewedOnMobile;
          }
          if (statistic.totalVideoPlayed) {
            totalVideoPlayedTemp += totalVideoPlayed;
            totalVideoPlayedMobileTemp += totalVideoPlayedOnMobile;
          }
        }
      }
    });
    setTotalPluginViewed(totalViewedTemp);
    setTotalPluginViewedOnMobile(totalViewedMobileTemp);
    setTotalVideoPlayed(totalVideoPlayedTemp);
    setTotalVideoPlayedOnMobile(totalVideoPlayedMobileTemp);
  }, [startDateValue]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [finalMinute, setFinalMinute] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [finalSeconds, setFinalSeconds] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [finalMinuteMobile, setFinalMinuteMobile] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [finalSecondsMobile, setFinalSecondsMobile] = useState<number>(0);

  useEffect(() => {
    const minutes = Math.floor(totalSecondPlayed / 60);
    const seconds = totalSecondPlayed - minutes * 60;
    setFinalMinute(minutes);
    setFinalSeconds(seconds);
  }, [totalSecondPlayed]);

  useEffect(() => {
    const minutes = Math.floor(totalSecondPlayedOnMobile / 60);
    const seconds = totalSecondPlayedOnMobile - minutes * 60;
    setFinalMinuteMobile(minutes);
    setFinalSecondsMobile(seconds);
  }, [totalSecondPlayedOnMobile]);

  const [topPages, setTopPages] = useState<
    { tag: string; totalViewed: number; totalHovered: number }[]
  >([]);

  const [topPagesOnMobile, setTopPagesOnMobile] = useState<
    { tag: string; totalViewedOnMobile: number; totalHoveredOnMobile: number }[]
  >([]);

  const [topTotalPages, setTopTotalPages] = useState<
    { tag: string; totalViewed: number; totalHovered: number }[]
  >([]);

  useEffect(() => {
    let totalSecondPlayedTemp = 0;
    let totalTestimonialHoveredTemp = 0;
    let totalSecondPlayedTempOnMobile = 0;
    let totalTestimonialHoveredTempOnMobile = 0;
    testimonials.map((testimonial) => {
      if (testimonial.stats) {
        testimonial.stats.map((stats) => {
          const x = new Date(stats.lastUpdate);
          const y = new Date(startDateValue);

          if (x > y) {
            if (testimonial.testimonialType === 'video') {
              if (stats.secondsPlayedVideo > 0) {
                totalSecondPlayedTemp += stats.secondsPlayedVideo;
              }
              if (stats.secondsPlayedVideoOnMobile > 0) {
                totalSecondPlayedTempOnMobile +=
                  stats.secondsPlayedVideoOnMobile;
              }
            }
            totalTestimonialHoveredTemp += stats.hoverNumberPerPeriode ?? 0;
            totalTestimonialHoveredTempOnMobile +=
              stats.hoverNumberPerPeriodeOnMobile ?? 0;
          }
        });
      }
    });
    setTotalSecondPlayed(totalSecondPlayedTemp);
    setTotalHoveredTestimonial(totalTestimonialHoveredTemp);
    setTotalSecondPlayedOnMobile(totalSecondPlayedTempOnMobile);
    setTotalHoveredTestimonialOnMobile(totalTestimonialHoveredTempOnMobile);
  }, [startDateValue, testimonials]);

  const clickedHelp = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    Intercom('show');`;
    document.body.appendChild(script);
    script.onload = () => {
      script.remove();
    };
  };

  useEffect(() => {
    if (window.location.origin.includes('staging')) {
      user?.settings.pluginSettings.tagsList.map((tag) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let maxsurvol = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let maxviewed = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let maxclicked = 0;
        console.log('---------------------------');
        console.log(tag.tag);
        if (tag.statistics)
          tag?.statistics.map((stats) => {
            if (stats.lastUpdated) {
              const x = new Date(stats.lastUpdated);
              const y = new Date(startDateValue);
              console.log(stats);
              if (x > y) {
                maxsurvol += stats.totalTestimonialHovered ?? 0;
                maxviewed += stats.totalPluginViewed ?? 0;
                maxclicked += stats.totalTestimonialClicked ?? 0;

                maxsurvol += stats.totalTestimonialHoveredOnMobile ?? 0;
                maxviewed += stats.totalPluginViewedOnMobile ?? 0;
                maxclicked += stats.totalTestimonialClickedOnMobile ?? 0;
              }
            }
          });
        // console.log('survol : ', maxsurvol);
        // console.log('viewed : ', maxviewed);
        // console.log('clicked : ', maxclicked);

        console.log('---------------------------');
      });
    }
  }, [user?.settings.pluginSettings.tagsList, startDateValue]);

  // useEffect(() => {
  //   console.log('====================================');
  //   testimonials
  //     .filter((testimonial) => {
  //       return testimonial.testimonialType === 'video';
  //     })
  //     .map((testimonials) => {
  //       let maxClicked = 0;
  //       if (testimonials.stats) {
  //         testimonials.stats.map((stats) => {
  //           const x = new Date(stats.lastUpdate);
  //           const y = new Date(startDateValue);
  //           if (x > y) {
  //             maxClicked += stats.clickedNumberPerPeriode ?? 0;
  //             maxClicked += stats.clickedNumberPerPeriodeOnMobile ?? 0;
  //           }
  //         });
  //       }
  //       console.log('testimonials :', testimonials.content);
  //       console.log('clicked :', maxClicked);
  //     });
  //   console.log('====================================');
  // });

  useEffect(() => {
    const totalViewedPerTestimonial: {
      tag: string;
      totalViewed: number;
      totalHovered: number;
      totalViewedOnMobile: number;
      totalHoveredOnMobile: number;
    }[] = [];

    const totalViewedTotalTemp: {
      tag: string;
      totalViewed: number;
      totalHovered: number;
    }[] = [];
    let totalViewedPagesTemp = 0;
    let totalViewedPagesTempOnMobile = 0;
    user?.settings.pluginSettings.tagsList.map((tag) => {
      let totalViewedTemp = 0;
      let totalHoveredTemp = 0;
      let totalViewedTempOnMobile = 0;
      let totalHoveredTempOnMobile = 0;
      tag.statistics?.forEach((statistic) => {
        if (statistic.lastUpdated === undefined) return;
        const x = new Date(statistic.lastUpdated);
        const y = new Date(startDateTopPageValue);
        if (x > y) {
          totalViewedPagesTemp += statistic.totalPluginViewed ?? 0;
          totalViewedTemp += statistic.totalPluginViewed ?? 0;
          totalHoveredTemp += statistic.totalTestimonialHovered ?? 0;
          totalViewedPagesTempOnMobile +=
            statistic.totalPluginViewedOnMobile ?? 0;
          totalViewedTempOnMobile += statistic.totalPluginViewedOnMobile ?? 0;
          totalHoveredTempOnMobile +=
            statistic.totalTestimonialHoveredOnMobile ?? 0;
        }
      });
      totalViewedPerTestimonial.push({
        tag: tag.tag,
        totalViewed: totalViewedTemp,
        totalHovered: totalHoveredTemp,
        totalViewedOnMobile: totalViewedTempOnMobile,
        totalHoveredOnMobile: totalHoveredTempOnMobile,
      });
    });
    const totalViewedSorted = totalViewedPerTestimonial
      .sort((a, b) => b.totalViewed - a.totalViewed)
      .slice(0, 5);

    const totalViewedSortedMobile = totalViewedPerTestimonial
      .sort((a, b) => b.totalViewedOnMobile - a.totalViewedOnMobile)
      .slice(0, 5);

    totalViewedPerTestimonial.forEach((tag) => {
      totalViewedTotalTemp.push({
        tag: tag.tag,
        totalViewed: tag.totalViewed + tag.totalViewedOnMobile,
        totalHovered: tag.totalHovered + tag.totalHoveredOnMobile,
      });
    });

    const totalViewedPerTestimonialSorted = totalViewedTotalTemp
      .sort((a, b) => b.totalViewed - a.totalViewed)
      .slice(0, 5);

    setTopTotalPages(totalViewedPerTestimonialSorted);
    setTopPages(totalViewedSorted);
    setTopPagesOnMobile(totalViewedSortedMobile);
    setTotalViewedPages(totalViewedPagesTemp);
    setTotalViewedPagesOnMobile(totalViewedPagesTempOnMobile);
  }, [startDateTopPageValue]);

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      background: 'white',
      minWidth: 240,
      color: '#060640',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
      },
    },
  }));

  return user === null ? (
    <></>
  ) : (
    <div className={classes.statisticsPage}>
      <div className={classes.statisticsContainer}>
        <Paper className={classes.item} style={{ flex: '38%' }}>
          <div className={classes.iconContainer}>
            <Paper className={classes.subItem} style={{ flex: '1' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <g clipPath="url(#clip0_498_761)">
                  <path
                    d="M38 48H10C7.34881 47.9968 4.80712 46.9422 2.93244 45.0676C1.05776 43.1929 0.00317572 40.6512 0 38L0 10C0.00317572 7.34881 1.05776 4.80712 2.93244 2.93244C4.80712 1.05776 7.34881 0.00317572 10 0L38 0C40.6512 0.00317572 43.1929 1.05776 45.0676 2.93244C46.9422 4.80712 47.9968 7.34881 48 10V38C47.9968 40.6512 46.9422 43.1929 45.0676 45.0676C43.1929 46.9422 40.6512 47.9968 38 48ZM10 4C8.4087 4 6.88258 4.63214 5.75736 5.75736C4.63214 6.88258 4 8.4087 4 10V38C4 39.5913 4.63214 41.1174 5.75736 42.2426C6.88258 43.3679 8.4087 44 10 44H38C39.5913 44 41.1174 43.3679 42.2426 42.2426C43.3679 41.1174 44 39.5913 44 38V10C44 8.4087 43.3679 6.88258 42.2426 5.75736C41.1174 4.63214 39.5913 4 38 4H10ZM18.684 34.01C17.8503 34.0072 17.032 33.7844 16.312 33.364C15.6007 32.9569 15.0103 32.3681 14.6013 31.6579C14.1922 30.9477 13.9792 30.1416 13.984 29.322V18.678C13.9833 17.8583 14.1982 17.0528 14.6072 16.3424C15.0161 15.632 15.6047 15.0416 16.3138 14.6305C17.023 14.2194 17.8278 14.002 18.6475 14.0001C19.4672 13.9983 20.273 14.2121 20.984 14.62L31.54 19.89C32.2732 20.2858 32.8873 20.8706 33.3185 21.5836C33.7497 22.2966 33.9823 23.112 33.9923 23.9452C34.0023 24.7784 33.7893 25.5991 33.3753 26.3222C32.9614 27.0454 32.3615 27.6447 31.638 28.058L20.886 33.432C20.2154 33.8145 19.456 34.0138 18.684 34.01ZM18.634 18.01C18.5238 18.01 18.4155 18.0389 18.32 18.094C18.2163 18.1517 18.1302 18.2365 18.071 18.3394C18.0118 18.4423 17.9817 18.5593 17.984 18.678V29.322C17.9847 29.4389 18.0158 29.5536 18.0742 29.6549C18.1326 29.7562 18.2164 29.8405 18.3172 29.8996C18.4181 29.9587 18.5326 29.9906 18.6495 29.992C18.7664 29.9935 18.8817 29.9646 18.984 29.908L29.736 24.532C29.8161 24.4694 29.8798 24.3882 29.9216 24.2956C29.9634 24.2029 29.982 24.1015 29.976 24C29.9785 23.881 29.9483 23.7637 29.8887 23.6607C29.8291 23.5577 29.7424 23.4731 29.638 23.416L19.09 18.146C18.9525 18.0616 18.7953 18.0147 18.634 18.01Z"
                    fill="#FFC001"
                  />
                  <path
                    d="M38 48H10C7.34881 47.9968 4.80712 46.9422 2.93244 45.0676C1.05776 43.1929 0.00317572 40.6512 0 38L0 10C0.00317572 7.34881 1.05776 4.80712 2.93244 2.93244C4.80712 1.05776 7.34881 0.00317572 10 0L38 0C40.6512 0.00317572 43.1929 1.05776 45.0676 2.93244C46.9422 4.80712 47.9968 7.34881 48 10V38C47.9968 40.6512 46.9422 43.1929 45.0676 45.0676C43.1929 46.9422 40.6512 47.9968 38 48ZM10 4C8.4087 4 6.88258 4.63214 5.75736 5.75736C4.63214 6.88258 4 8.4087 4 10V38C4 39.5913 4.63214 41.1174 5.75736 42.2426C6.88258 43.3679 8.4087 44 10 44H38C39.5913 44 41.1174 43.3679 42.2426 42.2426C43.3679 41.1174 44 39.5913 44 38V10C44 8.4087 43.3679 6.88258 42.2426 5.75736C41.1174 4.63214 39.5913 4 38 4H10ZM18.684 34.01C17.8503 34.0072 17.032 33.7844 16.312 33.364C15.6007 32.9569 15.0103 32.3681 14.6013 31.6579C14.1922 30.9477 13.9792 30.1416 13.984 29.322V18.678C13.9833 17.8583 14.1982 17.0528 14.6072 16.3424C15.0161 15.632 15.6047 15.0416 16.3138 14.6305C17.023 14.2194 17.8278 14.002 18.6475 14.0001C19.4672 13.9983 20.273 14.2121 20.984 14.62L31.54 19.89C32.2732 20.2858 32.8873 20.8706 33.3185 21.5836C33.7497 22.2966 33.9823 23.112 33.9923 23.9452C34.0023 24.7784 33.7893 25.5991 33.3753 26.3222C32.9614 27.0454 32.3615 27.6447 31.638 28.058L20.886 33.432C20.2154 33.8145 19.456 34.0138 18.684 34.01ZM18.634 18.01C18.5238 18.01 18.4155 18.0389 18.32 18.094C18.2163 18.1517 18.1302 18.2365 18.071 18.3394C18.0118 18.4423 17.9817 18.5593 17.984 18.678V29.322C17.9847 29.4389 18.0158 29.5536 18.0742 29.6549C18.1326 29.7562 18.2164 29.8405 18.3172 29.8996C18.4181 29.9587 18.5326 29.9906 18.6495 29.992C18.7664 29.9935 18.8817 29.9646 18.984 29.908L29.736 24.532C29.8161 24.4694 29.8798 24.3882 29.9216 24.2956C29.9634 24.2029 29.982 24.1015 29.976 24C29.9785 23.881 29.9483 23.7637 29.8887 23.6607C29.8291 23.5577 29.7424 23.4731 29.638 23.416L19.09 18.146C18.9525 18.0616 18.7953 18.0147 18.634 18.01Z"
                    fill="#273EAC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_498_761">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography className={classes.numberTypography}>
                {testimonials.filter((t) => t.testimonialType === 'video')
                  .length ?? 0}
              </Typography>
              <Typography className={classes.descriptionTypography}>
                {t('pages.Dashboard.videos')}
              </Typography>
            </Paper>

            <Paper className={classes.subItem} style={{ flex: '1' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <g clipPath="url(#clip0_498_785)">
                  <path
                    d="M16.875 15C17.9106 15 18.75 14.1606 18.75 13.125C18.75 12.0894 17.9106 11.25 16.875 11.25C15.8394 11.25 15 12.0894 15 13.125C15 14.1606 15.8394 15 16.875 15ZM24.375 15C25.4106 15 26.25 14.1606 26.25 13.125C26.25 12.0894 25.4106 11.25 24.375 11.25C23.3394 11.25 22.5 12.0894 22.5 13.125C22.5 14.1606 23.3394 15 24.375 15ZM38.625 15H37.5V18.75H38.625C41.7266 18.75 44.25 21.2734 44.25 24.375V31.875C44.25 34.9766 41.7271 37.5 38.625 37.5H36.75V40.924L31.7008 37.5H24.375C21.2734 37.5 18.75 34.9766 18.75 31.875V30H15V31.875C15 37.0444 19.2056 41.25 24.375 41.25H30.5492L40.5 48V41.0618C44.7738 40.191 48 36.4027 48 31.875V24.375C48 19.2056 43.7944 15 38.625 15ZM11.25 13.125C11.25 12.0894 10.4106 11.25 9.375 11.25C8.33944 11.25 7.5 12.0894 7.5 13.125C7.5 14.1606 8.33944 15 9.375 15C10.4106 15 11.25 14.1606 11.25 13.125ZM17.5 26.25H24.375C29.5444 26.25 33.75 22.0444 33.75 16.875V9.375C33.75 4.20563 29.5444 0 24.375 0H9.375C4.20563 0 0 4.20563 0 9.375V16.875C0 21.4024 3.22622 25.1904 7.5 26.0617V33.75L17.5 26.25ZM3.75 16.875V9.375C3.75 6.27338 6.27338 3.75 9.375 3.75H24.375C27.4766 3.75 30 6.27338 30 9.375V16.875C30 19.9766 27.4766 22.5 24.375 22.5H16.25L11.25 26.25V22.5H9.375C6.27338 22.5 3.75 19.9766 3.75 16.875Z"
                    fill="#FFC001"
                  />
                  <path
                    d="M16.875 15C17.9106 15 18.75 14.1606 18.75 13.125C18.75 12.0894 17.9106 11.25 16.875 11.25C15.8394 11.25 15 12.0894 15 13.125C15 14.1606 15.8394 15 16.875 15ZM24.375 15C25.4106 15 26.25 14.1606 26.25 13.125C26.25 12.0894 25.4106 11.25 24.375 11.25C23.3394 11.25 22.5 12.0894 22.5 13.125C22.5 14.1606 23.3394 15 24.375 15ZM38.625 15H37.5V18.75H38.625C41.7266 18.75 44.25 21.2734 44.25 24.375V31.875C44.25 34.9766 41.7271 37.5 38.625 37.5H36.75V40.924L31.7008 37.5H24.375C21.2734 37.5 18.75 34.9766 18.75 31.875V30H15V31.875C15 37.0444 19.2056 41.25 24.375 41.25H30.5492L40.5 48V41.0618C44.7738 40.191 48 36.4027 48 31.875V24.375C48 19.2056 43.7944 15 38.625 15ZM11.25 13.125C11.25 12.0894 10.4106 11.25 9.375 11.25C8.33944 11.25 7.5 12.0894 7.5 13.125C7.5 14.1606 8.33944 15 9.375 15C10.4106 15 11.25 14.1606 11.25 13.125ZM17.5 26.25H24.375C29.5444 26.25 33.75 22.0444 33.75 16.875V9.375C33.75 4.20563 29.5444 0 24.375 0H9.375C4.20563 0 0 4.20563 0 9.375V16.875C0 21.4024 3.22622 25.1904 7.5 26.0617V33.75L17.5 26.25ZM3.75 16.875V9.375C3.75 6.27338 6.27338 3.75 9.375 3.75H24.375C27.4766 3.75 30 6.27338 30 9.375V16.875C30 19.9766 27.4766 22.5 24.375 22.5H16.25L11.25 26.25V22.5H9.375C6.27338 22.5 3.75 19.9766 3.75 16.875Z"
                    fill="#273EAC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_498_785">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography className={classes.numberTypography}>
                {testimonials.filter((t) => t.testimonialType === 'written')
                  .length ?? 0}
              </Typography>
              <Typography className={classes.descriptionTypography}>
                {t('pages.Dashboard.written')}
              </Typography>
            </Paper>

            <Paper className={classes.subItem} style={{ flex: '1' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M38 44H10C7.34881 43.9968 4.80712 42.9422 2.93244 41.0676C1.05776 39.1929 0.00317572 36.6512 0 34V14C0.00317572 11.3488 1.05776 8.80712 2.93244 6.93244C4.80712 5.05776 7.34881 4.00318 10 4H38C40.6512 4.00318 43.1929 5.05776 45.0676 6.93244C46.9422 8.80712 47.9968 11.3488 48 14V34C47.9968 36.6512 46.9422 39.1929 45.0676 41.0676C43.1929 42.9422 40.6512 43.9968 38 44ZM10 8C8.4087 8 6.88258 8.63214 5.75736 9.75736C4.63214 10.8826 4 12.4087 4 14V34C4 35.5913 4.63214 37.1174 5.75736 38.2426C6.88258 39.3679 8.4087 40 10 40H38C39.5913 40 41.1174 39.3679 42.2426 38.2426C43.3679 37.1174 44 35.5913 44 34V14C44 12.4087 43.3679 10.8826 42.2426 9.75736C41.1174 8.63214 39.5913 8 38 8H10ZM38 26H22C21.4696 26 20.9609 25.7893 20.5858 25.4142C20.2107 25.0391 20 24.5304 20 24C20 23.4696 20.2107 22.9609 20.5858 22.5858C20.9609 22.2107 21.4696 22 22 22H38C38.5304 22 39.0391 22.2107 39.4142 22.5858C39.7893 22.9609 40 23.4696 40 24C40 24.5304 39.7893 25.0391 39.4142 25.4142C39.0391 25.7893 38.5304 26 38 26ZM10 26H14C14.5304 26 15.0391 25.7893 15.4142 25.4142C15.7893 25.0391 16 24.5304 16 24C16 23.4696 15.7893 22.9609 15.4142 22.5858C15.0391 22.2107 14.5304 22 14 22H10C9.46957 22 8.96086 22.2107 8.58579 22.5858C8.21071 22.9609 8 23.4696 8 24C8 24.5304 8.21071 25.0391 8.58579 25.4142C8.96086 25.7893 9.46957 26 10 26ZM26 36H10C9.46957 36 8.96086 35.7893 8.58579 35.4142C8.21071 35.0391 8 34.5304 8 34C8 33.4696 8.21071 32.9609 8.58579 32.5858C8.96086 32.2107 9.46957 32 10 32H26C26.5304 32 27.0391 32.2107 27.4142 32.5858C27.7893 32.9609 28 33.4696 28 34C28 34.5304 27.7893 35.0391 27.4142 35.4142C27.0391 35.7893 26.5304 36 26 36ZM34 36H38C38.5304 36 39.0391 35.7893 39.4142 35.4142C39.7893 35.0391 40 34.5304 40 34C40 33.4696 39.7893 32.9609 39.4142 32.5858C39.0391 32.2107 38.5304 32 38 32H34C33.4696 32 32.9609 32.2107 32.5858 32.5858C32.2107 32.9609 32 33.4696 32 34C32 34.5304 32.2107 35.0391 32.5858 35.4142C32.9609 35.7893 33.4696 36 34 36Z"
                  fill="#273EAC"
                />
              </svg>
              <Typography className={classes.numberTypography}>
                {user.settings.pluginSettings.tagsList.length ?? 0}
              </Typography>
              <Typography className={classes.descriptionTypography}>
                {t('pages.Dashboard.pages')}
              </Typography>
            </Paper>
          </div>

          <Paper
            className={classes.subItem}
            style={{
              paddingTop: '24px',
              width: '100%',
              alignItems: 'start',
            }}
          >
            <Typography className={classes.titleTypography}>
              {t('pages.Dashboard.addContent')}
            </Typography>
            <Typography
              className={classes.descriptionTypography}
              style={{ textAlign: 'initial' }}
            >
              {t('pages.Dashboard.addContentDescription')}
            </Typography>
            <Link className={classes.addContentButton} to={'/website'}>
              {t('pages.Dashboard.addContent')}
            </Link>
          </Paper>
        </Paper>

        <Paper className={classes.item} style={{ flex: '62%' }}>
          <div className={classes.titleContainer}>
            <Typography className={classes.titleTypography}>
              {t('pages.Dashboard.statistics')}
            </Typography>
            <div className={classes.selectContainer}>
              <div className={classes.buttonContainer}>
                <ButtonBase
                  className={`${classes.buttonSelector} ${
                    switchButton === 'all' ? 'actif' : ''
                  } ${classes.titleTypography} ${classes.buttonSelectorAll}`}
                  onClick={() => {
                    setSwitchButton('all');
                  }}
                >
                  All
                </ButtonBase>
                <ButtonBase
                  className={`${classes.buttonSelector} ${
                    switchButton === 'mobile' ? 'actif' : ''
                  } ${classes.centerButton}`}
                  onClick={() => {
                    setSwitchButton('mobile');
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="18"
                    viewBox="0 0 12 18"
                    fill="none"
                  >
                    <path
                      d="M10.9556 0.534411C10.593 0.178006 10.1632 0 9.66665 0H2.33345C1.8368 0 1.40717 0.178153 1.04431 0.534411C0.681454 0.890472 0.5 1.31243 0.5 1.79991V16.2C0.5 16.6874 0.681454 17.1093 1.04431 17.4655C1.40717 17.8218 1.8368 18 2.33345 18H9.66665C10.1631 18 10.593 17.8216 10.9556 17.4655C11.3185 17.1093 11.5 16.6874 11.5 16.2V1.79991C11.5 1.31243 11.3187 0.890669 10.9556 0.534411ZM4.85424 1.79991H7.14611C7.29892 1.79991 7.37512 1.87492 7.37512 2.02495C7.37512 2.17493 7.29887 2.25004 7.14611 2.25004H4.85424C4.70148 2.25004 4.62508 2.17493 4.62508 2.02495C4.62503 1.87492 4.70143 1.79991 4.85424 1.79991ZM6.80954 16.9946C6.585 17.2147 6.3153 17.3249 6.0001 17.3249C5.68505 17.3249 5.41535 17.2147 5.19091 16.9946C4.96646 16.7741 4.85424 16.5091 4.85424 16.2C4.85424 15.8905 4.96661 15.6257 5.19091 15.4054C5.4152 15.1853 5.6851 15.0747 6.0001 15.0747C6.31535 15.0747 6.58505 15.1853 6.80954 15.4054C7.03394 15.6257 7.14606 15.8905 7.14606 16.2C7.14606 16.5095 7.03379 16.7741 6.80954 16.9946ZM10.1252 13.9501C10.1252 14.0718 10.0799 14.1777 9.98907 14.2665C9.89812 14.3555 9.79096 14.3998 9.667 14.3998H2.33345C2.20934 14.3998 2.10193 14.3554 2.01123 14.2665C1.92053 14.1777 1.87518 14.0718 1.87518 13.9501V4.05015C1.87518 3.92829 1.92048 3.82274 2.01123 3.73378C2.10198 3.64488 2.20934 3.60011 2.33345 3.60011H9.66665C9.79096 3.60011 9.89822 3.64468 9.98907 3.73378C10.0799 3.82279 10.125 3.92829 10.125 4.05015V13.9501H10.1252V13.9501Z"
                      fill={'#060640'}
                    />
                  </svg>
                </ButtonBase>
                <ButtonBase
                  className={`${classes.buttonSelector} ${
                    switchButton === 'desktop' ? 'actif' : ''
                  }`}
                  onClick={() => {
                    setSwitchButton('desktop');
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.5592 4.45203C20.2655 4.15078 19.9126 4 19.4999 4H4.5C4.08751 4 3.73448 4.15078 3.44057 4.45203C3.14681 4.75338 3 5.11542 3 5.53851V16C3 16.4232 3.14681 16.7852 3.44057 17.0866C3.73448 17.3879 4.08751 17.5384 4.5 17.5384H9.60007C9.60007 17.7819 9.55003 18.0322 9.45001 18.2884C9.35006 18.5448 9.25004 18.7691 9.15002 18.9613C9.05013 19.1538 9.00012 19.2946 9.00012 19.3843C9.00012 19.5509 9.05946 19.6955 9.17823 19.817C9.29693 19.9389 9.43757 20 9.60007 20H14.4001C14.5626 20 14.7032 19.9389 14.8219 19.817C14.9408 19.6955 15.0001 19.551 15.0001 19.3843C15.0001 19.3012 14.95 19.1617 14.85 18.9662C14.75 18.7708 14.65 18.543 14.5501 18.2836C14.4501 18.024 14.4001 17.7756 14.4001 17.5384H19.5002C19.9126 17.5384 20.2656 17.3879 20.5593 17.0866C20.8531 16.7853 21 16.4233 21 16V5.53851C21.0001 5.11542 20.8531 4.75338 20.5592 4.45203ZM19.8 13.5385C19.8 13.6218 19.7703 13.6938 19.7109 13.7548C19.6515 13.8156 19.5812 13.846 19.4999 13.846H4.5C4.41879 13.846 4.34846 13.8156 4.28909 13.7548C4.22975 13.6936 4.20004 13.6218 4.20004 13.5385V5.53851C4.20004 5.45516 4.22966 5.38309 4.28909 5.3222C4.34849 5.26141 4.41883 5.23087 4.5 5.23087H19.5001C19.5813 5.23087 19.6517 5.26131 19.7109 5.3222C19.7703 5.38312 19.8 5.45519 19.8 5.53851V13.5385Z"
                      fill={'#060640'}
                    />
                  </svg>
                </ButtonBase>
              </div>

              <div>
                <ButtonBase
                  onClick={handleClick}
                  className={classes.dateSelectorButton}
                >
                  {rangeDateTypography}
                  <KeyboardArrowDownIcon />
                </ButtonBase>
                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEle}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  disablePortal
                >
                  <MenuItem
                    onClick={() => {
                      setRangeDate('lastWeek');
                      handleClose();
                    }}
                  >
                    {t('pages.Dashboard.lastWeek')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRangeDate('lastMonth');
                      handleClose();
                    }}
                  >
                    {t('pages.Dashboard.lastMonth')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRangeDate('lastTrimester');
                      handleClose();
                    }}
                  >
                    {t('pages.Dashboard.lastTrimester')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRangeDate('lastYear');
                      handleClose();
                    }}
                  >
                    {t('pages.Dashboard.lastYear')}
                  </MenuItem>
                </StyledMenu>
              </div>
            </div>
          </div>
          <div className={classes.statsContainer}>
            <div className={classes.statsContainer}>
              <Paper
                className={classes.subItem}
                style={{ flex: 1, alignItems: 'flex-start' }}
              >
                <Typography className={classes.descriptionTypography}>
                  {t('pages.Dashboard.numberOfViews')}
                  <Tooltip
                    title={
                      <div>{t('pages.Dashboard.numberOfViewsTooltip')}</div>
                    }
                  >
                    <InfoOutlinedIcon
                      style={{
                        width: '15px',
                        height: '15px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Typography>

                <Typography
                  className={`${classes.numberTypography} ${classes.statisticsItem}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M20.7992 11.7814C19.8615 10.1911 18.6008 8.91327 17.0172 7.94801C15.4335 6.98271 13.7612 6.5 12 6.5C10.2388 6.5 8.56642 6.98252 6.9827 7.94801C5.39895 8.91327 4.13831 10.1911 3.20082 11.7814C3.06695 12.0244 3 12.264 3 12.5002C3 12.7364 3.06695 12.976 3.20082 13.2189C4.13831 14.8093 5.39895 16.0872 6.9827 17.0522C8.56642 18.0177 10.2388 18.5 12 18.5C13.7612 18.5 15.4335 18.0193 17.0172 17.0575C18.6008 16.0957 19.8618 14.8161 20.7992 13.2189C20.9331 12.976 21 12.7365 21 12.5002C21 12.264 20.9331 12.0244 20.7992 11.7814ZM9.84528 8.93232C10.4446 8.31082 11.1627 7.99996 11.9999 7.99996C12.1338 7.99996 12.2477 8.04871 12.3415 8.14587C12.4351 8.24307 12.4822 8.36117 12.4822 8.50004C12.4822 8.63901 12.4351 8.75704 12.3417 8.85409C12.248 8.9514 12.134 9 12.0001 9C11.4243 9 10.9319 9.21191 10.5235 9.63543C10.115 10.0592 9.91079 10.5696 9.91079 11.1668C9.91079 11.3058 9.86379 11.4238 9.7701 11.5211C9.67627 11.6184 9.56249 11.667 9.42859 11.667C9.29458 11.667 9.18076 11.6183 9.08697 11.5211C8.99314 11.4238 8.94628 11.3057 8.94628 11.1668C8.94628 10.2988 9.24603 9.55385 9.84528 8.93232ZM16.3645 15.9015C15.0218 16.745 13.567 17.167 12 17.167C10.433 17.167 8.97826 16.7453 7.63564 15.9015C6.29303 15.0576 5.17639 13.9239 4.28579 12.5002C5.30364 10.8612 6.57931 9.6354 8.11278 8.82304C7.70432 9.54517 7.50002 10.3265 7.50002 11.1667C7.50002 12.4516 7.94039 13.5503 8.82089 14.4637C9.70139 15.377 10.7612 15.8335 12 15.8335C13.2389 15.8335 14.2984 15.3768 15.1791 14.4637C16.0598 13.5506 16.5 12.4516 16.5 11.1667C16.5 10.3265 16.2957 9.54506 15.8872 8.82304C17.4206 9.6354 18.6963 10.8613 19.7141 12.5002C18.8237 13.9239 17.7071 15.0576 16.3645 15.9015Z"
                      fill="#273EAC"
                    />
                  </svg>
                  {switchButton === 'desktop' && <div>{totalPluginViewed}</div>}
                  {switchButton === 'mobile' && (
                    <div>{totalPluginViewedOnMobile}</div>
                  )}
                  {switchButton === 'all' && (
                    <div>{totalPluginViewed + totalPluginViewedOnMobile}</div>
                  )}
                </Typography>
              </Paper>

              <Paper
                className={classes.subItem}
                style={{ flex: 1, alignItems: 'flex-start' }}
              >
                <Typography className={classes.descriptionTypography}>
                  {t('pages.Dashboard.numberOfHovered')}
                  <Tooltip
                    title={
                      <div>{t('pages.Dashboard.numberOfHoveredTooltip')}</div>
                    }
                  >
                    <InfoOutlinedIcon
                      style={{
                        width: '15px',
                        height: '15px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Typography>
                <Typography
                  className={`${classes.numberTypography} ${classes.statisticsItem}`}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0363 17.3772H1.11515C0.819391 17.3772 0.53575 17.2913 0.326619 17.1386C0.117489 16.9858 0 16.7786 0 16.5626C0 16.3466 0.117489 16.1394 0.326619 15.9866C0.53575 15.8339 0.819391 15.748 1.11515 15.748H10.0363C10.3321 15.748 10.6157 15.8339 10.8248 15.9866C11.034 16.1394 11.1515 16.3466 11.1515 16.5626C11.1515 16.7786 11.034 16.9858 10.8248 17.1386C10.6157 17.2913 10.3321 17.3772 10.0363 17.3772Z"
                      fill="#273EAC"
                    />
                    <path
                      d="M5.25712 11.9455H0.584124C0.429205 11.9455 0.280631 11.8597 0.171086 11.7069C0.0615416 11.5542 0 11.347 0 11.131C0 10.9149 0.0615416 10.7077 0.171086 10.555C0.280631 10.4022 0.429205 10.3164 0.584124 10.3164H5.25712C5.41204 10.3164 5.56061 10.4022 5.67016 10.555C5.7797 10.7077 5.84124 10.9149 5.84124 11.131C5.84124 11.347 5.7797 11.5542 5.67016 11.7069C5.56061 11.8597 5.41204 11.9455 5.25712 11.9455Z"
                      fill="#273EAC"
                    />
                    <path
                      d="M9.5584 6.51778H1.06204C0.780373 6.51778 0.510238 6.43197 0.311066 6.27921C0.111894 6.12645 0 5.91926 0 5.70323C0 5.48719 0.111894 5.28001 0.311066 5.12725C0.510238 4.97449 0.780373 4.88867 1.06204 4.88867H9.5584C9.84007 4.88867 10.1102 4.97449 10.3094 5.12725C10.5086 5.28001 10.6204 5.48719 10.6204 5.70323C10.6204 5.91926 10.5086 6.12645 10.3094 6.27921C10.1102 6.43197 9.84007 6.51778 9.5584 6.51778Z"
                      fill="#273EAC"
                    />
                    <g clipPath="url(#clip0_14_898)">
                      <path
                        d="M15.7865 4.85966C14.9846 4.44614 14.0504 4.54805 13.3514 5.12327L6.72692 10.5842C5.919 11.25 5.70074 12.3658 6.19736 13.298C6.693 14.2305 7.72798 14.6489 8.71355 14.3167L11.5962 13.3453L12.5486 16.3007C12.8732 17.3081 13.8178 17.9098 14.8448 17.7638C14.9789 17.7447 15.1082 17.7139 15.2318 17.6723C16.0565 17.3944 16.6272 16.6367 16.6652 15.7156L17.0254 7.01921C17.0634 6.10143 16.5886 5.27417 15.7865 4.85966ZM15.4779 6.95202L15.1177 15.6484C15.0997 16.0936 14.7429 16.1788 14.6317 16.1947C14.5225 16.2103 14.1553 16.2279 14.0189 15.8047L12.8242 12.0971C12.6904 11.6819 12.2523 11.456 11.8463 11.5928L8.22815 12.8121C7.81333 12.9519 7.60932 12.6393 7.55676 12.5403C7.50419 12.4413 7.35825 12.0963 7.69762 11.8169L14.3226 6.35578C14.3997 6.29311 14.4765 6.25164 14.5511 6.22653C14.7972 6.14358 15.0142 6.23499 15.0879 6.27298C15.1837 6.3224 15.495 6.51766 15.4771 6.95118L15.4779 6.95202Z"
                        fill="#273EAC"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_14_898">
                        <rect
                          width="18.6274"
                          height="18.9655"
                          fill="white"
                          transform="matrix(0.947639 -0.319345 0.306711 0.951803 0.53125 5.94922)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  {switchButton === 'desktop' && (
                    <div>{totalHoveredTestimonial}</div>
                  )}
                  {switchButton === 'all' && (
                    <div>
                      {totalHoveredTestimonial +
                        totalHoveredTestimonialOnMobile}
                    </div>
                  )}
                  {switchButton === 'mobile' && (
                    <div>{totalHoveredTestimonialOnMobile}</div>
                  )}
                </Typography>
              </Paper>
            </div>
          </div>
          <div className={classes.statsContainer}>
            <Paper
              className={classes.subItem}
              style={{ flex: 1, alignItems: 'flex-start' }}
            >
              <Typography className={classes.descriptionTypography}>
                {t('pages.Dashboard.numberOfVideosPlayed')}
                <Tooltip
                  title={
                    <div>
                      {t('pages.Dashboard.numberOfVideosPlayedTooltip')}
                    </div>
                  }
                >
                  <InfoOutlinedIcon
                    style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                  />
                </Tooltip>
              </Typography>
              <Typography
                className={`${classes.numberTypography} ${classes.statisticsItem}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_498_761)">
                    <path
                      d="M19 24H5C3.6744 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 5C0.00158786 3.6744 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.6744 0.00158786 5 0L19 0C20.3256 0.00158786 21.5964 0.528882 22.5338 1.46622C23.4711 2.40356 23.9984 3.6744 24 5V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24ZM5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2H5ZM9.342 17.005C8.92513 17.0036 8.51601 16.8922 8.156 16.682C7.80034 16.4784 7.50515 16.184 7.30063 15.8289C7.09611 15.4738 6.9896 15.0708 6.992 14.661V9.339C6.99166 8.92914 7.09911 8.52641 7.30358 8.1712C7.50805 7.81599 7.80234 7.52079 8.15692 7.31524C8.5115 7.10968 8.9139 7.00099 9.32376 7.00007C9.73361 6.99915 10.1365 7.10604 10.492 7.31L15.77 9.945C16.1366 10.1429 16.4436 10.4353 16.6592 10.7918C16.8748 11.1483 16.9912 11.556 16.9962 11.9726C17.0012 12.3892 16.8946 12.7995 16.6877 13.1611C16.4807 13.5227 16.1808 13.8223 15.819 14.029L10.443 16.716C10.1077 16.9073 9.728 17.0069 9.342 17.005ZM9.317 9.005C9.26189 9.00498 9.20774 9.01946 9.16 9.047C9.10814 9.07585 9.0651 9.11826 9.0355 9.1697C9.00591 9.22114 8.99087 9.27966 8.992 9.339V14.661C8.99234 14.7195 9.00788 14.7768 9.03709 14.8274C9.0663 14.8781 9.10818 14.9202 9.15861 14.9498C9.20905 14.9794 9.2663 14.9953 9.32475 14.996C9.3832 14.9968 9.44084 14.9823 9.492 14.954L14.868 12.266C14.908 12.2347 14.9399 12.1941 14.9608 12.1478C14.9817 12.1015 14.991 12.0507 14.988 12C14.9893 11.9405 14.9742 11.8818 14.9444 11.8304C14.9146 11.7789 14.8712 11.7365 14.819 11.708L9.545 9.073C9.47624 9.03082 9.39763 9.00737 9.317 9.005Z"
                      fill="#FFC001"
                    />
                    <path
                      d="M19 24H5C3.6744 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 5C0.00158786 3.6744 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.6744 0.00158786 5 0L19 0C20.3256 0.00158786 21.5964 0.528882 22.5338 1.46622C23.4711 2.40356 23.9984 3.6744 24 5V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24ZM5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2H5ZM9.342 17.005C8.92513 17.0036 8.51601 16.8922 8.156 16.682C7.80034 16.4784 7.50515 16.184 7.30063 15.8289C7.09611 15.4738 6.9896 15.0708 6.992 14.661V9.339C6.99166 8.92914 7.09911 8.52641 7.30358 8.1712C7.50805 7.81599 7.80234 7.52079 8.15692 7.31524C8.5115 7.10968 8.9139 7.00099 9.32376 7.00007C9.73361 6.99915 10.1365 7.10604 10.492 7.31L15.77 9.945C16.1366 10.1429 16.4436 10.4353 16.6592 10.7918C16.8748 11.1483 16.9912 11.556 16.9962 11.9726C17.0012 12.3892 16.8946 12.7995 16.6877 13.1611C16.4807 13.5227 16.1808 13.8223 15.819 14.029L10.443 16.716C10.1077 16.9073 9.728 17.0069 9.342 17.005ZM9.317 9.005C9.26189 9.00498 9.20774 9.01946 9.16 9.047C9.10814 9.07585 9.0651 9.11826 9.0355 9.1697C9.00591 9.22114 8.99087 9.27966 8.992 9.339V14.661C8.99234 14.7195 9.00788 14.7768 9.03709 14.8274C9.0663 14.8781 9.10818 14.9202 9.15861 14.9498C9.20905 14.9794 9.2663 14.9953 9.32475 14.996C9.3832 14.9968 9.44084 14.9823 9.492 14.954L14.868 12.266C14.908 12.2347 14.9399 12.1941 14.9608 12.1478C14.9817 12.1015 14.991 12.0507 14.988 12C14.9893 11.9405 14.9742 11.8818 14.9444 11.8304C14.9146 11.7789 14.8712 11.7365 14.819 11.708L9.545 9.073C9.47624 9.03082 9.39763 9.00737 9.317 9.005Z"
                      fill="#273EAC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_498_761">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {switchButton === 'desktop' && <div>{totalVideoPlayed}</div>}
                {switchButton === 'all' && (
                  <div>{totalVideoPlayed + totalVideoPlayedOnMobile}</div>
                )}
                {switchButton === 'mobile' && (
                  <div>{totalVideoPlayedOnMobile}</div>
                )}
              </Typography>
            </Paper>
            {/* 
            <Paper
              className={classes.subItem}
              style={{ flex: 1, alignItems: 'flex-start' }}
            >
              <Typography className={classes.descriptionTypography}>
                {t('pages.Dashboard.numberOfSecondsViewed')}
                <Tooltip title={<div>{t('common.zindex')}</div>}>
                  <InfoOutlinedIcon style={{ width: '15px', height: '15px' }} />
                </Tooltip>
              </Typography>

              <Typography
                className={`${classes.numberTypography} ${classes.statisticsItem}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.55544 5.84824C8.87104 4.96919 10.4178 4.5 12 4.5C14.121 4.50229 16.1545 5.34589 17.6543 6.84568C19.1541 8.34547 19.9977 10.379 20 12.5C20 14.0823 19.5308 15.629 18.6518 16.9446C17.7727 18.2602 16.5233 19.2855 15.0615 19.891C13.5997 20.4965 11.9911 20.655 10.4393 20.3463C8.88743 20.0376 7.46197 19.2757 6.34315 18.1569C5.22433 17.038 4.4624 15.6126 4.15372 14.0607C3.84504 12.5089 4.00347 10.9003 4.60897 9.43853C5.21447 7.97672 6.23985 6.72729 7.55544 5.84824ZM8.66658 17.4888C9.65328 18.1481 10.8133 18.5 12 18.5C13.5908 18.4982 15.1159 17.8655 16.2407 16.7407C17.3655 15.6159 17.9982 14.0908 18 12.5C18 11.3133 17.6481 10.1533 16.9888 9.16658C16.3295 8.17988 15.3925 7.41085 14.2961 6.95672C13.1997 6.5026 11.9933 6.38378 10.8295 6.61529C9.66558 6.8468 8.59648 7.41824 7.75736 8.25736C6.91825 9.09647 6.3468 10.1656 6.11529 11.3295C5.88378 12.4933 6.0026 13.6997 6.45673 14.7961C6.91085 15.8925 7.67989 16.8295 8.66658 17.4888ZM9.40001 12.8701L11 11.8701V9.6814C11 9.41618 11.1054 9.16183 11.2929 8.97429C11.4804 8.78675 11.7348 8.6814 12 8.6814C12.2652 8.6814 12.5196 8.78675 12.7071 8.97429C12.8947 9.16183 13 9.41618 13 9.6814V12.2321C13.0006 12.4585 12.9435 12.6814 12.8341 12.8797C12.7247 13.078 12.5666 13.2451 12.3747 13.3654L10.462 14.5654C10.3507 14.6352 10.2267 14.6823 10.0972 14.7042C9.96767 14.726 9.8351 14.7221 9.70707 14.6927C9.57904 14.6633 9.45806 14.6089 9.35104 14.5328C9.24401 14.4566 9.15305 14.3601 9.08334 14.2487C9.01358 14.1374 8.96643 14.0135 8.94458 13.8839C8.92274 13.7544 8.92664 13.6218 8.95604 13.4938C8.98545 13.3658 9.03979 13.2448 9.11597 13.1378C9.19215 13.0307 9.28867 12.9398 9.40001 12.8701Z"
                    fill="#273EAC"
                  />
                </svg>
                {switchButton === 'desktop' && (
                  <div>
                    {finalMinute} m {finalSeconds.toFixed(0)} s
                  </div>
                )}
                {switchButton === 'mobile' && (
                  <div>
                    {finalMinuteMobile} m {finalSecondsMobile.toFixed(0)} s
                  </div>
                )}
                {switchButton === 'all' && (
                  <div>
                    {finalMinute + finalMinuteMobile} m{' '}
                    {(finalSeconds + finalSecondsMobile).toFixed(0)} s
                  </div>
                )}
              </Typography>
            </Paper> */}
          </div>
        </Paper>
      </div>

      <div className={classes.statisticsContainer}>
        <Paper
          className={classes.item}
          style={{ flex: '58%', height: '300px' }}
        >
          <div className={classes.namePageContainer}>
            <Typography className={classes.titleTypography}>
              {t('pages.Dashboard.topPage')}
            </Typography>
            <div className={classes.selectContainer}>
              <div className={classes.buttonContainer}>
                <ButtonBase
                  className={`${classes.buttonSelector} ${
                    switchButtonTopPage === 'all' ? 'actif' : ''
                  } ${classes.titleTypography} ${classes.buttonSelectorAll}`}
                  onClick={() => {
                    setSwitchButtonTopPage('all');
                  }}
                >
                  All
                </ButtonBase>
                <ButtonBase
                  className={`${classes.buttonSelector} ${
                    switchButtonTopPage === 'mobile' ? 'actif' : ''
                  } ${classes.centerButton}`}
                  onClick={() => {
                    setSwitchButtonTopPage('mobile');
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="18"
                    viewBox="0 0 12 18"
                    fill="none"
                  >
                    <path
                      d="M10.9556 0.534411C10.593 0.178006 10.1632 0 9.66665 0H2.33345C1.8368 0 1.40717 0.178153 1.04431 0.534411C0.681454 0.890472 0.5 1.31243 0.5 1.79991V16.2C0.5 16.6874 0.681454 17.1093 1.04431 17.4655C1.40717 17.8218 1.8368 18 2.33345 18H9.66665C10.1631 18 10.593 17.8216 10.9556 17.4655C11.3185 17.1093 11.5 16.6874 11.5 16.2V1.79991C11.5 1.31243 11.3187 0.890669 10.9556 0.534411ZM4.85424 1.79991H7.14611C7.29892 1.79991 7.37512 1.87492 7.37512 2.02495C7.37512 2.17493 7.29887 2.25004 7.14611 2.25004H4.85424C4.70148 2.25004 4.62508 2.17493 4.62508 2.02495C4.62503 1.87492 4.70143 1.79991 4.85424 1.79991ZM6.80954 16.9946C6.585 17.2147 6.3153 17.3249 6.0001 17.3249C5.68505 17.3249 5.41535 17.2147 5.19091 16.9946C4.96646 16.7741 4.85424 16.5091 4.85424 16.2C4.85424 15.8905 4.96661 15.6257 5.19091 15.4054C5.4152 15.1853 5.6851 15.0747 6.0001 15.0747C6.31535 15.0747 6.58505 15.1853 6.80954 15.4054C7.03394 15.6257 7.14606 15.8905 7.14606 16.2C7.14606 16.5095 7.03379 16.7741 6.80954 16.9946ZM10.1252 13.9501C10.1252 14.0718 10.0799 14.1777 9.98907 14.2665C9.89812 14.3555 9.79096 14.3998 9.667 14.3998H2.33345C2.20934 14.3998 2.10193 14.3554 2.01123 14.2665C1.92053 14.1777 1.87518 14.0718 1.87518 13.9501V4.05015C1.87518 3.92829 1.92048 3.82274 2.01123 3.73378C2.10198 3.64488 2.20934 3.60011 2.33345 3.60011H9.66665C9.79096 3.60011 9.89822 3.64468 9.98907 3.73378C10.0799 3.82279 10.125 3.92829 10.125 4.05015V13.9501H10.1252V13.9501Z"
                      fill={'#060640'}
                    />
                  </svg>
                </ButtonBase>
                <ButtonBase
                  className={`${classes.buttonSelector} ${
                    switchButtonTopPage === 'desktop' ? 'actif' : ''
                  }`}
                  onClick={() => {
                    setSwitchButtonTopPage('desktop');
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.5592 4.45203C20.2655 4.15078 19.9126 4 19.4999 4H4.5C4.08751 4 3.73448 4.15078 3.44057 4.45203C3.14681 4.75338 3 5.11542 3 5.53851V16C3 16.4232 3.14681 16.7852 3.44057 17.0866C3.73448 17.3879 4.08751 17.5384 4.5 17.5384H9.60007C9.60007 17.7819 9.55003 18.0322 9.45001 18.2884C9.35006 18.5448 9.25004 18.7691 9.15002 18.9613C9.05013 19.1538 9.00012 19.2946 9.00012 19.3843C9.00012 19.5509 9.05946 19.6955 9.17823 19.817C9.29693 19.9389 9.43757 20 9.60007 20H14.4001C14.5626 20 14.7032 19.9389 14.8219 19.817C14.9408 19.6955 15.0001 19.551 15.0001 19.3843C15.0001 19.3012 14.95 19.1617 14.85 18.9662C14.75 18.7708 14.65 18.543 14.5501 18.2836C14.4501 18.024 14.4001 17.7756 14.4001 17.5384H19.5002C19.9126 17.5384 20.2656 17.3879 20.5593 17.0866C20.8531 16.7853 21 16.4233 21 16V5.53851C21.0001 5.11542 20.8531 4.75338 20.5592 4.45203ZM19.8 13.5385C19.8 13.6218 19.7703 13.6938 19.7109 13.7548C19.6515 13.8156 19.5812 13.846 19.4999 13.846H4.5C4.41879 13.846 4.34846 13.8156 4.28909 13.7548C4.22975 13.6936 4.20004 13.6218 4.20004 13.5385V5.53851C4.20004 5.45516 4.22966 5.38309 4.28909 5.3222C4.34849 5.26141 4.41883 5.23087 4.5 5.23087H19.5001C19.5813 5.23087 19.6517 5.26131 19.7109 5.3222C19.7703 5.38312 19.8 5.45519 19.8 5.53851V13.5385Z"
                      fill={'#060640'}
                    />
                  </svg>
                </ButtonBase>
              </div>

              <div>
                <ButtonBase
                  onClick={handleClickTopPage}
                  className={classes.dateSelectorButton}
                >
                  {rangeDateTopPageTypography}
                  <KeyboardArrowDownIcon />
                </ButtonBase>
                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorElTopPage}
                  open={openTopPage}
                  onClose={handleCloseTopPage}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  disablePortal
                >
                  <MenuItem
                    onClick={() => {
                      setRangeDateTopPage('lastWeek');
                      handleCloseTopPage();
                    }}
                  >
                    {t('pages.Dashboard.lastWeek')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRangeDateTopPage('lastMonth');
                      handleCloseTopPage();
                    }}
                  >
                    {t('pages.Dashboard.lastMonth')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRangeDateTopPage('lastTrimester');
                      handleCloseTopPage();
                    }}
                  >
                    {t('pages.Dashboard.lastTrimester')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRangeDateTopPage('lastYear');
                      handleCloseTopPage();
                    }}
                  >
                    {t('pages.Dashboard.lastYear')}
                  </MenuItem>
                </StyledMenu>
              </div>
            </div>
          </div>

          <Paper
            className={classes.subItem}
            style={{ alignItems: 'start', gap: '5px', display: 'flex' }}
          >
            <div className={classes.namePageContainer}>
              <Typography
                className={classes.typographyPage}
                style={{ flex: '90%', textAlignLast: 'start' }}
              >
                {t('pages.Dashboard.namePage')}
              </Typography>
              <div style={{ display: 'flex', flex: '20%' }}>
                <Typography
                  className={classes.typographyPage}
                  style={{ flex: '50%', textAlignLast: 'end' }}
                >
                  {t('pages.Dashboard.hover')}
                </Typography>
                <Typography
                  className={classes.typographyPage}
                  style={{ flex: '50%', textAlignLast: 'end' }}
                >
                  {t('pages.Dashboard.views')}
                </Typography>
              </div>
            </div>
            {switchButtonTopPage === 'desktop' && (
              <>
                {topPages.map((page, index) => {
                  return (
                    <div className={classes.progressContainer} key={index}>
                      <div className={classes.namePageContainer}>
                        <Typography
                          className={classes.typographyPage}
                          style={{ flex: '80%', textAlignLast: 'start' }}
                        >
                          {index + 1}. {page.tag}
                        </Typography>
                        <div style={{ display: 'flex', flex: '20%' }}>
                          <Typography
                            className={classes.typographyPage}
                            style={{ flex: '50%', textAlignLast: 'end' }}
                          >
                            {page.totalHovered}
                          </Typography>
                          <Typography
                            className={classes.typographyPage}
                            style={{ flex: '50%', textAlignLast: 'end' }}
                          >
                            {page.totalViewed}
                          </Typography>
                        </div>
                      </div>
                      <LinearProgress
                        className={classes.linearProgress}
                        variant="determinate"
                        value={(page.totalViewed * 100) / totalViewedPages}
                      />
                    </div>
                  );
                })}
                {topPages.length < 5 &&
                  Array(5 - topPages.length)
                    .fill(null)
                    .map((_, index) => {
                      return (
                        <div className={classes.progressContainer} key={index}>
                          <div
                            className={classes.namePageContainer}
                            style={{ filter: 'blur(3px)' }}
                          >
                            <Typography
                              className={classes.typographyPage}
                              style={{ flex: '80%', textAlignLast: 'start' }}
                            >
                              {index + 1 + topPages.length}. test
                            </Typography>
                            <div style={{ display: 'flex', flex: '20%' }}>
                              <Typography
                                className={classes.typographyPage}
                                style={{ flex: '50%', textAlignLast: 'end' }}
                              >
                                44
                              </Typography>
                              <Typography
                                className={classes.typographyPage}
                                style={{ flex: '50%', textAlignLast: 'end' }}
                              >
                                44
                              </Typography>
                            </div>
                          </div>
                          <LinearProgress
                            className={classes.linearProgress}
                            style={{ filter: 'blur(3px)' }}
                            variant="determinate"
                            value={10}
                          />
                        </div>
                      );
                    })}
              </>
            )}

            {switchButtonTopPage === 'mobile' && (
              <>
                {topPagesOnMobile.map((page, index) => {
                  return (
                    <div className={classes.progressContainer} key={index}>
                      <div className={classes.namePageContainer}>
                        <Typography
                          className={classes.typographyPage}
                          style={{ flex: '80%', textAlignLast: 'start' }}
                        >
                          {index + 1}. {page.tag}
                        </Typography>
                        <div style={{ display: 'flex', flex: '20%' }}>
                          <Typography
                            className={classes.typographyPage}
                            style={{ flex: '50%', textAlignLast: 'end' }}
                          >
                            {page.totalHoveredOnMobile}
                          </Typography>
                          <Typography
                            className={classes.typographyPage}
                            style={{ flex: '50%', textAlignLast: 'end' }}
                          >
                            {page.totalViewedOnMobile}
                          </Typography>
                        </div>
                      </div>
                      <LinearProgress
                        className={classes.linearProgress}
                        variant="determinate"
                        value={
                          (page.totalViewedOnMobile * 100) /
                          totalViewedPagesOnMobile
                        }
                      />
                    </div>
                  );
                })}
                {topPages.length < 5 &&
                  Array(5 - topPages.length)
                    .fill(null)
                    .map((_, index) => {
                      return (
                        <div className={classes.progressContainer} key={index}>
                          <div
                            className={classes.namePageContainer}
                            style={{ filter: 'blur(3px)' }}
                          >
                            <Typography
                              className={classes.typographyPage}
                              style={{ flex: '80%', textAlignLast: 'start' }}
                            >
                              {index + 1 + topPages.length}. test
                            </Typography>
                            <div style={{ display: 'flex', flex: '20%' }}>
                              <Typography
                                className={classes.typographyPage}
                                style={{ flex: '50%', textAlignLast: 'end' }}
                              >
                                44
                              </Typography>
                              <Typography
                                className={classes.typographyPage}
                                style={{ flex: '50%', textAlignLast: 'end' }}
                              >
                                44
                              </Typography>
                            </div>
                          </div>
                          <LinearProgress
                            className={classes.linearProgress}
                            style={{ filter: 'blur(3px)' }}
                            variant="determinate"
                            value={10}
                          />
                        </div>
                      );
                    })}
              </>
            )}

            {switchButtonTopPage === 'all' && (
              <>
                {topTotalPages.map((page, index) => {
                  return (
                    <div className={classes.progressContainer} key={index}>
                      <div className={classes.namePageContainer}>
                        <Typography
                          className={classes.typographyPage}
                          style={{ flex: '80%', textAlignLast: 'start' }}
                        >
                          {index + 1}. {page.tag}
                        </Typography>
                        <div style={{ display: 'flex', flex: '20%' }}>
                          <Typography
                            className={classes.typographyPage}
                            style={{ flex: '50%', textAlignLast: 'end' }}
                          >
                            {page.totalHovered}
                          </Typography>
                          <Typography
                            className={classes.typographyPage}
                            style={{ flex: '50%', textAlignLast: 'end' }}
                          >
                            {page.totalViewed}
                          </Typography>
                        </div>
                      </div>
                      <LinearProgress
                        className={classes.linearProgress}
                        variant="determinate"
                        value={
                          (page.totalViewed * 100) /
                          (totalViewedPages + totalViewedPagesOnMobile)
                        }
                      />
                    </div>
                  );
                })}
                {topPages.length < 5 &&
                  Array(5 - topPages.length)
                    .fill(null)
                    .map((_, index) => {
                      return (
                        <div className={classes.progressContainer} key={index}>
                          <div
                            className={classes.namePageContainer}
                            style={{ filter: 'blur(3px)' }}
                          >
                            <Typography
                              className={classes.typographyPage}
                              style={{ flex: '80%', textAlignLast: 'start' }}
                            >
                              {index + 1 + topPages.length}. test
                            </Typography>
                            <div style={{ display: 'flex', flex: '20%' }}>
                              <Typography
                                className={classes.typographyPage}
                                style={{ flex: '50%', textAlignLast: 'end' }}
                              >
                                44
                              </Typography>
                              <Typography
                                className={classes.typographyPage}
                                style={{ flex: '50%', textAlignLast: 'end' }}
                              >
                                44
                              </Typography>
                            </div>
                          </div>
                          <LinearProgress
                            className={classes.linearProgress}
                            style={{ filter: 'blur(3px)' }}
                            variant="determinate"
                            value={10}
                          />
                        </div>
                      );
                    })}
              </>
            )}
          </Paper>
        </Paper>

        <Paper
          className={classes.item}
          style={{
            background: `url(${backgroundContactUs})`,
            flex: '42%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            alignItems: 'flex-end',
            flexDirection: 'row',
            height: '300px',
            borderRadius: '14px',
          }}
        >
          <ButtonBase
            className={classes.addContentButton}
            onClick={() => {
              clickedHelp();
            }}
          >
            {t('pages.Dashboard.consultHelpCenter')}
          </ButtonBase>
        </Paper>
      </div>
    </div>
  );
};
