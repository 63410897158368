import { Alert, IconButton } from '@mui/material';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import React from 'react';
import { Flex } from 'src/components/basic/Flex';
import Close from '@mui/icons-material/Close';

interface Props {
  closeNotification: () => void;
  message: string;
  variant: boolean;
}

export const DisplayNotification: React.VFC<Props> = ({
  closeNotification,
  message,
  variant,
}) => {
  return (
    <Alert
      style={{ cursor: 'pointer', alignItems: 'center' }}
      onClose={() => closeNotification()}
      severity={variant ? 'success' : 'error'}
      action={
        <Flex>
          <IconButton onClick={() => closeNotification()}>
            <Close />
          </IconButton>
        </Flex>
      }
    >
      {message}
    </Alert>
  );
};

export const handleNotification = async (
  action: Promise<any>,
  successMessage: string,
  errorMessage: string,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey,
  closeSnackbar: (key?: SnackbarKey | undefined) => void,
) => {
  return await action
    .then((res) => {
      const message = res.error === undefined ? successMessage : errorMessage;

      const key = enqueueSnackbar(message, {
        content: (
          <DisplayNotification
            closeNotification={() => closeSnackbar(key)}
            message={message}
            variant={res.error == undefined}
          />
        ),
      });
    })
    .catch(() => {
      const message = errorMessage;
      const key = enqueueSnackbar(message, {
        content: (
          <DisplayNotification
            closeNotification={() => closeSnackbar(key)}
            message={message}
            variant={false}
          />
        ),
      });
    });
};
