import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import {
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import party from 'src/assets/party.gif';
import {
  SideBarHandleContext,
  TestimonialEditionHandleContext,
  TestimonialListHandleContext,
} from 'src/hooks/context';
import { useAppDispatch } from 'src/hooks/store';
import { Testimonial, updateTestimonial } from 'src/slices/testimonial';
import PlusIcon from '../../assets/plus.png';
import { theme } from '../../constants/theme';
import {
  DisplayLocation,
  EmbeddedSettings,
  Trigger,
  User,
  updateEmbeddedSetting,
  updatePluginSettings,
  updateTriggerAndDisplayLocationPluginSetting,
  updateUserStep,
} from '../../slices/auth';
import UpgradeDialog from '../UpgradeDialog';
import TestimonialListCard from './TestimonialsListCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Flex } from '../basic/Flex';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { HexColorPicker } from 'react-colorful';

const MAX_WIDTH = '50rem';
const useStyles = createUseStyles({
  testimonialsNoContent: {
    margin: 'auto',
    color: '#637381',
    textAlign: 'center',
  },
  TestimonialListItem: {
    display: 'inline-block',
  },
  buttonWrapper: {
    background: 'white',
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingLeft: '240px',
    zIndex: 4,
    width: '100%',
    // boxShadow: '0 -1px 30px 0 rgba(50, 50, 93, 0.05)',
    boxShadow:
      'rgba(255, 255, 255, 0.1) 0px -1px 1px 0px inset, rgba(50, 50, 93, 0.1) 0px -50px 100px -20px, rgba(0, 0, 0, 0.2) 0px -30px 60px -30px',
  },
  buttonWrapperInner: {
    padding: theme.spacing(2),
    maxWidth: MAX_WIDTH,
    margin: 'auto',
  },
  buttonCancel: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  cardPage: {
    background: 'white',
    borderRadius: '8px',
    width: '428px',
    minWidth: '428px',
    maxWidth: '428px',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '18px',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: 'none',
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#FFFFFF',
      borderRadius: '60px',
      opacity: '0.5',
      display: 'none',
    },
  },
  cardAddPage: {
    background: 'rgba(255, 255, 255, 0.50)',
    width: '427px',
    height: '100%',
    border: '1px dashed var(--Blue-3, #56CCF2);',
    borderRadius: '8px',
    minWidth: '427px',
    maxWidth: '427px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '30px',
    justifyContent: 'center',
  },
  tagName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '140%',
    color: '#060640',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    cursor: 'pointer',
  },
  moreOptionButton: {
    borderRadius: '50px',
    color: '#273EAC',
    width: '25px',
    height: '25px',
    justifyContent: 'flex-end',
  },
  deleteDialog: {
    zIndex: 3000,
    '& .MuiDialog-paper': {
      textAlign: 'center',
      overflow: 'hidden',
      zIndex: '2000',
      padding: '50px',
    },
  },
  dialogContainer: {
    position: 'relative',
    display: 'flex',
    width: '360px',
    alignSelf: 'center',
    flexDirection: 'column',
    paddingTop: '24px',
    gap: '25px',
    alignItems: 'center',
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '140%',
    color: '#060640',
    flex: 1,
  },
  dialogDeleteTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#060640',
    flex: 1,
  },
  dialogContent: {
    position: 'relative',
    top: '85px',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldEditTag: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },

    '& .MuiOutlinedInput-root': {
      width: '190px',
      height: '32px',
      borderRadius: '8px',
      border: '1px solid #CED3E8',
      background: 'white',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #CED3E8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #CED3E8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  tagEditionContainer: {
    display: 'flex',
    flex: 1,
    gap: '10px',
  },
  textFieldDeletePage: {
    alignItems: 'center',
    flex: 1,
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },
    '& .MuiOutlinedInput-root': {
      width: '350px',
      borderRadius: '8px',
      border: '1px solid #8F93B8',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #8F93B8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #8F93B8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  dialogActionDeleteButton: {
    borderRadius: '8px',
    width: '123px',
    padding: '10px',
    flex: 1,
  },
  saveButtonTagEdition: {
    color: 'white',
    height: '32px',
    width: '40px',
    borderRadius: '8px',
    background: '#273EAC',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '-0.4px',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
  },
  listContainer: {
    gap: '30px',
    maxHeight: 'calc(100vh - 20px)',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    overflowX: 'scroll',
    overflowY: 'visible',
    height: '100%',
    paddingBottom: '30px',
    paddingTop: '24px',
    paddingLeft: '20px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  buttonScrollLeft: {
    width: '80px',
    height: '80px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    color: '#273EAC',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '252px',
    rotate: '180deg',
  },
  buttonScrollRight: {
    width: '80px',
    height: '80px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    color: '#273EAC',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    right: '30px',
  },
  addPageTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    textAlign: 'center',
    color: '#273EAC',
  },
  testimonialCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
  },

  nextButton: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    color: 'white',
    backgroundColor: '#273EAC',
    borderRadius: '20px',
    width: '100px',
    height: '30px',
  },

  previousButton: {
    fontFamily: 'Montserrat',
    fontSize: '10px',
    textAlign: 'center',
    textDecoration: 'underline',
  },

  editNameContainer: {
    display: 'flex',
    width: '100%',
    background: 'rgba(237, 240, 255, 0.60)',
    borderRadius: '8px',
    height: '48px',
    padding: '8px 12px 8px 16px',
    alignItems: 'center',
    marginBottom: '16px',
    justifyContent: 'space-between',
  },

  editNameContainerTutorial: {
    display: 'flex',
    width: '100%',
    background: 'rgba(237, 240, 255, 0.60)',
    borderRadius: '8px',
    height: '48px',
    padding: '8px 12px 8px 16px',
    alignItems: 'center',
    marginBottom: '16px',
    gap: '10px',
    position: 'relative',
  },

  tutorialDialog: {
    zIndex: 1600,
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    },
    '& .MuiDialog-paper': {
      top: '8vh',
      width: '100%',
      background: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      gap: '50px',
      maxWidth: '100%',
      marginLeft: '282px',
      boxShadow: 'none',
    },
  },
  helpContainer: {
    borderRadius: '8px',
    background:
      'linear-gradient(0deg, rgba(57, 138, 247, 0.15) 0%, rgba(57, 138, 247, 0.15) 100%), var(--Blanc, #FFF)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '26px 16px 32px 16px',
    height: 'fit-content',
    width: '380px',
  },
  typographyHelp: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
  },
  typographyHelpTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
  },
  finishTitleTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '25px',
    lineHeight: '140%',
    color: '#060640',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  helpDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    paddingLeft: '16px',
  },

  helpDescriptionTypographyContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },

  descriptionIconError: {
    backgroundColor: 'red !important',
  },

  buttonHelp: {
    borderRadius: '8px',
    background: 'var(--Blue-1, #2F80ED)',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    textAlign: 'center',
    color: 'white',
    letterSpacing: '-0.4px',
    height: '48px',
    padding: '10px',
  },
  helpTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    marginBottom: '21px',
  },
  helpIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    background: '#2F80ED',
    justifyContent: 'center',
  },
  descriptionIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    background: '#F2994A',
    justifyContent: 'center',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '15px',
    fontFeatureSettings: "'clig' off, 'liga' off",
  },
  descriptionIconAbsolute: {
    display: 'flex',
    alignItems: 'center',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    background: '#F2994A',
    justifyContent: 'center',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '15px',
    fontFeatureSettings: "'clig' off, 'liga' off",
    position: 'absolute',
    right: '-8px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  typographyContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '280px',
    alignItems: 'flex-start',
  },
  addTestimonialCard: {
    height: '72px',
    filter: 'drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.07))',
    background: 'white',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '12px',
  },
  addTestimonialText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    color: 'white',
  },
  buttonContainerAddTestimonial: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    gap: '24px',
  },

  buttonSelector: {
    width: '50px',
    color: '#060640',
    height: '100%',
    borderTop: '1px solid #060640',
    borderBottom: '1px solid #060640',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: '400',
    '&.actif': {
      background: 'rgba(39, 62, 172, 0.2)',
      color: '#273EAC',
      border: '1px solid #273EAC',
      fontWeight: '500',
    },
  },
  addTestimonialButton: {
    gap: '8px',
    display: 'flex',
    padding: '4px 24px',
    justifyContent: 'center',
    borderRadius: '4px',
    background: 'white',
    height: '42px',
    alignSelf: 'center',
  },
  verticalDivider: {
    borderLeft: '1px solid #273EAC',
    height: '100%',
    alignSelf: 'center',
  },

  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  copyLinkTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
    marginRight: '16px',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },

  copyTextfield: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },

    '& .MuiOutlinedInput-root': {
      width: '100%',
      height: '40px',
      borderRadius: '8px',
      border: '1px solid #CED3E8',
      background: 'white',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #CED3E8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #CED3E8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  mustUpgradeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  mustUpgradeTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '35px',
    color: '#060640',
  },
  mustUpgradeDescription: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#060640',
    textAlign: 'start',
    maxWidth: '500px',
    alignSelf: 'self-start',
  },
  mustUpgradeButton: {
    borderRadius: '8px',
    background: '#273EAC',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    width: '233px',
    height: '40px',
    alignSelf: 'center',
  },
  offerDialog: {
    zIndex: 3000,
    '& .MuiDialog-paper': {
      width: 'auto',
      borderRadius: '8px',
      background: 'white',
      paddingTop: '50px',
      paddingBottom: '30px',
      paddingLeft: '58px',
      paddingRight: '58px',
      maxWidth: '100%',
    },
  },
  triggerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '20px',
  },
  toggle: {
    marginLeft: 0,
    borderRadius: '1rem',
    transition: '0.15s',
    padding: theme.spacing(1, 1, 1, 2),
    width: '100%',
    '& span:nth-child(2)': {
      flex: 1,
      paddingRight: theme.spacing(1),
    },
    '&:hover:not(.is-disabled)': {
      background: theme.palette.grey[100],
    },
  },
  toggleBold: {
    '& span:nth-child(2)': {
      fontWeight: '500',
      fontFamily: 'Poppins',
      fontSize: '15px',
      lineHeight: '17px',
    },
  },
  triggerAction: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  triggerActionTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#060640',
  },
  triggerField: {
    width: '70px',
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
      padding: '2px',
      textAlignLast: 'end',
    },
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
        appearance: 'textfield',
        marginLeft: '5px',
        opacity: 1,
      },
    '& .MuiOutlinedInput-root': {
      height: '32px',
      borderRadius: '8px',
      border: '1px solid #CED3E8',
      background: 'white',
      paddingRight: '2px',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #CED3E8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #CED3E8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  desktopContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #060640',
    borderRadius: '8px',
    paddingRight: '15px',
    paddingLeft: '15px',
    paddingBottom: '15px',
    paddingTop: '6px',
  },
  legendClasses: {
    padding: '0px 6px',
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    zIndex: 2000,
    '& .MuiMenu-paper': {
      borderRadius: '8px',
      boxShadow: '0px 10px 10px 0px rgba(3, 3, 37, 0.10)',
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '140%',
      color: '#060640',
    },
  },

  menuColorPicker: {
    '& .MuiPaper-root': {
      padding: '20px',
      background: 'transparent',
      boxShadow: 'none',
    },
  },

  buttonContainer: {
    display: 'flex',
    background: '#EDF0FF',
    height: '100%',
    width: 'fit-content',
  },
  displayLocationContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  titleTypography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    color: '#060640',
  },
  settingsContainer: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
  },
  pluginPositionSelector: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '15px',
    fontStyle: 'normal',
    width: '130px',
  },

  textFieldStyle: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CED3E8',
      },
      '& input': {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '15px',
        fontStyle: 'normal',
      },
      '&:hover fieldset': {
        borderColor: '#273EAC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#273EAC',
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Poppins',
      fontWeight: '400',
      fontSize: '15px',
      fontStyle: 'normal',
      '&.Mui-focused': {
        color: '#273EAC',
      },
    },
    '& .fieldset': {
      border: 'none',
    },
    marginBottom: '8px',
  },
});

interface Props {
  testimonials: Testimonial[];
  allTestimonials: Testimonial[];
  user: User | null;
  addPageButtonClicked?: boolean;
  setAddPageButtonClicked: any;
  disableAll: boolean;
}

const PageList: React.FC<Props> = ({
  testimonials,
  allTestimonials,
  user,
  addPageButtonClicked,
  setAddPageButtonClicked,
  disableAll,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tagSelected, setTagSelected] = useState('');

  const [step, setStep] = useState(user?.step ?? 0);

  const [openStepTutorialDialog, setOpenStepTutorialDialog] = useState(false);

  const updateStep = async () => {
    await dispatch(updateUserStep({ id: user?._id, step: 1 }));
    setOpenStepTutorialDialog(false);
  };

  useEffect(() => {
    if (user?.step) {
      setStep(user.step);
    }
  }, [user?.step]);

  useEffect(() => {
    if (step === 2) {
      setOpenStepTutorialDialog(true);
    }
  }, [step]);

  const [settings, setSettings] = useState(() => {
    const temp: {
      tag: string;
      urls: string[];
      allPage: boolean;
      option?: { actif: boolean; key: { value: string; type: string }[] };
      trigger?: Trigger;
      displayLocation?: DisplayLocation;
      embeddedSettings?: EmbeddedSettings;
    }[] = [];
    user?.settings.pluginSettings.tagsList.forEach((setting) => {
      temp.push({
        tag: setting.tag,
        urls: setting.urls,
        allPage: setting.allPage,
        option: setting.option,
        trigger: setting.trigger,
        displayLocation: setting.displayLocation,
        embeddedSettings: setting.embeddedSettings,
      });
    });
    return temp;
  });
  const [isEditingTagName, setIsEditingTagName] = useState<boolean[]>(() => {
    const temp: boolean[] = [];
    settings.map((setting) => {
      if (setting.tag !== '') {
        temp.push(true);
      } else {
        temp.push(false);
      }
    });
    return temp;
  });

  const [tagNameToEdit, setTagNameToEdit] = useState<string[]>(() => {
    const temp: string[] = [];
    settings.map((setting) => {
      temp.push(setting.tag);
    });
    return temp;
  });

  useEffect(() => {
    const temp: {
      tag: string;
      urls: string[];
      allPage: boolean;
      option?: { actif: boolean; key: { value: string; type: string }[] };
      trigger?: Trigger;
      displayLocation?: DisplayLocation;
      embeddedSettings?: EmbeddedSettings;
    }[] = [];
    user?.settings.pluginSettings.tagsList.forEach((setting) => {
      temp.push({
        tag: setting.tag,
        urls: setting.urls,
        allPage: setting.allPage,
        option: setting.option,
        trigger: setting.trigger,
        displayLocation: setting.displayLocation,
        embeddedSettings: setting.embeddedSettings,
      });
    });
    setSettings(temp);
  }, [user?.settings.pluginSettings.tagsList]);

  const deleteTag = async (tag: string) => {
    if (tagToDelete === tag) {
      testimonials.map((testimonial) => {
        dispatch(
          updateTestimonial({
            _id: testimonial._id,
            tags: testimonial.tags.filter((tag) => tag !== tagToDelete),
          }),
        );
      });
      dispatch(
        updatePluginSettings({
          tagsList: settings.filter((setting) => setting.tag !== tagToDelete),
        }),
      );
    }
    setTagSelected('');
    setOpenDeleteConfirmAction(false);
    setTagToDelete('');
  };

  const [tempTag, setTempTag] = useState('');

  const [openDeleteConfirmAction, setOpenDeleteConfirmAction] = useState(false);

  const [tagToDelete, setTagToDelete] = useState('');

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (addPageButtonClicked) {
      addNewTag();
      setAddPageButtonClicked(false);
    }
  }, [addPageButtonClicked]);

  const changeTagName = async (indexTag: number, tag: string) => {
    // Change settings
    if (tag != '') {
      const settingsTemp = [...settings];
      const settingTemp = {
        ...settingsTemp[indexTag],
        tag: tagNameToEdit[indexTag],
      };
      settingsTemp[indexTag] = settingTemp;
      setSettings(settingsTemp);
      await dispatch(
        updatePluginSettings({
          tagsList: settingsTemp,
        }),
      );

      // Change testimonials
      testimonials.map((testimonial) => {
        if (testimonial.tags.includes(tag)) {
          dispatch(
            updateTestimonial({
              _id: testimonial._id,
              tags: testimonial.tags.map((tagItem) =>
                tagItem === tag ? tagNameToEdit[indexTag] : tagItem,
              ),
            }),
          );
        }
      });
    }
  };

  const saveDisplayLocation = async (
    indexTag: number,
    displayLocation: string,
  ) => {
    const settingsTemp = [...settings];
    const settingTemp = {
      ...settingsTemp[indexTag],
      displayLocation: {
        showOnPlugin: displayLocation === 'showOnPlugin' ?? false,
        showOnEmbedded: displayLocation === 'showOnEmbedded' ?? false,
      },
    };
    settingsTemp[indexTag] = settingTemp;
    setSettings(settingsTemp);

    await dispatch(
      updatePluginSettings({
        tagsList: settingsTemp,
      }),
    );
  };

  const [existTagName, setExistTagName] = useState(false);

  const [refs, setRefs] = useState<RefObject<HTMLDivElement>[]>();

  const [isDisabled, setIsDisabled] = useState(false);

  const testimonialListContext = useContext(TestimonialListHandleContext);

  const testimonialEditionContext = useContext(TestimonialEditionHandleContext);

  const sidebarContext = useContext(SideBarHandleContext);

  useEffect(() => {
    if (user?.limitation?.maxNumberOfPages) {
      setIsDisabled(
        user?.limitation?.maxNumberOfPages <
          user?.settings.pluginSettings.tagsList.length + 1,
      );
    }
  });

  useEffect(() => {
    const temp = [...isEditingTagName];
    settings.map((setting, index) => {
      if (tagNameToEdit.includes(setting.tag)) {
        temp[index] = false;
      } else {
        temp[index] = true;
      }
    });
    setIsEditingTagName(temp.slice(0, settings.length));
  }, [settings, tagNameToEdit]);

  useEffect(() => {
    setRefs(
      [...new Array(settings.length + 1)].map(() =>
        React.createRef<HTMLDivElement>(),
      ),
    );
  }, [settings.length]);

  const [copiedUrl, setCopiedUrl] = useState(false);

  const link = '<script async src="https://plugin.kudeo.co/shim.js"></script>';

  const [maxSlideRight, setMaxSlideRight] = useState(false);

  const [maxSlideLeft, setMaxSlideLeft] = useState(true);

  const scrollToElement = (direction: string) => {
    if (refs) {
      let wait = false;
      if (direction === 'right') {
        refs.some((ref) => {
          if (ref.current) {
            const elementRect = ref.current?.getBoundingClientRect();
            const windowWidth = window.innerWidth;

            if (!(elementRect?.left < windowWidth && elementRect?.right >= 0)) {
              if (wait) {
                ref.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                  inline: 'nearest',
                });
                return ref;
              }
            } else {
              wait = true;
            }
          }
        });
      } else {
        refs
          .slice(0)
          .reverse()
          .some((ref) => {
            if (ref.current) {
              const elementRect = ref.current?.getBoundingClientRect();
              const windowWidth = window.innerWidth;

              if (
                !(elementRect?.left < windowWidth && elementRect?.right >= 0)
              ) {
                if (wait) {
                  ref.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                  });
                  return ref;
                }
              } else {
                wait = true;
              }
            }
          });
      }
    }
  };

  const [openMustUpgradeDialog, setOpenMustUpgradeDialog] = useState(false);

  const [hideOffer, setHideOffer] = useState(false);

  useEffect(() => {
    if (user?.hideOffer) {
      setHideOffer(true);
    }
    if (user?._id === '654cff6144abb7a43e4a0a9b') {
      setHideOffer(true);
    }
  }, [user]);

  const [setting, setSetting] = useState<{
    tag: string;
    urls: string[];
    allPage: boolean;
    option?: { actif: boolean; key: { value: string; type: string }[] };
    trigger?: Trigger;
    displayLocation?: DisplayLocation;
    embeddedSettings?: EmbeddedSettings;
  }>();

  useEffect(() => {
    if (!setting?.trigger && setting) {
      setSetting({
        ...setting,
        trigger: {
          openTestimonialOnDesktopOnLoad: true,
          openTestimonialOnMobileOnLoad: false,
          openOnScroll: false,
          openOnScrollMobile: false,
          timeBeforeOpenOnDesktopState: false,
          timeBeforeOpenOnMobileState: false,
          openOnClick: false,
        },
      });
    }
  }, [setting]);

  const saveUrl = () => {
    const settingsTemp = [...settings];
    const i = settingsTemp.findIndex((setting) => setting.tag === tagSelected);
    if (setting && user && setting.trigger) {
      settingsTemp[i] = setting;
      dispatch(
        updateTriggerAndDisplayLocationPluginSetting({
          userId: user?._id,
          trigger: setting.trigger,
          tag: setting.tag,
        }),
      ).then((res) => {
        if (res.payload) {
          setOpenAddTriggerDialog(false);
        }
      });
    }
  };

  const saveEmbeddedSettings = () => {
    const settingsTemp = [...settings];
    const i = settingsTemp.findIndex((setting) => setting.tag === tagSelected);
    if (setting && user && setting.embeddedSettings) {
      settingsTemp[i] = setting;
      dispatch(
        updateEmbeddedSetting({
          userId: user?._id,
          embeddedSettings: setting.embeddedSettings,
          tag: setting.tag,
        }),
      ).then((res) => {
        if (res.payload) {
          setOpenAddTriggerDialog(false);
        }
      });
    }
  };

  useEffect(() => {
    const tagListTemp = user?.settings.pluginSettings.tagsList.find(
      (tag) => tag.tag === tagSelected,
    );

    if (tagListTemp)
      setSetting({
        tag: tagListTemp.tag,
        urls: tagListTemp.urls,
        allPage: tagListTemp.allPage,
        option: tagListTemp.option,
        trigger: tagListTemp.trigger,
        displayLocation: tagListTemp.displayLocation,
        embeddedSettings: tagListTemp.embeddedSettings,
      });
  }, [tagSelected, user]);

  const [openAddTriggerDialog, setOpenAddTriggerDialog] = useState(false);

  const handleScroll = () => {
    if (refs) {
      if (refs[refs.length - 1]?.current) {
        const elementRect =
          refs[refs.length - 1]?.current?.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        if (elementRect) {
          if (elementRect?.left < windowWidth && elementRect?.right >= 0) {
            setMaxSlideRight(true);
          } else {
            setMaxSlideRight(false);
          }
        }
      }

      if (refs[0].current) {
        const elementRect = refs[0]?.current?.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        if (elementRect) {
          if (elementRect?.left < windowWidth && elementRect?.right >= 0) {
            setMaxSlideLeft(true);
          } else {
            setMaxSlideLeft(false);
          }
        }
      }
    }
  };

  const [clickedStep1Tutorial, setClickedStep1Tutorial] = useState(false);

  const addTestimonials = (item: string, defaultTab: string) => {
    if (testimonialListContext) {
      testimonialListContext.setTestimonials(
        allTestimonials.filter((t) => !t.tags.includes(item)),
      );
      if (allTestimonials.filter((t) => !t.tags.includes(item))?.length === 0) {
        testimonialEditionContext?.setTag(item);
        testimonialEditionContext?.setTestimonialType(
          defaultTab === 'text' ? 'written' : 'video',
        );
        sidebarContext?.setOpenTestimonialEdition(true);
      } else {
        testimonialListContext.setTag(item);
        testimonialListContext.setDefaultTab(defaultTab);
        sidebarContext?.setOpenTestimonialList(true);
      }

      testimonialListContext?.setDisplayLocation(
        user?.settings.pluginSettings.tagsList.find((t) => {
          return t.tag === item;
        })?.displayLocation.showOnEmbedded
          ? 'embedded'
          : 'plugin',
      );

      testimonialEditionContext?.setDisplayLocation(
        user?.settings.pluginSettings.tagsList.find((t) => {
          return t.tag === item;
        })?.displayLocation.showOnEmbedded
          ? 'embedded'
          : 'plugin',
      );
    }
  };

  const [clickedStep2Tutorial, setClickedStep2Tutorial] = useState(false);

  const [clickedStep3Tutorial, setClickedStep3Tutorial] = useState(false);

  const [isValidUrl, setIsValidUrl] = useState<boolean>();

  useEffect(() => {
    if (testimonialEditionContext?.tutorialClicked) {
      setClickedStep2Tutorial(testimonialEditionContext.tutorialClicked);
    }
  }, [testimonialEditionContext?.tutorialClicked]);

  const ref = useRef<
    HTMLUListElement | undefined
  >() as React.MutableRefObject<HTMLUListElement>;
  useEffect(() => {
    if (ref && settings.length != 0) {
      ref.current?.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (ref && settings.length != 0) {
        ref.current?.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref, refs]);

  const [anchorElColorPicker, setAnchorElColorPicker] =
    React.useState<null | HTMLElement>(null);
  const openColorPicker = Boolean(anchorElColorPicker);
  const handleClickColorPicker = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElColorPicker(event.currentTarget);
  };
  const handleCloseColorPicker = () => {
    setAnchorElColorPicker(null);
  };

  const addNewTag = () => {
    dispatch(
      updatePluginSettings({
        tagsList: [
          ...settings,
          {
            tag: 'new page',
            urls: [],
            allPage: false,
            option: { actif: false, key: [] },
            displayLocation: {
              showOnPlugin: true,
              showOnEmbedded: false,
            },
          },
        ],
      }),
    );
  };

  // useEffect(() => {
  //   const shopifyApp = useAppBridge();
  //   const fetchData = async () => {
  //     try {
  //       const test = await shopifyApp.resourcePicker({
  //         type: 'product',
  //         action: 'select',
  //       });
  //       if (test) {
  //         const url =
  //           'https://' + shopifyApp.config.shop + '/products/' + test[0].handle;
  //         console.log(url);
  //       }
  //     } catch (error) {
  //       // Handle error
  //       console.log('Error while fetching authorization code', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const [openOfferDialog, setOpenOfferDialog] = useState<boolean>(false);

  return user != undefined ? (
    <>
      <ul
        className={classes.listContainer}
        id="listContainer"
        ref={ref}
        style={disableAll ? { pointerEvents: 'none' } : {}}
      >
        {settings.length != 0 &&
          settings.map((item, indexTag) => (
            <React.Fragment key={indexTag}>
              <div
                className={classes.cardPage}
                ref={refs ? refs[indexTag] : undefined}
              >
                <div className={classes.testimonialCardContainer}>
                  <div className={classes.editNameContainer}>
                    {isEditingTagName[indexTag] === false ? (
                      <ButtonBase
                        onClick={() => {
                          setIsEditingTagName((prev) => {
                            const temp = [...prev];
                            temp[indexTag] = true;
                            return temp;
                          });
                        }}
                        className={classes.tagName}
                      >
                        {item.tag}
                      </ButtonBase>
                    ) : (
                      <div className={classes.tagEditionContainer}>
                        <TextField
                          className={classes.textFieldEditTag}
                          placeholder={item.tag}
                          value={tagNameToEdit[indexTag]}
                          onChange={(e) => {
                            const temporary = [...tagNameToEdit];
                            temporary[indexTag] = e.target.value;
                            setTagNameToEdit(temporary);
                            settings.map((setting) => {
                              if (setting.tag === e.target.value) {
                                setExistTagName(true);
                              } else {
                                setExistTagName(false);
                              }
                            });
                          }}
                        />

                        <ButtonBase
                          className={classes.saveButtonTagEdition}
                          onClick={() => {
                            changeTagName(indexTag, item.tag);
                          }}
                          disabled={
                            tagNameToEdit[indexTag] === '' ||
                            existTagName === true
                          }
                        >
                          Ok
                        </ButtonBase>
                      </div>
                    )}

                    {isEditingTagName[indexTag] === false &&
                      user?.earlyAccess && (
                        <>
                          <div className={classes.buttonContainer}>
                            <ButtonBase
                              className={`${classes.buttonSelector} ${
                                settings[indexTag].displayLocation?.showOnPlugin
                                  ? 'actif'
                                  : ''
                              } ${classes.titleTypography}`}
                              onClick={() => {
                                saveDisplayLocation(indexTag, 'showOnPlugin');
                              }}
                              style={
                                settings[indexTag].displayLocation?.showOnPlugin
                                  ? {
                                      borderLeft: '1px solid #273EAC',
                                      borderTopLeftRadius: '4px',
                                      WebkitBorderBottomLeftRadius: '4px',
                                    }
                                  : {
                                      borderLeft: '1px solid #060640',
                                      borderTopLeftRadius: '4px',
                                      WebkitBorderBottomLeftRadius: '4px',
                                    }
                              }
                            >
                              Plugin
                            </ButtonBase>

                            <ButtonBase
                              className={`${classes.buttonSelector} ${
                                settings[indexTag].displayLocation
                                  ?.showOnEmbedded
                                  ? 'actif'
                                  : ''
                              } ${classes.titleTypography}`}
                              onClick={() => {
                                saveDisplayLocation(indexTag, 'showOnEmbedded');
                              }}
                              style={
                                settings[indexTag].displayLocation
                                  ?.showOnEmbedded
                                  ? {
                                      borderTopRightRadius: '4px',
                                      borderBottomRightRadius: '4px',
                                      borderRight: '1px solid #273EAC',
                                    }
                                  : {
                                      borderTopRightRadius: '4px',
                                      borderBottomRightRadius: '4px',
                                      borderRight: '1px solid #060640',
                                    }
                              }
                            >
                              Embed
                            </ButtonBase>
                          </div>
                        </>
                      )}

                    <ButtonBase
                      className={`${classes.moreOptionButton}`}
                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(event) => {
                        handleClick(event);
                        setTempTag(item.tag);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12C16 13.1046 16.8954 14 18 14Z"
                          fill="#060640"
                        />
                      </svg>
                    </ButtonBase>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      style={{ zIndex: '2000' }}
                      className={classes.menu}
                      disablePortal
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenAddTriggerDialog(true);
                          setTagSelected(tempTag);
                        }}
                      >
                        {t('pages.Website.trigger.pluginSettingsDisplay')}
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenDeleteConfirmAction(true);
                          setTagSelected(tempTag);
                        }}
                        style={{ color: 'red' }}
                      >
                        {t('common.delete')}
                      </MenuItem>
                    </Menu>
                  </div>
                  <TestimonialListCard
                    testimonials={testimonials.filter((testimonial) => {
                      if (testimonial.tags)
                        return testimonial.tags.includes(item.tag);
                    })}
                    setting={item}
                    setSettings={setSettings}
                    settings={settings}
                    tutorial={false}
                  />
                </div>

                <div className={classes.addTestimonialCard}>
                  <div className={classes.buttonContainerAddTestimonial}>
                    <ButtonBase
                      className={classes.addTestimonialButton}
                      onClick={() => {
                        addTestimonials(item.tag, 'text');
                      }}
                      style={
                        user?.settings.pluginSettings.tagsList.find((t) => {
                          return t.tag === item.tag;
                        })?.displayLocation?.showOnEmbedded
                          ? { background: '#060640', opacity: '0.6' }
                          : { background: '#060640' }
                      }
                      disabled={
                        user?.settings.pluginSettings.tagsList.find((t) => {
                          return t.tag === item.tag;
                        })?.displayLocation?.showOnEmbedded
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_116_953)">
                          <path
                            d="M8.4375 7.5C8.95528 7.5 9.375 7.08028 9.375 6.5625C9.375 6.04472 8.95528 5.625 8.4375 5.625C7.91972 5.625 7.5 6.04472 7.5 6.5625C7.5 7.08028 7.91972 7.5 8.4375 7.5ZM12.1875 7.5C12.7053 7.5 13.125 7.08028 13.125 6.5625C13.125 6.04472 12.7053 5.625 12.1875 5.625C11.6697 5.625 11.25 6.04472 11.25 6.5625C11.25 7.08028 11.6697 7.5 12.1875 7.5ZM19.3125 7.5H18.75V9.375H19.3125C20.8633 9.375 22.125 10.6367 22.125 12.1875V15.9375C22.125 17.4883 20.8635 18.75 19.3125 18.75H18.375V20.462L15.8504 18.75H12.1875C10.6367 18.75 9.375 17.4883 9.375 15.9375V15H7.5V15.9375C7.5 18.5222 9.60281 20.625 12.1875 20.625H15.2746L20.25 24V20.5309C22.3869 20.0955 24 18.2013 24 15.9375V12.1875C24 9.60281 21.8972 7.5 19.3125 7.5ZM5.625 6.5625C5.625 6.04472 5.20528 5.625 4.6875 5.625C4.16972 5.625 3.75 6.04472 3.75 6.5625C3.75 7.08028 4.16972 7.5 4.6875 7.5C5.20528 7.5 5.625 7.08028 5.625 6.5625ZM8.75002 13.125H12.1875C14.7722 13.125 16.875 11.0222 16.875 8.4375V4.6875C16.875 2.10281 14.7722 0 12.1875 0H4.6875C2.10281 0 0 2.10281 0 4.6875V8.4375C0 10.7012 1.61311 12.5952 3.75 13.0308V16.875L8.75002 13.125ZM1.875 8.4375V4.6875C1.875 3.13669 3.13669 1.875 4.6875 1.875H12.1875C13.7383 1.875 15 3.13669 15 4.6875V8.4375C15 9.98831 13.7383 11.25 12.1875 11.25H8.12498L5.625 13.125V11.25H4.6875C3.13669 11.25 1.875 9.98831 1.875 8.4375Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_116_953">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <Typography className={classes.addTestimonialText}>
                        {t('pages.Website.addText')}
                      </Typography>
                    </ButtonBase>
                    <ButtonBase
                      className={classes.addTestimonialButton}
                      onClick={() => {
                        addTestimonials(item.tag, 'video');
                      }}
                      style={{ background: '#273EAC' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_116_1064)">
                          <path
                            d="M19 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L19 0C20.3256 0.00158786 21.5964 0.528882 22.5338 1.46622C23.4711 2.40356 23.9984 3.67441 24 5V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24ZM5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2H5ZM9.342 17.005C8.92513 17.0036 8.51601 16.8922 8.156 16.682C7.80034 16.4784 7.50515 16.184 7.30063 15.8289C7.09611 15.4738 6.9896 15.0708 6.992 14.661V9.339C6.99166 8.92914 7.09911 8.52641 7.30358 8.1712C7.50805 7.81599 7.80234 7.52079 8.15692 7.31524C8.5115 7.10968 8.9139 7.00099 9.32376 7.00007C9.73361 6.99915 10.1365 7.10604 10.492 7.31L15.77 9.945C16.1366 10.1429 16.4436 10.4353 16.6592 10.7918C16.8748 11.1483 16.9912 11.556 16.9962 11.9726C17.0012 12.3892 16.8946 12.7995 16.6877 13.1611C16.4807 13.5227 16.1808 13.8223 15.819 14.029L10.443 16.716C10.1077 16.9073 9.728 17.0069 9.342 17.005ZM9.317 9.005C9.26189 9.00498 9.20774 9.01946 9.16 9.047C9.10814 9.07585 9.0651 9.11826 9.0355 9.1697C9.00591 9.22114 8.99087 9.27966 8.992 9.339V14.661C8.99234 14.7195 9.00788 14.7768 9.03709 14.8274C9.0663 14.8781 9.10818 14.9202 9.15861 14.9498C9.20905 14.9794 9.2663 14.9953 9.32475 14.996C9.3832 14.9968 9.44084 14.9823 9.492 14.954L14.868 12.266C14.908 12.2347 14.9399 12.1941 14.9608 12.1478C14.9817 12.1015 14.991 12.0507 14.988 12C14.9893 11.9405 14.9742 11.8818 14.9444 11.8304C14.9146 11.7789 14.8712 11.7365 14.819 11.708L9.545 9.073C9.47624 9.03082 9.39763 9.00737 9.317 9.005Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_116_1064">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <Typography className={classes.addTestimonialText}>
                        {t('pages.Website.addVideo')}
                      </Typography>
                    </ButtonBase>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}

        <div
          className={`${classes.cardAddPage}`}
          ref={refs ? refs[settings.length] : undefined}
        >
          <Button
            onClick={() => {
              if (isDisabled) {
                setOpenMustUpgradeDialog(true);
              } else {
                addNewTag();
              }
            }}
          >
            <img src={`/static/icons/plusIcon.svg`} />
          </Button>

          <Typography className={classes.addPageTitle}>
            {t('pages.Testimonials.addPage')}
          </Typography>
        </div>
      </ul>
      <>
        <Dialog
          open={openDeleteConfirmAction}
          onClose={() => {
            setOpenDeleteConfirmAction(false);
            setTagSelected('');
            setTagToDelete('');
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deleteDialog}
        >
          <ButtonBase
            style={{ position: 'absolute', right: '10px', top: '10px' }}
            onClick={() => {
              setOpenDeleteConfirmAction(false);
            }}
          >
            <CloseIcon style={{ color: '#414354', borderRadius: '50%' }} />
          </ButtonBase>
          <div id="alert-dialog-title" className={classes.dialogContainer}>
            <Typography className={classes.dialogTitle}>
              {t('pages.Testimonials.deleteAskTitle')}
            </Typography>

            <Typography className={classes.dialogDeleteTypography}>
              {t('pages.Testimonials.enterTitlePage')}
            </Typography>
            <TextField
              onChange={(e) => {
                setTagToDelete(e.target.value);
              }}
              className={classes.textFieldDeletePage}
              placeholder={t('pages.Testimonials.titleOfPage')}
            />
            <ButtonBase
              onClick={() => {
                deleteTag(tagSelected);
              }}
              className={classes.dialogActionDeleteButton}
              disabled={tagToDelete !== tagSelected}
              style={
                tagToDelete === tagSelected
                  ? {
                      background: '#273EAC',
                      color: 'white',
                      border: '1px solid #273EAC',
                    }
                  : {
                      background: 'none',
                      color: 'grey',
                      border: '1px solid grey',
                    }
              }
            >
              <DoneIcon />
              {t('common.validate')}
            </ButtonBase>
          </div>
        </Dialog>
        {!maxSlideRight && (
          <ButtonBase
            className={classes.buttonScrollRight}
            onClick={() => {
              scrollToElement('right');
            }}
          >
            <img src={PlusIcon} />
          </ButtonBase>
        )}
        {!maxSlideLeft && (
          <ButtonBase
            onClick={() => {
              scrollToElement('left');
            }}
            className={classes.buttonScrollLeft}
          >
            <img src={PlusIcon} />
          </ButtonBase>
        )}

        <Dialog
          open={openStepTutorialDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.tutorialDialog}
        >
          {settings.length != 0 && (
            <>
              <div>
                <div className={classes.cardPage} style={{ height: '85vh' }}>
                  <div className={classes.testimonialCardContainer}>
                    <div className={classes.editNameContainerTutorial}>
                      {clickedStep1Tutorial === true ? (
                        <ButtonBase
                          onClick={() => {
                            setClickedStep1Tutorial(false);
                          }}
                          className={classes.tagName}
                        >
                          {settings[0].tag}
                        </ButtonBase>
                      ) : (
                        <>
                          <TextField
                            className={classes.textFieldEditTag}
                            placeholder={settings[0].tag ?? ''}
                            value={tagNameToEdit[0]}
                            onChange={(e) => {
                              const temporary = [...tagNameToEdit];
                              temporary[0] = e.target.value;
                              setTagNameToEdit(temporary);
                              settings.map((setting) => {
                                if (setting.tag === e.target.value) {
                                  setExistTagName(true);
                                } else {
                                  setExistTagName(false);
                                }
                              });
                            }}
                          />

                          <ButtonBase
                            className={classes.saveButtonTagEdition}
                            onClick={() => {
                              changeTagName(0, settings[0].tag);
                              setClickedStep1Tutorial(true);
                            }}
                            disabled={
                              tagNameToEdit[0] === '' || existTagName === true
                            }
                          >
                            Ok
                          </ButtonBase>
                        </>
                      )}
                      <div
                        className={classes.descriptionIconAbsolute}
                        style={
                          clickedStep1Tutorial
                            ? {
                                background: '#08E8AF',
                              }
                            : {}
                        }
                      >
                        1
                      </div>
                    </div>

                    <TestimonialListCard
                      testimonials={testimonials.filter((testimonial) => {
                        if (testimonial.tags)
                          return testimonial.tags.includes(settings[0].tag);
                      })}
                      setting={settings[0]}
                      setSettings={setSettings}
                      settings={settings}
                      tutorial={true}
                      setClickedStep3Tutorial={setClickedStep3Tutorial}
                      isValidUrl={isValidUrl}
                      setIsValidUrl={setIsValidUrl}
                    />
                  </div>

                  <div
                    className={classes.addTestimonialCard}
                    style={{ position: 'relative' }}
                  >
                    <div
                      className={classes.descriptionIconAbsolute}
                      style={
                        clickedStep2Tutorial
                          ? { right: '4px', background: 'rgb(8, 232, 175)' }
                          : { right: '4px' }
                      }
                    >
                      3
                    </div>
                    <div className={classes.buttonContainerAddTestimonial}>
                      <ButtonBase
                        className={classes.addTestimonialButton}
                        onClick={() => {
                          addTestimonials(settings[0].tag, 'text');
                        }}
                        style={{ background: '#060640' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_116_953)">
                            <path
                              d="M8.4375 7.5C8.95528 7.5 9.375 7.08028 9.375 6.5625C9.375 6.04472 8.95528 5.625 8.4375 5.625C7.91972 5.625 7.5 6.04472 7.5 6.5625C7.5 7.08028 7.91972 7.5 8.4375 7.5ZM12.1875 7.5C12.7053 7.5 13.125 7.08028 13.125 6.5625C13.125 6.04472 12.7053 5.625 12.1875 5.625C11.6697 5.625 11.25 6.04472 11.25 6.5625C11.25 7.08028 11.6697 7.5 12.1875 7.5ZM19.3125 7.5H18.75V9.375H19.3125C20.8633 9.375 22.125 10.6367 22.125 12.1875V15.9375C22.125 17.4883 20.8635 18.75 19.3125 18.75H18.375V20.462L15.8504 18.75H12.1875C10.6367 18.75 9.375 17.4883 9.375 15.9375V15H7.5V15.9375C7.5 18.5222 9.60281 20.625 12.1875 20.625H15.2746L20.25 24V20.5309C22.3869 20.0955 24 18.2013 24 15.9375V12.1875C24 9.60281 21.8972 7.5 19.3125 7.5ZM5.625 6.5625C5.625 6.04472 5.20528 5.625 4.6875 5.625C4.16972 5.625 3.75 6.04472 3.75 6.5625C3.75 7.08028 4.16972 7.5 4.6875 7.5C5.20528 7.5 5.625 7.08028 5.625 6.5625ZM8.75002 13.125H12.1875C14.7722 13.125 16.875 11.0222 16.875 8.4375V4.6875C16.875 2.10281 14.7722 0 12.1875 0H4.6875C2.10281 0 0 2.10281 0 4.6875V8.4375C0 10.7012 1.61311 12.5952 3.75 13.0308V16.875L8.75002 13.125ZM1.875 8.4375V4.6875C1.875 3.13669 3.13669 1.875 4.6875 1.875H12.1875C13.7383 1.875 15 3.13669 15 4.6875V8.4375C15 9.98831 13.7383 11.25 12.1875 11.25H8.12498L5.625 13.125V11.25H4.6875C3.13669 11.25 1.875 9.98831 1.875 8.4375Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_116_953">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <Typography className={classes.addTestimonialText}>
                          {t('pages.Website.addText')}
                        </Typography>
                      </ButtonBase>
                      <ButtonBase
                        className={classes.addTestimonialButton}
                        onClick={() => {
                          addTestimonials(settings[0].tag, 'video');
                        }}
                        style={{ background: '#273EAC' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_116_1064)">
                            <path
                              d="M19 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L19 0C20.3256 0.00158786 21.5964 0.528882 22.5338 1.46622C23.4711 2.40356 23.9984 3.67441 24 5V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24ZM5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2H5ZM9.342 17.005C8.92513 17.0036 8.51601 16.8922 8.156 16.682C7.80034 16.4784 7.50515 16.184 7.30063 15.8289C7.09611 15.4738 6.9896 15.0708 6.992 14.661V9.339C6.99166 8.92914 7.09911 8.52641 7.30358 8.1712C7.50805 7.81599 7.80234 7.52079 8.15692 7.31524C8.5115 7.10968 8.9139 7.00099 9.32376 7.00007C9.73361 6.99915 10.1365 7.10604 10.492 7.31L15.77 9.945C16.1366 10.1429 16.4436 10.4353 16.6592 10.7918C16.8748 11.1483 16.9912 11.556 16.9962 11.9726C17.0012 12.3892 16.8946 12.7995 16.6877 13.1611C16.4807 13.5227 16.1808 13.8223 15.819 14.029L10.443 16.716C10.1077 16.9073 9.728 17.0069 9.342 17.005ZM9.317 9.005C9.26189 9.00498 9.20774 9.01946 9.16 9.047C9.10814 9.07585 9.0651 9.11826 9.0355 9.1697C9.00591 9.22114 8.99087 9.27966 8.992 9.339V14.661C8.99234 14.7195 9.00788 14.7768 9.03709 14.8274C9.0663 14.8781 9.10818 14.9202 9.15861 14.9498C9.20905 14.9794 9.2663 14.9953 9.32475 14.996C9.3832 14.9968 9.44084 14.9823 9.492 14.954L14.868 12.266C14.908 12.2347 14.9399 12.1941 14.9608 12.1478C14.9817 12.1015 14.991 12.0507 14.988 12C14.9893 11.9405 14.9742 11.8818 14.9444 11.8304C14.9146 11.7789 14.8712 11.7365 14.819 11.708L9.545 9.073C9.47624 9.03082 9.39763 9.00737 9.317 9.005Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_116_1064">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <Typography className={classes.addTestimonialText}>
                          {t('pages.Website.addVideo')}
                        </Typography>
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.helpContainer}>
                {clickedStep1Tutorial &&
                clickedStep2Tutorial &&
                clickedStep3Tutorial ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <Typography className={classes.finishTitleTypography}>
                      <img
                        src={party}
                        alt="congrats"
                        style={{ width: '30px', height: '30px' }}
                      />
                      {t('common.finishTutorialTitle')}
                      <img
                        src={party}
                        alt="congrats"
                        style={{ width: '30px', height: '30px' }}
                      />
                    </Typography>
                    <Typography
                      className={classes.copyLinkTypography}
                      style={{
                        fontWeight: '600',
                        marginBottom: '40px',
                        fontSize: '20px',
                        textAlign: 'center',
                      }}
                    >
                      {t('common.finishTutorialText')}
                    </Typography>
                    <Typography
                      className={classes.copyLinkTypography}
                      style={{
                        fontWeight: '600',
                        fontSize: '14px',
                      }}
                    >
                      ⚠️ {t('common.warning')} ⚠️
                    </Typography>
                    <Typography className={classes.copyLinkTypography}>
                      {t('common.finishTutorialText2')}
                    </Typography>
                    <div className={classes.finishContainer}>
                      <Typography className={classes.copyLinkTypography}>
                        {t('common.copyLinkTuto')}
                      </Typography>
                      <TextField
                        className={classes.copyTextfield}
                        value={link}
                        onClick={() => {
                          setCopiedUrl(true);
                          navigator.clipboard.writeText(link);
                        }}
                        sx={
                          copiedUrl
                            ? {
                                '& .MuiOutlinedInput-root': {
                                  border: '1px solid #273EAC !important',
                                },
                                '& input': {
                                  color: '#273EAC !important',
                                  cursor: 'pointer',
                                },
                              }
                            : {
                                '& input': {
                                  cursor: 'pointer',
                                },
                              }
                        }
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                {copiedUrl ? (
                                  <DoneIcon style={{ color: '#273EAC' }} />
                                ) : (
                                  <ContentCopyIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <ButtonBase
                      className={classes.buttonHelp}
                      onClick={() => {
                        updateStep();
                      }}
                    >
                      {t('pages.Testimonials.helpButton')}
                    </ButtonBase>
                  </div>
                ) : (
                  <>
                    <div className={classes.helpTitleContainer}>
                      <div className={classes.helpIcon}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_62_793)">
                            <path
                              d="M8.21637 10.1816L8.06733 10.7908C7.62021 10.9673 7.26305 11.1016 6.9971 11.194C6.73085 11.2866 6.4215 11.3327 6.06902 11.3327C5.52774 11.3327 5.10672 11.2001 4.80644 10.9367C4.50617 10.6723 4.35598 10.3372 4.35598 9.93072C4.35598 9.77336 4.36688 9.61151 4.38954 9.44661C4.41238 9.28151 4.44871 9.09557 4.49842 8.88773L5.0572 6.90998C5.10691 6.7206 5.14917 6.54116 5.18301 6.37147C5.21733 6.20283 5.23387 6.04767 5.23387 5.908C5.23387 5.65543 5.18157 5.47876 5.07747 5.37944C4.97336 5.2804 4.7747 5.23011 4.47978 5.23011C4.33533 5.23011 4.18687 5.25324 4.03553 5.29799C3.88353 5.34292 3.75361 5.38632 3.64453 5.42666L3.79395 4.81693C4.16 4.66789 4.50989 4.54027 4.84459 4.43434C5.17928 4.32813 5.49552 4.27498 5.79465 4.27498C6.33221 4.27498 6.74701 4.4049 7.0383 4.66474C7.32959 4.92477 7.47519 5.26185 7.47519 5.67723C7.47519 5.76317 7.46563 5.9146 7.44508 6.13104C7.425 6.34795 7.38762 6.5467 7.33313 6.72748L6.77674 8.6973C6.73114 8.85552 6.69013 9.03639 6.65447 9.23992C6.61766 9.44211 6.60007 9.5966 6.60007 9.70023C6.60007 9.96179 6.65839 10.1404 6.77531 10.2353C6.89309 10.3302 7.09595 10.3775 7.38408 10.3775C7.51936 10.3775 7.67327 10.3535 7.8443 10.3063C8.01485 10.2592 8.13922 10.2178 8.21637 10.1816ZM8.35747 1.91167C8.35747 2.25487 8.22813 2.54798 7.96819 2.78899C7.70893 3.03085 7.39651 3.15188 7.03104 3.15188C6.66441 3.15188 6.35123 3.03085 6.089 2.78899C5.82725 2.54788 5.69609 2.25487 5.69609 1.91167C5.69609 1.56914 5.82725 1.27556 6.089 1.03149C6.35075 0.787809 6.66451 0.666016 7.03104 0.666016C7.39642 0.666016 7.70893 0.788096 7.96819 1.03149C8.22832 1.27556 8.35747 1.56924 8.35747 1.91167Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_62_793">
                              <rect
                                width="10.6667"
                                height="10.6667"
                                fill="white"
                                transform="translate(0.667969 0.666016)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <Typography
                        className={classes.typographyHelpTitle}
                        style={{ fontSize: '15px' }}
                      >
                        {t('pages.Testimonials.helpTitle')}
                      </Typography>
                    </div>

                    <div className={classes.helpDescriptionContainer}>
                      <div
                        className={classes.helpDescriptionTypographyContainer}
                      >
                        <div
                          className={classes.descriptionIcon}
                          style={
                            clickedStep1Tutorial
                              ? {
                                  background: '#08E8AF',
                                }
                              : {}
                          }
                        >
                          1
                        </div>
                        <div className={classes.typographyContainer}>
                          <Typography className={classes.typographyHelpTitle}>
                            {t('pages.Testimonials.helpTitleDescription1')}
                          </Typography>{' '}
                          <Typography className={classes.typographyHelp}>
                            {t('pages.Testimonials.helpDescription1')}
                          </Typography>
                        </div>
                      </div>

                      <div
                        className={classes.helpDescriptionTypographyContainer}
                      >
                        <div
                          className={`${classes.descriptionIcon} ${
                            isValidUrl === false
                              ? classes.descriptionIconError
                              : ''
                          }`}
                          style={
                            !clickedStep3Tutorial
                              ? {
                                  background: '#F2994A',
                                }
                              : {
                                  background: '#08E8AF ',
                                }
                          }
                        >
                          2
                        </div>
                        <div className={classes.typographyContainer}>
                          <Typography className={classes.typographyHelpTitle}>
                            {t('pages.Testimonials.helpTitleDescription2')}
                          </Typography>{' '}
                          <Typography className={classes.typographyHelp}>
                            {t('pages.Testimonials.helpDescription2')}
                          </Typography>
                        </div>
                      </div>

                      <div
                        className={classes.helpDescriptionTypographyContainer}
                        style={{ marginBottom: '24px' }}
                      >
                        <div
                          className={classes.descriptionIcon}
                          style={
                            clickedStep2Tutorial
                              ? {
                                  background: '#08E8AF',
                                }
                              : {}
                          }
                        >
                          3
                        </div>
                        <div className={classes.typographyContainer}>
                          <Typography className={classes.typographyHelpTitle}>
                            {t('pages.Testimonials.helpTitleDescription3')}
                          </Typography>{' '}
                          <Typography className={classes.typographyHelp}>
                            {t('pages.Testimonials.helpDescription3')}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classes.helpDescriptionTypographyContainer}
                      style={{ marginBottom: '24px', alignSelf: 'center' }}
                    >
                      <ButtonBase
                        className={classes.buttonHelp}
                        onClick={() => {
                          updateStep();
                        }}
                        style={{ paddingRight: '30px', paddingLeft: '30px' }}
                      >
                        {t('pages.Testimonials.skip')}
                      </ButtonBase>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Dialog>
        {!hideOffer && (
          <UpgradeDialog
            openOfferDialog={openOfferDialog}
            setOpenOfferDialog={setOpenOfferDialog}
          />
        )}

        <Dialog
          open={openMustUpgradeDialog}
          onClose={() => {
            setOpenMustUpgradeDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.offerDialog}
        >
          <div className={classes.mustUpgradeContainer}>
            <Typography className={classes.mustUpgradeTitle}>
              {t('common.mustUpgradeTitlePage')}
            </Typography>
            <Typography className={classes.mustUpgradeDescription}>
              {t('common.maxVideoLength')}
            </Typography>
            <ButtonBase
              className={classes.mustUpgradeButton}
              onClick={() => {
                setOpenMustUpgradeDialog(false);
                setOpenOfferDialog(true);
              }}
            >
              {t('common.upgradeNow')}
            </ButtonBase>
          </div>
        </Dialog>

        <Dialog
          open={openAddTriggerDialog}
          onClose={() => {
            setOpenAddTriggerDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.offerDialog}
        >
          {setting?.displayLocation?.showOnEmbedded ? (
            <div className={classes.mustUpgradeContainer}>
              <Typography className={classes.mustUpgradeTitle}>
                {t('pages.Website.displayLocation.embeddedSettingsDisplay')}
              </Typography>
              <div className={classes.settingsContainer}>
                <Flex
                  style={{
                    gap: '10px',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    className={classes.triggerActionTitle}
                    style={{
                      alignSelf: 'center',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('pages.Settings.testimonialBubbleSize')}
                  </Typography>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={setting.embeddedSettings?.videoSize || 'medium'}
                      onChange={(e) => {
                        setSetting({
                          ...setting,
                          embeddedSettings: {
                            ...setting.embeddedSettings,
                            videoSize: e.target.value,
                          },
                        });
                      }}
                      className={classes.pluginPositionSelector}
                      style={{ width: '130px' }}
                      MenuProps={{
                        sx: {
                          zIndex: 9999,
                        },
                        MenuListProps: {
                          sx: {
                            '& li': {
                              fontFamily: 'Poppins',
                              fontWeight: '400',
                              fontSize: '15px',
                              fontStyle: 'normal',
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value={'small'}>
                        {t('pages.Settings.small')}
                      </MenuItem>
                      <MenuItem value={'medium'}>
                        {t('pages.Settings.medium')}
                      </MenuItem>
                      <MenuItem value={'large'}>
                        {t('pages.Settings.large')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Flex>

                <Flex
                  style={{
                    gap: '10px',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    className={classes.triggerActionTitle}
                    style={{
                      alignSelf: 'center',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('pages.Settings.testimonialBubbleOutline')}
                  </Typography>

                  <TextField
                    id="video-outline"
                    value={setting.embeddedSettings?.videoOutline || ''}
                    onChange={(e) => {
                      setSetting({
                        ...setting,
                        embeddedSettings: {
                          ...setting.embeddedSettings,
                          videoOutline: e.target.value,
                        },
                      });
                    }}
                    className={classes.textFieldStyle}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ButtonBase onClick={handleClickColorPicker}>
                            <ColorLensIcon />
                          </ButtonBase>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    sx={{ width: '130px' }}
                  />
                  <Menu
                    id="color-picker"
                    anchorEl={anchorElColorPicker}
                    open={openColorPicker}
                    onClose={handleCloseColorPicker}
                    className={classes.menuColorPicker}
                    disablePortal
                  >
                    <HexColorPicker
                      color={setting.embeddedSettings?.videoOutline}
                      onChange={(color) => {
                        setSetting({
                          ...setting,
                          embeddedSettings: {
                            ...setting.embeddedSettings,
                            videoOutline: color,
                          },
                        });
                      }}
                    />
                  </Menu>
                </Flex>
              </div>
              <ButtonBase
                className={classes.mustUpgradeButton}
                onClick={() => {
                  saveEmbeddedSettings();
                }}
              >
                ok
              </ButtonBase>
            </div>
          ) : (
            <div className={classes.mustUpgradeContainer}>
              <Typography className={classes.mustUpgradeTitle}>
                {t('pages.Website.trigger.pluginSettingsDisplay')}
              </Typography>
              <div className={classes.triggerContainer}>
                <fieldset className={classes.desktopContainer}>
                  <legend className={classes.legendClasses}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M20.5592 4.45203C20.2655 4.15078 19.9126 4 19.4999 4H4.5C4.08751 4 3.73448 4.15078 3.44057 4.45203C3.14681 4.75338 3 5.11542 3 5.53851V16C3 16.4232 3.14681 16.7852 3.44057 17.0866C3.73448 17.3879 4.08751 17.5384 4.5 17.5384H9.60007C9.60007 17.7819 9.55003 18.0322 9.45001 18.2884C9.35006 18.5448 9.25004 18.7691 9.15002 18.9613C9.05013 19.1538 9.00012 19.2946 9.00012 19.3843C9.00012 19.5509 9.05946 19.6955 9.17823 19.817C9.29693 19.9389 9.43757 20 9.60007 20H14.4001C14.5626 20 14.7032 19.9389 14.8219 19.817C14.9408 19.6955 15.0001 19.551 15.0001 19.3843C15.0001 19.3012 14.95 19.1617 14.85 18.9662C14.75 18.7708 14.65 18.543 14.5501 18.2836C14.4501 18.024 14.4001 17.7756 14.4001 17.5384H19.5002C19.9126 17.5384 20.2656 17.3879 20.5593 17.0866C20.8531 16.7853 21 16.4233 21 16V5.53851C21.0001 5.11542 20.8531 4.75338 20.5592 4.45203ZM19.8 13.5385C19.8 13.6218 19.7703 13.6938 19.7109 13.7548C19.6515 13.8156 19.5812 13.846 19.4999 13.846H4.5C4.41879 13.846 4.34846 13.8156 4.28909 13.7548C4.22975 13.6936 4.20004 13.6218 4.20004 13.5385V5.53851C4.20004 5.45516 4.22966 5.38309 4.28909 5.3222C4.34849 5.26141 4.41883 5.23087 4.5 5.23087H19.5001C19.5813 5.23087 19.6517 5.26131 19.7109 5.3222C19.7703 5.38312 19.8 5.45519 19.8 5.53851V13.5385Z"
                        fill={'#060640'}
                      />
                    </svg>
                  </legend>
                  <div className={classes.triggerAction}>
                    <Switch
                      checked={
                        setting?.trigger?.openTestimonialOnDesktopOnLoad ?? true
                      }
                      onChange={(e) => {
                        if (setting)
                          setSetting({
                            ...setting,
                            trigger: {
                              ...setting.trigger,
                              openTestimonialOnDesktopOnLoad: e.target.checked,
                              openOnScroll: false,
                              timeBeforeOpenOnDesktopState: false,
                            },
                          });
                      }}
                    />
                    <Typography className={classes.triggerActionTitle}>
                      {t('pages.Website.trigger.openOnLoadOnDesktop')}
                    </Typography>
                  </div>

                  <div
                    className={classes.triggerAction}
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div className={classes.actionContainer}>
                      <Switch
                        checked={
                          setting?.trigger?.timeBeforeOpenOnDesktopState ??
                          false
                        }
                        onChange={(e) => {
                          if (setting)
                            setSetting({
                              ...setting,
                              trigger: {
                                ...setting.trigger,
                                timeBeforeOpenOnDesktopState: e.target.checked,
                                openTestimonialOnDesktopOnLoad: false,
                                openOnScroll: false,
                              },
                            });
                        }}
                      />
                      <Typography className={classes.triggerActionTitle}>
                        {t('pages.Website.trigger.timeBeforeOpenOnDesktop')}
                      </Typography>
                    </div>

                    {setting?.trigger?.timeBeforeOpenOnDesktopState && (
                      <div className={classes.actionContainer}>
                        <TextField
                          value={setting?.trigger?.timeBeforeOpenOnDesktop ?? 0}
                          className={classes.triggerField}
                          type="number"
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 1)
                              if (setting)
                                setSetting({
                                  ...setting,
                                  trigger: {
                                    ...setting.trigger,
                                    timeBeforeOpenOnDesktop: isNaN(value)
                                      ? 0
                                      : value,
                                  },
                                });
                          }}
                          InputProps={{
                            inputProps: {
                              min: 2,
                            },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{ marginLeft: '0px' }}
                              >
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className={classes.triggerAction}>
                    <Switch
                      checked={setting?.trigger?.openOnScroll ?? false}
                      onChange={(e) => {
                        if (setting)
                          setSetting({
                            ...setting,
                            trigger: {
                              ...setting.trigger,
                              openOnScroll: e.target.checked,
                              openTestimonialOnDesktopOnLoad: false,
                              timeBeforeOpenOnDesktopState: false,
                            },
                          });
                      }}
                    />
                    <Typography className={classes.triggerActionTitle}>
                      {t('pages.Website.trigger.openOnScroll')}
                    </Typography>
                  </div>

                  <div className={classes.triggerAction}>
                    <Switch
                      checked={setting?.trigger?.openOnClick ?? false}
                      onChange={(e) => {
                        if (setting)
                          setSetting({
                            ...setting,
                            trigger: {
                              ...setting.trigger,
                              openOnClick: e.target.checked,
                            },
                          });
                      }}
                    />
                    <Typography className={classes.triggerActionTitle}>
                      {t('pages.Website.trigger.openOnClick')}
                    </Typography>
                  </div>
                </fieldset>

                <fieldset className={classes.desktopContainer}>
                  <legend className={classes.legendClasses}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="18"
                      viewBox="0 0 12 18"
                      fill="none"
                    >
                      <path
                        d="M10.9556 0.534411C10.593 0.178006 10.1632 0 9.66665 0H2.33345C1.8368 0 1.40717 0.178153 1.04431 0.534411C0.681454 0.890472 0.5 1.31243 0.5 1.79991V16.2C0.5 16.6874 0.681454 17.1093 1.04431 17.4655C1.40717 17.8218 1.8368 18 2.33345 18H9.66665C10.1631 18 10.593 17.8216 10.9556 17.4655C11.3185 17.1093 11.5 16.6874 11.5 16.2V1.79991C11.5 1.31243 11.3187 0.890669 10.9556 0.534411ZM4.85424 1.79991H7.14611C7.29892 1.79991 7.37512 1.87492 7.37512 2.02495C7.37512 2.17493 7.29887 2.25004 7.14611 2.25004H4.85424C4.70148 2.25004 4.62508 2.17493 4.62508 2.02495C4.62503 1.87492 4.70143 1.79991 4.85424 1.79991ZM6.80954 16.9946C6.585 17.2147 6.3153 17.3249 6.0001 17.3249C5.68505 17.3249 5.41535 17.2147 5.19091 16.9946C4.96646 16.7741 4.85424 16.5091 4.85424 16.2C4.85424 15.8905 4.96661 15.6257 5.19091 15.4054C5.4152 15.1853 5.6851 15.0747 6.0001 15.0747C6.31535 15.0747 6.58505 15.1853 6.80954 15.4054C7.03394 15.6257 7.14606 15.8905 7.14606 16.2C7.14606 16.5095 7.03379 16.7741 6.80954 16.9946ZM10.1252 13.9501C10.1252 14.0718 10.0799 14.1777 9.98907 14.2665C9.89812 14.3555 9.79096 14.3998 9.667 14.3998H2.33345C2.20934 14.3998 2.10193 14.3554 2.01123 14.2665C1.92053 14.1777 1.87518 14.0718 1.87518 13.9501V4.05015C1.87518 3.92829 1.92048 3.82274 2.01123 3.73378C2.10198 3.64488 2.20934 3.60011 2.33345 3.60011H9.66665C9.79096 3.60011 9.89822 3.64468 9.98907 3.73378C10.0799 3.82279 10.125 3.92829 10.125 4.05015V13.9501H10.1252V13.9501Z"
                        fill={'#060640'}
                      />
                    </svg>
                  </legend>

                  <div className={classes.triggerAction}>
                    <Switch
                      checked={
                        setting?.trigger?.openTestimonialOnMobileOnLoad ?? false
                      }
                      onChange={(e) => {
                        if (setting)
                          setSetting({
                            ...setting,
                            trigger: {
                              ...setting.trigger,
                              openTestimonialOnMobileOnLoad: e.target.checked,
                              openOnScrollMobile: false,
                              timeBeforeOpenOnMobileState: false,
                            },
                          });
                      }}
                    />
                    <Typography className={classes.triggerActionTitle}>
                      {t('pages.Website.trigger.openOnLoadOnMobile')}
                    </Typography>
                  </div>

                  <div
                    className={classes.triggerAction}
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div className={classes.actionContainer}>
                      <Switch
                        checked={
                          setting?.trigger?.timeBeforeOpenOnMobileState ?? false
                        }
                        onChange={(e) => {
                          if (setting)
                            setSetting({
                              ...setting,
                              trigger: {
                                ...setting.trigger,
                                timeBeforeOpenOnMobileState: e.target.checked,
                                openTestimonialOnMobileOnLoad: false,
                                openOnScrollMobile: false,
                              },
                            });
                        }}
                      />
                      <Typography className={classes.triggerActionTitle}>
                        {t('pages.Website.trigger.timeBeforeOpenOnMobile')}
                      </Typography>
                    </div>

                    {setting?.trigger?.timeBeforeOpenOnMobileState && (
                      <div className={classes.actionContainer}>
                        <TextField
                          value={setting?.trigger?.timeBeforeOpenOnMobile ?? 0}
                          className={classes.triggerField}
                          type="number"
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 1)
                              if (setting)
                                setSetting({
                                  ...setting,
                                  trigger: {
                                    ...setting.trigger,
                                    timeBeforeOpenOnMobile: isNaN(value)
                                      ? 0
                                      : value,
                                  },
                                });
                          }}
                          InputProps={{
                            inputProps: {
                              min: 2,
                            },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{ marginLeft: '0px' }}
                              >
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className={classes.triggerAction}>
                    <Switch
                      checked={setting?.trigger?.openOnScrollMobile ?? false}
                      onChange={(e) => {
                        if (setting)
                          setSetting({
                            ...setting,
                            trigger: {
                              ...setting.trigger,
                              openOnScrollMobile: e.target.checked,
                              openTestimonialOnMobileOnLoad: false,
                              timeBeforeOpenOnMobileState: false,
                            },
                          });
                      }}
                    />
                    <Typography className={classes.triggerActionTitle}>
                      {t('pages.Website.trigger.openOnScrollMobile')}
                    </Typography>
                  </div>
                </fieldset>
              </div>
              <ButtonBase
                className={classes.mustUpgradeButton}
                onClick={() => {
                  saveUrl();
                }}
              >
                ok
              </ButtonBase>
            </div>
          )}
        </Dialog>
      </>
    </>
  ) : (
    <></>
  );
};

export default PageList;
